import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getValue'
})
export class GetValuePipe implements PipeTransform {

    transform(value: string, items: any, keyRequest: string, keyResponse: string): any {
        if (Array.isArray(items)) {
            const data = items.find(item => item[keyRequest] === value);
            if (data) {
                return data[keyResponse];
            }
            return null;
        }
        return null;
    }

}
