import { Component, OnInit, ViewChild, ViewEncapsulation, ElementRef, AfterContentInit, OnChanges, SimpleChanges, AfterContentChecked } from '@angular/core';

@Component({
    selector: 'app-text-truncate',
    template: `<p #content><ng-content></ng-content></p>`,
    styleUrls: ['./text-truncate.component.scss'],
    encapsulation: ViewEncapsulation.None,
    preserveWhitespaces: true
})
export class TextTruncateComponent implements OnInit {
    @ViewChild('content') contentWrapper: ElementRef;
    constructor(
        private elementRef: ElementRef
    ) { }
    // ngAfterContentInit(): void {
    //     // const htmlString = this.elementRef.nativeElement.childNodes[0].innerHTML as string;
    //     // console.log('htmlString', htmlString);
    //     // this.elementRef.nativeElement.childNodes[0].innerHTML = htmlString.trim();
    //     // console.log(this.elementRef.nativeElement.childNodes[0]);

    // }

    ngOnInit(): void {
    }

}
