import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { Observable, from } from 'rxjs';
import { ItemInterface, ItemModel } from '@shared/models/item.model';
import { map } from 'rxjs/operators';
import { ItemPaginationAdapter } from '@app/shared/adapters/item-pagination.adapter';
import { ItemCategoryModel } from '../models/item-category.model';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { ApiOptionInterface } from '@app/core/models/api-option.interface';

@Injectable({
    providedIn: 'root'
})
export class ItemApiService {
    static ITEM_SERVICE = 'items';
    static UPDATE_PRICE = 'items/:itemId/price/:priceId';
    static ADD_PRICE = 'items/:itemId/price';
    static DELETE_PRICE = 'items/:itemId/price/:priceId';
    static DELETE_PHOTO = 'items/:itemId/photo/:photoId';
    static GET_LIST_PRICE_NAME = 'items/price';
    static ITEM_CATEGORY = 'items/categories/realEstate';
    static ITEM_EXPORT = 'items/export';
    static ITEM_IMPORT = 'items/import';
    static ITEM_EXPORT_EXAMPLE_FILE = 'items/import/download-example/propertyHeader';

    constructor(private apiService: ApiService) { }

    create(data): Observable<ItemModel> {
        return this.apiService
            .post(ItemApiService.ITEM_SERVICE, data)
            .pipe(
                map(
                    (res: ItemInterface) => ItemModel.fromJson(res) as ItemModel
                )
            );
    }

    update(data): Observable<any> {
        return this.apiService.put(ItemApiService.ITEM_SERVICE, data, { excludeFields: ['dimension', 'description', 'attachments'] });
    }

    getItems(query = {}, option?: ApiOptionInterface): Observable<ItemPaginationAdapter> {
        return this.apiService
            .get(ItemApiService.ITEM_SERVICE, query, option)
            .pipe(map(data => new ItemPaginationAdapter(data)));
    }

    delete(itemId: string): Observable<any> {
        return this.apiService.delete(
            ItemApiService.ITEM_SERVICE + '/' + itemId
        );
    }

    getItemDetail(itemId: number): Observable<ItemModel> {
        return this.apiService
            .get(ItemApiService.ITEM_SERVICE + '/' + itemId)
            .pipe(map(res => ItemModel.fromJson(res) as ItemModel));
    }

    getCategories(): Observable<ItemCategoryModel> {
        return this.apiService
            .get(ItemApiService.ITEM_CATEGORY)
            .pipe(map(res => ItemCategoryModel.fromJson(res) as ItemCategoryModel));
    }

    export(query) {
        return this.apiService.post(ItemApiService.ITEM_EXPORT, query, {
            pretreatmentResponse: false,
            requestOptions: { responseType: 'arraybuffer' }
        })
            .pipe(
                map(res => {
                    const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    saveAs(blob, `Exported Items ${moment().format('(DD-MM-YYYY)')}.xlsx`);
                    return res;
                })
            );
    }

    import(data: { keyFileName: string }) {
        return this.apiService.post(ItemApiService.ITEM_IMPORT, data);
    }

    exportExampleFile() {
        return this.apiService.get(ItemApiService.ITEM_EXPORT_EXAMPLE_FILE, {}, {
            pretreatmentResponse: false,
            requestOptions: { responseType: 'arraybuffer' }
        })
            .pipe(
                map(res => {
                    const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    saveAs(blob, `example-import-item.xlsx`);
                    return res;
                })
            );
    }
}
