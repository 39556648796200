import {
    OnInit,
    OnChanges,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef,
    SimpleChanges,
    Directive,
    Component
} from '@angular/core';
import { FileUploaderInterface } from './file-uploader.model';
import { UploadDirective } from './upload.directive';

@Component({
    selector: 'app-upload-base',
    template: '<div></div>'
})
export abstract class UploadBaseComponent implements OnInit, OnChanges {
    files = [];
    allFiles: FileUploaderInterface[] = [];
    isFileOver = false;
    isAvailableUploadBtn = false;
    dragDropMode = true;
    previewMimeTypes = [
        'image/jpg',
        'image/jpeg',
        'image/png',
        'image/bmp',
        'image/gif'
    ];
    @Input() existFileKeys: Array<string> = [];
    @Input() uploadToFolder = 'asset';
    @Input() disabled = false;
    @Input() isUploadImmediately = false;
    @Input() message = 'Multiple files format under 15MB each';
    @Input() uploadTitle = 'upload_file.drag_and_drop';
    @Input() maxFiles = 15;
    @Input() maxFileSize = 15 * 1024 * 1024;
    @Input() allowedMimeType: Array<string>;
    @Input() allowExtensions = ['png', 'jpeg', 'jpg'];
    @Input() preview = true;
    @Input() isMultiple = true;
    @Input() isReplaced = false;
    @Input() isHidenButtonClearAll = false;
    @Output() all = new EventEmitter<FileUploaderInterface[]>();
    @Output() added = new EventEmitter<FileUploaderInterface[]>();
    @Output() removed = new EventEmitter<FileUploaderInterface[]>();
    @Output() lastUploaded = new EventEmitter<FileUploaderInterface[]>();
    @Output() begin = new EventEmitter<any>();
    @Output() finished = new EventEmitter<any>();
    @Output() beginFile = new EventEmitter<FileUploaderInterface>();
    @Output() finishedFile = new EventEmitter<FileUploaderInterface>();
    @Output() fullSlot = new EventEmitter<void>();
    @ViewChild(UploadDirective, { static: true }) uploadDirective;
    @ViewChild('input', { static: true }) protected inputElement: ElementRef;

    constructor() {
        this.dragDropMode = this.allFiles.length === 0;
    }

    ngOnInit() { }

    ngOnChanges(changes: SimpleChanges) {
    }

    onFullSlot(): void {
        this.fullSlot.emit();
    }

    onAllFiles(files) {
        this.allFiles = files;
        this.checkPreview();
        this.dragDropMode = this.allFiles.length === 0;
        this.all.emit(this.allFiles);
    }

    onAdded(files) {
        this.isAvailableUploadBtn = true;
        this.added.emit(files);
    }

    onRemoved(files) {
        this.removed.emit(files);
    }

    onLastUpdated(files) {
        this.lastUploaded.emit(files);
    }

    onBegin() {
        this.begin.emit();
    }

    onFinished() {
        this.finished.emit();
    }

    onBeginFile(file) {
        this.beginFile.emit(file);
    }

    onFinishedFile(file) {
        this.finishedFile.emit(file);
    }

    checkPreview() {
        this.allFiles.forEach(file => {
            file.isPreview = this.previewMimeTypes.includes(file.file.type);
        });
    }

    fileDrop(event) {
        this.uploadDirective.onChange(event);
    }

    openSelectBox() {
        this.inputElement.nativeElement.click();
    }

    upload() {
        this.uploadDirective.upload();
    }

    clear() {
        this.uploadDirective.clear();
    }

    deleteFile(id): void {
        this.uploadDirective.deleteFile(id);
    }

    fileOver(isOver) {
        this.isFileOver = isOver;
    }

    loadImgInfo(fileUploader, info) {
        fileUploader.file.size = info.contentLength;
        fileUploader.file.type = info.contentType;
        fileUploader.data = info.objectUrl;
        fileUploader.isPreview = this.previewMimeTypes.includes(
            fileUploader.file.type
        );
    }

    openConfirmationDeleteAttachmentModal(fileUploader) {
        // const self = this;
        // if (fileUploader.completed) {
        //     self.dialog
        //         .open(ConfirmationModalComponent, {
        //             width: getScreenWidth() < SCREENWIDTH.MOBILE ? '100%' : '480px',
        //             height: getScreenWidth() < SCREENWIDTH.MOBILE ? '100%' : '',
        //             data: {
        //                 title: 'Attachment',
        //                 message: 'Do you want to delete this attachment?',
        //                 isShowTagInput: false,
        //                 inputPlaceholder: '',
        //                 value: fileUploader
        //             },
        //             autoFocus: false
        //         })
        //         .afterClosed()
        //         .subscribe((result) => {
        //             if (result && result.isSubmit && result.value) {
        //                 self.deleteFile(result.value);
        //             }
        //         });
        // } else {
        //     self.deleteFile(fileUploader);
        // }
    }

    openConfirmationDeleteAllAttachmentModal() {
        // const self = this;
        // self.dialog
        //     .open(ConfirmationModalComponent, {
        //         width: getScreenWidth() < SCREENWIDTH.MOBILE ? '100%' : '480px',
        //         height: getScreenWidth() < SCREENWIDTH.MOBILE ? '100%' : '',
        //         data: {
        //             title: 'Attachment',
        //             message: 'Do you want to delete all these attachments?',
        //             isShowTagInput: false,
        //             inputPlaceholder: '',
        //             value: ''
        //         },
        //         autoFocus: false
        //     })
        //     .afterClosed()
        //     .subscribe((result) => {
        //         if (result && result.isSubmit) {
        //             self.deleteAll();
        //         }
        //     });
    }

    error() { }
}
