import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { VendorModel } from '../../../shared/models/vendor.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalService } from '@app/core/services/global.service';
import { MetadataApiService } from '@app/shared/services/metadata-api.service';
import { OneMapApiService } from '@app/shared/services/one-map-api.service';
import { AddressInterface } from '@app/shared/models/address.model';
import { UuidService } from '@app/core/services/uuid.service';
import { UploadDirective } from '@app/modules/upload/upload.directive';
import {
    OneMapResultInterface,
    OneMapResultModel,
} from '@app/shared/models/one-map.model';
import { MESSAGE_TYPE } from '@app/shared/models/message.model';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from '@app/core/common/helper';
import { PhoneNumber } from '@app/modules/shared/phone-number/phone-number.component';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { AppConstant } from '@app/app.constant';
import { Subject } from 'rxjs';
import { RedrValidator } from '@app/core/validators/redr.validator';

@Component({
    selector: 'app-vendor-form',
    templateUrl: './vendor-form.component.html',
    styleUrls: ['./vendor-form.component.scss'],
})
export class VendorFormComponent implements OnInit, OnDestroy {
    @Input() item: VendorModel;
    form: FormGroup;
    addressForm: FormGroup;
    contactForm: FormGroup;
    allowedMimeType = UploadDirective.imageMineTypes;
    oneMapResults: OneMapResultInterface[] = [];
    _unsubscribeAll: Subject<void> = new Subject<void>()
    fileUploader;
    constructor(
        private globalService: GlobalService,
        private metadataApiService: MetadataApiService,
        private formBuilder: FormBuilder,
        private oneMapApiService: OneMapApiService,
        private uuidService: UuidService,
        private translateService: TranslateService
    ) {}
    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    ngOnInit(): void {
        this.form = this.createForm(this.item);
        this.addressForm = this.createAddressForm(this.item);
        this.addressForm.controls.enabled.valueChanges
            .pipe(
                tap((val) => {
                    if (!val) {
                        this.addressForm.controls.addressObj.setValue({});
                    }
                }),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe();
        this.contactForm = this.createContactForm(this.item);
        this.contactForm.controls.enabled.valueChanges
            .pipe(
                tap((val) => {
                    if (!val) {
                        (
                            this.contactForm.controls.contact as FormGroup
                        ).reset();
                    }
                }),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe();
        this.fileUploader = { key: this.item?.logo };
    }

    createForm(item?: VendorModel): FormGroup {
        return this.formBuilder.group({
            id: [item?.id],
            name: [item?.name, [Validators.required]],
            code: [item?.code, [Validators.required]],
            website: [item?.website],
            uenNumber: [item?.uenNumber],
            email: [item?.email, [RedrValidator.email]],
            phone: [
                new PhoneNumber({
                    phoneNumber: item?.phone?.phoneNumber,
                    countryCode: item?.phone?.countryCode,
                }),
            ],
            promoGCE: [
                typeof item?.promoGCE === 'boolean' ? item.promoGCE : true,
            ],
            remark: [item?.remark],
        });
    }
    createAddressForm(item?: VendorModel): FormGroup {
        return this.formBuilder.group({
            enabled: [!!item?.address?.postalCode],
            addressObj: [item?.address,[RedrValidator.addressRequired]],
        });
    }
    createContactForm(item?: VendorModel): FormGroup {
        return this.formBuilder.group({
            enabled: [!!item?.contact?.name],
            contact: this.formBuilder.group({
                name: [item?.contact?.name,[Validators.required]],
                email: [item?.contact?.email, [RedrValidator.email]],
                title: [item?.contact?.title],
                phone: [item?.contact?.phone],
            }),
        });
    }
    onFinishedFile(file) {
        if (file.completed) {
            this.fileUploader = file;
        } else {
            this.globalService.message.next({
                type: MESSAGE_TYPE.error,
                message: this.translateService.instant(file.error.message),
            });
        }
    }
    numberOnly(e) {
        return Helper.numberOnly(e);
    }
    displayWith(value: OneMapResultModel): string {
        if (value instanceof OneMapResultModel) {
            return value.POSTAL;
        } else {
            return value;
        }
    }
    customizeFileNameFn(file: File): string {
        return `${this.uuidService.generate()}`;
    }
    get invalid():boolean{
        if(this.form.invalid){
            console.log('form invalid')
            return true;
        }
        const enableAddress = this.addressForm.get('enabled').value
        if(enableAddress && this.addressForm.invalid){
            console.log('address invalid')
            return true
        }
        const enableContact = this.contactForm.get('enabled').value
        if(enableContact && this.contactForm.invalid){
            return true
        }
        return false
    }
    markAllTouched():void{
        this.form.markAllAsTouched();
        const enableAddress = this.addressForm.get('enabled').value
        if(enableAddress ){
            this.addressForm.markAllAsTouched()
        }
        const enableContact = this.contactForm.get('enabled').value
        if(enableContact ){
            this.contactForm.markAllAsTouched()
        }
    }
    getPayload(): Record<string, any> {
        const general = this.form.getRawValue();
        const address = this.addressForm.getRawValue();
        const contactData = this.contactForm.getRawValue();
        const logo = this.fileUploader?.key;
        return { ...general, logo, address: address.addressObj, contact:contactData.contact };
    }
}
