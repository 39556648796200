import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
    url;
    routes = [];

    constructor(private router: Router,
                private location: Location) {
        this.url = location.path();
        this.updateLinks();
    }

    ngOnInit() {
        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd)
            )
            .subscribe(
                (event: NavigationEnd) => {
                    this.url = event.url;
                    this.updateLinks();

                }
            );
    }

    updateLinks() {
        this.routes = [];
        const paths = this.url.split('?')[0].split('/');
        paths.shift();
        paths.forEach((path, i) => {
            let link = '';
            for (let j = 0; j <= i; j++) {
                link = link + '/' + paths[j];
            }
            this.routes.push({
                path: path,
                link
            });
        });
    }
}
