import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import * as moment from 'moment';
import { UAParser } from 'ua-parser-js';
import { PhoneNumber } from '@app/modules/shared/phone-number/phone-number.component';
import { COUNTRY_LIST } from '../data/countries.data';
import { isString } from 'lodash-es';

export class Helper {
    static getScreenWidth() {
        return (
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth
        );
    }

    static parseUrlToRedirectObject(url) {
        let redirectUrl;
        if (url.indexOf('?') >= 0) {
            redirectUrl = url.split('?');
        } else if (url.indexOf(';') >= 0) {
            redirectUrl = url.split(';');
        } else {
            return [url];
        }
        if (redirectUrl[1]) {
            redirectUrl[1] = JSON.parse(
                '{"' +
                    decodeURI(redirectUrl[1])
                        .replace(/"/g, '\\"')
                        .replace(/&/g, '","')
                        .replace(/=/g, '":"') +
                    '"}'
            );
        }
        return redirectUrl;
    }

    static validateEmail(email) {
        // tslint:disable-next-line: max-line-length
        const re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    static customNumberMask(
        decimalLimit,
        includeThousandsSeparator,
        integerLimit,
        suffix?
    ) {
        return createNumberMask({
            prefix: '',
            suffix,
            allowDecimal: true,
            decimalSymbol: '.',
            decimalLimit: decimalLimit,
            includeThousandsSeparator: includeThousandsSeparator,
            integerLimit: integerLimit,
            allowLeadingZeroes: true,
        });
    }

    static removeCommaFromText(txt) {
        let finalString = txt;
        if (txt && Object.prototype.toString.call(txt) === '[object String]') {
            finalString = finalString.replace(/,/g, '');
        }
        return +finalString;
    }

    static removeEmpty(data, exclude: string[] = []) {
        if (!data) {
            return data;
        }
        switch (typeof data) {
            case 'object': {
                if (Array.isArray(data)) {
                    return data.filter(
                        (item) =>
                            !(
                                item === null ||
                                item === undefined ||
                                item === '' ||
                                (Array.isArray(item) && !item.length)
                            )
                    );
                }

                Object.keys(data).forEach((key) => {
                    if (exclude.every(fieldExcluded => fieldExcluded !== key)) {
                        const val = Helper.removeEmpty(data[key], exclude);
                        if (
                            val === null ||
                            val === undefined ||
                            val === '' ||
                            (Array.isArray(val) && !val.length)
                        ) {
                            delete data[key];
                        } else {
                            data[key] = val;
                        }
                    }
                });
                return data;
            }
            case 'string': {
                return data.trim();
            }
            default:
                return data;
        }
    }

    static flatParams(params: object): object {
        if (!params) {
            return params;
        }
        Object.keys(params).forEach((key) => {
            if (Array.isArray(params[key])) {
                params[key] = params[key].join(',');
            }
        });
        return params;
    }

    static getListYearInFuture(
        numberOfYear,
        startYear: number = moment().year()
    ) {
        const years = [];

        for (let i = 0; i <= numberOfYear; i++) {
            years.push(startYear + i);
        }

        return years;
    }

    static currencyNumberMask(
        decimalLimit: number = 2,
        integerLimit: number = 9
    ) {
        return this.customNumberMask(decimalLimit, true, integerLimit);
    }

    static percentageNumberMask(integerLimit: number = 3) {
        return createNumberMask({
            prefix: '',
            suffix: '',
            allowDecimal: true,
            decimalSymbol: '.',
            decimalLimit: 2,
            includeThousandsSeparator: false,
            integerLimit: integerLimit,
            allowLeadingZeroes: true,
        });
    }

    static postalCodeMask() {
        return createNumberMask({
            prefix: '',
            suffix: '',
            includeThousandsSeparator: false,
            allowLeadingZeroes: true,
            integerLimit: 6
        })
    }

    static pad(d) {
        return d < 10 ? '0' + d.toString() : d.toString();
    }

    // https://stackoverflow.com/questions/41465542/angular2-input-field-to-accept-only-numbers
    static numberOnly(event, isAllowPeriod?): boolean {
        const charCode = event.which ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            if (isAllowPeriod && charCode === 46) {
                return true;
            } else {
                return false;
            }
        }
        return true;
    }

    static slugify(input: string): string {
        return input
            .toLowerCase()
            .replace(/\s+/g, '-') // Replace spaces with -
            .replace(/\-\-+/g, '-') // Replace multiple - with single -
            .replace(/^-+/, '') // Trim - from start of text
            .replace(/-+$/, '')
            .replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
            .replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
            .replace(/ì|í|ị|ỉ|ĩ/g, 'i')
            .replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
            .replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
            .replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
            .replace(/đ/g, 'd')
            .replace(/[^\w\-]+/g, ''); // Remove all non-word chars;
    }

    static camelize(input: string): string {
        return Helper.slugify(input)
            .replace(/\-(.)/g, (char) => char.toUpperCase()) // UpperCase first character
            .replace(/-/g, ''); // Remove -
    }

    static generateGUILD() {
        const pad4 = (str) => {
            return '0000'.substring(str.length) + str;
        };
        const hex4 = () => {
            return pad4(
                Math.floor(Math.random() * 0x10000 /* 65536 */).toString(16)
            );
        };
        return (
            hex4() +
            hex4() +
            hex4() +
            hex4() +
            hex4() +
            hex4() +
            hex4() +
            hex4()
        );
    }

    static addCommasToNumber(amount) {
        if (amount || amount === 0) {
            return (
                !isNaN(amount) &&
                amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            );
        } else {
            return false;
        }
    }

    static convertAmountToCurrencyString(amount, currency = '$') {
        return `${currency}${this.addCommasToNumber(
            Number(amount).toFixed(2)
        )}`;
    }

    static shiftDataFromQueues<T>(queues: T[]): T {
        const payload = queues.shift();
        if (payload) {
            return payload;
        } else {
            return queues.length > 0
                ? this.shiftDataFromQueues<T>(queues)
                : null;
        }
    }

    static textCutter(text: string, n: number) {
        let shortText = text.substr(0, n);

        if (/^\S/.test(text.substr(n))) {
            shortText = shortText.replace(/\s+\S*$/, '');
        }

        if (shortText.length < text.length) {
            shortText += '...';
        }
        return shortText;
    }

    static generateRandomGradient() {
        const hexValues = [
            '0',
            '1',
            '2',
            '3',
            '4',
            '5',
            '6',
            '7',
            '8',
            '9',
            'a',
            'b',
            'c',
            'd',
            'e',
        ];

        function populate(a) {
            for (let i = 0; i < 6; i++) {
                const x = Math.round(Math.random() * 14);
                const y = hexValues[x];
                a += y;
            }
            return a;
        }

        const newColor1 = populate('#');
        const newColor2 = populate('#');
        const angle = Math.round(Math.random() * 360);

        return (
            'linear-gradient(' +
            angle +
            'deg, ' +
            newColor1 +
            ', ' +
            newColor2 +
            ')'
        );
    }

    static cloneArrayObject<T>(arr: T[]): T[] {
        return arr.map((item) => {
            return Object.assign({}, item);
        });
    }

    static getRandomStatusColor() {
        const colors = [
            '#6d8e51',
            '#f2b965',
            '#6c90ec',
            '#dc4724',
            '#938bf1',
            '#d796a0',
        ];
        const random = Math.floor(Math.random() * colors.length);
        return colors[random];
    }

    static groupBy(list, keyGetter, converter?) {
        const map = new Map<string,any[]>();
        list.forEach((item) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [converter ? converter(item) : item]);
            } else {
                collection.push(converter ? converter(item) : item);
            }
        });
        return map;
    }

    static isObject(item: any): boolean {
        return item && typeof item === 'object' && !Array.isArray(item);
    }

    static mergeDeep(target: any, source: any): any {
        const output = Object.assign({}, target);
        if (Helper.isObject(target) && Helper.isObject(source)) {
            Object.keys(source).forEach((key) => {
                if (Helper.isObject(source[key])) {
                    if (!(key in target)) {
                        Object.assign(output, { [key]: source[key] });
                    } else {
                        output[key] = Helper.mergeDeep(
                            target[key],
                            source[key]
                        );
                    }
                } else {
                    Object.assign(output, { [key]: source[key] });
                }
            });
        }
        return output;
    }

    static leftJoin(target: any, source: any): any {
        const output = Object.assign({}, target);
        if (Helper.isObject(target) && Helper.isObject(source)) {
            Object.keys(target).forEach((key) => {
                if (Helper.isObject(target[key])) {
                    if (!(key in source)) {
                        Object.assign(output, { [key]: target[key] });
                    } else {
                        output[key] = Helper.mergeDeep(
                            target[key],
                            source[key]
                        );
                    }
                } else {
                    Object.assign(output, {
                        [key]: source[key] || target[key],
                    });
                }
            });
        }
        return output;
    }

    static isEqual(target: any, source: any): boolean {
        const targetType = typeof target;
        const sourceType = typeof source;
        if (targetType !== sourceType) {
            console.log('khac type', target, source);
            return false;
        }
        if (Array.isArray(target) && Array.isArray(source)) {
            const length = target.length;
            if (length !== source.length) {
                console.log('khac len');
                return false;
            }
            let check = true;
            for (let i = 0; i < length; i++) {
                check = Helper.isEqual(target[i], source[i]);
                if (!check) {
                    break;
                }
            }
            return check;
        }

        if (Helper.isObject(target) && Helper.isObject(source)) {
            const targetValue = Object.values(target);
            const sourceValue = Object.values(source);
            return Helper.isEqual(targetValue, sourceValue);
        }

        return target === source;
    }

    static isEqualFields(
        target: any,
        source: any,
        compares: string[] = []
    ): boolean {
        for (let i = 0; i < compares.length; i++) {
            const targetValue = Helper.get(target, compares[i]);
            const sourceValue = Helper.get(source, compares[i]);
            if (targetValue.trim() !== sourceValue.trim()) {
                return false;
            }
        }
        return true;
    }

    static isEmpty(input: any): boolean {
        if (!input) {
            return true;
        }
        if (Array.isArray(input)) {
            return input.length === 0;
        }
        if (typeof input === 'object') {
            return Object.keys(input).length === 0;
        }
        return false;
    }

    static insertAt(str: string, str2: string, index: number) {
        return str ? str.slice(0, index) + str2 + str.slice(index) : str;
    }

    static removeAt(str: string, index: number, num: number = 1) {
        return str ? str.slice(0, index) + str.slice(index + num) : str;
    }

    static get(data: object, path: string): any {
        path = path.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        path = path.replace(/^\./, ''); // strip a leading dot
        const paths = path.split('.');
        for (let i = 0, n = paths.length; i < n; ++i) {
            const k = paths[i];
            if (data && k in data) {
                data = data[k];
            } else {
                return;
            }
        }
        return data;
    }

    static getBrowserInfo(ua: string) {
        return new UAParser(ua);
    }

    static toPhoneNumber(value: any): PhoneNumber {
        if (!value) {
            return null;
        }
        if (value instanceof PhoneNumber) {
            return value;
        }
        if (typeof value === 'string') {
            const data = value.trim().split(' ');
            if (!data) {
                return null;
            }
            return new PhoneNumber({
                countryCode: data[0].replace(/\D/g, ''),
                phoneNumber: data[1],
            });
        }
        if (Array.isArray(value)) {
            return new PhoneNumber({
                countryCode: value[0].replace(/\D/g, ''),
                phoneNumber: value[1],
            });
        }
        if (typeof value === 'object') {
            return new PhoneNumber(value);
        }
        return null;
    }

    static uniqBy(a, key: string | Array<string>) {
        const seen = {};
        return a.filter(function (item) {
            let v = '';
            if (Array.isArray(key)) {
                key.forEach((k) => {
                    v += item[k];
                });
            } else {
                v = item[key];
            }
            return seen.hasOwnProperty(v) ? false : (seen[v] = true);
        });
    }
    static getCountryNameByNationality(nationality:string):string{
        let validCountry = '';
        const validCountryList = Helper.filterCountry(nationality);
        if (validCountryList.length > 0) {
            validCountry = validCountryList[0];
        }
        return validCountry
    }
    static filterCountry(value?: string): string[] {
        const filterValue = value?.toLowerCase();
        const countries = COUNTRY_LIST.map((v) => v.country);
        return countries.filter(
            (option) => option.toLowerCase().indexOf(filterValue) === 0
        );
    }

    static getContentCKEditor(
        ckContent: string,
        options?: {
            trimValue?: boolean,
        }
    ): string {
        if (!isString(ckContent) || !ckContent.length) return '';

        const removeElmHTMLPattern = /<[^>]*>/g;
        const removeSpecHTMLPattern = /&nbsp;/gi;
        const clearHTMLValue = ckContent.replace(removeElmHTMLPattern, '').replace(removeSpecHTMLPattern, ' ');

        if (!!options?.trimValue) {
            const trimValue = clearHTMLValue.trim();
            return trimValue;
        } else {
            return clearHTMLValue;
        }
    }
}
