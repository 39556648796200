import { OnDestroy, OnInit, ViewChild, HostListener, Directive, Component } from '@angular/core';
import {
    FormGroup,
    FormArray,
    FormGroupDirective,
    FormBuilder
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { Router } from '@angular/router';
import { RouterService } from '@app/core/services/router.service';
import { Subject } from 'rxjs';

export interface RequestOption {
    reset?: boolean;
}

@Component({
    selector: 'app-abstract-form',
    template: '<div></div>'
})
export abstract class AbstractFormComponent implements OnInit, OnDestroy {
    protected constructor(
        protected routerService: RouterService,
        protected formBuilder: FormBuilder,
        protected dialog: MatDialog,
        protected router: Router
    ) {
    }

    previousUrl: string;
    form: FormGroup;
    navigateAwaySelection = new Subject<boolean>();

    @ViewChild('ngForm') ngForm: FormGroupDirective;
    /*
     * unsubscribe from all events
     * Set the private defaults
     */
    unsubscribeAll = new Subject<any>();

    abstract canDeactivate(): boolean;


    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any) {
        if (!this.canDeactivate()) {
            $event.returnValue = true;
        }
    }

    scrollToError(querySelectorString = '.ng-invalid[formControlName]'): void {
        const firstElementWithError = document.querySelector(
            querySelectorString
        );
        if (firstElementWithError) {
            firstElementWithError.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }

    getFormArray(formArrayName: string): FormArray {
        return this.form.get(formArrayName) as FormArray;
    }

    abstract createForm(data?: any): FormGroup;

    abstract submit(): void;

    confirm(): void {
        const dialogRef = this.dialog.open(ConfirmModalComponent, {
            width: '410px',
            maxHeight: '900px',
            autoFocus: false,
            disableClose: true,
            data: {
                title: 'confirm.title.navigation',
                message:
                    'confirm.message.leave_page',
                cancelLabel: 'confirm.label.stay_on_page',
                confirmLabel: 'confirm.label.leave_page'
            }
        });

        dialogRef.afterClosed().subscribe(isConfirm => {
            if (isConfirm === undefined) {
                this.navigateAwaySelection.next(false);
            } else {
                this.navigateAwaySelection.next(true);
            }
        });
    }

    getPreviousUrl(): string {
        return this.routerService.getPreviousUrl();
    }

    ngOnInit(): void {
        this.form = this.createForm();
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }
}
