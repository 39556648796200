import { get, find } from 'lodash-es';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'find'
})
export class FindPipe implements PipeTransform {

  transform(value: string | number, source: any[] | Record<string,any>,path:string, keyReturn?:string, defaultValue?:any): unknown {
    const res= find(source,{[path]:value});
    if(res && keyReturn){
      return res[keyReturn]
    }
    return defaultValue || res;
  }

}
