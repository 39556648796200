import { BusinessUnitInterface, BusinessUnitModel } from './business-unit.model';
import { BaseModel } from '@app/core/models/base.model';
import { Expose, Type } from 'class-transformer';
import { OrganizationMetadataInterface, OrganizationMetadataModel } from './metadata.model';
import { SettingModel } from '@shared/models/setting.model';

export interface OrganizationInterface {
    id: string;
    name: string;
    status: string;
    logo: string;
    favicon: string;
    email: string;
    address: string;
    phone: string;
    website: string;
    maximumBusinessUnit: number;
    ownerId: number;
    businessUnits: BusinessUnitInterface[];
    metadata: OrganizationMetadataInterface;
    settings: Array<SettingModel>;
}

export class OrganizationModel extends BaseModel implements OrganizationInterface {
    @Expose()
    id: string;
    @Expose()
    name: string;
    @Expose()
    status: string;
    @Expose()
    logo: string;
    @Expose()
    favicon: string;
    @Expose()
    email: string;
    @Expose()
    address: string;
    @Expose()
    phone: string;
    @Expose()
    website: string;
    @Expose()
    maximumBusinessUnit: number;
    @Expose()
    ownerId: number;
    @Expose()
    @Type(() => BusinessUnitModel)
    businessUnits: BusinessUnitModel[];
    @Expose()
    @Type(() => OrganizationMetadataModel)
    metadata: OrganizationMetadataModel;

    settings: Array<SettingModel>;
}
