import { BaseModel } from '@core/models/base.model';
import { Type } from 'class-transformer';
import { PermissionModel } from '@shared/models/permission.model';
import { Default } from '@core/decorators/default.decorator';
import { GroupModel } from '@shared/models/group.model';
import { UserModel } from '@shared/models/user.model';

export interface GroupUserInterface {
    users: UserModel[];
}

export class GroupUserModel extends GroupModel implements GroupUserInterface {
    @Default([])
    @Type(() => UserModel)
    users: UserModel[];
}
