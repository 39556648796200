import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FirebaseOptions } from "./firebase.interface";
import { FirebaseService } from './firebase.service';

export const FIREBASE_CONFIGS = new InjectionToken<FirebaseOptions>('FIREBASE_CONFIGS')

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class FirebaseModule {
    static forRoot(configs:FirebaseOptions):ModuleWithProviders<FirebaseModule>{
        return {
            ngModule: FirebaseModule,
            providers: [
                {
                    provide: FIREBASE_CONFIGS,
                    useValue: configs
                },
                FirebaseService,


            ]
        };
    }
}
