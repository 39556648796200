import {
    ComponentFactoryResolver,
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    Renderer2,
    ViewContainerRef,
} from '@angular/core';
import { PolicyService } from '@modules/permission/policy.service';
import { Observable } from 'rxjs';
import { Forbidden2Component } from '@modules/shared/forbidden-2/forbidden-2.component';

@Directive({
    selector: '[appPolicy]',
})
export class PolicyDirective implements OnChanges, OnDestroy {
    @Input() policy: string;
    @Input() preAction: Promise<any> | Observable<any> | (() => void);
    @Input() postAction:
        | 'hide'
        | 'delete'
        | 'disable'
        | 'custom'
        | 'message'
        | ((el) => void) = 'delete';
    @Input() customClasses: string | string[];
    @Output() forbidden: EventEmitter<ElementRef> = new EventEmitter();
    @Output() allowable: EventEmitter<ElementRef> = new EventEmitter();

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private policyService: PolicyService,
        private viewContainerRef: ViewContainerRef,
        private componentFactoryResolver: ComponentFactoryResolver
    ) {}

    ngOnChanges(): void {
        this.handle();
    }

    check(policy, args: any[] = []) {
        // console.log(
        //   "policy ---> ",
        //   policy,
        //   this.policyService.can(policy, ...args)
        // );
        return this.policyService.can(policy, ...args);
    }

    parsePolicy() {
        const [policy, params] = this.policy
            ? this.policy.split('|')
            : [undefined, undefined];
        const args = params ? params.split('&') : [];
        return {
            policy: policy,
            args: args,
        };
    }

    async handle() {
        const { policy, args } = this.parsePolicy();
        if (!policy) {
            return;
        }

        await this.doPreAction();
        const checkResult = this.check(policy, args);
        if (!checkResult) {
            this.forbidden.next(this.elementRef);
            if (typeof this.postAction === 'string') {
                switch (this.postAction) {
                    case 'delete': {
                        this.delete();
                        break;
                    }
                    case 'disable': {
                        this.disable();
                        break;
                    }
                    case 'hide': {
                        this.hide();
                        break;
                    }
                    case 'custom': {
                        this.custom();
                        break;
                    }
                    case 'message': {
                        this.message();
                        break;
                    }
                    default: {
                        this.delete();
                    }
                }
            } else if (typeof this.postAction === 'function') {
                this.postAction(this.elementRef);
            }
        } else {
            this.allowable.next(this.elementRef);
        }
    }

    hide() {
        this.elementRef.nativeElement.style.display = 'none';
        this.elementRef.nativeElement.classList.add(this.customClasses);
    }

    disable() {
        this.renderer.setAttribute(
            this.elementRef.nativeElement,
            'disabled',
            'true'
        );
        this.elementRef.nativeElement.style.cursor = 'not-allowed';
        this.elementRef.nativeElement.style.pointerEvents = 'none';
        this.elementRef.nativeElement.classList.add(this.customClasses);
    }

    delete() {
        this.viewContainerRef.clear();
        this.elementRef.nativeElement.remove();
    }

    message() {
        this.viewContainerRef.clear();
        this.viewContainerRef.element.nativeElement.remove();
        const componentFactory =
            this.componentFactoryResolver.resolveComponentFactory(
                Forbidden2Component
            );
        const componentRef =
            this.viewContainerRef.createComponent(componentFactory);
    }

    custom() {
        this.elementRef.nativeElement.classList.add(this.customClasses);
    }

    async doPreAction() {
        if (typeof this.preAction === 'function') {
            this.preAction();
        } else if (typeof this.preAction === 'object') {
            if (this.preAction instanceof Promise) {
                await this.preAction;
            } else if (this.preAction instanceof Observable) {
                await this.preAction.toPromise();
            }
        }
    }

    ngOnDestroy(): void {}
}
