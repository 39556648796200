import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatDialogModule } from "@angular/material/dialog";
import { UploadModule } from "@app/modules/upload/upload.module";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatButtonModule } from "@angular/material/button";
import { SelectTemplateComponent } from "./select-template.component";
import { SelectTemplateDialogService } from "./select-template-dialog.service";
import { SharedComponentModule } from "@app/modules/shared/shared-component.module";
import { RouterModule } from "@angular/router";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSelectModule } from "@angular/material/select";
import { DirectivesModule } from "@app/shared/directives/directives.module";
import {MatListModule} from '@angular/material/list';
import { MatTooltipModule } from "@angular/material/tooltip";
import { TranslateModule } from "@ngx-translate/core";
import { MatTabsModule } from "@angular/material/tabs";
import { PermissionModule } from '@modules/permission/permission.module';
import { DocumentTemplatePolicyCollection } from "@app/modules/document-template/document-template.policy-collection";

@NgModule({
    imports: [
        CommonModule,
        UploadModule,
        MatDialogModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatButtonModule,
        SharedComponentModule,
        DirectivesModule,
        RouterModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatListModule,
        MatTooltipModule,
        TranslateModule,
        MatTabsModule,
        PermissionModule.forChild([
            DocumentTemplatePolicyCollection
        ])
    ],
    declarations: [
        SelectTemplateComponent
    ],
    exports: [
        SelectTemplateComponent
    ],
    providers: [
        SelectTemplateDialogService
    ]
})
export class SelectTemplateDialogModule {
}
