import { DynamicField } from './dynamic-field.model';
import { FormFieldBase } from '@app/core/models/form-field-base.model';
import { Moment } from 'moment';

export class DynamicFormDateTime extends DynamicField {
    controlType = 'date';
    type: string;

    constructor(options: { [x in string]: any } = {}) {
        super(options);
        this.type = options['type'] || '';
        this.config.format = options.config?.format || 'DD/MM/YYYY';
    }
}
