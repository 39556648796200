import { FirebaseModule } from "./shared/firebase/firebase.module";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { AppRouting } from "@app/app.routing";
import { GrecaptchaModule } from "@shared/grecaptcha";
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ModulePreLoading } from "@app/module-pre-loading";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ToastrModule } from "ngx-toastr";
import {
  DateAdapter,
  ErrorStateMatcher,
  MAT_DATE_FORMATS,
  ShowOnDirtyErrorStateMatcher,
} from "@angular/material/core";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import {
  APP_DATE_FORMATS,
  AppMomentDateAdapter,
} from "@core/adapters/datepicker.adapter";
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
} from "@angular/material/form-field";
import { SharedComponentModule } from "@modules/shared/shared-component.module";
import { HttpErrorInterceptor } from "@core/interceptors/http-error.interceptor";
import { OverlayModule } from "@angular/cdk/overlay";
import { MatTooltipModule } from "@angular/material/tooltip";
import { Angulartics2Module } from "angulartics2";
import { ErrorService } from "@core/services/error.service";
import { AppConstant } from "@app/app.constant";
import { RedrConfigModule } from "./modules/layout/redr-config.module";
import { MatSelectModule } from "@angular/material/select";
import { DBConfig, NgxIndexedDBModule } from "ngx-indexed-db";
import { HttpLogInterceptor } from "@core/interceptors/http-log.interceptor";
import { HttpRefreshTokenInterceptor } from "@core/interceptors/http-refresh-token.interceptor";
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular";
import { EncodeHttpParamsInterceptor } from "./core/interceptors/http-encode-url.interceptor";
import { environment } from "environments/environment";
import { RecaptchaInterceptor } from "./core/interceptors/recaptcha.interceptor";
import { RedocConnectModule } from "./shared/redoc-connect/redoc-connect.module";
import { AuthService } from "./modules/auth/auth.service";
import { SSOAuthService } from "./modules/auth/sso-auth.service";
import { GlobalService } from "./core/services/global.service";
import { GlobalSSOService } from "./core/services/global-sso.service";
import { RedMediaWatcherModule } from "./modules/layout/classic-layout/media-watcher";
import { LayoutPublicModule } from "./modules/layout/layout-public/layout-public.module";
import { QuickActionModule } from "./modules/layout/quick-action/quick-action.module";
import { RetryInterceptor } from "./core/interceptors/http-retry.interceptor";
import { DateTimeFormatterModule } from "./modules/shared/datetime-formatter/datetime-formatter.module";
import { ExceljsViewerModule } from "./shared/exceljs-viewer/exceljs-viewer.module";

export function TranslateHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/app/", ".json");
}

// 'legacy' | 'standard' | 'fill' | 'outline'
const formFieldDefault: MatFormFieldDefaultOptions = {
  appearance: "outline",
  floatLabel: "always",
};

const materialProviders = [
  {
    provide: MAT_DATE_FORMATS,
    useValue: APP_DATE_FORMATS,
  },
  {
    provide: DateAdapter,
    useClass: AppMomentDateAdapter,
  },
  {
    provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
    useValue: formFieldDefault,
  },
  {
    provide: ErrorStateMatcher,
    useClass: ShowOnDirtyErrorStateMatcher,
  },
];

const loggerProviders = function () {
  const providers = [];
  if (AppConstant.APP_LOGGER.sentryLog.enable) {
    providers.push({
      provide: Sentry.TraceService,
      deps: [Router],
    });
    providers.push({
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    });
  }
  return providers;
};

const dbConfig: DBConfig = {
  name: "RedocDB",
  version: 1,
  objectStoresMeta: [
    {
      store: "documents",
      storeConfig: { keyPath: "id", autoIncrement: true },
      storeSchema: [
        {
          name: "userId",
          keypath: "userId",
          options: { unique: false },
        },
        {
          name: "documentId",
          keypath: "documentId",
          options: { unique: true },
        },
        {
          name: "content",
          keypath: "content",
          options: { unique: false },
        },
        { name: "key", keypath: "key", options: { unique: false } },
        {
          name: "createdAt",
          keypath: "createdAt",
          options: { unique: false },
        },
        {
          name: "updatedAt",
          keypath: "updatedAt",
          options: { unique: false },
        },
      ],
    },
    {
      store: "templates",
      storeConfig: { keyPath: "id", autoIncrement: true },
      storeSchema: [
        {
          name: "userId",
          keypath: "userId",
          options: { unique: false },
        },
        {
          name: "templateId",
          keypath: "templateId",
          options: { unique: true },
        },
        {
          name: "content",
          keypath: "content",
          options: { unique: false },
        },
        { name: "key", keypath: "key", options: { unique: false } },
        {
          name: "createdAt",
          keypath: "createdAt",
          options: { unique: false },
        },
        {
          name: "updatedAt",
          keypath: "updatedAt",
          options: { unique: false },
        },
      ],
    },
  ],
};

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedComponentModule,
    AppRouting,
    GrecaptchaModule.forRoot({
      siteKey: AppConstant.APP_RECAPTCHA_SITEKEY,
    }),
    Angulartics2Module.forRoot({
      developerMode: AppConstant.APP_DEVELOPER_MODE,
      gst: {
        trackingIds: AppConstant.APP_GOOGLE_ANALYTICS.trackingIds,
        anonymizeIp: true,
      },
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateHttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    QuickActionModule.forRoot(),
    MatProgressBarModule,
    ToastrModule.forRoot({
      maxOpened: 5,
    }),
    OverlayModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatSelectModule,
    RedrConfigModule.forRoot(),
    NgxIndexedDBModule.forRoot(dbConfig),
    FirebaseModule.forRoot(environment.firebaseConfig),
    RedocConnectModule.forRoot(environment.redocConnect),
    RedMediaWatcherModule,
    LayoutPublicModule,
    DateTimeFormatterModule.forRoot(),
    ExceljsViewerModule.forRoot({
      origin: environment.baseUrl,
    }),
  ],
  declarations: [AppComponent],
  providers: [
    ModulePreLoading,
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RecaptchaInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpLogInterceptor,
      multi: true,
    },
    // {
    //     provide: HTTP_INTERCEPTORS,
    //     useClass: EncodeHttpParamsInterceptor,
    //     multi: true
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRefreshTokenInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: RetryInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useClass: ErrorService,
    },
    ...materialProviders,
    ...loggerProviders(),

    {
      provide: AuthService,
      useClass: SSOAuthService,
    },
    {
      provide: GlobalService,
      useClass: GlobalSSOService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
