<div class="app-error-import layout__main-modal-2">
    <img src="../../../../assets/images/icons/illustrations/warning.svg" alt="">
    <div class="dialog-title">
        <div class="title">{{ 'common.confirm' | translate}}</div>
    </div>
    <div class="err-msg">
        We've started importing data into the system.
        <br>
        This may take a while, please come back later.
    </div>
    <div class="dialog-action">

        <button class="btn-ok" type="button" mat-flat-button color="primary" (click)="close()">
            {{ 'common.ok' | translate | uppercase }}
        </button>
    </div>
</div>
