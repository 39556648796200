import { Directive, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { isMobileScreen, isTabletScreen } from "@app/core/common/common.utils";

const POSITION_MAP = {
    center: 'center center',
    centerTop:'center top',
    centerBottom:'center bottom',
    leftCenter: 'left center',
    leftTop:'left top',
    leftBottom:'left bottom',
    rightCenter: 'right center',
    rightTop:'right top',
    rightBottom:'right bottom'
}
@Directive({
    selector: '[appScaleByWindow]',
})
export class ScaleByWindowDirective implements OnInit, OnDestroy {
    @Input('appScaleByWindow') windowMinWidth:number;
    @Input() scalePosition: string = 'center';
    clientWidth:number;
    @Output() scaleRatioChange = new EventEmitter<number>()
    constructor(
        private elementRef:ElementRef
    ){
        console.log('heello ScaleByWindowDirective')
    }
    ngOnDestroy(): void {
        this.scaleRatioChange.complete()
    }
    ngOnInit(): void {
        
        this.calculateScale();
    }
    @HostListener('window:resize', ['$event'])
    onResize(_) {
        this.calculateScale();
    }
    calculateScale(): void {
        
        console.log('calculateScale')
        // this.windowMinWidth =  this.windowMinWidth || this.width
        if (this.windowMinWidth && window.outerWidth < this.windowMinWidth) {
            if(!this.clientWidth){
                this.clientWidth = this.elementRef.nativeElement.clientWidth;
                console.log('this.clientWidth',this.clientWidth)
                
            }
            setTimeout(() => {
                // this.elementRef.nativeElement.style = null;
                const scaleRatio =
                    window.outerWidth < this.windowMinWidth
                        ? window.outerWidth / this.windowMinWidth
                        : 1;
                const heightScale = this.elementRef.nativeElement.clientHeight * scaleRatio;
                // 50 to fix blocked view with bottom bar
                this.elementRef.nativeElement.style.width = this.windowMinWidth +  'px';
                this.elementRef.nativeElement.style.transform = 'scale(' + scaleRatio + ')';
                this.elementRef.nativeElement.style.transformOrigin =POSITION_MAP[this.scalePosition];
                // this.scaleIndex = scaleRatio;
                console.log('this.elementRef.nativeElement.style', this.elementRef.nativeElement.style)
                this.scaleRatioChange.emit(scaleRatio)
            });
        } else {
            const scaleRatio =
                window.outerWidth < this.windowMinWidth
                    ? window.outerWidth / this.windowMinWidth
                    : 1;
                    this.elementRef.nativeElement.style.transform = 'scale(' + scaleRatio + ')';
                    if (this.elementRef.nativeElement.style.removeProperty) {
                        this.elementRef.nativeElement.style.removeProperty('width');
                    } else {
                        this.elementRef.nativeElement.style.removeAttribute('width');
                    }
                   
                    this.scaleRatioChange.emit(scaleRatio)    
            // this.scaleIndex = scaleRatio;
        }
       
    }
}