<mat-form-field class="full-width custom-height-form-field">
    <mat-icon matPrefix>search</mat-icon>
    <input
        #inputSearch
        matInput
        aria-label="State"
        [matAutocomplete]="auto"
        [formControl]="clientCtrl"
        type="text"
        placeholder="Search..."
        #autocompleteTrigger="matAutocompleteTrigger"
    />
    <button
        class="btn-clear"
        *ngIf="inputSearch.value"
        mat-icon-button
        matSuffix
        (click)="clear($event)"
    >
        <mat-icon>clear</mat-icon>
    </button>
    <mat-autocomplete
        #auto="matAutocomplete"
        [displayWith]="displayWith"
        class="redr-autocomplete"
    >
        <div class="autocomplete-content">
            <mat-option
                *ngFor="let client of filters"
                [value]="client"
                [disabled]="optionDisabled.isSelected(client.id)"
            >
                <img
                    class="example-option-img"
                    [key]="
                        client.isCompany()
                            ? client.logo
                            : client.mainContact?.avatar
                    "
                    appLoadImage
                    [enableLoader]="false"
                    height="25"
                    width="25"
                />
                <span>{{ client.name }}</span> |
                <small>{{ client.type | titlecase }}</small>
            </mat-option>
        </div>

        <div
            class="autocomplete-action"
            style="height: unset"
            *ngIf="allowCreate && partyProfile !== 'ecdd'"
        >
            <mat-option style="line-height: unset; padding: 0">
                <button
                    type="button"
                    mat-flat-button
                    [matMenuTriggerFor]="menu"
                    (click)="$event.stopPropagation()"
                    class="full-width"
                    color="primary"
                >
                    <div
                        fxLayout="row"
                        fxLayoutAlign="start center"
                        fxLayoutGap="8px"
                    >
                        <mat-icon> add_circle </mat-icon>
                        <div>{{ 'common.add_new' | translate }}</div>
                    </div>
                </button>
                <mat-menu #menu="matMenu">
                    <button
                        mat-menu-item
                        (click)="
                            autocompleteTrigger.closePanel(); createIndividual()
                        "
                    >
                        {{ 'common.individual' | translate }}
                    </button>
                    <button
                        mat-menu-item
                        (click)="
                            autocompleteTrigger.closePanel(); createCompany()
                        "
                    >
                        {{ 'common.company' | translate }}
                    </button>
                </mat-menu>
            </mat-option>
        </div>
    </mat-autocomplete>
</mat-form-field>
