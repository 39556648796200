
<div class="layout__main-modal">
    <div  class="dialog-title">
        <div>{{ item ? 'Edit Vendor' : 'Create New Vendor'}}</div>
        <button type="button" mat-icon-button (click)="close()">
            <mat-icon class="icon-close">close</mat-icon>
        </button>
    </div>
    <mat-dialog-content class="dialog-content">
        <app-vendor-form [item]="item" class="flex flex-col flex-auto"></app-vendor-form>
    </mat-dialog-content>
        
    <mat-dialog-actions class="dialog-actions flex flex-row justiry-end items-center gap-4" >
        <button type="button" (click)="close()" mat-button  >
            Cancel
        </button>
        <button type="button" (click)="save()" mat-flat-button color="primary" >
            Save
        </button>
    </mat-dialog-actions>
</div>