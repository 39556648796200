

import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-import-success',
    templateUrl: './import-success-modal.component.html',
    styleUrls: ['./import-success-modal.component.scss']
})

export class ImportSuccessModalComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<ImportSuccessModalComponent>) {
    }

    ngOnInit() { }

    close() {
        this.dialogRef.close();
    }
}
