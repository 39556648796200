<div class="layout__main-modal">
    <div class="dialog-title">
        <div>{{ (client ? 'customer.edit_individual' : 'customer.create_new_individual') | translate}}</div>
        <button type="button" mat-icon-button (click)="close()">
            <mat-icon class="icon-close">close</mat-icon>
        </button>
    </div>
    <mat-dialog-content class="dialog-content">
        <app-client-individual-form (requestSubmit)="submit($event)" [client]="client"></app-client-individual-form>
    </mat-dialog-content>
        
    <mat-dialog-actions class="dialog-actions" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px">
        <button type="submit" mat-flat-button color="primary" form="my-form" >
            {{ 'common.save_changes' | translate }}
        </button>
    </mat-dialog-actions>
</div>
