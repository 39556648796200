import { Injectable } from '@angular/core';
import { PermissionService } from '@modules/permission/permission.service';
import { Observable } from 'rxjs';
import { Policies } from '@modules/permission/policy.constant';

@Injectable()
export class BasePolicyCollection {
    policyCollectionKey = 'base';

    constructor(protected permissionService: PermissionService) {}

    create(): boolean | Promise<boolean> | Observable<boolean> {
        
        return (
            (this.permissionService.hasPolicy(
                this.policyCollectionKey + '::create'
            ) ||
                this.permissionService.hasPolicy(
                    this.policyCollectionKey + '::createOwner'
                )) &&
            this.permissionService.hasPermissions(
                Policies[this.policyCollectionKey].create
            )
        );
    }

    update(): boolean | Promise<boolean> | Observable<boolean> {
        return (
            (this.permissionService.hasPolicy(
                this.policyCollectionKey + '::update'
            ) ||
                this.permissionService.hasPolicy(
                    this.policyCollectionKey + '::updateOwner'
                )) &&
            this.permissionService.hasPermissions(
                Policies[this.policyCollectionKey].update
            )
        );
    }

    updateOwner(id): boolean | Promise<boolean> | Observable<boolean> {
        return (
            (this.permissionService.hasPolicy(
                this.policyCollectionKey + '::update'
            ) ||
                (this.permissionService.hasPolicy(
                    this.policyCollectionKey + '::updateOwner'
                ) &&
                    +id === this.permissionService.user.id)) &&
            this.permissionService.hasPermissions(
                Policies[this.policyCollectionKey].update
            )
        );
    }

    view(): boolean | Promise<boolean> | Observable<boolean> {
        // return true;
        return (
            (this.permissionService.hasPolicy(
                this.policyCollectionKey + '::view'
            ) ||
                this.permissionService.hasPolicy(
                    this.policyCollectionKey + '::viewOwner'
                )) &&
            this.permissionService.hasPermissions(
                Policies[this.policyCollectionKey].view
            )
        );
    }

    delete(): boolean | Promise<boolean> | Observable<boolean> {
        return (
            this.permissionService.hasPolicy(
                this.policyCollectionKey + '::delete'
            ) &&
            this.permissionService.hasPermissions(
                Policies[this.policyCollectionKey].delete
            )
        );
    }

    deleteOwner(id): boolean | Promise<boolean> | Observable<boolean> {
        return (
            (this.permissionService.hasPolicy(
                this.policyCollectionKey + '::delete'
            ) ||
                (this.permissionService.hasPolicy(
                    this.policyCollectionKey + '::deleteOwner'
                ) &&
                    +id === this.permissionService.user.id)) &&
            this.permissionService.hasPermissions(
                Policies[this.policyCollectionKey].delete
            )
        );
    }

    accessUrl() {
        return this.view() || this.create() || this.update();
    }
}
