import { transformPropertyAddrObjectToString } from '@app/core/common/common.utils';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'fullAddress'
})
export class FullAddressPipe implements PipeTransform {
    transform(value: any): string {
        if (!value) {
            return '';
        }
        return transformPropertyAddrObjectToString(value);
    }
}
