import {
  Component,
  ContentChild,
  Inject,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Subject } from "rxjs";
import {
  RedNavigationService,
  VerticalNavigationComponent,
  VerticalNavigationAppearance,
  NavigationItem,
} from "./navigation";
import { ActivatedRoute, Router } from "@angular/router";
import { RedMediaWatcherService } from "./media-watcher";
import { NavigationGroup } from "./navigation/navigation.types";
import {
  NavigationBaseService,
  NAVIGATION_SERVICE,
} from "./navigation/navigation.service";
import { GroupActionHeaderDirective } from "./group-action-header.directive";
import { takeUntil } from "rxjs/operators";
import { LogoHeaderDirective } from "./logo-header.directive";

@Component({
  selector: "red-classic-layout",
  templateUrl: "./classic-layout.component.html",
  styleUrls: ["./classic-layout.component.scss"],
})
export class ClassicLayoutComponent implements OnInit, OnDestroy {
  @Input() hideHeader = false;
  @Input() hideNav = false;
  isScreenSmall!: boolean;
  navigationAppearance: VerticalNavigationAppearance = "default";
  naviagtionMode: "over" | "side" = "side";
  navigation!: NavigationGroup;
  navigationSelected: NavigationItem[] = [];
  /** Content for the tab label given by `<ng-template redGroupActionHeader>`. */
  @ContentChild(GroupActionHeaderDirective)
  get templateLabel(): GroupActionHeaderDirective | undefined {
    return this._templateLabel;
  }
  set templateLabel(value: GroupActionHeaderDirective | undefined) {
    this._templateLabel = value;
  }

  private _templateLabel?: GroupActionHeaderDirective;
  private _unsubscribeAll: Subject<void> = new Subject<void>();
  @ContentChild(LogoHeaderDirective)
  get logoLabel(): LogoHeaderDirective | undefined {
    return this._logoLabel;
  }
  set logoLabel(value: LogoHeaderDirective | undefined) {
    this._logoLabel = value;
  }
  private _logoLabel?: LogoHeaderDirective;
  /**
   * Constructor
   */
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    @Inject(NAVIGATION_SERVICE)
    private _navigationService: NavigationBaseService,
    private _redNavigationService: RedNavigationService,
    private _mediaWatcherService: RedMediaWatcherService
  ) {}
  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to navigation data
    this._navigationService.navigation$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((navigation: NavigationGroup) => {
        this.navigation = navigation;
      });

    // Subscribe to media changes
    this._mediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases, matchingQueries }) => {
        // Check if the screen is small
        const isMobileScreen = matchingAliases.length === 0;
        if (isMobileScreen) {
          this.naviagtionMode = "over";
          this.navigationAppearance = "default";
        } else {
          this.naviagtionMode = "side";
          this.isScreenSmall =
            matchingAliases.length === 0 || !matchingAliases.includes("lg");
          this.navigationAppearance = this.isScreenSmall ? "dense" : "default";
        }

        this._redNavigationService
          .getComponent<VerticalNavigationComponent>("mainNavigation")
          ?.closeAside();
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptionsß
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
  /**
   * Toggle navigation
   *
   * @param name
   */
  toggleNavigation(name: string): void {
    // Get the navigation
    const navigation =
      this._redNavigationService.getComponent<VerticalNavigationComponent>(
        name
      );

    if (navigation) {
      // Toggle the opened status
      navigation.toggle();
    }
  }
  /**
   * Toggle the navigation appearance
   */
  toggleNavigationAppearance(): void {
    console.log("toggleNavigationAppearance", this.navigationAppearance);
    this.navigationAppearance =
      this.navigationAppearance === "default" ? "dense" : "default";
  }
}
