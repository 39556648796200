<form [formGroup]="form" class="flex" [class.flex-row]="layout === 'row'" [class.flex-col]="layout === 'column'"  >
    <mat-form-field class="full-width">
        <mat-label>{{
            dateLabel | translate | titlecase
    }}</mat-label>
        <input matInput [matDatepicker]="datePicker" type="text" formControlName="date"
               placeholder="{{datePlaceholder | translate | titlecase}}" (click)="datePicker.open()" readonly
               [required]="required">
        <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
        <mat-datepicker #datePicker></mat-datepicker>
        <mat-error *ngIf="form.controls.date.hasError('required')">
            {{ 'validator.required' | translate}}</mat-error>
    </mat-form-field>
    <mat-form-field class="full-width">
        <mat-label>{{
        timeLabel | translate | titlecase
    }}</mat-label>
        <input [ngxTimepicker]="timePicker" type="text" formControlName="time" matInput
               placeholder="{{timePlaceholder | translate | titlecase}}" readonly [required]="required" />
        <ngx-material-timepicker-toggle matSuffix [for]="timePicker"></ngx-material-timepicker-toggle>
        <ngx-material-timepicker #timePicker [appendToInput]="true"></ngx-material-timepicker>
        <mat-error *ngIf="form.controls.time.hasError('required')">
            {{ 'validator.required' | translate}}</mat-error>
    </mat-form-field>
</form>
