import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UploadComponent } from './upload.component';
import { UploadDirective } from './upload.directive';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PipesModule } from '@shared/pipes/pipes.module';
import { DirectivesModule } from '@shared/directives/directives.module';
import { UploadImageComponent } from './upload-image/upload-image.component';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        PipesModule,
        DirectivesModule,
        MatIconModule,
        MatButtonModule,
        MatProgressBarModule,
        FlexLayoutModule
    ],
    declarations: [
        UploadComponent,
        UploadDirective,
        UploadImageComponent
    ],
    exports: [
        UploadComponent,
        UploadImageComponent,
        UploadDirective,
    ]
})
export class UploadModule {
}
