import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { Observable } from 'rxjs';
import { SubscriptionModel, SubscriptionPlanModel } from '../models/subscription.model';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionApiService {
    static SUBSCRIPTION_PLAN = 'subscriptions/subscribe';
    static CONFIRM_SUBSCRIPTION_PLAN = 'subscriptions/confirm';
    static GET_PUBLIC_PLAN = 'subscriptions-plans/public';
    static MY_SUBSCRIPTION = 'subscriptions/me';
    static CHANGE_PLAN_DETAIL = 'subscriptions/:subscriptionId';
    static CANCEL_SUBSCRIPTION = 'subscriptions/cancel';
    static REACTIVE_SUBSCRIPTION = 'subscriptions/active';
    static GET_BILLING = 'subscriptions/:subscriptionId/billing';
    static CHANGE_OTHER_PLAN = 'subscriptions/change';

    constructor(
        private apiService: ApiService
    ) {
    }

    subscripbe(data: { planId: number; ownerId: number; interval: string }) {
        return this.apiService.post(SubscriptionApiService.SUBSCRIPTION_PLAN, data);
    }

    confirm(data: { id: number; internalTxId: string }) {
        return this.apiService.post(SubscriptionApiService.CONFIRM_SUBSCRIPTION_PLAN, data);
    }

    getPublicPlan(): Observable<SubscriptionPlanModel[]> {
        return this.apiService.get(SubscriptionApiService.GET_PUBLIC_PLAN)
            .pipe(map((rs) => rs.map(v => SubscriptionPlanModel.fromJson(v))));
    }

    getMySubscription(): Observable<SubscriptionModel[]> {
        return this.apiService.get(SubscriptionApiService.MY_SUBSCRIPTION)
            .pipe(map((rs) => rs.map(v => SubscriptionModel.fromJson(v))));
    }

    changPlanDetail(data: { subscriptionId: number; interval: string }) {
        return this.apiService.put(SubscriptionApiService.CHANGE_PLAN_DETAIL, data);
    }

    cancel(data: { id: number; uuid: string; confirm: string }) {
        return this.apiService.post(SubscriptionApiService.CANCEL_SUBSCRIPTION, data);
    }

    reactive(data: { id: number; uuid: string; confirm: string }) {
        return this.apiService.post(SubscriptionApiService.REACTIVE_SUBSCRIPTION, data);
    }

    getBilling(data: { subscriptionId: number }) {
        return this.apiService.get(SubscriptionApiService.GET_BILLING, data);
    }

    changeOtherPlan(data: { subscriptionId: number; planId: number; interval?: string }) {
        return this.apiService.post(SubscriptionApiService.CHANGE_OTHER_PLAN, data);
    }
}
