export class UtilValidator {
    static assertString(input: string) {
        const isString = typeof input === 'string';

        if (!isString) {
            let invalidType;
            if (input === null) {
                invalidType = 'null';
            } else {
                invalidType = typeof input;
                if (invalidType === 'object' && input.constructor && input.constructor.hasOwnProperty('name')) {
                    invalidType = input.constructor.name;
                } else {
                    invalidType = `a ${invalidType}`;
                }
            }
            throw new TypeError(`Expected string but received ${invalidType}.`);
        }

    }
    static merge(obj = {}, defaults: object) {
        for (const key in defaults) {
            if (typeof obj[key] === 'undefined') {
                obj[key] = defaults[key];
            }
        }
        return obj;
    }
}
