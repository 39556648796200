<div class="flex flex-col flex-auto relative">
    <div
        class="sticky z-10 top-0 border-b flex flex-col w-full"
        *ngIf="!hideHeader"
    >
        <div class="flex flex-row items-center max-h-11 h-11 bg-white">
            <div class="flex flex-row items-center">
                <ng-container *ngTemplateOutlet="contentHeaderLogo">
                </ng-container>
            </div>

            <div class="flex flex-row items-center flex-auto gap-6">
                <ng-template [ngIf]="templateLabel">
                    <ng-template
                        [cdkPortalOutlet]="templateLabel"
                    ></ng-template>
                </ng-template>
            </div>
        </div>
        <!-- <ng-container *ngTemplateOutlet="contentHeader">
    </ng-container> -->
    </div>
    <div class="flex z-0 flex-row flex-auto">
        <!-- Navigation -->
        <red-vertical-navigation
            *ngIf="navigation && !hideNav"
            class="dark bg-gray-900 print:hidden"
            [appearance]="navigationAppearance"
            [mode]="naviagtionMode"
            [name]="'mainNavigation'"
            [navigation]="navigation.default"
            [autoCollapse]="false"
            [opened]="naviagtionMode !== 'over'"
        >
            <!-- Navigation header hook -->
            <ng-container redocVerticalNavigationHeader>
                <div class="sm:hidden">
                    <ng-container *ngTemplateOutlet="contentHeaderLogo">
                    </ng-container>
                </div>
            </ng-container>
        </red-vertical-navigation>
        <!-- Content -->
        <div class="flex flex-col flex-auto">
            <div class="flex flex-col flex-auto layout-content">
                <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
       Otherwise, layout changes won't be registered and the view won't be updated! -->
                <router-outlet *ngIf="true"></router-outlet>
            </div>
        </div>
    </div>
</div>

<ng-template #contentHeaderLogo>
    <div class="flex flex-row items-center gap-4 h-11 bg-white text-theme-dark">
        <!-- <div> -->
        <!-- <div class="h-10 border-r flex flex-row items-center">
      <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
        <mat-icon>menu</mat-icon>
      </button>
    </div> -->
        <div
            *ngIf="navigation"
            class="h-11 min-w-14 border-r flex flex-row justify-center items-center"
        >
            <button
                mat-icon-button
                (click)="toggleNavigation('mainNavigation')"
            >
                <mat-icon>menu</mat-icon>
            </button>
        </div>
        <!-- </div> -->
        <ng-template [ngIf]="logoLabel">
            <a
                class="flex flex-row"
                [routerLink]="['']"
                [class.ml-4]="!navigation"
            >
                <div class="h-6 max-w-24">
                    <ng-template [cdkPortalOutlet]="logoLabel"></ng-template>
                </div>
            </a>
        </ng-template>
    </div>
</ng-template>
