import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'transactionSubType'})
export class TransactionSubTypePipe implements PipeTransform {

    transform(value) {
        switch (value) {
            case 'newLease':
                return 'case_submission.transaction_sub_type.new_Lease';
            case 'extensionOfLease':
                return 'case_submission.transaction_sub_type.extension_of_lease';
            case 'assignmentOfLease':
                return 'case_submission.transaction_sub_type.assignment_of_lease';
            default:
                return value;
        }
    }
}

@Pipe({name: 'marketingRights'})
export class MarketingRightsPipe implements PipeTransform {

    transform(value) {
        switch (value) {
            case 'nonExclusive':
                return 'case_submission.marketing_rights.non_exclusive';
            case 'exclusive':
                return 'case_submission.marketing_rights.exclusive';
            default:
                return value;
        }
    }
}

@Pipe({name: 'leaseStatus'})
export class LeaseStatusPipe implements PipeTransform {

    transform(value) {
        switch (value) {
            case 'expired':
                return 'case_submission.lease_status.expired';
            case 'active':
                return 'case_submission.lease_status.active';
            default:
                return value;
        }
    }
}

@Pipe({name: 'leaseDurationUnit'})
export class LeaseDurationUnitPipe implements PipeTransform {

    transform(value) {
        switch (value) {
            case 'years':
                return 'case_submission.lease_duration_unit.years';
            case 'months':
                return 'case_submission.lease_duration_unit.months';
            case 'days':
                return 'case_submission.lease_duration_unit.days';
            default:
                return value;
        }
    }
}