import { Component, OnInit, ViewChild, ElementRef, Input, ViewEncapsulation } from '@angular/core';
import { UploadBaseComponent } from '../upload-base.component';

@Component({
    selector: 'app-upload-image',
    templateUrl: './upload-image.component.html',
    styleUrls: ['./upload-image.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class UploadImageComponent extends UploadBaseComponent implements OnInit {
    @Input() title;
    @Input() isShowHeader = true;
    @Input() isShowMaximunFileText = true;
    @Input() uploadTitle;

    constructor() {
        super();
    }

}
