import { BaseModel } from '@app/core/models/base.model';
import { Type } from 'class-transformer';
import { GroupUserInterface, GroupUserModel } from './group-user.model';
import { UserInterface, UserModel } from './user.model';

export interface ViewGroupInterface {
    id: number;
    name: string;
    slug: string;
    uuid: string;
    description: string;
    status: string;
    createdById: string;
    metadata: any;
    groups: GroupUserInterface[];
    users: UserInterface[];
}

export class ViewGroupModel extends BaseModel implements ViewGroupInterface {
    id: number;
    name: string;
    slug: string;
    uuid: string;
    description: string;
    status: string;
    createdById: string;
    metadata: any;

    @Type(() => GroupUserModel)
    groups: GroupUserModel[];

    @Type(() => UserModel)
    users: UserModel[];
}
