import { AttributeInsertExecutePayload, CkeditorPayload } from '@app/shared/models/ckeditor-payload.model';
import { ContentBuilderFieldType } from '../types/content-builder-field-type';
import { pick,isEqual } from 'lodash-es';

export class FormFieldBase {
    key: string;
    label: string;
    required: boolean;
    readonly: boolean;
    disable: boolean;
    order: number;
    controlType: string;
    fieldType: ContentBuilderFieldType;
    type: string;
    options: string[];
    count?: number;
    config: any;

    constructor(options: {
        key?: string,
        label?: string,
        required?: boolean,
        readonly?: boolean;
        disable?: boolean;
        order?: number,
        controlType?: string,
        fieldType?: ContentBuilderFieldType,
        type?: string,
        count?: number;
        config?: any
    } = {}) {
        this.key = options.key || '';
        this.label = options.label || '';
        this.required = !!options.required;
        this.readonly = !!options.readonly;
        this.disable = !!options.disable;
        this.order = options.order === undefined ? 1 : options.order;
        this.controlType = options.controlType || '';
        this.fieldType = options.fieldType || 'dynamicField';
        this.type = options.type || '';
        this.config = options.config || {};
        this.count = typeof options.count === 'number' ? options.count : null;
    }

    getPlaceHolder(): string {
        return `<div class="fake-label">${this.label}</div>`;
    }

    getPlaceHolderForDownload(): string {
        let str = '';
        for(let i = 0; i < this.label.length; i++) {
            str += '_';
        }

        return `<span style="word-break: break-all">${str}</span>`;
    }

    getMarkerName(): string {
        return '';
    }

    getAttributePayloadForCk(): { attributeKey: string; value: string }[] {
        return [];
    }

    getCkAlias(keyName: string): string {
        return keyName;
    }

    getCommandPayloadToUpdateAttribute(data: any): AttributeInsertExecutePayload {
        return null;
    }
    getCommandPayloadToCreate(value: string): CkeditorPayload {
        return null;
    }
    
    isEqual(data:Partial<FormFieldBase>):boolean{
        const availableKeys = Object.keys(this)
        const currValue = pick(this,availableKeys);
        const newValue = pick(data,availableKeys);
        return isEqual(currValue,newValue)

    }
}
