import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AppConstant } from '@app/app.constant';
import { OrganizationApiService } from '@shared/services/organization-api.service';
import { GlobalService } from '../services/global.service';
import { map, catchError } from 'rxjs/operators';
import { AuthService } from '@app/modules/auth/auth.service';
import { Location } from '@angular/common';
import { QuickActionService } from '@app/modules/layout/quick-action/quick-action.service';

@Injectable({
    providedIn: 'root'
})
export class QuickActionResolver implements Resolve<any> {
    constructor(
        private router: Router,
        private quickActionService: QuickActionService,
    ) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return this.quickActionService.get()
    }
}
