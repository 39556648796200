import { Pipe, PipeTransform } from "@angular/core";
import { PolicyService } from "./policy.service";

@Pipe({
  name: "hasPolicy",
})
export class HasPolicyPipe implements PipeTransform {
  constructor(private policyService: PolicyService) {}
  transform(policy: string | string[]) {
    const policies = Array.isArray(policy) ? policy : [policy];
    return policies.some((item) => this.policyService.can(item));
  }
}
