import { BaseModel } from '@app/core/models/base.model';
import { Expose, Type } from 'class-transformer';

export type PlanStatus = 'draft' | 'activated' | 'deactivated';
export type SubscriptionType = 'auto' | 'manual';

export interface SubscriptionPlanInterface {
    id: number;
    name: string;
    slug: string;
    description: string;
    price: number;
    attrs: SubscriptionPlanAttrsInterface;
    uuid: string;
    createdAt: string;
    updatedAt: string;
    prices: {
        month: number;
        year: number;
    };
    status: PlanStatus;
    isPublished: boolean;
    trialPeriod: number;
}

export class SubscriptionPlanModel
    extends BaseModel
    implements SubscriptionPlanInterface {
    id: number;
    name: string;
    slug: string;
    description: string;
    price: number;
    @Type(() => SubscriptionPlanAttrsModel)
    attrs: SubscriptionPlanAttrsModel;
    uuid: string;
    createdAt: string;
    updatedAt: string;

    status: PlanStatus;
    prices: {
        month: number;
        year: number;
    };
    isPublished: boolean;
    trialPeriod: number;

    @Expose()
    get isSettingPrice(): boolean {
        return (this.prices?.month > 0 && this.prices?.year > 0);
    }

    @Expose()
    get pricePerMonthOfYear(): number {
        if (this.prices && this.prices?.year) {
            return this.prices.year / 12;
        }

        return 0;
    }
}


export interface SubscriptionPlanAttrsInterface {
    maxUsers: number;
    group: string;
    intro: string[];
    photo: string;
    isPopular: boolean;
}

export class SubscriptionPlanAttrsModel
    extends BaseModel
    implements SubscriptionPlanAttrsInterface {
    maxUsers = 0;
    group: string;
    intro: string[];
    photo: string;
    isPopular: boolean;
}

export interface SubscriptionInterface {
    id: number;
    name: string;
    status: string;
    type: SubscriptionType;
    amount: number;
    interval: string;
    organizationId: number;
    ownerId: number;
    paymentMethodId: number;
    paymentTxId: number;
    planId: number;
    startAt: string;
    endAt: string;
    cancelAt: string;
    canceledAt: string;
    trialEndAt: string;
    deletedAt: string;
    createdAt: string;
    updatedAt: string;
    renewAt: string;
    renewPlanId: number;
    uuid: string;
    plan: SubscriptionPlanInterface;
}

export class SubscriptionModel
    extends BaseModel
    implements SubscriptionInterface {
    id: number;
    name: string;
    status: string;
    type: SubscriptionType;
    amount: number;
    interval: string;
    organizationId: number;
    ownerId: number;
    paymentMethodId: number;
    paymentTxId: number;
    planId: number;
    startAt: string;
    endAt: string;
    cancelAt: string;
    canceledAt: string;
    trialEndAt: string;
    deletedAt: string;
    createdAt: string;
    updatedAt: string;
    renewAt: string;
    renewPlanId: number;
    uuid: string;
    @Type(() => SubscriptionPlanModel)
    plan: SubscriptionPlanModel;

    get isFreePlan(): boolean {
        if (this.type === 'auto' && this.amount === 0) {
            return true;
        }

        return false;
    }
}
