import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'gender'})
export class GenderPipe implements PipeTransform {
    transform(value) {
        switch (value) {
            case 'male':
                return 'Male';
            case 'female':
                return 'Female';
            case 'other':
                return 'Other';
            default:
                return value;
        }
    }
}

@Pipe({name: 'residencyStatus'})
export class residencyStatusPipe implements PipeTransform {
    transform(value) {
        switch (value) {
            case 'citizen':
                return 'Citizen';
            case 'pr':
                return 'PR';
            case 'foreigner':
                return 'Foreigner';
            default:
                return value;
        }
    }
}

@Pipe({name: 'sasulation'})
export class sasulationPipe implements PipeTransform {
    transform(value) {
        switch (value) {
            case 'mr':
                return 'Mr';
            case 'ms':
                return 'Ms';
            case 'mrs':
                return 'Mrs';
            case 'others':
                return 'Others';
            default:
                return value;
        }
    }
}