import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { AuthService } from "@modules/auth/auth.service";
import { Location } from "@angular/common";
import { OrganizationApiService } from "@app/shared/services/organization-api.service";
import { forkJoin } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { ErrorResponseInterface } from "../models/error-response.interface";
import { HTTP_STATUS_CODE } from "../common/http-status-code";
import { RedocConnectService } from "@app/shared/redoc-connect/redoc-connect.service";
@Injectable({
  providedIn: "root",
})
export class LoginAccessGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private location: Location,
    private redocConnect: RedocConnectService
  ) {}

  canActivate() {
    if (this.authService.isLoggedIn) {
      return true;
    } else {
      this.authService.redirectToLogin()
      return false;
    }
  }
}
