import { Default } from "@app/core/decorators/default.decorator";
import { PhoneNumber } from "@app/core/decorators/phone-number.decorator";
import { BaseModel } from "@app/core/models/base.model";
import { Exclude, Expose, Transform } from "class-transformer";

@Exclude()
export class VendorCreateDto extends BaseModel{
    @Expose()
    code:string;

    @Expose()
    name:string;

    @Expose()
    uenNumber:string;

    @Expose()
    logo:string
    @Expose()
    email:string
    @Expose()
    website:string;

    @Expose()
    @PhoneNumber()
    phone:Record<string,string>
    
    @Expose()
    address:{
        postalCode?: string;
        unitNumber?: string;
        blockNumber?: string;
        streetName?: string;
        buildingName?: string;
        floorNumber?: string;
        dictrict: string;
    }

    @Expose()
    contact:{
        name:string;
        title:string;
        email:string;
        phone:Record<string,string>
    }
    @Expose()
    @Default(true)
    promoGCE:boolean;

    @Expose()
    remark: string;
}

@Exclude()
export class VendorEditDto extends BaseModel{ 
    @Expose()
    id:number;
    @Expose()
    code:string;

    @Expose()
    name:string;

    @Expose()
    uenNumber:string;

    @Expose()
    logo:string
    @Expose()
    email:string
    @Expose()
    website:string;

    @Expose()
    @PhoneNumber()
    phone:Record<string,string>
    
    @Expose()
    address:{
        postalCode?: string;
        unitNumber?: string;
        blockNumber?: string;
        streetName?: string;
        buildingName?: string;
        floorNumber?: string;
        dictrict: string;
    }

    @Expose()
    contact:{
        name:string;
        title:string;
        email:string;
        phone:Record<string,string>
    }
    @Expose()
    @Default(true)
    promoGCE:boolean;

    @Expose()
    remark: string;
}