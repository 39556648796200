import { Default } from '@app/core/decorators/default.decorator';
import { BaseModel } from '@core/models/base.model';
import { Exclude, Expose, Transform, Type } from 'class-transformer';
import { SettingModel } from './setting.model';
import { SettingEntityModel } from './setting-entity.model';

export const fieldTypeMappingConst = ['dynamicField', 'signatureField', 'dealTable'] as const;
export type FieldTypeMapping = typeof fieldTypeMappingConst[number];

export type FieldBinding = { [P in FieldTypeMapping]?: { [x in string]: SettingEntityModel } };
@Exclude()
export class SystemFieldMappingValueModel extends BaseModel {

    @Expose()
    @Transform((data: FieldBinding) => {
        if (!data) {
            return {
                signatureField: {},
                dynamicField: {},
                dealTable: {}
            };
        }
        Object.values(data).forEach(dataObj => {
            Object.values(dataObj).forEach(item => SettingEntityModel.fromJson(item));
        });
        return data;
    })
    fieldBinding: FieldBinding;
    @Expose()
    version: string;
}

@Exclude()
export class SystemFieldMappingModel extends SettingModel {

    @Expose()
    @Type(() => SystemFieldMappingValueModel)
    value: SystemFieldMappingValueModel;
}
