import { FormFieldBase } from '@app/core/models/form-field-base.model';

export class DealTableField extends FormFieldBase {

    constructor(options = {}) {
        super(options);
        this.label = 'items';
        this.fieldType = 'dealTable';
        this.controlType = 'dealTable';
        this.count = 1;

    }

    getPlaceHolder(): string {
        return '';
    }
}
