export interface IndustryInterface {
    id: number;
    name: string;
}
export class IndustryModel implements IndustryInterface {
    id: number;
    name: string;
    constructor(data: IndustryInterface) {
        this.name = data.name;
        this.id = data.id;
    }
}
