import { Helper } from './../common/helper';
import { Transform } from 'class-transformer';

export function Default(defaultValue: any) {
    return Transform((value: any, obj: any) => {
        if (value !== null && value !== undefined) {
            return value;
        }
        if (typeof defaultValue === 'function') {
            return defaultValue(obj);
        }
        if (Array.isArray(defaultValue)) {
            return [...defaultValue];
        }
        if (typeof defaultValue === 'object') {
            return defaultValue === null ? null : { ...defaultValue };
        }
        return defaultValue;
    });
}

export function TransformNumber() {
    return Transform((value: any) => {
        if (typeof value === 'string') {
            return Helper.removeCommaFromText(value);
        }
        if (typeof value === 'number') {
            return value;
        }
        return null;
    });
}
