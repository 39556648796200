import { CommissionDistributionStatus } from './../models/commission-distribution.model';
import { InvoiceStatus } from './../models/invoice.model';
import { Pipe, PipeTransform } from '@angular/core';
import { ComponentStatus } from '@app/core/types/component-status';
import { CommissionMatrixStatus } from '../models/commission-matrix.model';
import { EcddStatus } from '../data/ecdd.data';

@Pipe({
    name: 'status',
})
export class StatusPipe implements PipeTransform {
    transform(value: string): ComponentStatus {
        switch (value) {
            case 'success':
            case 'active':
                return 'success';
            case 'block':
            case 'draft':
                return 'danger';
            default:
                return 'default';
        }
    }
}

@Pipe({
    name: 'documentStatus',
})
export class DocumentStatusPipe implements PipeTransform {
    transform(value: string): ComponentStatus {
        switch (value) {
            case 'completed':
                return 'success';
            case 'processing':
                return 'processing';
            case 'canceled':
            case 'aborted':
                return 'danger';
            case 'expired':
                return 'expired';
            default:
                return 'default';
        }
    }
}

@Pipe({
    name: 'invoiceStatus',
})
export class InvoiceStatusPipe implements PipeTransform {
    transform(value: InvoiceStatus): ComponentStatus {
        switch (value) {
            case 'paid':
                return 'success';
            case 'issued':
                return 'processing';
            case 'partial-paid':
                return 'partial-paid';
                case 'canceled':
                    return 'danger';
            default:
                return 'default';
        }
    }
}

@Pipe({
    name: 'displayInvoiceStatus',
})
export class DisplayInvoiceStatusPipe implements PipeTransform {
    transform(value: InvoiceStatus): string {
        switch (value) {
            case 'canceled':
                return 'aborted';
            case 'partial-paid':
                return 'partial paid';
            default:
                return value;
        }
    }
}
@Pipe({
    name: 'supplierInvoiceStatus',
})
export class SupplierInvoiceStatusPipe implements PipeTransform {
    transform(value: InvoiceStatus): ComponentStatus {
        switch (value) {
            case 'paid':
                return 'success';
            case 'issued':
                return 'processing';
            case 'partial-paid':
                return 'partial-paid';
            default:
                return 'new';
        }
    }
}

@Pipe({
    name: 'displaySupplierInvoiceStatus',
})
export class DisplaySupplierInvoiceStatusPipe implements PipeTransform {
    transform(value: InvoiceStatus): string {
        switch (value) {
            case 'canceled':
                return 'aborted';
            case 'partial-paid':
                return 'partial paid';
            case 'draft':
                return 'new';
            default:
                return value;
        }
    }
}
@Pipe({
    name: 'caseStatus',
})
export class CaseStatusPipe implements PipeTransform {
    transform(value: string): ComponentStatus {
        switch (value) {
            case 'draft':
                return 'new';
            case 'submitted':
            case 'processing':
                return 'processing';
            case 'confirmed':
                return 'confirm';
            case 'completed':
                return 'success';
            case 'rejected':
                return 'danger';
            default:
                return 'default';
        }
    }
}

@Pipe({
    name: 'subscriptionStatus',
})
export class SubscriptionStatusPipe implements PipeTransform {
    transform(value: string): ComponentStatus {
        switch (value) {
            case 'pending':
                return 'processing';
            case 'trial':
            case 'active':
                return 'success';
            case 'cancel':
            case 'canceled':
                return 'danger';
        }
    }
}

@Pipe({
    name: 'commissionMatrixStatus',
})
export class CommissionMatrixStatusPipe implements PipeTransform {
    transform(value: CommissionMatrixStatus): ComponentStatus {
        switch (value) {
            case 'active':
                return 'success';
            case 'inactive':
                return 'default';
            default:
                return 'default';
        }
    }
}

@Pipe({
    name: 'commissionDistributionStatus',
})
export class CommissionDistributionStatusPipe implements PipeTransform {
    transform(value: CommissionDistributionStatus): ComponentStatus {
        switch (value) {
            case 'pending':
                return 'processing';
            case 'approve':
                return 'success';
            case 'rejected':
                return 'danger';
            default:
                return 'default';
        }
    }
}

@Pipe({
    name: 'ecddStatus',
})
export class EcddStatusPipe implements PipeTransform {
    transform(value: EcddStatus): ComponentStatus {
        switch (value) {
            case 'draft':
                return 'default';
            case 'pending':
                return 'processing';
            case 'rejected':
                return 'danger';
            case 'approved':
            case 'auto-approved':
                return 'success';
            case 'clientSigned':
                return 'partial-paid';
        }
    }
}
