import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, DoCheck, ElementRef, HostBinding, HostListener, Inject, Input, Optional, Renderer2, Self } from '@angular/core';
import { NgControl, AbstractControlDirective, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { CanUpdateErrorState, ErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Observable, Subject } from 'rxjs';
import { ContentEditableBase } from './contenteditable.base';

@Directive({
  selector:
    // eslint-disable-next-line @angular-eslint/directive-selector
    '[redMatContenteditable]',
  providers: [
    { 
      provide: MatFormFieldControl, 
      useExisting: MatContenteditableDirective }
    ],
})
export class MatContenteditableDirective extends ContentEditableBase implements MatFormFieldControl<string>, DoCheck, CanUpdateErrorState {
  /**
   * Implemented as part of MatFormFieldControl.
   * See https://material.angular.io/guide/creating-a-custom-form-field-control
   */
  static nextId = 0;
  stateChanges: Subject<void> = new Subject<void>();
  @HostBinding() id = `red-mat-contenteditable-${MatContenteditableDirective.nextId++}`;

  @HostBinding('class.red-mat-contenteditable') hostClass = true;

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }
  private _placeholder = '';

  focused = false;
  @Input() contentEmpty: Array<string> = ['<br>', '<div><br></div>'];
  get empty(): boolean {
    return !this.value || this.contentEmpty.includes(this.value);
  }

  @HostBinding('class.floating')
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  @Input()
  get required() {
    return this._required;
  }
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(dis) {
    this._disabled = coerceBooleanProperty(dis);
    this.stateChanges.next();
  }
  private _disabled = false;
  @HostBinding('attr.aria-invalid') errorState = false;
  controlType = 'mat-contenteditable';
  autofilled?: boolean | undefined;
  @HostBinding('aria-describedby') userAriaDescribedBy: string | undefined;

  errorStateMatcher: ErrorStateMatcher = {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
      const isSubmitted = form && form.submitted;
      return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    },
  };
  constructor(
    protected  readonly elementRef: ElementRef<Element>,
    protected  readonly renderer: Renderer2,
    @Optional() @Self() public ngControl: NgControl,
    @Optional() public _parentForm: NgForm,
    @Optional() public _parentFormGroup: FormGroupDirective
  ) {
    super(elementRef, renderer);
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  setDescribedByIds(ids: string[]): void {
    this.userAriaDescribedBy = ids.join(' ');
  }
  onContainerClick(event: MouseEvent): void {
    (this.elementRef.nativeElement as HTMLElement).focus();
  }
  ngDoCheck(): void {
    if (this.ngControl) {
      // We need to re-evaluate this on every change detection cycle, because there are some
      // error triggers that we can't subscribe to (e.g. parent form submissions). This means
      // that whatever logic is in here has to be super lean or we risk destroying the performance.
      this.updateErrorState();
    }
  }
  updateErrorState(): void {
    const matcher = this.errorStateMatcher;

    const parent = this._parentFormGroup || this._parentForm;
    const control = this.ngControl ? this.ngControl.control as FormControl : null;

    const newState = matcher.isErrorState(control, parent);
    
    if (newState !== this.errorState) {
      this.errorState = newState;
      this.stateChanges.next();
    }

    // this.errorState = this.ngControl.errors !== null && !!this.ngControl.touched;
  }

  @HostListener('focusin')
  onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  @HostListener('focusout')
  onFocusOut(event: FocusEvent) {
    if (!this.elementRef.nativeElement.contains(event?.relatedTarget as Element)) {
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }
}
