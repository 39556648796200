import { ItemApiService } from '@app/shared/services/item-api.service';
import { ItemModel } from '@shared/models/item.model';
import {
    Component,
    ElementRef,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    Input,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import {
    debounceTime,
    filter,
    map,
    startWith,
    switchMap,
    takeUntil,
} from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

import { ItemCreateQuickModalComponent } from '@app/modules/item/item-create-quick-modal/item-create-quick-modal.component';
import { PropertySnapshotModel } from '@app/shared/models/invoice.model';
import { PolicyService } from '@app/modules/permission/policy.service';

@Component({
    selector: 'app-property-search',
    templateUrl: './property-search.component.html',
    styleUrls: ['./property-search.component.scss'],
})
export class PropertySearchComponent implements OnInit, OnDestroy {
    @Input() properties: ItemModel[] = [];
    @Output() selectionChange = new EventEmitter<ItemModel>();

    @ViewChild('inputSearch') inputSearch: ElementRef;
    itemCtrl = new FormControl();
    filteredItems: Observable<ItemModel[]>;

    unsubscribeAll = new Subject<any>();
    constructor(
        private itemApiService: ItemApiService,
        private dialog: MatDialog,
        private policyService: PolicyService
    ) {
        this.filteredItems = this.itemCtrl.valueChanges.pipe(
            startWith(''),
            debounceTime(300),
            map((val) => {
                if (val instanceof ItemModel) {
                    return '';
                }
                return val;
            }),
            switchMap((keyword) =>
                this.itemApiService.getItems(
                    { key: keyword, status: 'active' },
                    { loader: false }
                )
            ),
            map((data) => data.results),
            takeUntil(this.unsubscribeAll)
        );

        this.itemCtrl.valueChanges
            .pipe(
                filter((val) => val instanceof ItemModel),
                takeUntil(this.unsubscribeAll)
            )
            .subscribe((item) => {
                this.selectionChange.emit(item);
            });
    }
    ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
        this.selectionChange.complete();
    }

    ngOnInit(): void {}

    displayWith(val: ItemModel): string {
        return '';
    }
    clear(evt: MouseEvent): void {
        evt.stopPropagation();
        this.itemCtrl.reset();
        this.inputSearch.nativeElement.focus();
    }
    create() {
        this.dialog
            .open(ItemCreateQuickModalComponent, {
                width: '700px',
                disableClose: true,
            })
            .afterClosed()
            .subscribe((data: ItemModel) => {
                if (!data) {
                    return;
                }

                this.selectionChange.emit(data);
            });
    }
    get allowCreate(): boolean {
        return this.policyService.can('item::create');
    }
}
