import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmModalComponent } from './confirm-modal.component';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
const components = [
    MatIconModule,
    TranslateModule,
    MatButtonModule,
    MatDialogModule,
];
@NgModule({
    imports: [CommonModule, ...components],
    declarations: [ConfirmModalComponent],
    exports: [ConfirmModalComponent],
})
export class ConfirmModalModule {}
