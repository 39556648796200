import { Default } from '@app/core/decorators/default.decorator';
import { BaseModel } from '@core/models/base.model';
import { Exclude, Expose, Transform, Type } from 'class-transformer';
import { FieldBinding, SettingModel } from './setting.model';
import { SettingEntityModel } from './setting-entity.model';

export interface InvoiceMappingValueInterface {
    templateMatching: string;
}
@Exclude()
export class InvoiceMappingValueModel extends BaseModel {
    @Expose()
    templateMatching: string;

    @Expose()
    @Transform((data: FieldBinding) => {
        if (!data) {
            return {
                signatureField: {},
                dynamicField: {},
                dealTable: {},
            };
        }
        Object.values(data).forEach((dataObj) => {
            Object.values(dataObj).forEach((item) =>
                SettingEntityModel.fromJson(item)
            );
        });
        return data;
    })
    fieldBinding: FieldBinding;
}

@Exclude()
export class InvoiceMappingModel extends SettingModel {
    @Expose()
    @Type(() => InvoiceMappingValueModel)
    value: InvoiceMappingValueModel;
}
