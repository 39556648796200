<div class="red-vertical-navigation-wrapper">

  <!-- Header -->
  <div class="red-vertical-navigation-header">
      <ng-content select="[redocVerticalNavigationHeader]"></ng-content>
  </div>

  <!-- Content -->
  <div
      class="red-vertical-navigation-content"
      [redScrollbar]=true
      [redScrollbarOptions]="{wheelPropagation: inner, suppressScrollX: true}"
      #navigationContent>

      <!-- Content header -->
      <div class="red-vertical-navigation-content-header">
          <ng-content select="[redocVerticalNavigationContentHeader]"></ng-content>
      </div>

      <!-- Items -->
      <ng-container *ngFor="let item of navigation; trackBy: trackByFn">

          <!-- Skip the hidden items -->
          <ng-container *ngIf="(item.hidden && !item.hidden(item)) || !item.hidden">

              <!-- Aside -->
              <ng-container *ngIf="item.type === 'aside'">
                  <red-vertical-navigation-aside-item
                      [item]="item"
                      [name]="name"
                      [activeItemId]="activeAsideItemId"
                      [autoCollapse]="autoCollapse"
                      [skipChildren]="true"
                      (click)="toggleAside(item)"></red-vertical-navigation-aside-item>
              </ng-container>

              <!-- Basic -->
              <ng-container *ngIf="item.type === 'basic'">
                  <red-vertical-navigation-basic-item
                      [item]="item"
                      [name]="name"></red-vertical-navigation-basic-item>
              </ng-container>

              <!-- Collapsable -->
              <ng-container *ngIf="item.type === 'collapsable'">
                  <red-vertical-navigation-collapsable-item
                      [item]="item"
                      [name]="name"
                      [autoCollapse]="autoCollapse"></red-vertical-navigation-collapsable-item>
              </ng-container>

              <!-- Divider -->
              <ng-container *ngIf="item.type === 'divider'">
                  <red-vertical-navigation-divider-item
                      [item]="item"
                      [name]="name"></red-vertical-navigation-divider-item>
              </ng-container>

              <!-- Group -->
              <ng-container *ngIf="item.type === 'group'">
                  <red-vertical-navigation-group-item
                      [item]="item"
                      [name]="name"
                      [autoCollapse]="autoCollapse"></red-vertical-navigation-group-item>
              </ng-container>

              <!-- Spacer -->
              <ng-container *ngIf="item.type === 'spacer'">
                  <red-vertical-navigation-spacer-item
                      [item]="item"
                      [name]="name"></red-vertical-navigation-spacer-item>
              </ng-container>

          </ng-container>

      </ng-container>

      <!-- Content footer -->
      <div class="red-vertical-navigation-content-footer">
          <ng-content select="[cimbVerticalNavigationContentFooter]"></ng-content>
      </div>

  </div>

  <!-- Footer -->
  <div class="red-vertical-navigation-footer">
      <ng-content select="[cimbVerticalNavigationFooter]"></ng-content>
  </div>

</div>

<!-- Aside -->
<ng-container *ngIf="activeAsideItemId">
  <div
      class="red-vertical-navigation-aside-wrapper"
      [redScrollbar]="true"
      [redScrollbarOptions]="{wheelPropagation: inner, suppressScrollX: true}"
      [@fadeInLeft]="position === 'left'"
      [@fadeInRight]="position === 'right'"
      [@fadeOutLeft]="position === 'left'"
      [@fadeOutRight]="position === 'right'">

      <!-- Items -->
      <ng-container *ngFor="let item of navigation; trackBy: trackByFn">

          <!-- Skip the hidden items -->
          <ng-container *ngIf="(item.hidden && !item.hidden(item)) || !item.hidden">

              <!-- Aside -->
              <ng-container *ngIf="item.type === 'aside' && item.id === activeAsideItemId">
                  <red-vertical-navigation-aside-item
                      [item]="item"
                      [name]="name"
                      [autoCollapse]="autoCollapse"></red-vertical-navigation-aside-item>
              </ng-container>
              <!-- Basic -->
              <ng-container *ngIf="item.type === 'basic'">
                <red-vertical-navigation-basic-item
                    [item]="item"
                    [name]="name"></red-vertical-navigation-basic-item>
              </ng-container>
              <!-- Collapsable -->
              <ng-container *ngIf="item.type === 'collapsable'">
                <red-vertical-navigation-collapsable-item
                    [item]="item"
                    [name]="name"
                    [autoCollapse]="autoCollapse"></red-vertical-navigation-collapsable-item>
            </ng-container>
          </ng-container>

      </ng-container>

  </div>
</ng-container>
