<div class="app-upload-file">
    <div
        class="upload-file-container"
        appFileDrop
        (fileOver)="fileOver($event)"
        (fileDrop)="fileDrop($event)"
        fxHide
        [fxShow]="isShowUploadBox"
        [ngClass]="{ 'file-over': isFileOver }"
    >
        <div class="drag-drop-container">
            <mat-icon color="primary">cloud_upload</mat-icon>
            <div class="title">
                {{ title || 'upload_file.drag_and_drop' | translate }}
            </div>
            
            <div class="description">
                or
                <a class="browse-btn" (click)="openSelectBox()">
                    {{ 'upload_file.browse' | translate }}
                </a>
            </div>
            <div class="text-b3 text-theme-dark text-center mt-8">
                {{ message }}
                <span *ngIf="isMultiple">{{
                    'upload_file.maximum_files'
                        | translate : { maxFiles: maxFiles }
                }}</span>
            </div>
        </div>
        <input
            class="input-file"
            type="file"
            #input
            [accept]="allowedMimeType"
            [multiple]="isMultiple"
            appUpload
            [uploadToFolder]="uploadToFolder"
            [disabled]="disabled"
            [isUploadImmediately]="isUploadImmediately"
            [maxFiles]="maxFiles"
            [maxFileSize]="maxFileSize"
            [allowedMimeType]="allowedMimeType"
            [isReplaced]="isReplaced"
            [existFileKeys]="existFileKeys"
            (all)="onAllFiles($event)"
            (added)="onAdded($event)"
            (removed)="onRemoved($event)"
            (lastUploaded)="onLastUpdated($event)"
            (begin)="onBegin()"
            (finished)="onFinished()"
            (beginFile)="onBeginFile($event)"
            (finishedFile)="onFinishedFile($event)"
            [allowExtensions]="allowExtensions"
        />
    </div>
    <ng-container *ngIf="preview && allFiles.length">
        <div class="remove-all-file">
            <button
                class="upload-btn"
                *ngIf="!isUploadImmediately"
                [disabled]="!isAvailableUploadBtn"
                mat-flat-button
                color="primary"
                (click)="upload()"
            >
                {{ 'upload_file.upload_all' | translate }}
            </button>
            <button mat-button (click)="clear()" *ngIf="!isHidenButtonClearAll">
                {{ 'upload_file.clear_all' | translate }}
            </button>
        </div>
        <div class="preview-container">
            <div
                class="img-preview-container"
                *ngFor="let fileUploader of allFiles"
            >
                <div class="preview">
                    <div class="info-preview">
                        <div class="img-preview">
                            <img
                                *ngIf="fileUploader.isExistFile"
                                appLoadImage
                                [key]="fileUploader?.key"
                                (info)="loadImgInfo(fileUploader, $event)"
                            />
                            <img
                                *ngIf="
                                    !fileUploader.isExistFile &&
                                    fileUploader.isPreview &&
                                    fileUploader.data
                                "
                                [src]="fileUploader.data"
                            />
                            <mat-icon
                                *ngIf="
                                    !fileUploader.isExistFile &&
                                    !fileUploader.isPreview
                                "
                                >insert_drive_file</mat-icon
                            >
                        </div>
                        <div class="img-info">
                            <div class="img-info__item img-name">
                                {{ fileUploader.file.name }}
                            </div>
                            <mat-progress-bar
                                class="img-info__item pending"
                                *ngIf="fileUploader.uploading"
                                mode="indeterminate"
                            ></mat-progress-bar>
                            <mat-progress-bar
                                class="img-info__item danger"
                                *ngIf="fileUploader.error.status"
                                mode="determinate"
                                value="5"
                            ></mat-progress-bar>
                            <mat-progress-bar
                                class="img-info__item success"
                                *ngIf="fileUploader.completed"
                                mode="determinate"
                                value="100"
                            ></mat-progress-bar>
                            <div class="img-info__item img-info__group">
                                <span class="img-size">
                                    {{ fileUploader.file.size | fileSize }}
                                </span>
                                <span
                                    class="img-status"
                                    [ngClass]="{
                                        success: fileUploader.completed,
                                        pending: fileUploader.uploading,
                                        danger: fileUploader.error?.status
                                    }"
                                >
                                    {{ fileUploader.completed ? 'Done' : '' }}
                                    {{
                                        fileUploader.uploading
                                            ? 'Uploading'
                                            : ''
                                    }}
                                    {{
                                        fileUploader.error.status ? 'Error' : ''
                                    }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div
                        class="remove-img-btn"
                        (click)="deleteFile(fileUploader.id)"
                    >
                        <mat-icon>delete_forever</mat-icon>
                    </div>
                </div>
                <div *ngIf="fileUploader.error">
                    <span class="error-message">{{
                        fileUploader.error.message | translate
                    }}</span>
                </div>
            </div>
        </div>
    </ng-container>
</div>
