import {
    Component,
    OnInit,
    OnDestroy,
    ContentChild,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation,
} from '@angular/core';
import { LayoutService } from '../layout.service';
import { ActivatedRoute } from '@angular/router';
import { LayoutPublicHeaderDirective } from './layout-public-header.directive';
export type Layout = 'empty' | 'classic';
@Component({
    selector: 'app-layout-public',
    templateUrl: './layout-public.component.html',
    styleUrls: ['./layout-public.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class LayoutPublicComponent implements OnInit, OnDestroy {
    layout!: Layout;
    get templateLabel(): TemplateRef<any> | undefined {
        return this._templateLabel;
    }
    set templateLabel(value: TemplateRef<any> | undefined) {
        this._templateLabel = value;
    }
    @ViewChild('container', { read: ViewContainerRef, static: true })
    topbarContainer: ViewContainerRef;
    private _templateLabel?: TemplateRef<any>;
    constructor(private _activatedRoute: ActivatedRoute) {}
    ngOnDestroy(): void {}

    ngOnInit(): void {
        this._updateLayout();
    }
    /**
     * Update the selected layout
     */
    private _updateLayout(): void {
        // Get the current activated route
        let route = this._activatedRoute;
        while (route.firstChild) {
            route = route.firstChild;
        }

        // 3. Iterate through the paths and change the layout as we find
        // a config for it.
        //
        // The reason we do this is that there might be empty grouping
        // paths or componentless routes along the path. Because of that,
        // we cannot just assume that the layout configuration will be
        // in the last path's config or in the first path's config.
        //
        // So, we get all the paths that matched starting from root all
        // the way to the current activated route, walk through them one
        // by one and change the layout as we find the layout config. This
        // way, layout configuration can live anywhere within the path and
        // we won't miss it.
        //
        // Also, this will allow overriding the layout in any time so we
        // can have different layouts for different routes.
        const paths = route.pathFromRoot;
        paths.forEach((path) => {
            // Check if there is a 'layout' data
            if (
                path.routeConfig &&
                path.routeConfig.data &&
                path.routeConfig.data['layout']
            ) {
                // Set the layout
                this.layout = path.routeConfig.data['layout'];
            }
        });
    }
}
