import { ItemPriceInterface, ItemPriceModel } from './item-price.model';
import { ItemPhotoInterface, ItemPhotoModel } from './item-photo.model';
import { BaseModel } from '@core/models/base.model';
import { Type, Exclude, Expose, Transform } from 'class-transformer';
import { Default } from '@app/core/decorators/default.decorator';
import { OneMapResultInterface } from './one-map.model';
import { UserInterface, UserModel } from './user.model';
import { transformPropertyAddrObjectToString } from '@app/core/common/common.utils';
import { COUNTRY_LIST } from '@app/core/data/countries.data';

export interface ItemAttrInterface {
    valuationDate: string;
    valuation: string;
    numberOfBathrooms: string;
    isPropertyTypeHasLand: boolean;
    valuationCompany: string;
    topYear: string;
    floorSize: string;
    landSize: string;
    psf: string;
    tenure: string;
    developer: string;
    numberOfRoom: string;
    furnishings: string[];
    price: number;
    rentalPrice: number;
    address: OneMapResultInterface;
    postalCode: string;
    dictrict: string;
    country: string;
    countryCode: string;
    addressLine: string;
    unitNumber: string;
    blockNumber: string;
    streetName: string;
    buildingName: string;
    floorNumber?: string;
    linkedCustomer: {
        id: number;
        customerName: string;
        contactName: string;
        contactEmail: string;
    };
}

@Exclude()
export class ItemAttrModel extends BaseModel implements ItemAttrInterface {
    @Expose()
    valuationDate: string;
    @Expose()
    valuation: string;
    @Expose()
    valuationCompany: string;
    @Expose()
    topYear: string;
    @Expose()
    isPropertyTypeHasLand: boolean;
    @Expose()
    numberOfBathrooms: string;
    @Expose()
    floorSize: string;
    @Expose()
    landSize: string;
    @Expose()
    psf: string;
    @Expose()
    tenure: string;
    @Expose()
    developer: string;
    @Expose()
    numberOfRoom: string;
    @Expose()
    furnishings: string[];
    @Expose()
    price: number;
    @Expose()
    rentalPrice: number;
    @Expose()
    address: OneMapResultInterface;
    @Expose()
    postalCode: string;
    @Expose()
    dictrict: string;
    @Expose()
    @Transform((value, obj) => {
        return obj.countryCode ? COUNTRY_LIST.find(item => item.countryCode === obj.countryCode)?.country : value;
    })
    country: string;
    @Expose()
    @Transform((value, obj) => {
        if (!value && !obj.country) {
            return 'SG';
        } else {
            return obj.country ? COUNTRY_LIST.find(item => item.country === obj.country)?.countryCode : value;
        }
    })
    countryCode: string;
    @Expose()
    addressLine: string;
    @Expose()
    unitNumber: string;
    @Expose()
    blockNumber: string;
    @Expose()
    streetName: string;
    @Expose()
    buildingName: string;
    @Expose()
    floorNumber?: string;
    @Expose()
    linkedCustomer: { id: number; customerName: string; contactName: string; contactEmail: string; };

}
export interface ItemInterface {
    id: number;
    code: string;
    sku: string;
    attrs?: ItemAttrInterface;
    name: string;
    type: string;
    category: string;
    dimension: string;
    description: string;
    itemPrices: ItemPriceInterface[];
    attachments: ItemPhotoInterface[];
    status: string;
    createdByUser: UserInterface;
}
@Exclude()
export class ItemModel extends BaseModel implements ItemInterface {
    @Expose()
    id: number;

    @Expose()
    @Default('')
    code: string;

    @Expose()
    @Default('')
    sku: string;

    @Expose()
    @Type(() => ItemAttrModel)
    attrs: ItemAttrModel;

    @Expose()
    name: string;

    @Expose()
    type: string;

    @Expose()
    typeDisplay: string;

    @Expose()
    category: string;

    @Expose()
    dimension: string;

    @Expose()
    @Default([])
    @Type(() => ItemPhotoModel)
    attachments: ItemPhotoModel[];

    @Expose()
    @Type(() => UserModel)
    createdByUser: UserModel;

    @Expose()
    get photos(): ItemPhotoModel[] {
        return this.attachments?.filter(item => item.type === 'photo');
    }

    @Expose()
    get floorPlans(): ItemPhotoModel[] {
        return this.attachments?.filter(item => item.type === 'floorPlan');
    }

    @Expose()
    get photoCover(): ItemPhotoModel {
        return this.photos?.length > 0 ? this.photos[0] : null;
    }

    @Expose()
    description: string;

    @Expose()
    @Default([])
    @Type(() => ItemPriceModel)
    itemPrices: ItemPriceModel[];

    @Expose()
    status: string;

    @Expose()
    get displayFurnishing(): string {
        const furnishings: string[] = this.attrs.furnishings;
        if (furnishings && furnishings.length) {
            return furnishings.join(', ');
        }

        return '';
    }

    @Expose()
    get address(): string {
        return transformPropertyAddrObjectToString({
            postalCode: this.attrs?.postalCode,
            unitNumber: this.attrs?.unitNumber,
            blockNumber: this.attrs?.blockNumber,
            streetName: this.attrs?.streetName,
            buildingName: this.attrs?.buildingName,
            floorNumber: this.attrs?.floorNumber,
            countryCode:this.attrs?.countryCode,
            addressLine:this.attrs.addressLine
        });
    }

    @Expose()
    get isSG(): boolean {
        return this.attrs?.countryCode === 'SG';
    }
}

@Exclude()
export class ClientItemModel extends BaseModel {
    @Expose()
    id: number;
    @Expose()
    customerRole: string;
    @Expose()
    relationship: 'own' | 'interested';

    @Expose()
    itemId: string;

    @Expose()
    @Type(() => ItemModel)
    item: ItemModel;
}
