import { ServiceUnavailableComponent } from '@modules/shared/service-unavailable/service-unavailable.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ModulePreLoading } from '@app/module-pre-loading';
import { LoginAccessGuard } from '@core/guards/login.guard';
import { UserResolver } from '@core/resolvers/user.resolver';
import { OrganizationResolver } from '@core/resolvers/organization.resolver';
import { NotFoundComponent } from '@modules/shared/not-found/not-found.component';
import { ForbiddenComponent } from '@modules/shared/forbidden/forbidden.component';
import { NonAuthResolver } from '@core/resolvers/non-auth.resolver';
import { LayoutPublicComponent } from './modules/layout/layout-public/layout-public.component';
import { QuickActionResolver } from './core/resolvers/quick-action.resolver';

const routes: Routes = [
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: '' },
    {
        path: 'document-signing',
        loadChildren: () =>
            import('@modules/document-signing/document-signing.module').then(
                (m) => m.DocumentSigningModule
            ),
        resolve: {
            isNonAuth: NonAuthResolver,
        },
    },
    {
        path: 'pdf-signing',
        loadChildren: () =>
            import('@modules/pdf/pdf-signing/pdf-signing.module').then(
                (m) => m.PdfSigningModule
            ),
        resolve: {
            isNonAuth: NonAuthResolver,
        },
    },
    {
        path: 'auth',
        loadChildren: () =>
            import('@modules/auth/auth.module').then((m) => m.AuthModule),
        resolve: {
            isNonAuth: NonAuthResolver,
        },
    },
    {
        path: 'initiation',
        loadChildren: () =>
            import('@modules/initiation/initiation.module').then(
                (m) => m.InitiationModule
            ),
        resolve: {
            isNonAuth: NonAuthResolver,
        },
    },
    {
        path: 'invite',
        loadChildren: () =>
            import('@modules/invite/invite.module').then((m) => m.InviteModule),
        resolve: {
            isNonAuth: NonAuthResolver,
        },
    } ,
    { path: '404', component: NotFoundComponent },
    { path: '403', component: ForbiddenComponent },
    { path: '503', component: ServiceUnavailableComponent },
    {
        path:'public',
        component:LayoutPublicComponent,
        data:{
            layout:'classic'
        },
        children:[
            {
                path: 'ecdd',
                loadChildren: () =>
                    import('@modules/ecdd/ecdd-public/ecdd-public.module').then(
                        (m) => m.EcddPublicModule
                    ),
                resolve: {
                    isNonAuth: NonAuthResolver,
                },
            },
            {
                path: 'huttons/ecdd',
                loadChildren: () =>
                    import('@modules/ecdd/huttons/ecdd-public/ecdd-public.module').then(
                        (m) => m.EcddPublicModule
                    ),
                resolve: {
                    isNonAuth: NonAuthResolver,
                },
            }
        ]
    },
    {
        path: '',
        canActivate: [LoginAccessGuard],
        resolve: {
            user: UserResolver,
            organization: OrganizationResolver,
            quickAction:QuickActionResolver
        },
        loadChildren: () =>
            import('@modules/layout/layout.module').then((m) => m.LayoutModule),
    },
    { path: '**', redirectTo: '/404' },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: false,
            preloadingStrategy: ModulePreLoading,
            enableTracing: false,
        }),
    ],
    exports: [RouterModule],
})
export class AppRouting {}
