export type DealTableColumnStyleType = 'alignLeft' | 'alignCenter' | 'alignRight';
export type DealTableRateGroupType = 'rateGroupItem' | 'divide';
export interface DealTableRateGroupInterface {
    type: DealTableRateGroupType;
    title?: string;
    description?: string;
}
export class DealTableRateGroup implements DealTableRateGroupInterface {
    type: DealTableRateGroupType;
}
export class DealTableRateGroupItem extends DealTableRateGroup {
    type: DealTableRateGroupType = 'rateGroupItem';
    title: string;
    description: string;
    constructor(option: DealTableRateGroupInterface) {
        super();
        this.title = option.title;
        this.description = option.description;
    }
}
export class DealTableRateGroupDivide extends DealTableRateGroup {
    type: DealTableRateGroupType = 'divide';
    constructor() {
        super();
    }
}
export class CkeditorPayload {
    executeName: string;
    payload: object;
    constructor(data) {
        this.executeName = data.executeName;
        this.payload = data.payload;
    }
}

export class DynamicFieldExecutePayload extends CkeditorPayload {
    executeName = 'dynamicField';
    payload: {
        key: string;
        label: string;
        value: string;
        type: string;
        metadata?: string;
    };
    constructor(option: {
        payload: {
            key: string;
            label: string;
            value: string;
            type: string;
            metadata?: string;
        }
    }) {
        super(option);
        this.payload = option.payload;
    }
}
export class DynamicImageExecutePayload extends CkeditorPayload {
    executeName = 'insertDynamicImage';
    payload: {
        key: string;
        label: string;
        src: string;
    };
    constructor(option: {
        payload: {
            key: string;
            label: string;
            src: string;
        }
    }) {
        super(option);
        this.payload = option.payload;
    }
}
export class AttributeInsertExecutePayload extends CkeditorPayload {
    payload: {
        key: string;
        attributes: {
            attributeKey: string;
            value: string
        }[]
    };
    constructor(option: {
        payload: {
            key: string;
            attributes: {
                attributeKey: string;
                value: string
            }[]
        }
    }) {
        super(option);
        this.payload = option.payload;
    }
}
export class DynamicFieldCreateOrUpdateExecutePayload extends AttributeInsertExecutePayload {
    executeName = 'createOrUpdateDynamicFieldByMarker';
}
export class DynamicImageCreateOrUpdateExecutePayload extends AttributeInsertExecutePayload {
    executeName = 'createOrUpdateByMarkerDynamicImage';
}
export class DynamicFieldAttributeInsertExecutePayload extends AttributeInsertExecutePayload {
    executeName = 'insertAttributeDynamicField';
}
export class DynamicImageAttributeInsertExecutePayload extends AttributeInsertExecutePayload {
    executeName = 'updateAttributeDynamicImage';
}
export class SignatureFieldAttributeInsertExecutePayload extends AttributeInsertExecutePayload {
    executeName = 'insertSignatureFieldAttribute';
}
export class DynamicFieldDeleteExecutePayload extends CkeditorPayload {
    executeName = 'deleteDynamicField';
    payload: {
        key: string;
    };
    constructor(option: {
        payload: {
            key: string;
        }
    }) {
        super(option);
        this.payload = option.payload;
    }
}
export class DynamicFieldAttributeExecutePayload extends CkeditorPayload {
    executeName = 'insertAttributeDynamicField';
    payload: {
        key: string;
        attributes: {
            attributeKey: string;
            value: string
        }[]

    };
    constructor(option: {
        payload: {
            key: string;
            attributes: {
                attributeKey: string;
                value: string
            }[]
        }
    }) {
        super(option);
        this.payload = option.payload;
    }
}
export class NormalExecutePayload extends CkeditorPayload {
    executeName: string;
    constructor(option: {
        executeName: string,
        [x: string]: any
    }) {
        super(option);
    }
}
export class FieldExecutePayload extends CkeditorPayload {
    executeName = 'insertSignatureField';
    payload: {
        selectionId?: string;
        uuid?: string;
        email?: string;
        label: string;
        owner?: string;
    };
    constructor(option: {
        payload: {
            type: 'signatureField';
            selectionId?: string;
            uuid?: string;
            email?: string;
            label: string;
            owner?: string;
        }
    }) {
        super(option);
        option.payload.owner = option.payload.owner ? option.payload.owner : '';
        this.payload = option.payload;
    }
}

export class ParticipantFieldExecutePayload extends CkeditorPayload {
    executeName: 'redrInlineTextField' | 'redrInlineDateField' = 'redrInlineTextField';
    payload: {
        selectionId?: string;
        key?: string;
        uuid?: string;
        email?: string;
        label?: string;
        owner?: string;
        value?: string;
        metadata?: string;
    };
    constructor(option: {
        executeName?: 'redrInlineTextField' | 'redrInlineDateField'
        payload: {
            selectionId?: string;
            key?: string;
            uuid?: string;
            email?: string;
            label?: string;
            owner?: string;
            value?: string;
            metadata?: string;
        }
    }) {

        super(option);
        if (option.executeName) {
            this.executeName = option.executeName;
        }
        option.payload.owner = option.payload.owner ? option.payload.owner : '';
        this.payload = option.payload;
    }
}

export class ParticipantAttributeInsertExecutePayload extends AttributeInsertExecutePayload {
    executeName = 'insertInlineFieldAttribute';
}

export class DealTableExecutePayload extends CkeditorPayload {
    executeName = 'insertDealTable';
    payload: {
        selectionId?: string;
        title?: string;
        dataTable: {
            config?: {
                hasHeader?: boolean,
                styleColumn?: DealTableColumnStyleType[],
            },
            data: string[][]
        };
        rateGroup: DealTableRateGroup[]
    };
    constructor(option: {
        payload: {
            selectionId?: string;
            title?: string;
            dataTable: {
                config?: {
                    hasHeader?: boolean,
                    styleColumn?: DealTableColumnStyleType[],
                },
                data: string[][]
            };
            rateGroup: DealTableRateGroup[]
        }
    }) {
        super(option);
        this.payload = option.payload;
    }
}
