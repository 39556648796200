export interface CountryInterface {
    name: string;
    iso2Code: string;
    dialCode: string;
    order?: number;
    areaCode?: string[];
}
export class CountryModel implements CountryInterface {
    name: string;
    iso2Code: string;
    dialCode: string;
    order: number;
    areaCode?: string[];
    constructor(data: CountryInterface) {
        this.name = data.name;
        this.iso2Code = data.iso2Code;
        this.dialCode = data.dialCode;
        this.order = data.order;
        this.areaCode = data.areaCode;
    }

    static fromArray(data: [string, string, string, number?, string[]?]) {
        return new CountryModel({
            name: data[0],
            iso2Code: data[1],
            dialCode: data[2],
            order: data[3],
            areaCode: data[4]
        });
    }
}
