import { DateMoment } from '@app/core/common/date-moment';
import { Default } from '@app/core/decorators/default.decorator';
import { BaseModel } from '@app/core/models/base.model';
import { Expose, Transform, Type } from 'class-transformer';
import { Moment } from 'moment';
import { BusinessUnitModel } from './business-unit.model';
import { ContentBuilderFieldModel } from './content-builder-field.model';
import { DocumentModel } from './document.model';
import { ReminderChannel, ReminderInterface, ReminderScheduleSetting, ReminderStage, ReminderStatus, ShareReminderUser } from './reminder.model';
import { UserModel } from './user.model';

export const ContractGeneralReminderTypeKeys = {
    otherDate: 'other_date'
};

export class ContractGeneralReminderModel extends BaseModel implements ReminderInterface {
    id: number;
    name: string;
    status: ReminderStatus = ReminderStatus.Enabled;
    stage: ReminderStage = ReminderStage.Pending;
    channel: string = ReminderChannel.Email;
    triggerAt: string;
    @Expose()
    @Type(() => ReminderScheduleSetting)
    schedule: ReminderScheduleSetting = new ReminderScheduleSetting();
    remark: string;
    @Expose()
    @Type(() => ContractGeneralReminderMetadataModel)
    metadata?: ContractGeneralReminderMetadataModel;
    @Type(() => ShareReminderUser)
    sharedEmail?: ShareReminderUser[] = [];
    businessUnitId: number;
    @Expose()
    @Type(() => BusinessUnitModel)
    businessUnit: BusinessUnitModel;
    createdById: number;
    @Expose()
    @Type(() => UserModel)
    createdBy: UserModel;
    createdAt: string;
    updatedAt: string;
    deletedAt?: string;
    restoredAt?: string;

    get hasSharing() {
        return this.sharedEmail?.length > 0;
    }

    get triggerAtMoment(): Moment {
        return DateMoment.date(this.triggerAt);
    }

    get fieldLabel() {
        if (this.schedule.type === ContractGeneralReminderTypeKeys.otherDate) {
            return this.metadata?.customDateLabel || null;
        }

        return this.metadata?.snapshot?.contract.fields.properties[this.schedule.type]?.label || null;
    }
}

export interface AddReminderToContractInterface {
    name: string;
    contractId: number;
    schedule: ReminderScheduleSetting;
    triggerAt: string;
    sharedEmail: ShareReminderUser[];
    remark: string;
    metadata?: ContractGeneralReminderMetadataModel;
}

export interface UpdateReminderToContractInterface extends AddReminderToContractInterface {
    newSharedEmail: any[];
}

class SnapshotContractInfo extends BaseModel {
    @Expose()
    @Default(ContentBuilderFieldModel.fromJson({}))
    @Type(() => ContentBuilderFieldModel)
    fields: ContentBuilderFieldModel;
}

/**
 * Snapshot data for a reminder
 */
export class ContractGeneralReminderSnapshotModel extends BaseModel {
    @Expose()
    @Default(SnapshotContractInfo.fromJson({}))
    @Type(() => SnapshotContractInfo)
    contract: SnapshotContractInfo;
}

export class ContractGeneralReminderMetadataModel extends BaseModel {
    @Expose()
    @Type(() => ContractGeneralReminderSnapshotModel)
    snapshot: ContractGeneralReminderSnapshotModel;
    customDateLabel?: string;
}

