import { Directive, EventEmitter, HostListener, Input, Output, ElementRef, OnDestroy, forwardRef, OnInit, AfterContentInit, Self, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { A, Z } from '@angular/cdk/keycodes';
@Directive({
    selector: '[appUppercase]',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => UppercaseDirective),
            multi: true
        }
    ]
})
export class UppercaseDirective implements OnInit, AfterContentInit, ControlValueAccessor {
    /** implements ControlValueAccessorInterface */
    onChange: (_: any) => void;

    /** implements ControlValueAccessorInterface */
    touched: () => void;

    constructor(@Self() private el: ElementRef, private renderer: Renderer2) { }
    ngOnInit(): void {
        this.renderer.setStyle(
            this.el.nativeElement,
            'text-transform',
            'uppercase'
        );
    }

    ngAfterContentInit(): void {
        const value = this.el.nativeElement.value;
        const valueUpperCase = value.toUpperCase();
        if (value !== valueUpperCase) {
            this.onChange(valueUpperCase);
        }
    }
    /** Trata as teclas */
    @HostListener('keyup', ['$event'])
    onKeyDown(evt: KeyboardEvent) {
        const value = this.el.nativeElement.value.toUpperCase();
        this.renderer.setProperty(this.el.nativeElement, 'value', value);
        this.onChange(value);
        evt.preventDefault();
    }

    @HostListener('blur', ['$event'])
    onBlur() {
        this.touched();
    }

    /** Implementation for ControlValueAccessor interface */
    writeValue(value: any): void {
        this.renderer.setProperty(this.el.nativeElement, 'value', value);
    }

    /** Implementation for ControlValueAccessor interface */
    registerOnChange(fn: (_: any) => void): void {
        this.onChange = fn;
    }

    /** Implementation for ControlValueAccessor interface */
    registerOnTouched(fn: () => void): void {
        this.touched = fn;
    }

    /** Implementation for ControlValueAccessor interface */
    setDisabledState(isDisabled: boolean): void {
        this.renderer.setProperty(this.el.nativeElement, 'disabled', isDisabled);
    }
}
