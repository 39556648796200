import { BaseModel } from '@core/models/base.model';
import { Exclude, Expose, Transform, Type } from 'class-transformer';
import { UserModel } from './user.model';
import { ParticipantInterface, ParticipantModel } from './participant.model';
import { Default } from '@app/core/decorators/default.decorator';
import { DOCUMENT_STATUS_KEY } from '@app/modules/document/document.constant';
import {
    ContentBuilderFieldInterface,
    ContentBuilderFieldModel,
} from './content-builder-field.model';
import { AttachmentInterface, AttachmentModel } from './attachment.model';
import {
    DocumentMetadataInterface,
    DocumentMetadataModel,
} from './metadata.model';
import { DealTableInterface, DealTableModel } from './deal-table.model';
import * as moment from 'moment';
import { ClientInterface, ClientModel } from './client.model';
import {
    ContentBuilderContentInterface,
    ContentBuilderContentModel,
} from './content-builder-content.model';
import { ContentPdfOModel } from '@shared/models/content-pdf-o.model';
import { IContentBuilderContentType } from './content-builder.model';
import { CONTENT_BUILDER_STYLE } from '@app/modules/content-builder/constants/config';

export type DocumentAction = 'read' | 'create' | 'edit' | 'delete';

export interface ComponentInterface {
    dealTables: DealTableInterface[];
}

export class ComponentModel extends BaseModel implements ComponentInterface {
    @Expose()
    @Type(() => DealTableModel)
    dealTables: DealTableModel[];
}

export interface DocumentInterface {
    id: string;
    name: string;
    status: string;
    businessUnitId: number;
    tenantId: number;
    signingPortal: string;
    signingMethod: string;
    content: ContentBuilderContentInterface;
    version: number;
    hash: string;
    tenant: ClientInterface;
    docType: string;
    createdBy: UserModel;
    createdAt: string;
    updatedAt: string;
    metadata: DocumentMetadataInterface;
    components: ComponentInterface;
    contentType: IContentBuilderContentType;
    items: any;
    participants: ParticipantInterface[];
    attachments: AttachmentInterface[];
    code: string;
    fields: ContentBuilderFieldInterface;
}
@Exclude()
export class DocumentPublicLink extends BaseModel {
    @Expose()
    // participant id
    id: number; 

    @Expose()
    // participant name
    name: string; 

    @Expose()
    // participant email
    email?: string; 

    @Expose()
    // participant id
    link: string; 
}
export class DocumentModel extends BaseModel implements DocumentInterface {
    static currentVersion = '2.0.1'
    @Expose()
    id: string;

    @Expose()
    name: string;

    @Expose()
    status: string;

    @Expose()
    code: string;

    @Expose()
    businessUnitId: number;

    @Expose()
    tenantId: number;

    @Expose()
    signingPortal: string;

    @Expose()
    signingMethod: string;

    @Expose()
    content: ContentBuilderContentModel;

    @Expose()
    @Default('jsonM')
    contentType: IContentBuilderContentType;

    @Expose()
    docType: string;

    @Expose()
    version: number;

    @Expose()
    hash: string;

    @Expose()
    @Type(() => ClientModel)
    tenant: ClientModel;

    @Expose({ name: 'createdByUser' })
    @Type(() => UserModel)
    createdBy: UserModel;

    @Expose()
    createdAt: string;

    @Expose()
    updatedAt: string;

    @Expose()
    @Transform((data) => {
        if (!data || !data.mode) {
            return DocumentMetadataModel.createEmpty();
        }
        return data;
    })
    @Type(() => DocumentMetadataModel)
    metadata: DocumentMetadataModel;

    @Expose()
    @Type(() => ComponentModel)
    components: ComponentModel;

    @Expose()
    items: any;

    @Expose()
    @Default([])
    @Type(() => ParticipantModel)
    participants: ParticipantModel[];

    @Expose()
    @Type(() => AttachmentModel)
    attachments: AttachmentModel[];

    @Expose()
    @Default(ContentBuilderFieldModel.fromJson({}))
    @Type(() => ContentBuilderFieldModel)
    fields: ContentBuilderFieldModel;

    @Expose()
    dynamicField: { [x in string]: string };

    get isAllowEditReminder() {
        return [DOCUMENT_STATUS_KEY.PROCESSING].includes(this.status);
    }

    get isAllowEditExpiration() {
        return [DOCUMENT_STATUS_KEY.PROCESSING].includes(this.status);
    }

    get isAllowEdit() {
        return this.status === DOCUMENT_STATUS_KEY.DRAFT;
    }

    get isAllowDelete() {
        return this.status === DOCUMENT_STATUS_KEY.DRAFT;
    }
    get isProcessing(){
        return this.status === DOCUMENT_STATUS_KEY.PROCESSING;
    }

    get totalPrice() {
        if (
            this.components &&
            this.components.dealTables &&
            this.components.dealTables.length > 0
        ) {
            return this.components.dealTables[0].totalPrices;
        }

        return 0;
    }

    get itemIds() {
        let itemIds = [];
        const tempArrayIds = [];

        if (
            this.components &&
            this.components.dealTables &&
            this.components.dealTables.length > 0
        ) {
            this.components.dealTables.forEach((dealTable) => {
                if (dealTable.entries && dealTable.entries.length) {
                    dealTable.entries.forEach((entry) => {
                        if (entry.itemId) {
                            tempArrayIds.push(entry.itemId);
                        }
                    });
                }
            });

            // https://stackoverflow.com/questions/1960473/get-all-unique-values-in-a-javascript-array-remove-duplicates
            // Get unique value in array
            itemIds = tempArrayIds.filter((v, i, a) => a.indexOf(v) === i);
        }

        return itemIds;
    }

    get isAllowAbort() {
        return this.status === DOCUMENT_STATUS_KEY.PROCESSING;
    }

    get numberOfExpirationDay() {
        const expirationSetting = this.metadata?.expirationSetting;
        if (expirationSetting && expirationSetting.enableExpiration) {
            const expiredDate = expirationSetting.expirationDate;
            return expiredDate?.diff(moment(), 'days');
        }

        return null;
    }

    get shouldShowReminder() {
        return this.status === DOCUMENT_STATUS_KEY.COMPLETED;
    }

    static toJsonGeneralDoc(data: DocumentModel): any {
        data.fields.layouts.participantField?.forEach((item) => {
            data.fields.data[item.key] = '';
        });
        return {
            name: data.name,
            content: data.content,
            contentType: data.contentType,
            docType: 'general',
            fields: data.fields,
            attachments: data.attachments,
            participants: data.participants.map((participant) =>
                ParticipantModel.toJsonForClone(participant)
            ),
            items: data.items,
            metadata: {
                mode: data.metadata?.mode,
                note: data.metadata?.note,
                // videoCall: data.metadata?.videoCall,
                eSigning: data.metadata?.eSigning || {},
                version:data.metadata?.version
            },
        };
    }
    static getDefault():DocumentModel{
        return DocumentModel.fromJson({
            name:'Untitled Document',
            contentType:'jsonM',
            content: {
                html: '',
                css: CONTENT_BUILDER_STYLE.URL,
                printOptions: {
                    margin: {
                        top: CONTENT_BUILDER_STYLE.PRINT_OPTIONS.MARGIN.TOP + 'px',
                        bottom: CONTENT_BUILDER_STYLE.PRINT_OPTIONS.MARGIN.BOTTOM + 'px',
                        left: CONTENT_BUILDER_STYLE.PRINT_OPTIONS.MARGIN.LEFT + 'px',
                        right: CONTENT_BUILDER_STYLE.PRINT_OPTIONS.MARGIN.RIGHT + 'px'
                    }
                }
            },
            metadata: {
                mode: 'editable-mode',
                version:DocumentModel.currentVersion,
                eSigning:{}
            }
        })
    }
    get isEditableMode(){
        return this.metadata.mode === 'editable-mode'
    }
    get isFormMode(){
        return this.metadata.mode === 'form-mode'
    }
}

export interface DocumentDraftInterface {
    id: number;
    content: any;
    key: string;
    userId: number;
    documentId?: number;
    createdAt: string;
    updatedAt: string;
}
export function createDocumentPayloadByHtml(
    html: string,
    options?: { name: string;signingPortal?:string }
): Record<string, any> {
    return {
        name: options?.name ?? 'Untitled Document',
        content: {
            html,
            css:  CONTENT_BUILDER_STYLE.URL,
            printOptions: {
                margin: {
                    top: '60px',
                    bottom: '60px',
                    left: '50px',
                    right: '50px',
                },
            },
        },
        contentType: 'jsonM',
        metadata: {
            mode: 'editable-mode',
            eSigning: {
                enableESigning: false,
                provider: 'singpass',
            },
            groupDataFields: [],
            groupIdOrders: [
                'Client Fields',
                'Property Fields',
                'Leasing / Rental Fields',
                'Sales / Purchase Fields',
                'Agent Fields',
                'Co-broke Agent',
                'Invoice Fields',
                'General Fields',
            ],
        },
        fields: {
            data: {},
            properties: {},
            layouts: {
                dynamicField: [],
                signatureField: [],
                participantField: [],
            },
            type: 'jsonSchema',
            placeholder: {},
        },
        signingPortal:options.signingPortal || undefined,
        participants: [],
        items: [],
        components: {},
    };
}
