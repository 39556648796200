import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppConstant } from '@app/app.constant';
import { GlobalService } from '@app/core/services/global.service';
import { DOC_SHARE_MODE } from '@app/modules/document-template/constants/document-template-constant';
import { DocumentTemplatePaginationAdapter } from '@app/shared/adapters/document-template-pagination.adapter';
import { UserGroupModel } from '@app/shared/models/user-group.model';
import { DocumentTemplateApiService } from '@app/shared/services/document-template-api.service';
import { debounceTime } from 'rxjs/operators';
import { PolicyService } from '../../permission/policy.service';

@Component({
    selector: 'app-select-template',
    templateUrl: './select-template.component.html',
    styleUrls: ['./select-template.component.scss'],
})
export class SelectTemplateComponent implements OnInit {
    categories = [];
    filters = {};
    templates = [];
    filterForm: FormGroup;
    isShowDeleteSearchText = false;
    totalTemplates = 0;
    activeLink: 'my-template' | 'org' | 'public' = 'my-template';
    totalPrivateTemplates = 0;
    totalPublicTemplates = 0;
    totalOrgTemplates = 0;
    user: UserGroupModel;

    constructor(
        private documentTemplateApiService: DocumentTemplateApiService,
        private formBuilder: FormBuilder,
        private dialogRef: MatDialogRef<SelectTemplateComponent>,
        private router: Router,
        private globalService: GlobalService,
        private policyService: PolicyService,
    ) {
    }

    ngOnInit() {
        this.getUser();
        this.getTotalOrgTemplates();
        if (this.policyService.can('template::viewSystemTemplate')) {
            this.getTotalPublicTemplates();
        }
        this.getTotalMyTemplates();
        this.initFilterForm();
        this.getTemplates(true);
    }

    initFilterForm() {
        this.filterForm = this.formBuilder.group({
            key: '',
            category: [['all'], []],
            limit: 10000,
            page: 1,
            status: 'active'
        });

        this.filterForm.controls['key'].valueChanges
            .pipe(debounceTime(AppConstant.DEFAULT_SETTINGS.DEBOUNCE_TIME))
            .subscribe((value) => {
                if (value.length > 0) {
                    this.isShowDeleteSearchText = true;
                } else {
                    this.isShowDeleteSearchText = false;
                }

                this.getTemplates(true);
            });

        this.filterForm.controls['category'].valueChanges
            .pipe(debounceTime(AppConstant.DEFAULT_SETTINGS.DEBOUNCE_TIME))
            .subscribe((data) => {
                this.getTemplates();
            });
    }

    getTotalOrgTemplates() {
        this.documentTemplateApiService.find({
            limit: 10000,
            page: 1,
            status: ['active'],
            shareMode: [DOC_SHARE_MODE.EDITABLE, DOC_SHARE_MODE.READ_ONLY]
        })
            .subscribe((data: DocumentTemplatePaginationAdapter) => {
                this.totalOrgTemplates = data.pagination.total;
            });
    }

    getTotalPublicTemplates() {
        this.documentTemplateApiService.findInLibrary({
            limit: 10000,
            page: 1,
            status: 'active'
        })
            .subscribe((data: DocumentTemplatePaginationAdapter) => {
                this.totalPublicTemplates = data.pagination.total;
            });
    }

    getTotalMyTemplates() {
        this.documentTemplateApiService.find({
            createdBy: this.user.id,
            limit: 10000,
            page: 1,
            status: 'active'
        })
            .subscribe((data: DocumentTemplatePaginationAdapter) => {
                this.totalPrivateTemplates = data.pagination.total;
            });
    }

    getTemplates(isInit: boolean = false) {
        this.request().subscribe((data: DocumentTemplatePaginationAdapter) => {
            this.templates = [...data.results];

            if (isInit) {
                this.totalTemplates = this.templates.length;
                this.categories = data.pagination.categories;
            }
        });
    }

    getUser() {
        this.globalService.storage.watch(AppConstant.GLOBAL_STORAGE.USER)
            .subscribe(data => {
                this.user = data;
            });
    }

    request() {
        if (this.activeLink === 'public') {
            return this.documentTemplateApiService.findInLibrary(this.prepareFilterData());
        } else if (this.activeLink === 'org') {
            return this.documentTemplateApiService.find({
                ...this.prepareFilterData(),
                status: ['active'],
                shareMode: [DOC_SHARE_MODE.EDITABLE, DOC_SHARE_MODE.READ_ONLY]
            });
        } else {
            return this.documentTemplateApiService.find({
                ...this.prepareFilterData(),
                createdBy: this.user.id
            });
        }
    }

    resetItemSearchValue() {
        this.filterForm.controls.key.patchValue('');
    }

    close(result?) {
        this.dialogRef.close(result);
    }

    prepareFilterData() {
        const data = this.filterForm.getRawValue();

        if (data.category && data.category.length && data.category[0] === 'all') {
            delete data.category;
        }

        return data;
    }

    navigateToCreateDocument(template) {
        this.close(template);
    }

    activeTemplate(option: 'my-template' | 'org' | 'public') {
        if (option === 'org') {
            this.totalTemplates = this.totalOrgTemplates;
        } else if (option === 'public') {
            this.totalTemplates = this.totalPublicTemplates;
        } else {
            this.totalTemplates = this.totalPrivateTemplates;
        }

        this.activeLink = option;
        this.filterForm.patchValue({
            key: '',
            category: ['all'],
            limit: 10000,
        });
    }
}
