<div class="app-confirm-modal layout__main-modal-2">
    <!-- <mat-icon *ngIf="action === 'confirm'" class="log-out-img" svgIcon="warning"></mat-icon> -->
    <img *ngIf="action === 'confirm'" src="../../../../assets/images/icons/illustrations/warning.svg" alt="">
    <img *ngIf="action === 'delete'" src="../../../../assets/images/icons/illustrations/delete.svg" alt="">
    <img *ngIf="action === 'error'" src="../../../../assets/images/icons/illustrations/error.svg" alt="">
    <img *ngIf="action === 'notify'" src="../../../../assets/images/icons/illustrations/done.svg" alt="">
    <div class="dialog-title">
        <div class="title">{{title | translate}}</div>
        <div class="sub-title">{{message | translate}}</div>
    </div>
    <div class="dialog-action">
        <button *ngIf="!disableConfirm" mat-flat-button color="primary" type="button" class="button confirm-modal-btn"
            (click)="action === 'delete' ? close() : close('confirm')">
            {{ (action === 'delete' ? cancelLabel : confirmLabel )| translate | titlecase}}
        </button>
        <button *ngIf="!disableCancel" mat-button type="button" class="button cancel-btn confirm-modal-btn"
            (click)="action === 'delete' ? close('confirm') : close()">
            {{ (action === 'delete' ? confirmLabel : cancelLabel )| translate | titlecase}}
        </button>
    </div>
</div>
