import { Inject, Injectable, InjectionToken } from '@angular/core';
import { NavigationEnd, ResolveEnd, Router } from '@angular/router';
import { AppConstant } from '@app/app.constant';
import { UserModel } from '@app/shared/models/user.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { Helper } from '../common/helper';
import { RedrConfig, RedrConfigOption } from '../models/redr-config.model';
import { GlobalService } from './global.service';
import { LocalStorageService } from './local-storage.service';

// Create the injection token for the custom settings
export const REDR_CONFIG = new InjectionToken('redrConfig');
@Injectable({
    providedIn: 'root'
})
export class RedrConfigService {
    private configSubject!: BehaviorSubject<RedrConfig>;
    private readonly defaultConfig: RedrConfig;
    private localStorageKey: string;
    private readonly configToSaveLocalStorage = {
        displayColumns: {}
    };

    private readonly optDefaults: RedrConfigOption = { emitEvent: true, updateLocalStrorage: true };
    constructor(
        private router: Router,
        private globalService: GlobalService,
        @Inject(REDR_CONFIG) private redrConfig: RedrConfig
    ) {
        // Set the default config from the user provided config (from forRoot)
        this.defaultConfig = this.redrConfig;
        // Initialize the service
        this.init();
    }
    /**
     * Set and get the config
     */
    set config(value) {
        // Get the value from the behavior subject
        const currentConfig = this.configSubject.getValue();

        // Merge the new config
        const config = Helper.mergeDeep(currentConfig, value);

        // Notify the observers
        this.configSubject.next(config);
    }

    get config(): any | Observable<any> {
        return this.configSubject.asObservable();
    }

    /**
     * Get default config
     *
     * @returns {any}
     */
    get default(): any {
        return this.defaultConfig;
    }
    /**
     * Initialize
     *
     * @private
     */
    private init(): void {
        // Set the config from the default config
        this.configSubject = new BehaviorSubject(this.default);
        this.router.events
            .pipe(filter(event => event instanceof ResolveEnd))
            .subscribe(() => {
                // Clone the current config
                const config = this.configSubject.getValue();
                if (!Helper.isEqual(config.layout, this.defaultConfig.layout)) {

                    // Reset the layout from the default config
                    config.layout = this.defaultConfig.layout;
                    // Set the config
                    this.setConfig(config);
                }
            });

        this.globalService.storage.watch(AppConstant.GLOBAL_STORAGE.USER).subscribe((data: UserModel) => {
            this.setLocalStorageKey(`redrConfig_${data.id}`);
            const config = LocalStorageService.getItem(this.localStorageKey);
            if (config) {
                // this.configSubject.next(config);
                this.setConfig(config, { updateLocalStrorage: false });
            }

        });
        this.router.events.pipe(
            filter((event) => {
                // console.log('hellp router', event);
                if (event instanceof NavigationEnd && event.url.includes('auth/login')) {
                    return true;
                } else {
                    return false;
                }
            })
        ).subscribe(data => {
            this.configSubject.next(this.defaultConfig);
        });
    }

    /**
     * Set config
     *
     * @param value
     * @param {{emitEvent: boolean}} opts
     */
    setConfig(value: RedrConfig, opts?: RedrConfigOption): void {
        // Get the value from the behavior subject
        let config = this.configSubject.getValue();
        // Merge the new config
        config = Helper.mergeDeep(config, value);
        // Merge the new options
        opts = Helper.mergeDeep(this.optDefaults, opts);
        if (opts.updateLocalStrorage) {
            const LocalStorageData = Helper.leftJoin(this.configToSaveLocalStorage, value);
            LocalStorageService.setItem(this.localStorageKey, JSON.stringify(LocalStorageData));
        }

        // If emitEvent option is true...
        if (opts.emitEvent === true) {
            // Notify the observers
            this.configSubject.next(config);
        }
    }

    /**
     * Get config
     *
     * @returns {Observable<any>}
     */
    getConfig(): Observable<RedrConfig> {
        return this.configSubject.asObservable();
    }

    mergeConfig(currentValue: any, newValue: any): any {
        return { ...currentValue, ...newValue };
    }


    setLocalStorageKey(key: string): void {
        this.localStorageKey = key;
    }

    getLocalStorageKey(): string {
        return this.localStorageKey;
    }

}
