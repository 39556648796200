import { Pipe, PipeTransform } from '@angular/core';
import { ITEM_TYPES } from '@app/modules/item/item.constant';

@Pipe({
    name: 'itemType'
})
export class ItemTypePipe implements PipeTransform {

    transform(value: string): unknown {
        const foundType = ITEM_TYPES.find(item => item.key === value || item.value.toLocaleLowerCase().includes(value.toLocaleLowerCase()));
        return foundType ? foundType.value : value;
    }

}
