import { MAT_DATE_LOCALE, MatDateFormats } from '@angular/material/core';
import * as moment from 'moment';
import { Inject, Injectable, Optional } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { AppConstant } from '@app/app.constant';

@Injectable()
export class AppMomentDateAdapter extends MomentDateAdapter {
    constructor(@Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string) {
        super(dateLocale);
    }

    format(date: moment.Moment, displayFormat: string): string {
        date = this.clone(date);
        if (!this.isValid(date)) {
            throw Error('MomentDateAdapter: Cannot format invalid date.');
        }
        if (displayFormat === 'input') {
            return date.format(AppConstant.FORMAT_DATE);
        } else {
            return date.format(displayFormat);
        }
    }

    deserialize(value) {
        if (typeof value === 'string') {
            if (moment(value, moment.ISO_8601).isValid()) {
                return moment(value, moment.ISO_8601);
            } else if (moment(value, AppConstant.FORMAT_DATE).isValid()) {
                return moment(value, AppConstant.FORMAT_DATE);
            } else if (moment(value, AppConstant.FORMAT_TIME_DATE).isValid()) {
                return moment(value, AppConstant.FORMAT_TIME_DATE);
            } else if (moment(value).isValid()) {
                return moment(value);
            } else {
                return '';
            }
        }
        return value;
    }
}
export const APP_DATE_FORMATS: MatDateFormats = {
    parse: {
        dateInput: 'l',
    },
    display: {
        dateInput: 'input',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
        monthYearLabel: 'MMM YYYY',
    },
};
