import {
    BusinessUnitModel,
    BusinessUnitInterface,
} from '@app/shared/models/business-unit.model';
import {
    PromotionMatrixInterface,
    PromotionMatrixModel,
} from './promotion-matrix.model';
import { UserModel } from '@app/shared/models/user.model';
import { UserInterface } from './user.model';
import { BaseModel } from '@app/core/models/base.model';
import { Exclude, Expose, Type } from 'class-transformer';
import { AddressInterface } from './address.model';
import {
    PhoneNumber,
    PhoneNumberInterface,
} from '@app/modules/shared/phone-number/phone-number.component';
import { Default } from '@app/core/decorators/default.decorator';

export interface IVendor {
    id: number;
    code: string;
    uenNumber: string;
    name: string;
    email?: string;
    logo?: string;
    phone?: PhoneNumberInterface;
    postalCode?: string;
    unitNumber?: string;
    blockNumber?: string;
    streetName?: string;
    buildingName?: string;
    floorNumber?: string;
    dictrict?: string;
    contact?: {
        name?: string;
        title?: string;
        email?: string;
        phone?: PhoneNumberInterface;
    };
    promoGCE: boolean;
    remark: string;
    createdBy: UserModel;
}

@Exclude()
export class VendorModel extends BaseModel implements IVendor {
    @Expose()
    id: number;

    @Expose()
    code: string;

    @Expose()
    name: string;

    @Expose()
    uenNumber: string;

    @Expose()
    logo: string;
    @Expose()
    email: string;
    @Expose()
    website: string;

    @Expose()
    phone: PhoneNumberInterface;

    @Expose()
    address: {
        postalCode?: string;
        unitNumber?: string;
        blockNumber?: string;
        streetName?: string;
        buildingName?: string;
        floorNumber?: string;
        dictrict: string;
        country: string;
    };

    @Expose()
    contact?: {
        name?: string;
        title?: string;
        email?: string;
        phone?: PhoneNumberInterface;
    };
    @Expose()
    @Default(true)
    promoGCE: boolean;
    @Expose()
    remark: string;

    // @Expose({ name: 'createdByUser' })
    @Expose()
    @Type(() => UserModel)
    createdBy: UserModel;

    @Expose()
    updatedAt: Date;
}
