import { Pipe, PipeTransform } from '@angular/core';
import { Helper } from '@app/core/common/helper';

@Pipe({
    name: 'phoneNumberLabel'
})
export class PhoneNumberLabelPipe implements PipeTransform {
    transform(value: any): string {
        const data = Helper.toPhoneNumber(value);
        if (!data) {
            return '';
        }
        if (!data.countryCode && !data.phoneNumber) {
            return '';
        }
        // if (!data.countryCode && !data.phoneNumber) {
        //     return `empty`;
        // }
        if (!data.countryCode || !data.phoneNumber) {
            return `${data.countryCode || ''}${data.phoneNumber || ''}`;
        }
        // if (!data.countryCode || !data.phoneNumber) {
        //     return `${data.countryCode || ''}${data.phoneNumber || ''} (invalid)`;
        // }
        return `+${data.countryCode} ${data.phoneNumber}`;
    }
}
