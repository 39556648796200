import { Default } from '@app/core/decorators/default.decorator';
import { BaseModel } from '@app/core/models/base.model';
import { Exclude, Expose, Transform, Type } from 'class-transformer';
import { SettingModel } from './setting.model';
export enum CaseSelectionRestrictedKey {
    PARTIES = 'case_submission.parties',
    PROPERTY_INFO = 'case_submission.property_info',
    TRANSACTION_INFO = 'case_submission.transaction_info',
    COMMISSION = 'case_submission.commission',
    AGENCY_INFO = 'case_submission.agency',
    INTERNAL_SALESPERSON = 'case_submission.internal_salesperson',
    EXTERNAL_SALESPERSON = 'case_submission.external_salesperson',
    DOCUMENT = 'case_submission.document',
    NOTE = 'case_submission.note',
}
export interface DocRequirementForCaseInterface {
    id: string;
    name: string;
    description: string;
    isRequired: boolean;
    isEnabled: boolean;
    order: number;
    slug?: string;
}
@Exclude()
export class DocRequirementForCaseModel
    extends BaseModel
    implements DocRequirementForCaseInterface
{
    @Expose()
    id: string;
    @Expose()
    name: string;
    @Expose()
    description: string;
    @Expose()
    isRequired: boolean;
    @Expose()
    isEnabled: boolean;
    @Expose()
    order: number;

    @Expose()
    slug?: string;

    @Expose()
    metadata?: Record<string, any>;

    get transformType(): string {
        return this.isRequired ? 'required' : 'optional';
    }
}
export interface CaseSubmissionRequirementMappingItemValueInterface {
    id: string;
    name: string;
    description: string;
    type: string;
    status: string;
    isDeleted?: boolean;
    documentRequirements: DocRequirementForCaseInterface[];
}
@Exclude()
// tslint:disable-next-line: max-line-length
export class CaseSubmissionRequirementMappingItemValueModel
    extends BaseModel
    implements CaseSubmissionRequirementMappingItemValueInterface
{
    @Expose()
    id: string;

    @Expose()
    name: string;

    @Expose()
    @Default('')
    description: string;

    @Expose()
    type: string;

    @Expose()
    status: string;

    @Expose()
    isDeleted: boolean;

    @Expose()
    @Transform((data) => {
        return data || [];
    })
    @Type(() => DocRequirementForCaseModel)
    documentRequirements: DocRequirementForCaseModel[];
}
export interface CaseSubmissionPartyRoleMappingItemValueInterface {
    uuid: string;
    slug?: string;
    name: string;
    description: string;
    type: string;
    status: string;
    isDeleted?: boolean;
}

@Exclude()
// tslint:disable-next-line: max-line-length
export class CaseSubmissionPartyRoleMappingItemValueModel
    extends BaseModel
    implements CaseSubmissionPartyRoleMappingItemValueInterface
{
    @Expose()
    uuid: string;

    @Expose()
    slug?: string;

    @Expose()
    name: string;

    @Expose()
    @Default('')
    description: string;

    @Expose()
    type: string;

    @Expose()
    status: string;
    @Expose()
    isActive: boolean;

    @Expose()
    readonly: boolean;
    @Expose()
    isDeleted: boolean;

    isEqual(idOrSlug: string): boolean {
        return this.uuid === idOrSlug || this.slug === idOrSlug;
    }
    get identity() {
        return this.slug || this.uuid;
    }
}

export interface ICaseSectionRestrictedItem {
    key: string;
    name: string;
}
@Exclude()
// tslint:disable-next-line: max-line-length
export class CaseSectionRestrictedItemModel
    extends BaseModel
    implements ICaseSectionRestrictedItem
{
    @Expose()
    key: string;

    @Expose()
    name: string;
}
export interface CaseSectionRestrictedMappingItemValueInterface {
    queries: {
        status: string;
    };
    sections: string[];
}
@Exclude()
// tslint:disable-next-line: max-line-length
export class CaseSectionRestrictedMappingItemValueModel
    extends BaseModel
    implements CaseSectionRestrictedMappingItemValueInterface
{
    @Expose()
    queries: {
        status: string;
    };

    @Expose()
    sections: string[];
}
export interface CaseSubmissionRequirementMappingValueInterface {
    items: CaseSubmissionRequirementMappingItemValueInterface[];
    seedDefaultCasesAt: string;
}
@Exclude()
export class CaseSubmissionRequirementMappingValueModel
    extends BaseModel
    implements CaseSubmissionRequirementMappingValueInterface
{
    @Expose()
    @Type(() => CaseSubmissionRequirementMappingItemValueModel)
    items: CaseSubmissionRequirementMappingItemValueModel[];

    @Expose()
    seedDefaultCasesAt: string;
}
export interface CaseSubmissionPartyRoleMappingValueInterface {
    items: CaseSubmissionPartyRoleMappingItemValueInterface[];
    seedDefaultCasesAt: string;
}

export interface CaseSectionRestrictedMappingValueInterface {
    items: CaseSectionRestrictedMappingItemValueInterface[];
}

@Exclude()
export class CaseSubmissionPartyRoleMappingValueModel
    extends BaseModel
    implements CaseSubmissionPartyRoleMappingValueInterface
{
    @Expose()
    @Type(() => CaseSubmissionPartyRoleMappingItemValueModel)
    items: CaseSubmissionPartyRoleMappingItemValueModel[];

    @Expose()
    seedDefaultCasesAt: string;
}
@Exclude()
export class CaseSectionRestrictedMappingValueModel
    extends BaseModel
    implements CaseSectionRestrictedMappingValueInterface
{
    @Expose()
    @Type(() => CaseSectionRestrictedMappingItemValueModel)
    items: CaseSectionRestrictedMappingItemValueModel[];

    @Expose()
    seedDefaultCasesAt: string;
}
@Exclude()
export class CaseSubmissionRequirementSettingModel extends SettingModel {
    @Expose()
    @Default({
        items: [],
    })
    @Type(() => CaseSubmissionRequirementMappingValueModel)
    value: CaseSubmissionRequirementMappingValueModel;
}
@Exclude()
export class CaseSubmissionPartyRoleSettingModel extends SettingModel {
    @Expose()
    @Default({
        items: [],
    })
    @Type(() => CaseSubmissionPartyRoleMappingValueModel)
    value: CaseSubmissionPartyRoleMappingValueModel;
}
@Exclude()
export class CaseSectionRestrictedSettingModel extends SettingModel {
    @Expose()
    @Default({
        items: [],
    })
    @Type(() => CaseSectionRestrictedMappingValueModel)
    value: CaseSectionRestrictedMappingValueModel;
}
export interface AccountReceivedCaseSubmissionSettingInterface {
    keyName: string;
    displayName: string;
    description: string;
    value: {
        name: string;
        email: string;
        userId: number;
    };
}

export class AccountReceivedCaseSubmissionSettingModel
    extends BaseModel
    implements AccountReceivedCaseSubmissionSettingInterface
{
    keyName: string;
    displayName: string;
    description: string;
    value: {
        name: string;
        email: string;
        userId: number;
    };
}

export interface EcddPartyProfileSettingInterface {
    keyName: string;
    displayName: string;
    description: string;
    value: {
        name: 'ecdd' | 'normal';
    };
}

@Exclude()
export class EcddPartyProfileSettingModel
    extends BaseModel
    implements EcddPartyProfileSettingInterface
{
    @Expose()
    keyName: string;

    @Expose()
    displayName: string;

    @Expose()
    description: string;

    @Expose()
    value: {
        name: 'ecdd' | 'normal';
    };
}

@Exclude()
export class DocumentMappingValueModel
    extends BaseModel
{
    @Expose()
    enabled: boolean;

    @Expose()
    optionalEmail: boolean;
}
@Exclude()
export class DocumentSettingModel extends SettingModel {
    @Expose()
    @Type(() => DocumentMappingValueModel)
    value: DocumentMappingValueModel;
}