import { BaseModel } from '@app/core/models/base.model';
import { A4PrintOptions } from '@app/modules/content-builder/services/content-builder.service';

export interface ContentBuilderContentInterface {
    html: string;
    css: string[];
    printOptions: A4PrintOptions;
    annotations?: any[];
}

export class ContentBuilderContentModel
    extends BaseModel
    implements ContentBuilderContentInterface
{
    html: string;
    css: string[];
    printOptions: A4PrintOptions;
    annotations?: any[];
}
