<div (focusin)="onFocusIn($event)" (focusout)="onFocusOut($event)" [formGroup]="form" class="phone-number-control"
    *ngIf="countrySelected">
    <div class="iti__flag-container">
        <div class="iti__selected-flag" [appFloatPanel]="floatingFlagPanel" panelPosition="bottomLeft"
            [disabled]="disabled" (opened)="onPanelOpen()" (closed)="onPanelClose()">
            <div class="iti__flag iti__{{countrySelected.iso2Code}}"></div>
            <div class="iti__arrow" [ngClass]="{'iti__arrow--up': isFlagBtnClick}"></div>
        </div>
        <app-float-panel #floatingFlagPanel>
            <div class="iti__panel">
                <div class="iti__search">
                    <div class="iti__search-background">
                        <input #searchInput class="iti__input" type="text" name="" id=""
                            placeholder="Search country ..." (keyup)="onKeydown($event)">
                    </div>
                </div>
                <ul class="iti__country-list">
                    <app-country-item
                        *ngFor="let country of countries | filter: 'name' : searchInput.value; index as index"
                        [item]="country" class="iti__country" (click)="selectCountry(country)">
                        <div class="iti__flag-box">
                            <div class="iti__flag iti__{{country.iso2Code}}"></div>
                        </div>
                        <span class="iti__country-name">{{country.name}}</span>
                        <span class="iti__dial-code">+{{country.dialCode}}</span>
                    </app-country-item>
                </ul>
            </div>

        </app-float-panel>

    </div>
    <div class="itt__dial-code-container">
        <span class="iti__dial-code">+{{countrySelected.dialCode}}</span>
    </div>
    <input #phoneInput class="iti__input" [placeholder]="placeholder" formControlName="phoneNumber" appNumberOnly />
</div>
