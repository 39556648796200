import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { AppConstant } from '@app/app.constant';

@Pipe({
    name: 'datetime'
})
export class DateTimePipe implements PipeTransform {

    transform(value: moment.Moment | string | Date, formatString: string = AppConstant.FORMAT_DATE_TIME): any {
        if (moment.isMoment(value)) {
            return value.format(formatString);
        }
        const momentValue = moment(value);
        if (momentValue.isValid()) {
            return momentValue.format(formatString);
        }
        return value;
    }
}
