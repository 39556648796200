import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {

    transform(value: any, args?: any): any {
        if (!isNaN(value)) {
            if (value >= 1024 * 1024) {
                return Math.round(value / 1024 / 1014) + 'MB';
            } else if (value >= 1024) {
                return Math.round(value / 1024) + 'KB';
            } else {
                return value + 'B';
            }
        } else {
            return '';
        }
    }
}
