import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling';
import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
    selector: '[appAutocompletePosition]',
})
export class AutocompletePositionDirective implements OnDestroy, OnInit {
    private matAutocompleteTrigger: MatAutocompleteTrigger;
    private unsubscribeAll = new Subject<any>();

    constructor(private scroll: ScrollDispatcher) {

    }
    ngOnInit(): void {
        this.scroll.scrolled().pipe(takeUntil(this.unsubscribeAll)).subscribe(this.scrollEvent);
    }

    @Input() set autocompletePosition(value: MatAutocompleteTrigger) {
        this.matAutocompleteTrigger = value;
    }

    private scrollEvent = (ev: CdkScrollable): void => {

        if (this.matAutocompleteTrigger == null) {
            return;
        }

        if (this.matAutocompleteTrigger.panelOpen) {
            this.matAutocompleteTrigger.updatePosition();

            const elePanel = this.matAutocompleteTrigger.autocomplete.panel.nativeElement as HTMLElement;
            const selectedElementRef = ev.getElementRef()
            if (selectedElementRef && elePanel.parentElement.getBoundingClientRect().y < selectedElementRef.nativeElement.getBoundingClientRect().y) {
                // Disappear the autocomplete panel
                elePanel.classList.add('hidden');
            } else {
                // Show the autocomplete panel
                if (elePanel.classList.contains('hidden')) {
                    elePanel.classList.remove('hidden');
                }
            }
        }
    }

    ngOnDestroy() {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }
}
