import { Component, OnDestroy, OnInit } from '@angular/core';
import { QuickActionService } from './quick-action.service';
import { IQuickAction, IQuickActions } from './quick-action.interface';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { RedMediaWatcherService } from '../classic-layout/media-watcher';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';

@Component({
    selector: 'app-quick-action',
    templateUrl: './quick-action.component.html',
    styleUrls: ['./quick-action.component.scss'],
})
export class QuickActionComponent implements OnInit, OnDestroy {
    actions: IQuickActions = [];
    isMobileScreen = false;
    private _unsubscribeAll: Subject<void> = new Subject<void>();

    constructor(
        private quickActionService: QuickActionService,
        private _mediaWatcherService: RedMediaWatcherService,
        private _router:Router
    ) {}
    ngOnInit(): void {
        this.quickActionService.actions$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res) => {
                console.log('res --> ',res)
                this.actions = res;
            });
        // Subscribe to media changes
        this._mediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases, matchingQueries }) => {
                // Check if the screen is small
                this.isMobileScreen = matchingAliases.length === 0;
            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    onSelectAction(event,action:IQuickAction):void{
        console.log('onSelectAction --> ',event,action)
        if(action.click){
            action.click(event);
            return;
        }
        if(action.link){
            try {
                const url = new URL(action.link);
                if(environment.baseUrl.includes(url.origin) ){
                    this._router.navigateByUrl(action.link)
                }else{
                    location.href = url.href
                }
            } catch (error) {
                this._router.navigateByUrl(action.link)
            }
            return;
        }
    }
}
