<!-- <div class="app-walkthrough-modal">
    <div class="title">{{ modules[index].title | translate | titlecase}}</div>
    <div class="img-shadow">
        <img [src]="modules[index].url" class="img-shadow">
    </div>
    <div class="dot-wrapper" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="center center">
        <div class="dot" *ngFor="let data of modules; let i = index" [ngClass]="{'dot-active': i === index}"></div>
    </div>
    <div class="description" fxLayout="row" fxLayoutAlign="center center">
        <div class="description-content">{{ modules[index].description | translate}}</div>
    </div>
    <div class="line"></div>
    <div class="walkthrough-button" fxLayout="row" fxLayoutAlign="space-between center">
        <button type="button" mat-button (click)="close()">{{ 'common.skip' | translate}}</button>

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="24px">
            <button type="button" mat-flat-button class="back-btn" (click)="back()" *ngIf="index > 0">
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                    <mat-icon>keyboard_arrow_left</mat-icon>
                    <span>{{ 'common.back' | translate}}</span>
                </div>
            </button>
            <button type="button" mat-flat-button color="primary" (click)="next()" *ngIf="index !== modules.length - 1">
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                    <span>{{ 'common.next' | translate}}</span>
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </div>
            </button>
        </div>
    </div>
</div> -->

<div class="app-walkthrough-modal flex flex-col items-center" style="gap: 24px;">
    <p class="m-0 text-h4 font-bold">{{ modules[index].title | translate | titlecase}}</p>
    <img [src]="modules[index].url" class="walkthrough-img block redoc-card">
    <div class="walkthrough-content flex flex-col gap-3">
        <div class="walkthrough-content__dot-wrapper flex flex-row gap-4 items-center justify-center">
            <div class="dot" *ngFor="let data of modules; let i = index" [ngClass]="{'dot-active': i === index}"></div>
        </div>
        <p class="m-0 text-center text-b1 text-theme-gray">
            {{ modules[index].description | translate}}
        </p>
    </div>
    <div class="walkthrough-actions flex flex-col gap-3">
        <div class="walkthrough-actions__nav flex justify-center lg:justify-end gap-2">
            <button type="button" mat-flat-button class="back-btn" (click)="back()"
                [ngClass]="{'back-btn--full': index === modules.length - 1}" *ngIf="index > 0">
                <div class="flex flex-row items-center justify-center gap-1">
                    <mat-icon>keyboard_arrow_left</mat-icon>
                    <span>{{ 'common.back' | translate}}</span>
                </div>
            </button>
            <button type="button" mat-flat-button color="primary" class="next-btn" (click)="next()"
                [ngClass]="{'next-btn--full': index === 0}" *ngIf="index !== modules.length - 1">
                <div class="flex flex-row items-center justify-center gap-1">
                    <span>{{ 'common.next' | translate}}</span>
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </div>
            </button>
        </div>
        <div class="walkthrough-actions__skip">
            <button type="button" mat-button color="primary" class="skip-btn skip-btn--full" (click)="startTour()">
                <span>{{ 'common.skip' | translate}}</span>
            </button>
        </div>
    </div>
</div>