import { BaseModel } from '@core/models/base.model';
import { UserModel } from '@shared/models/user.model';
import { Type } from 'class-transformer';

export interface AuditTrailInterface {
    id: number;
    action: string;
    clientAgent: string;
    clientIpAddress: string;
    contractUniqueId: number;
    createdAt: string;
    createdBy: number;
    createdByUser: UserModel;
    metadata: AuditTrialMetadataInterface;
    description: string;
    weight: number;
}

export class AuditTrailModel extends BaseModel implements AuditTrailInterface {
    id: number;
    action: string;
    clientAgent: string;
    clientIpAddress: string;
    contractUniqueId: number;
    createdAt: string;
    createdBy: number;

    @Type(() => UserModel)
    createdByUser: UserModel;

    @Type(() => AuditTrialMetadataModel)
    metadata: AuditTrialMetadataModel;

    description: string;
    weight: number;
}


export interface AuditTrialMetadataInterface {
    actor: any;
    blobName: any;
    request: any;
    sha256Hash: string;
    sourceHash: string;
    participant: any;
    change: any;
    action: any;
}

export class AuditTrialMetadataModel extends BaseModel implements AuditTrialMetadataInterface {
    actor: any;
    blobName: any;
    request: any;
    sha256Hash: string;
    sourceHash: string;
    participant: any;
    change: any;
    action: any;
}
