import {
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    ViewContainerRef,
} from '@angular/core';
import { merge, of, Subject, Subscription } from 'rxjs';
import {
    ConnectionPositionPair,
    FlexibleConnectedPositionStrategy,
    Overlay,
    OverlayConfig,
    OverlayRef,
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { POSITION_MAP } from '@app/shared/data/connection-position-pair';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { MatFormField } from '@angular/material/form-field';
import { FloatPanelComponent } from './float-panel.component';

enum MenuState {
    closed = 'closed',
    opened = 'opened',
}

@Directive({
    selector: '[appFloatPanel]',
})
export class FloatPanelDirective implements OnInit, OnDestroy {
    @Output() opened: EventEmitter<void> = new EventEmitter<void>();
    @Output() closed: EventEmitter<void> = new EventEmitter<void>();
    @Input() fromEvent: 'click' | 'focus' = 'click';
    @Input() panelPosition = 'right';
    @Input() disabled = false;

    /** References the menu instance that the trigger is associated with. */
    @Input('appFloatPanel')
    get appFloatingPanel() {
        return this.panel;
    }

    set appFloatingPanel(panel: FloatPanelComponent) {
        if (panel === this.panel) {
            return;
        }
        this.panel = panel;
        this.panelCloseSubscription.unsubscribe();

        if (panel) {
            this.panelCloseSubscription = panel.closed.subscribe((reason) => {
                this._destroyMenu();
                // If a click closed the menu, we should close the entire chain of nested menus.
                if (
                    (reason === 'click' || reason === 'tab') &&
                    this.parentPanel
                ) {
                    this.parentPanel.closed.emit(reason);
                }
            });
        }
    }

    private panel: FloatPanelComponent;
    private portal: TemplatePortal;
    private positions: ConnectionPositionPair[] = [
        POSITION_MAP.topCenter,
        POSITION_MAP.bottomCenter,
    ];
    private overlayRef: OverlayRef;
    private panelState = MenuState.closed;
    private closingActionsSubscription = Subscription.EMPTY;
    private panelCloseSubscription = Subscription.EMPTY;

    private elementClicked = new Subject<any>();
    private unsubscribeAll = new Subject<any>();

    constructor(
        private el: ElementRef,
        private overlay: Overlay,
        private vcr: ViewContainerRef,
        @Optional() private parentPanel: FloatPanelComponent,
        @Optional() private formFieldParent: MatFormField
    ) {}

    ngOnInit(): void {
        this.elementClicked
            .pipe(debounceTime(300), takeUntil(this.unsubscribeAll))
            .subscribe((event: MouseEvent | FocusEvent) => {
                if (!event) {
                    return;
                }
                if (!this.appFloatingPanel) {
                    return;
                }
                if (this.disabled) {
                    return;
                }
                if (
                    this.fromEvent === 'click' &&
                    this.el.nativeElement.contains(event.target)
                ) {
                    this.openPanel();
                    return;
                }
                if (
                    this.fromEvent === 'focus' &&
                    this.formFieldParent &&
                    this.formFieldParent._elementRef.nativeElement.contains(
                        event.target
                    )
                ) {
                    this.openPanel();
                    return;
                }
            });
    }

    ngOnDestroy(): void {
        if (this.overlayRef) {
            this.overlayRef.dispose();
            this.overlayRef = null;
        }
        this.closingActionsSubscription.unsubscribe();
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }

    @HostListener('document:click', ['$event']) onClick(event: MouseEvent) {
        this.elementClicked.next(event);
    }

    @HostListener('focus', ['$event']) onFocus(event) {
        this.elementClicked.next();
    }

    openPanel() {
        if (this.panelState === MenuState.opened) {
            return;
        }
        const overlayRef = this._createOverlay();
        overlayRef.attach(this.getPortal());
        this.closingActionsSubscription = this._menuClosingActions().subscribe(
            () => {
                this.closePanel();
            }
        );
        this.overlayRef = overlayRef;
        this.panelState = MenuState.opened;
        this.opened.emit();
    }

    closePanel(): void {
        if (this.panelState === MenuState.closed) {
            return;
        }
        this.overlayRef?.detach();
        this.panelState = MenuState.closed;
        this.closed.emit();
    }

    private getOverlayConfig(): OverlayConfig {
        const positionStrategy = this.overlay
            .position()
            .flexibleConnectedTo(this.el.nativeElement);
        return new OverlayConfig({
            positionStrategy,
            hasBackdrop: true,
            backdropClass: 'editor-panel__backdrop',
            panelClass: 'editor-panel__container',
            scrollStrategy: this.overlay.scrollStrategies.reposition(),
        });
    }

    private getPortal(): TemplatePortal {
        if (
            !this.portal ||
            this.portal.templateRef !== this.appFloatingPanel.panelTemplateRef
        ) {
            this.portal = new TemplatePortal<any>(
                this.appFloatingPanel.panelTemplateRef,
                this.vcr
            );
        }
        return this.portal;
    }

    private setOverlayPosition(
        positionStrategy: FlexibleConnectedPositionStrategy
    ) {
        const postionPairs = [
            POSITION_MAP[this.panelPosition],
            POSITION_MAP.right,
        ];
        this.positions = postionPairs;
        positionStrategy.withPositions([...this.positions]);
    }

    /** Returns a stream that emits whenever an action that should close the menu occurs. */
    private _menuClosingActions() {
        const backdrop = this.overlayRef?.backdropClick();
        const detachments = this.overlayRef?.detachments();
        const parentClose = this.appFloatingPanel
            ? this.appFloatingPanel.closed
            : of();
        return merge(backdrop, parentClose, detachments);
    }

    private _createOverlay(): OverlayRef {
        if (!this.overlayRef) {
            const config = this.getOverlayConfig();
            this.setOverlayPosition(
                config.positionStrategy as FlexibleConnectedPositionStrategy
            );
            this.overlayRef = this.overlay.create(config);

            // Consume the `keydownEvents` in order to prevent them from going to another overlay.
            // Ideally we'd also have our keyboard event logic in here, however doing so will
            // break anybody that may have implemented the `EditorPanelComponent` themselves.
            this.overlayRef.keydownEvents().subscribe();
        }

        return this.overlayRef;
    }

    /** Closes the menu and does the necessary cleanup. */
    private _destroyMenu() {
        if (!this.overlayRef || this.panelState === MenuState.closed) {
            return;
        }

        this.overlayRef.detach();
    }
}
