import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { AppConstant } from "@app/app.constant";
import { LocalStorageService } from "./local-storage.service";
import { LoaderModel } from "@shared/models/loader.model";
import { NotificationInterface } from "@shared/models/notification.model";
import { StorageModel } from "@shared/models/storage.model";
import { MessageInterface, MESSAGE_TYPE } from "@shared/models/message.model";
import { ActivatedRoute, Router } from "@angular/router";
import { decodeJWT } from "../common/common.utils";
import { MatDialog, MatDialogState } from "@angular/material/dialog";
import { ConfirmModalComponent } from "@app/modules/shared/confirm-modal/confirm-modal.component";
import { ErrorResponseInterface } from "../models/error-response.interface";
import { HTTP_STATUS_CODE } from "../common/http-status-code";
import { RedocConnectSSOService } from "@app/shared/redoc-connect/redoc-connect-sso.service";

@Injectable({
  providedIn: "root",
})
export class GlobalSSOService {
  loader = new LoaderModel();
  notification: Subject<NotificationInterface> = new Subject();
  storage = new StorageModel();
  message: Subject<MessageInterface> = new Subject();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private ssoService: RedocConnectSSOService
  ) {
    const tokenSet = this.ssoService.getTokenSet();
    this.storage.add(AppConstant.GLOBAL_STORAGE.TOKEN, tokenSet.accessToken);
    this.storage.add(
      AppConstant.GLOBAL_STORAGE.REFRESH_TOKEN,
      tokenSet.refreshToken
    );
    this.storage.add(AppConstant.GLOBAL_STORAGE.GROUPS_PERMISSIONS, []);
    this.storage.add(AppConstant.GLOBAL_STORAGE.USER, []);
    this.storage.add(AppConstant.GLOBAL_STORAGE.LOGIN_STATUS, tokenSet.isLogin);
    this.storage.add(AppConstant.GLOBAL_STORAGE.HAS_NEW_USER_LOGIN, false);
    this.storage.add(AppConstant.GLOBAL_STORAGE.MY_SUBSCRIPTION, []);
    this.storage.add(AppConstant.GLOBAL_STORAGE.ORGANIZATION, {});

    this.storage
      .watch(AppConstant.GLOBAL_STORAGE.HAS_NEW_USER_LOGIN)
      .subscribe((isBlocked) => {
        if (isBlocked) {
          const dialogRef = this.dialog.getDialogById('has-user-login-dialog') || this.dialog.open(ConfirmModalComponent, {
            id: "has-user-login-dialog",
            width: "500px",
            maxHeight: "900px",
            autoFocus: false,
            disableClose: true,
            data: {
              title: "common.wraning",
              message: "confirm.message.has_new_user_login",
              confirmLabel: "confirm.label.yes_activate_this_tab",
              disableCancel: true,
            },
          });
          
          // const dialogRef = this.dialog.open(ConfirmModalComponent, {
          //   id: "has-user-login-dialog",
          //   width: "500px",
          //   maxHeight: "900px",
          //   autoFocus: false,
          //   disableClose: true,
          //   data: {
          //     title: "common.wraning",
          //     message: "confirm.message.has_new_user_login",
          //     confirmLabel: "confirm.label.yes_activate_this_tab",
          //     disableCancel: true,
          //   },
          // });

          dialogRef.afterClosed().subscribe((confirm) => {
            /* When reactivate the page navigate to / that make sure the user doesn't move to 403 */
            window.location.assign("/");
          });
        }
      });

    this.listenStorageAnotherTab();
  }

  listenStorageAnotherTab() {
    /* Listen in the storage */
    window.addEventListener("storage", (data: StorageEvent) => {
      console.log("Listen in the storage", data);
      const tokenSet = this.ssoService.getTokenSet();
      // console.log()
      //   return;
      if (data.key === AppConstant.LOCAL_STORAGE_KEYS.TOKEN) {
        const isLogged = this.storage.get(
          AppConstant.GLOBAL_STORAGE.LOGIN_STATUS
        );
        // console.log("Listen in the storage", isLogged, data);

        if (isLogged) {
          if (this.hasNewUserLogin(data)) {
            this.storage.dispatch(
              AppConstant.GLOBAL_STORAGE.HAS_NEW_USER_LOGIN,
              true
            );
            return;
          }

          if (!data.newValue) {
            this.router.navigate(["/auth", "login"]);
            console.log("vao day nua");
            return;
          }
        }

        this.storage.dispatch(
          AppConstant.GLOBAL_STORAGE.TOKEN,
          tokenSet.accessToken
        );
      }
      if (data.key === AppConstant.LOCAL_STORAGE_KEYS.REFRESH_TOKEN) {
        this.storage.dispatch(
          AppConstant.GLOBAL_STORAGE.REFRESH_TOKEN,
          tokenSet.refreshToken
        );
      }
    });
  }

  /**
   * Check when have new user login to system.
   *  - {1} oldToken !== null && newToken !== null
   *      => Have an account logged at system. Need to decode newToken and currentToken. If diff userId that is login with another account
   *
   * @param data StorageEvent
   * @returns
   */
  private hasNewUserLogin(data: StorageEvent): boolean {
    // {1}
    if (data.oldValue && data.newValue) {
      const currTokenDecoded = decodeJWT(
        this.storage.get(AppConstant.GLOBAL_STORAGE.TOKEN)
      );
      const newTokenDecoded = decodeJWT(data.newValue);

      return currTokenDecoded.userId !== newTokenDecoded.userId;
    }

    return false;
  }

  handleHttpError(err: ErrorResponseInterface) {
    if (err.statusCode === HTTP_STATUS_CODE.FORBIDDEN) {
      this.message.next({
        type: MESSAGE_TYPE.error,
        message: "common.permission_denied",
      });
    } else if (err.statusCode === HTTP_STATUS_CODE.NOT_FOUND) {
      this.message.next({
        type: MESSAGE_TYPE.error,
        message: "It was deleted. Please refresh page.",
      });
      return;
    } else {
      this.message.next({
        type: MESSAGE_TYPE.error,
        message: "common.something_went_wrong",
      });
    }
  }
}
