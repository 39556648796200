import { BaseModel } from '@app/core/models/base.model';
import { Exclude, Expose } from 'class-transformer';
import {
    ReceiptCreateDto,
    ReceiptHistoryModel,
    ReceiptItemModel,
    ReceiptModel,
} from './receipt.model';
// old version
export interface PaymentMethodInterface {
    id: number;
    businessUnitId: number;
    createdById: number;
    userId: number;
    name: string;
    providerCustomerId: string;
    providerId: string;
    providerName: string;
    setupIntentId: string;
    status: string;
    type: string;
    updatedAt: string;
    createdAt: string;
    source: {
        card: {
            brand: string;
            country: string;
            funding: string;
            last4: string;
            exp_month: number;
            exp_year: number;
            fingerprint: string;
        };
    };
}

export class PaymentMethodModel
    extends BaseModel
    implements PaymentMethodInterface
{
    id: number;
    businessUnitId: number;
    createdById: number;
    userId: number;
    name: string;
    providerCustomerId: string;
    providerId: string;
    providerName: string;
    setupIntentId: string;
    status: string;
    type: string;
    updatedAt: string;
    createdAt: string;
    source: {
        card: {
            brand: string;
            country: string;
            funding: string;
            last4: string;
            exp_month: number;
            exp_year: number;
            fingerprint: string;
            checks: any;
            networks: any;
            three_d_secure_usage: any;
            wallet: any;
            generated_from: any;
        };
    };
}
// end old version
