import { environment } from '../environments/environment';
import { InjectionToken } from '@angular/core';
import { LocalStorageService } from '@core/services/local-storage.service';

export class AppConstant {
    static APP_BASE_URL = AppConstant.loadEnvironment('baseUrl');
    static API_HOST = AppConstant.loadEnvironment('apiHost');
    static API_URL_PREFIX = AppConstant.loadEnvironment('apiPrefix');
    static APP_PRODUCTION = AppConstant.loadEnvironment('production');
    static APP_STORAGE_DRIVE = AppConstant.loadEnvironment('storageDrive');
    static APP_EDITOR_DEBUG = AppConstant.loadEnvironment('editorDebug');
    static APP_ID = AppConstant.loadEnvironment('appId');
    static APP_LOGGER = AppConstant.loadEnvironment('logger');
    static APP_DEVELOPER_MODE = AppConstant.loadEnvironment('developerMode');
    static APP_RECAPTCHA_SITEKEY =
        AppConstant.loadEnvironment('recaptchaSitekey');
    static APP_GOOGLE_ANALYTICS =
        AppConstant.loadEnvironment('googleAnalytics');
    static APP_STORAGE_MEDIA_URL =
        AppConstant.loadEnvironment('storageMediaUrl');
    static SIGNING_MODE = AppConstant.loadEnvironment('signingMode');
    static DEVELOP_FEATURES = AppConstant.loadEnvironment('developFeatures');
    static DEFAULT_TIMEZONE = AppConstant.loadEnvironment('defaultTimeZone');
    static BLACK_LIST_EMAIL = AppConstant.loadEnvironment('blackListEmail');
    static SQUAREFOOT_API_TOKEN =
        AppConstant.loadEnvironment('squarefootApiToken');
    static HUTTON_URL = AppConstant.loadEnvironment('huttonUrl');
    static DEBUG_MYINFO = AppConstant.loadEnvironment('debugMyInfo') ?? false;

    static DEFAULT_SETTINGS = {
        LANGUAGE: 'en',
        DEBOUNCE_TIME: 500,
        FILTER_DEBOUNCE_TIME: 1500,
        REQUEST_TIMEOUT: 90000,
    };

    static GLOBAL_STORAGE = {
        TOKEN: 'token',
        REFRESH_TOKEN: 'refresh_token',
        USER: 'user',
        GROUPS_PERMISSIONS: 'groups_permissions',
        ORGANIZATION: 'organization',
        BUSINESS_UNITS: 'business_units',
        BUSINESS_UNIT: 'business_unit',
        LOGIN_STATUS: 'login_status',
        HAS_NEW_USER_LOGIN: 'has_new_user_login',
        MY_SUBSCRIPTION: 'my_subscription',
    };

    static LOCAL_STORAGE_KEYS = {
        LAYOUT_STATE: 'layout_state',
        TOKEN: 'redoc_access_token',
        REFRESH_TOKEN: 'redoc_refresh_token',
        USER_INFO: 'user_info',
        RAW_MY_INFO: 'raw_my_info',
        TOKEN_INVITE: 'token_invite',
        LOGIN_STATUS: 'login_status',
    };

    static SESSION_STORAGE_KEYS = {};

    static SCREEN_WIDTH = {
        MOBILE: 600,
        TABLET: 1280,
    };

    static FIELD_VALIDATION = {
        NAME_LENGTH: 120,
        // tslint:disable-next-line:max-line-length
        EMAIL_REGEXP:
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        EMAIL_LENGTH: 120,
        PASSWORD_MIN_LENGTH: 6,
        DESCRIPTION_LENGTH: 512,
        DIMENSION_LENGTH: 255,
        SKU_LENGTH: 128,
        URL: /(^|\s)((https?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)/,
        PHONE: /^-?[0-9]+(\.[0-9]*){0,1}$/g,
        ADDRESS_LENGTH: 240,
        PHONE_LENGTH: 20,
        NRIC_NUMBER_LENGTH: 20,
        PASSPORT_LENGTH: 30,
        WEBSITE_LENGTH: 240,
        ADDRESS_CHILD_LENGTH: 50,
        ADDRESS_STREET_LENGTH: 120,
        ZIP_CODE_LENGTH: 20,
        CATEGORY_LENGTH: 50,
        ITEM_NAME_LENGTH: 240,
    };

    static VIEW_MODE_OPTIONS = {
        list: 'list',
        grid: 'grid',
        detail: 'detail',
    };

    static CUSTOMER_TYPE = {
        COMPANY: 'company',
        INDIVIDUAL: 'individual',
    };

    static MAX_PRICE_INPUT = 999999999;
    static MAX_QUANTITY_INPUT = 999999;
    static FORMAT_DATE_TIME = 'DD/MM/YYYY • LT';
    static FORMAT_DATE_TIME_2 = 'MMM D, YYYY • LT';
    static FORMAT_DATE = 'DD/MM/YYYY';
    static FORMAT_DATE_2 = 'ddd, DD MMM, YYYY [GMT]Z';
    static FORMAT_DATE_3 = 'MMM DD, YYYY';
    static FORMAT_DATE_4 = 'DD MMM YYYY';
    static FORMAT_DATE_GMT = 'DD/MM/YYYY hh:mm:ss A [GMT]Z';
    static FORMAT_DAY = 'dddd';
    static FORMAT_DATE_SGT = 'DD MMM YYYY, hh:mm:ss A [SGT]';
    static FORMAT_TIME = 'hh:mm A';
    static FORMAT_TIME_DATE = 'hh:mm A - DD MMM, YYYY';
    static FORMAT_TIME_DATE_2 = 'hh:mm a • DD MMM, YYYY';
    static FORMAT_YEAR_MONTH_DAY = 'YYYY/MM/DD';
    static CURRENCY = '$';
    static CURRENCY_SINGAPORE = 'S$';
    static HTTP_ERROR = {
        HTTP_STATUS_CODE_EXCLUDE: 'http-status-code-exclude',
    };
    static HOME_PAGE_URL = 'https://www.realestatedoc.co/';

    static TOUR_COMPONENT_DATA = new InjectionToken<{}>('TOUR_COMPONENT_DATA');
    static IS_SHOW_WHAT_NEW_POPUP = true;
    static AUTOSAVE_INTERVAL_TIME = 5000;

    static loadEnvironment(key) {
        if (!environment.production) {
            const overrideEnv = LocalStorageService.getItem('environment');
            if (overrideEnv) {
                return overrideEnv[key] ?? environment[key];
            }
        }
        return environment[key];
    }
}
