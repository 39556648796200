import { NgModule } from "@angular/core";
import { DocumentUploadAndSignDialogComponent } from "./document-upload-and-sign-dialog.component";
import { CommonModule } from "@angular/common";
import { MatDialogModule } from "@angular/material/dialog";
import { UploadModule } from "@app/modules/upload/upload.module";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatButtonModule } from "@angular/material/button";
import { DocumentUploadAndSignDialogService } from "./document-upload-and-sign-dialog.service";

@NgModule({
    imports: [
        CommonModule,
        UploadModule,
        MatDialogModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatButtonModule
    ],
    declarations: [
        DocumentUploadAndSignDialogComponent
    ],
    exports: [
        DocumentUploadAndSignDialogComponent
    ],
    providers: [
        DocumentUploadAndSignDialogService
    ]
})
export class DocumentUploadAndSignDialogModule {
}
