<div class="app-what-new-modal" fxLayout="column" fxLayoutAlign="center center">
    <img src="../../../../assets/images/what-new.svg" width="200px" height="125px">
    <div class="dialog-title">What's new</div>
    <div class="dialog-des">
        We’re very excited to announce the new update for Redoc Platform. This release
        includes fixes for a couple of bugs and improve the UI for some features.
    </div>
    <div class="list-new-feature" fxLayout="column" fxLayoutGap="16px">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px" *ngFor="let data of listNewFeature">
            <mat-icon>check_circle</mat-icon>
            <div>{{data}}</div>
        </div>
    </div>
    <button type="button" mat-flat-button color="primary" (click)="close()">Let’s Get Started</button>
</div>
