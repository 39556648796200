
<div *ngIf="form" class="flex flex-col flex-auto gap-4" [formGroup]="form">
    <div class="redoc-card overflow-hidden bg-white p-4 md:gap-3 flex flex-col">
        <div class="flex flex-row items-center md:p-0 p-3">
            <mat-icon svgIcon="salesperson"></mat-icon>
            <span class="text-h7 font-bold ml-2"> Company Info</span>
        </div>
        <div
        class="flex flex-row justify-start items-center gap-6 mb-4 img-wrapper"
    >
        <div class="img-preview img-preview--circle w-16 h-16">
            <img appLoadImage [key]="fileUploader?.key" />
        </div>
        <button
            type="button"
            mat-button
            class="btn-border"
            (click)="inputFile.click()"
        >
            {{ 'customer.upload_company_logo' | translate }}
        </button>
        <input
            class="input-file"
            type="file"
            #inputFile
            [multiple]="false"
            appUpload
            [isUploadImmediately]="true"
            [maxFiles]="1"
            [isReplaced]="true"
            (finishedFile)="onFinishedFile($event)"
            [allowedMimeType]="allowedMimeType"
            [customizeFileNameFn]="
                customizeFileNameFn.bind(this)
            "
        />
    </div>
        <div class="grid md:grid-cols-2 grid-cols-1 md:gap-4">
            <mat-form-field class="full-width">
                <mat-label>{{
                    'customer.company_name_label'
                        | translate
                        | titlecase
                }}</mat-label>
                <input
                    autocomplete="off"
                    formControlName="name"
                    matInput
                    #name
                    placeholder="{{
                        'customer.company_name_placeholder' | translate
                    }}"
                    required
                    type="text"
                />
                <mat-error
                    *ngIf="form.controls['name'].hasError('required')"
                >
                    {{ 'validator.required' | translate }}
                </mat-error>
                <mat-error
                    *ngIf="
                        form.controls.name.errors &&
                        form.controls.name.errors.maxlength
                    "
                >
                    {{
                        'validator.max_length'
                            | translate
                                : {
                                      value: form.controls.name.errors
                                          .maxlength.requiredLength
                                  }
                    }}</mat-error
                >
            </mat-form-field>
            <mat-form-field class="full-width">
                <mat-label>{{
                    'common.code'
                        | translate
                        | titlecase
                }}</mat-label>
                <input
                    autocomplete="off"
                    formControlName="code"
                    matInput
                    #name
                    placeholder="Code"
                    required
                    type="text"
                />
                <mat-error
                    *ngIf="form.controls['code'].hasError('required')"
                >
                    {{ 'validator.required' | translate }}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="grid md:grid-cols-2 grid-cols-1 md:gap-4">
            <mat-form-field class="full-width">
                <mat-label>{{
                    'customer.uen_number' | translate
                }}</mat-label>
                <input
                    autocomplete="off"
                    formControlName="uenNumber"
                    matInput
                    placeholder="{{
                        'customer.enter_uen_number' | translate
                    }}"
                    
                    type="text"
                />
                <mat-error
                    *ngIf="
                        form.controls['uenNumber'].hasError('required')
                    "
                >
                    {{ 'validator.required' | translate }}
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width">
                <mat-label>{{
                    'vendor.company_email_label' | translate
                }}</mat-label>
                <input
                    autocomplete="off"
                    formControlName="email"
                    matInput
                    placeholder="{{
                        'vendor.company_email_placeholder' | translate
                    }}"
                    
                    type="text"
                />
                <mat-error
                    *ngIf="
                        form.controls['email'].hasError('required')
                    "
                >
                    {{ 'validator.required' | translate }}
                </mat-error>
                <mat-error
                    *ngIf="
                        form.controls['email'].hasError('email')
                    "
                >
                     {{ 'validator.email' | translate }}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="grid md:grid-cols-2 grid-cols-1 md:gap-4">
            <mat-form-field class="full-width">
                <mat-label>{{
                    'common.phone_number' | translate | titlecase
                }}</mat-label>
                <app-phone-number
                    formControlName="phone"
                    placeholder="{{
                        'common.phone_number_placeholder' | translate
                    }}"
                    
                >
                </app-phone-number>
                <mat-error
                    *ngIf="form.controls['phone'].hasError('required')"
                >
                    {{ 'validator.required' | translate }}
                </mat-error>
                <mat-error
                    *ngIf="
                        form.controls.phone.errors &&
                        form.controls.phone.errors.maxlength
                    "
                    >{{
                        'validator.max_length'
                            | translate
                                : {
                                      value: form.controls.phone.errors
                                          .maxlength.requiredLength
                                  }
                    }}</mat-error
                >
            </mat-form-field>
            <mat-form-field class="full-width">
                <mat-label>{{
                    'common.website' | translate
                }}</mat-label>
                <input
                    autocomplete="off"
                    formControlName="website"
                    matInput
                    placeholder="{{
                        'common.website_placeholder'
                            | translate
                            | titlecase
                    }}"
                    type="text"
                />
                <mat-error
                    *ngIf="
                        form.controls.website.errors &&
                        form.controls.website.errors.maxlength
                    "
                >
                    {{
                        'validator.max_length'
                            | translate
                                : {
                                      value: form.controls.website
                                          .errors.maxlength
                                          .requiredLength
                                  }
                    }}</mat-error
                >
            </mat-form-field>
        </div>
    </div>
    <div [formGroup]="addressForm"  class="redoc-card overflow-hidden bg-white p-4 md:gap-3 flex flex-col">
        <div class="flex flex-row justify-between items-center">
            <div class="flex flex-row items-center md:p-0 p-3">
                <mat-icon svgIcon="property_address"></mat-icon>
                <span class="text-h7 font-bold ml-2"> Address</span>
            </div>
            <mat-slide-toggle
          color="primary"
          formControlName="enabled">
      </mat-slide-toggle>
        </div>
        
        <app-one-map-address *ngIf="addressForm.get('enabled').value" formControlName="addressObj"></app-one-map-address>
    </div>
    <div  [formGroup]="contactForm" class="redoc-card overflow-hidden bg-white p-4 md:gap-3 flex flex-col">
        <div class="flex flex-row justify-between items-center">
            <div class="flex flex-row items-center md:p-0 p-3">
                <mat-icon svgIcon="referral_level"></mat-icon>
                <span class="text-h7 font-bold ml-2"> Contact Person</span>
            </div>
            <mat-slide-toggle
            color="primary"
            formControlName="enabled">
        </mat-slide-toggle>
        </div>
        <div *ngIf="contactForm.get('enabled').value" formGroupName="contact" class="flex flex-col w-full">
            <div  class="grid md:grid-cols-2 grid-cols-1 md:gap-4">
                <mat-form-field class="full-width">
                    <mat-label>{{
                        'vendor.contact_name'
                            | translate
                            | titlecase
                    }}</mat-label>
                    <input
                        autocomplete="off"
                        formControlName="name"
                        matInput
                        placeholder="{{
                            'vendor.contact_name_placeholder' | translate
                        }}"
                        required
                        type="text"
                    />
                    <mat-error
                        *ngIf="contactForm.get('contact.name')?.hasError('required')"
                    >
                        {{ 'validator.required' | translate }}
                    </mat-error>
                    <mat-error
                        *ngIf="
                            contactForm.get('contact.name')?.errors &&
                            contactForm.get('contact.name').errors.maxlength
                        "
                    >
                        {{
                            'validator.max_length'
                                | translate
                                    : {
                                          value: contactForm.get('contact.name').errors
                                              .maxlength.requiredLength
                                      }
                        }}</mat-error
                    >
                </mat-form-field>
                <mat-form-field class="full-width">
                    <mat-label>{{
                        'vendor.contact_title'
                            | translate
                            | titlecase
                    }}</mat-label>
                    <input
                        autocomplete="off"
                        formControlName="title"
                        matInput
                        placeholder="Enter title"
                        type="text"
                    />
                </mat-form-field>
            </div>
            <div class="grid md:grid-cols-2 grid-cols-1 md:gap-4">
                <mat-form-field class="full-width">
                    <mat-label>{{
                        'vendor.contact_email'
                            | translate
                            | titlecase
                    }}</mat-label>
                    <input
                        autocomplete="off"
                        formControlName="email"
                        matInput
                        #name
                        placeholder="Email"
                        type="text"
                    />
                    <mat-error
                    *ngIf="contactForm.get('contact.email')?.hasError('required')"
                >
                    {{ 'validator.required' | translate }}
                </mat-error>
                <mat-error
                    *ngIf="
                        contactForm.get('contact.email')?.hasError('email')
                    "
                >
                    {{ 'validator.email' | translate }}
                </mat-error>
                </mat-form-field>
                <mat-form-field class="full-width">
                    <mat-label>{{
                        'common.phone_number' | translate | titlecase
                    }}</mat-label>
                    <app-phone-number
                        formControlName="phone"
                        placeholder="{{
                            'common.phone_number_placeholder' | translate
                        }}"
                    >
                    </app-phone-number>
                    <mat-error
                        *ngIf="contactForm.get('contact.phone')?.hasError('required')"
                    >
                        {{ 'validator.required' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
     
   </div>
   <div  class="redoc-card overflow-hidden bg-white p-4 md:gap-3 flex flex-col">
    <div class="flex flex-row items-center md:p-0 p-3">
        <mat-icon svgIcon="content_block"></mat-icon>
        <span class="text-h7 font-bold ml-2">Additional information</span>
    </div>
    <mat-form-field class="full-width">
        <mat-label>{{
            'common.note'
                | translate
                | titlecase
        }}</mat-label>
        <textarea
            autocomplete="off"
            formControlName="remark"
            matInput
            placeholder="{{
                'common.note' | translate
            }}"
            type="text"
        > </textarea>
    
    </mat-form-field>
    <div class="flex flex-col gap-2 mb-3">
        <span>Agent's commission referred from this vendor will be accumulated?</span>
        <mat-radio-group formControlName="promoGCE" class="flex flex-row gap-4">
            <mat-radio-button [value]="true">Yes</mat-radio-button>
            <mat-radio-button [value]="false">No</mat-radio-button>
          </mat-radio-group>
    </div>
   
</div>
</div>