import { BaseModel } from '@core/models/base.model';

export interface PermissionInterface {
    id: number;
    name: string;
    slug: string;
    tag: Array<string>;
    description: string;
}

export class PermissionModel extends BaseModel implements PermissionInterface {
    id: number;
    name: string;
    slug: string;
    tag: Array<string>;
    description: string;
}
