import { Directive, EventEmitter, HostListener, Input, Output, ElementRef, OnDestroy } from '@angular/core';

@Directive({
    selector: '[appScrollEnd]'
})
export class ScrollEndDirective implements OnDestroy {
    @Input() stopListenScrollEnd = false;
    @Output() scrollEnd: EventEmitter<void> = new EventEmitter<void>();
    constructor(
        private elementRef: ElementRef
    ) {
    }
    ngOnDestroy(): void {
        this.scrollEnd.complete();
    }
    @HostListener('scroll', ['$event']) onDocumentClick(event: MouseEvent) {
        // tslint:disable-next-line: max-line-length
        const pos = this.elementRef.nativeElement.scrollTop + this.elementRef.nativeElement.offsetHeight;
        const max = this.elementRef.nativeElement.scrollHeight;
        // pos/max will give you the distance between scroll bottom and and bottom of screen in percentage.
        if (pos === max && !this.stopListenScrollEnd) {
            this.scrollEnd.emit();
        }
    }
}
