import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { AppConstant } from '@app/app.constant';
import { AuthApiService } from '@shared/services/auth-api.service';
import { GlobalService } from '../services/global.service';
import { concatMap, map, take } from 'rxjs/operators';
import { GroupApiService } from '@app/shared/services/group-api.service';
import { PolicyValues } from '@app/modules/permission/policy.constant';
import { SubscriptionApiService } from '@app/shared/services/subscription-api.service';

@Injectable({
    providedIn: 'root'
})
export class UserResolver implements Resolve<any> {
    constructor(
        private router: Router,
        private globalService: GlobalService,
        private authApiService: AuthApiService,
        private groupApiService: GroupApiService,
        private subscriptionApiService: SubscriptionApiService
    ) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return this.loadUserProfile();
    }

    loadUserProfile(from?) {
        return new Observable(observer => {
            this.globalService.storage.watch(AppConstant.GLOBAL_STORAGE.GROUPS_PERMISSIONS).pipe(take(1))
                .subscribe(data => {
                    if (!data || !data.length) {
                        return forkJoin([
                            this.authApiService.getUserProfile(),
                            this.subscriptionApiService.getMySubscription()
                        ]).pipe(concatMap(rs => {
                            this.globalService.storage.dispatch(AppConstant.GLOBAL_STORAGE.USER, rs[0]);
                            const subscriptions = rs[1];
                            if (subscriptions && subscriptions.length) {
                                this.globalService.storage.dispatch(AppConstant.GLOBAL_STORAGE.MY_SUBSCRIPTION, subscriptions[0]);
                            }

                            return this.groupApiService.getGroups();
                        }))
                            .subscribe(groups => {
                                // Hardcode
                                // Add full policy for "system", "organiztion owner" groups
                                groups.forEach(group => {
                                    if (['system', 'org'].includes(group.scope)) {
                                        if (!group.viewPolicy) {
                                            group.viewPolicy = {
                                                policy: []
                                            };
                                        }
                                        group.viewPolicy['policy'] = PolicyValues;
                                    }
                                });
                                this.globalService.storage.dispatch(AppConstant.GLOBAL_STORAGE.GROUPS_PERMISSIONS, groups);
                                observer.next();
                                observer.complete();
                            });
                    } else {
                        observer.next();
                        observer.complete();
                    }
                });
        });
    }
}
