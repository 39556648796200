import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { DocumentTemplatePaginationAdapter } from '../adapters/document-template-pagination.adapter';
import {
    DocumentTemplateModel,
    DocumentTemplateInterface,
} from '../models/document-template.model';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { ContentBuilderContentInterface } from '@shared/models/content-builder-content.model';


@Injectable({
    providedIn: 'root',
})
export class DocumentTemplateApiService {
    static DOCUMENT_TEMPLATE = 'content-builder/templates';
    static DOCUMENT_TEMPLATE_CLONE = 'content-builder/templates/clone';
    static DOCUMENT_TEMPLATE_PREVIEW = 'content-builder/preview';
    static DOCUMENT_TEMPLATE_LIBRARY = 'content-builder/templates-library';
    static TEMPLATE_SETTINGS = 'template-settings?keyPrefix="mapping"';
    static TEMPLATE_SETTING_LIST = 'template-settings/:key'; // key: mapping:{uuid}

    constructor(
        private apiService: ApiService,
        private httpClient: HttpClient
    ) {
    }

    create(data): Observable<DocumentTemplateModel> {
        return this.apiService
            .post(DocumentTemplateApiService.DOCUMENT_TEMPLATE, data, { excludeFields: ['content', 'fields'] })
            .pipe(
                map((res: DocumentTemplateInterface) =>
                    DocumentTemplateModel.fromJson(res)
                )
            );
    }

    find(query): Observable<DocumentTemplatePaginationAdapter> {
        return this.apiService
            .get(DocumentTemplateApiService.DOCUMENT_TEMPLATE, query)
            .pipe(map((data) => new DocumentTemplatePaginationAdapter(data)));
    }

    deleteById(id: string): Observable<any> {
        return this.apiService.delete(
            DocumentTemplateApiService.DOCUMENT_TEMPLATE + '/' + id
        );
    }

    findById(id: string | number): Observable<DocumentTemplateModel> {
        return this.apiService
            .get(DocumentTemplateApiService.DOCUMENT_TEMPLATE + '/' + id)
            .pipe(map((res: DocumentTemplateInterface) => {
                const data = DocumentTemplateModel.fromJson(res);
                return data;
            }));
    }

    clone(data: { id: string; status: string; name: string }): Observable<any> {
        return this.apiService
            .post(DocumentTemplateApiService.DOCUMENT_TEMPLATE_CLONE, data)
            .pipe(
                map((res: DocumentTemplateInterface) =>
                    DocumentTemplateModel.fromJson(res)
                )
            );
    }

    update(data: object): Observable<any> {
        return this.apiService.patch(
            DocumentTemplateApiService.DOCUMENT_TEMPLATE,
            data,
            { excludeFields: ['content', 'fields'] }
        );
    }

    previewFromSampleData(data: {
        id?: string;
        content?: string | ContentBuilderContentInterface;
        format: string;
        sampleData?: object;
        contentType?: string
    }): Observable<any> {
        return this.apiService
            .post(DocumentTemplateApiService.DOCUMENT_TEMPLATE_PREVIEW, data, {
                excludeFields: ['content','sampleData'],
                pretreatmentResponse: false,
                // requestOptions: {responseType: 'text'}
                requestOptions: {responseType: data.format === 'pdf' ? 'arraybuffer' : 'text'}
            })
            .pipe(
                map(res => {
                    // return res;
                    // const blob = new Blob([res], {type: 'application/pdf'});
                    // saveAs(blob, 'asdsad.pdf');
                    return res;
                })
            );
    }

    findInLibrary(query): Observable<DocumentTemplatePaginationAdapter> {
        return this.apiService
            .get(DocumentTemplateApiService.DOCUMENT_TEMPLATE_LIBRARY, {
                ...query,
                industry: 'cea'
            })
            .pipe(map((data) => new DocumentTemplatePaginationAdapter(data)));
    }
}
