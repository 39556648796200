import { Default } from "@app/core/decorators/default.decorator";
import { BaseModel } from "@core/models/base.model";
import { Exclude, Expose } from "class-transformer";

export interface ItemCategoryInterface {
  categories: string[];
  types: string[];
}

@Exclude()
export class ItemCategoryModel
  extends BaseModel
  implements ItemCategoryInterface
{
  @Expose()
  categories: string[];

  @Expose()
  @Default([])
  types: string[];

  @Expose()
  @Default([])
  typeLabels: {
    key: string;
    value: string;
  }[];
}
