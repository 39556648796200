import { Component, OnInit, ViewChild, Input, SimpleChanges, OnChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { AppConstant } from '@app/app.constant';

@Component({
    selector: 'app-attachment-table',
    templateUrl: './attachment-table.component.html',
    styleUrls: ['./attachment-table.component.scss']
})
export class AttachmentTableComponent implements OnInit, OnChanges {
    displayedColumns: string[] = ['name', 'contentType', 'createdAt', 'action'];
    dataSource;
    formatDate = AppConstant.FORMAT_DATE;

    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @Input() data = [];

    constructor(
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.dataSource = new MatTableDataSource(this.data);
        this.dataSource.sort = this.sort;
    }

    ngOnInit(): void {
        // this.dataSource.sort = this.sort;
    }
}
