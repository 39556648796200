import { Injectable } from '@angular/core';
import { BasePolicyCollection } from '@modules/permission/base.policy-collection';
import { Policies } from '../permission/policy.constant';
import { Observable } from 'rxjs';

@Injectable()
export class DashboardPolicyCollection extends BasePolicyCollection {
    readonly policyCollectionKey = 'dashboard';

    viewCompany(): boolean | Promise<boolean> | Observable<boolean> {
        return (
            this.permissionService.hasPolicy(
                this.policyCollectionKey + '::view-company'
            ) &&
            this.permissionService.hasPermissions(
                Policies[this.policyCollectionKey]['view-company']
            )
        );
    }

    accessUrl() {
        return this.view() || this.viewCompany();
    }
}
