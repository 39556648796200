import { Injectable } from '@angular/core';
import { BasePolicyCollection } from '@modules/permission/base.policy-collection';
import { DOC_SHARE_MODE } from './constants/document-template-constant';

@Injectable()
export class DocumentTemplatePolicyCollection extends BasePolicyCollection {
    readonly policyCollectionKey = 'template';

    updateTemplate(id, shareMode: DOC_SHARE_MODE) {
        return (
            this.updateOwner(id) &&
            +id === this.permissionService.user.id
        );
    }

    cloneTemplate(shareMode: DOC_SHARE_MODE) {
        return this.create() && shareMode === DOC_SHARE_MODE.EDITABLE;
    }

    publishOrganizationTemplate() {
        return (
            this.permissionService.hasPolicy(
                this.policyCollectionKey + '::create'
            ) &&
            this.permissionService.hasPolicy(
                this.policyCollectionKey + '::update'
            ) &&
            this.permissionService.hasPolicy(
                this.policyCollectionKey + '::view'
            )
        );
    }

    viewSystemTemplate() {
        return this.permissionService.hasPolicy(
            'system-template::view'
        );
    }
}
