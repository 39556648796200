import { ChangeDetectorRef, Component, DoCheck, forwardRef, Host, Input, OnDestroy, OnInit, Optional, Self } from "@angular/core";
import { ControlContainer, ControlValueAccessor, FormBuilder, FormControl, FormGroup, FormGroupDirective, NG_VALUE_ACCESSOR, NgControl, NgForm, ValidationErrors, Validators } from "@angular/forms";
import { Subject, Subscription } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";
import { IAddressBase } from "../cdk-address.interface";
import { isObject } from "lodash-es";
import { IOneMapResult, OneMapResultModel } from "./one-map-address.model";
import { OneMapAddressService } from "./one-map-address.service";
import { Helper } from "@app/core/common/helper";
import { coerceNumberProperty } from "@angular/cdk/coercion";
import { ErrorStateMatcher } from "@angular/material/core";
import { MatFormFieldControl } from "@angular/material/form-field";

@Component({
    selector:'app-one-map-address',
    templateUrl:'./one-map-address.component.html',
    styleUrls:['./one-map-address.component.scss'],
    providers: [
    //     {
    //         provide: NG_VALUE_ACCESSOR,
    //         useExisting: forwardRef(() => OneMapAddressComponent),
    //         multi: true
    //     },

    ]
})
export class OneMapAddressComponent implements ControlValueAccessor,DoCheck, OnInit,OnDestroy{
    
    @Input() required = false;

    @Input() get numOfGrids(){
        return this._numOfGrids
    }
    set numOfGrids(val){
        const num = coerceNumberProperty(val);
        if(num === 1 || num === 2){
            this._numOfGrids = num
        }
    }
    private _numOfGrids = 2
    form: FormGroup;

    private formControl: FormControl;
    subscriptions: Subscription[] = [];
    unsubscribeAll = new Subject<any>();
    searchCtr = new FormControl()
    oneMapResults: IOneMapResult[] = [];

    stateChanges: Subject<void> = new Subject<void>();
    errorState = false;
    constructor(
        private readonly formBuilder: FormBuilder,
        @Optional() @Self() public ngControl: NgControl,
        @Optional() @Host() parent: ControlContainer,
        private readonly cdr: ChangeDetectorRef,
        private addressService:OneMapAddressService,
        public parentFormGroup: FormGroupDirective,
        public defaultErrorStateMatcher: ErrorStateMatcher,
        @Optional() public parentForm: NgForm,
    ) {
        this.form = this.createForm();
        if (this.ngControl != null) {
            // Setting the value accessor directly (instead of using
            // the providers) to avoid running into a circular import.
            this.ngControl.valueAccessor = this;
        }



        this.onFormChange();
        // if (parent) {
        //     console.log('parent --> ',parent)
        //     if (parent['_parent']) {
        //         parent = parent['_parent'];
        //     }
        //     this.onParentSubmit(parent as FormGroupDirective);
        // }
        if (this.parentFormGroup) {
            this.onParentSubmit(this.parentFormGroup);
        }
        this.searchCtr.valueChanges
        .pipe(debounceTime(300))
        .subscribe(value => {
            if (value instanceof OneMapResultModel) {
                this._selectPostalCode(value);
            } else {
                this._searchPostalCode(value);
            }
        });
    }
    private _selectPostalCode(data: OneMapResultModel) {
        const temp = this.addressService.removeDataNullFromOneMapAddress(data);
        this.form.patchValue({
            blockNumber: temp.blkNo,
            streetName: temp.roadName,
            buildingName: temp.building,
            postalCode: temp.postal,
            unitNumber: null,
        });
    }
    private _searchPostalCode(value: string) {
        if (value) {
            this.addressService.search({
                searchVal: value,
                returnGeom: 'N',
                getAddrDetails: 'Y',
                // pageNum: '1'
            }).subscribe(data =>{
                this.oneMapResults = data
            });
        }
    }

    ngDoCheck(): void {
        // if (this.ngControl) {
        //     this.errorState = this.form.invalid && this.touched;
        // }
        // this.stateChanges.next();
        if (this.ngControl) {
            // We need to re-evaluate this on every change detection cycle, because there are some
            // error triggers that we can't subscribe to (e.g. parent form submissions). This means
            // that whatever logic is in here has to be super lean or we risk destroying the performance.
            this.updateErrorState();
        }
    }
    ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
        this.subscriptions.forEach(sub => sub.unsubscribe());
        this.stateChanges.complete();
    }
    onParentSubmit(parent: FormGroupDirective): void {
        parent.ngSubmit.pipe(takeUntil(this.unsubscribeAll)).subscribe(() => {
            console.log('parent submit',this.required)
            if (this.required) {
                this.form.markAllAsTouched();
            } else {
                const formData = this.form.getRawValue();
                if (this.formControl && this.formControl.value) {
                    Object.keys(formData).forEach(key => {
                        if (!formData[key]) {
                            this.form.controls[key].markAsTouched();
                        }
                    });
                }
            }
        });
    }
    onFormChange(): void {
        this.form.valueChanges.pipe(debounceTime(300), takeUntil(this.unsubscribeAll)).subscribe((data:IAddressBase) => {
            this.propagateChange(data);
            this.onTouched();
        });

    }
    createForm(): FormGroup {
        return this.formBuilder.group({
            postalCode: ['',[Validators.required]],
            unitNumber: [''],
            blockNumber: [''],
            streetName: [''],
            buildingName: [''],
            floorNumber: [''],
            dictrict:[''],
            country:['SINGAPORE']
        });
    }
    writeValue(obj: any): void {
        if(!obj){
            this.form.patchValue({})
        }else if(isObject(obj)){
            this.form.patchValue(obj)
        }else{
            throw new Error("Value invalid.");
        }
        
    }
    propagateChange = (_: any) => {
    }
    onTouched = () => {
        
    }
    registerOnChange(fn: any): void {
        
        this.propagateChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        isDisabled ? this.form.disable() : this.form.enable();
    }

    validate(formControl: FormControl): ValidationErrors | null {
        console.log('validate',formControl)
        this.formControl = formControl;
        this.required = formControl.hasError('required');
        return (this.form.dirty && this.form.invalid) ? {required: true} : null;
    }
    ngOnInit(): void {
    }
    displayWith(value: OneMapResultModel): string {
        if (value instanceof OneMapResultModel) {
            return value.postal;
        } else {
            return value;
        }
    }
    numberOnly(e) {
        return Helper.numberOnly(e);
    }

    updateErrorState() {
        const oldState = this.errorState;
        const parent = this.parentFormGroup || this.parentForm;
        const matcher = this.defaultErrorStateMatcher;
        const control = this.ngControl ? this.ngControl.control as FormControl : null;
        const newState = matcher.isErrorState(control, parent);

        if (newState !== oldState) {
            this.errorState = newState;
            this.stateChanges.next();
        }
        if(this.ngControl.touched && this.form.untouched){
            this.form.markAllAsTouched()
            this.stateChanges.next();
        }
        
    }
}