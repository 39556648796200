import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'referralTypeDropdownDataTransfer'
})
export class ReferralTypeDropdownDataTransferPipe implements PipeTransform {
    transform(value: string): string {
        switch (value) {
            case 'downline':
                return 'Downline(s)';
            case 'upline':
                return 'Direct upline';
            default:
                return value;
        }
    }
}
