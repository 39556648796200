<div class="layout__main-modal-2 app-feedback">
    <div class="dialog-title" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="8px">
        <mat-icon svgIcon="feedback"></mat-icon>
        <div>{{'common.send_your_feedback' | translate | titlecase}}</div>
    </div>
    <form [formGroup]="form" (ngSubmit)="submit()">
        <div class="dialog_content">
            <mat-form-field class="full-width">
                <mat-label>{{'common.subject' | translate }}</mat-label>
                <input type="text" matInput formControlName="subject" required
                    placeholder="{{'common.enter_subject' | translate }}" />

                <mat-error *ngIf="form.controls.subject.errors && form.controls.subject.errors.required">{{
                    'validator.required' | translate
                    }}</mat-error>
            </mat-form-field>
            <mat-form-field class="full-width">
                <mat-label>{{'common.your_feedback' | translate }}</mat-label>
                <textarea matInput formControlName="content" required
                    placeholder="{{'common.enter_your_feedback' | translate }}" rows="5"></textarea>

                <mat-error *ngIf="form.controls.content.errors && form.controls.content.errors.required">{{
                    'validator.required' | translate
                    }}</mat-error>

            </mat-form-field>
            <button type="button" mat-button>
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" (click)="inputFile.click()">
                    <mat-icon>attach_file</mat-icon>
                    <div>{{ 'common.attach_files' | translate}}</div>
                </div>
            </button>
            <input class="input-file" type="file" #inputFile [multiple]="true" appUpload [isUploadImmediately]="true"
                [maxFiles]="5" (finishedFile)="onFinishedFile($event)" [allowedMimeType]="allowedMimeType"
                [allowExtensions]="allowExtensions" [isPublic]="true">

            <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="8px">
                <div *ngFor="let file of attachFiles" class="attach-file" fxLayout="row" fxLayoutAlign="start center"
                    fxLayoutGap="8px">
                    <div>
                        {{ file.file?.name}}
                    </div>
                    <button type="button" mat-icon-button matTooltip="Delete" class="btn-cancel"
                        (click)="onDeleteFile(file)">
                        <mat-icon>cancel</mat-icon>
                    </button>
                </div>
            </div>

        </div>


        <div class="dialog-action" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="16px">
            <button mat-flat-button color="primary" type="submit">
                {{'common.send_feedback' | translate | titlecase}}
            </button>
        </div>
    </form>

</div>
