import { environment } from 'environments/environment';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class RedocCloudService {
    redocCloudConfigs = environment.redocCloud
    constructor(
        private httpClient:HttpClient
    ){}
    docxToHtml(file:File):Observable<{html:string}>{
        const input = new FormData();
        input.append( 'file', file );
        return this.httpClient.post<{html:string}>(this.redocCloudConfigs.serviceOrigin+'/api/content-builder/docx-to-html',input)
    }
}
