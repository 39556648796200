import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatusLabelComponent } from './status-label.component';

@NgModule({
    imports: [CommonModule],
    declarations: [StatusLabelComponent],
    exports: [StatusLabelComponent],
})
export class StatusLabelModule {}
