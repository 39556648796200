import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConstant } from '@app/app.constant';
import { ApiOptionInterface } from '@app/core/models/api-option.interface';

@Injectable({
    providedIn: 'root'
})
export class StorageApiService {
    static STORAGE_UPLOAD_LINK = 'storage/upload/:type';
    static STORAGE_PUBLIC_LINK = 'storage/link';
    private storageDrive = AppConstant.APP_STORAGE_DRIVE;

    constructor(private apiService: ApiService, private httpClient: HttpClient) {
    }

    getUploadLink(data: { type; name; acl?}) {
        return this.apiService.get(StorageApiService.STORAGE_UPLOAD_LINK, data);
    }

    getPublicLink(data: { keyName }, requestOption?: ApiOptionInterface) {
        return this.apiService.get(StorageApiService.STORAGE_PUBLIC_LINK, data, requestOption);
    }

    uploadFile(data) {
        if (this.storageDrive === 'azure') {
            return this.uploadAzureFile(data);
        } else if (this.storageDrive === 's3') {
            return this.uploadS3File(data);
        }
        return;
    }

    uploadS3File(data) {
        const input = new FormData();
        if (data.isPublic) {
            data.fields.acl = 'public-read';
        }
        Object.keys(data.fields).forEach(key => {
            input.append(key, data.fields[key]);
        });
        input.append('file', data.file);
        return this.httpClient.post(data.url, input);
    }

    uploadAzureFile(data) {
        const header = new HttpHeaders({
            'x-ms-blob-type': data.fields['x-ms-blob-type'],
            'Content-Type': data.fields['Content-Type']
        });
        return this.httpClient.put(data.url, data.file, { headers: header });
    }
}
