import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RedNavigationService } from '../../../navigation.service';
import { NavigationItem } from '../../../navigation.types';
import { VerticalNavigationComponent } from '../../vertical-navigation.component';
import { TitleCasePipe } from '@angular/common';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'red-vertical-navigation-basic-item',
  templateUrl: './basic.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers:[
    TranslatePipe,
    TitleCasePipe
  ]
})
export class VerticalNavigationBasicItemComponent implements OnInit, OnDestroy {
  @Input()
  item!: NavigationItem;
  @Input()
  name!: string;

  private verticalNavigationComponent!: VerticalNavigationComponent;
  private _unsubscribeAll: Subject<void> = new Subject<void>();

  /**
   * Constructor
   */
  constructor(private _changeDetectorRef: ChangeDetectorRef, private _fuseNavigationService: RedNavigationService,
    public translatePipe:TranslatePipe,
    public titleCasePipe:TitleCasePipe) {
    // Set the equivalent of {exact: false} as default for active match options.
    // We are not assigning the item.isActiveMatchOptions directly to the
    // [routerLinkActiveOptions] because if it's "undefined" initially, the router
    // will throw an error and stop working.
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Set the "isActiveMatchOptions" either from item's
    // "isActiveMatchOptions" or the equivalent form of
    // item's "exactMatch" option
    // this.isActiveMatchOptions = this.item.isActiveMatchOptions ?? this.item.exactMatch ? Helper.exactMatchOptions : Helper.subsetMatchOptions;

    // Get the parent navigation component
    this.verticalNavigationComponent = this._fuseNavigationService.getComponent(this.name);

    // Mark for check
    this._changeDetectorRef.markForCheck();

    // Subscribe to onRefreshed on the navigation component
    this.verticalNavigationComponent.onRefreshed.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      // Mark for check
      this._changeDetectorRef.markForCheck();
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
