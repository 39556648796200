import { Injectable } from "@angular/core";
import { IOneMapResultInput, OneMapResultModel } from "./one-map-address.model";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";

@Injectable({
    providedIn:'root'
})
export class OneMapAddressService{
    constructor(
        private httpClient: HttpClient,
    ) { }
     removeDataNullFromOneMapAddress(data: OneMapResultModel): OneMapResultModel {
        Object.keys(data).forEach(key => {
            if (data[key] === 'NIL') {
                data[key] = '';
            }
        });

        return data;
    }
    search(params: { searchVal: string; returnGeom: string; getAddrDetails: string; pageNum?: string; }):Observable<OneMapResultModel[]> {
        return this.httpClient.get('https://api.redoc.co/one-map', {
            params
        }).pipe(
            map(
                (res:{
                    found: number;
                    pageNum: number;
                    totalNumPages: number;
                    results: IOneMapResultInput[]
                }) => {
                    return res.results.filter(v => v.POSTAL !== 'NIL').map(v => OneMapResultModel.fromJson(v))
                }
            )
        );
    }
}