import { Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HTTP_STATUS_CODE } from '@app/core/common/http-status-code';
import { ErrorResponseInterface } from '@app/core/models/error-response.interface';
import { GlobalService } from '@app/core/services/global.service';
import { ClientModel, ClientInterface } from '@app/shared/models/client.model';
import { MESSAGE_TYPE } from '@app/shared/models/message.model';
import { ClientApiService } from '@app/shared/services/client-api.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-client-individual-create-modal',
    templateUrl: './client-individual-create-modal.component.html',
    styleUrls: ['./client-individual-create-modal.component.scss']
})
export class ClientIndividualCreateModalComponent {
    client: ClientModel;

    constructor(
        private clientApiService: ClientApiService,
        private globalService: GlobalService,
        private translateService: TranslateService,
        private dialogRef: MatDialogRef<ClientIndividualCreateModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { item: ClientModel },
    ) {
        if (data && data.item) {
            this.client = data.item;
        }
    }

    submit(data: { payload: any, form: FormGroup }) {
        this._request(data.payload).subscribe(
            (res) => {
                const message = this.client ? 'common.update_successfully' : 'customer.create_success';
                this.globalService.message.next({
                    type: MESSAGE_TYPE.success,
                    message: this.translateService.instant(message),
                });
                res.mainContact = res.contacts[0];
                this._initDataBeforeClose(res);
            },
            (error: ErrorResponseInterface) => {
                if (error.statusCode === HTTP_STATUS_CODE.BAD_REQUEST) {
                    switch (error.errorCode) {
                        case 'TNT002': {
                            data.form.get('email').setErrors({
                                existed: true,
                            });

                            break;
                        }
                    }
                }
            }
        );
    }

    private _request(payload): Observable<any> {
        if (this.client) {
            payload.id = this.client.id;
            payload.contacts[0].id = this.client.contacts[0].id;
            return this.clientApiService.update(payload);
        } else {
            return this.clientApiService.create(payload);
        }
    }
    private _initDataBeforeClose(item:ClientInterface):void{
        this.clientApiService.getCustomerDetail(item.id).subscribe(res => {
            this.dialogRef.close(res);
        })
        
    }
    close() {
        this.dialogRef.close();
    }
}
