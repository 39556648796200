export const COUNTRIES: [string, string, string, number?, string[]?][] = [
    ['Afghanistan (‫افغانستان‬‎)', 'af', '93'],
    ['Albania (Shqipëri)', 'al', '355'],
    ['Algeria (‫الجزائر‬‎)', 'dz', '213'],
    ['American Samoa', 'as', '1', 5, ['684']],
    ['Andorra', 'ad', '376'],
    ['Angola', 'ao', '244'],
    ['Anguilla', 'ai', '1', 6, ['264']],
    ['Antigua and Barbuda', 'ag', '1', 7, ['268']],
    ['Argentina', 'ar', '54'],
    ['Armenia (Հայաստան)', 'am', '374'],
    ['Aruba', 'aw', '297'],
    ['Australia', 'au', '61', 0],
    ['Austria (Österreich)', 'at', '43'],
    ['Azerbaijan (Azərbaycan)', 'az', '994'],
    ['Bahamas', 'bs', '1', 8, ['242']],
    ['Bahrain (‫البحرين‬‎)', 'bh', '973'],
    ['Bangladesh (বাংলাদেশ)', 'bd', '880'],
    ['Barbados', 'bb', '1', 9, ['246']],
    ['Belarus (Беларусь)', 'by', '375'],
    ['Belgium (België)', 'be', '32'],
    ['Belize', 'bz', '501'],
    ['Benin (Bénin)', 'bj', '229'],
    ['Bermuda', 'bm', '1', 10, ['441']],
    ['Bhutan (འབྲུག)', 'bt', '975'],
    ['Bolivia', 'bo', '591'],
    ['Bosnia and Herzegovina (Босна и Херцеговина)', 'ba', '387'],
    ['Botswana', 'bw', '267'],
    ['Brazil (Brasil)', 'br', '55'],
    ['British Indian Ocean Territory', 'io', '246'],
    ['British Virgin Islands', 'vg', '1', 11, ['284']],
    ['Brunei', 'bn', '673'],
    ['Bulgaria (България)', 'bg', '359'],
    ['Burkina Faso', 'bf', '226'],
    ['Burundi (Uburundi)', 'bi', '257'],
    ['Cambodia (កម្ពុជា)', 'kh', '855'],
    ['Cameroon (Cameroun)', 'cm', '237'],
    [
        'Canada',
        'ca',
        '1',
        1,
        // tslint:disable-next-line: max-line-length
        [
            '204',
            '226',
            '236',
            '249',
            '250',
            '289',
            '306',
            '343',
            '365',
            '387',
            '403',
            '416',
            '418',
            '431',
            '437',
            '438',
            '450',
            '506',
            '514',
            '519',
            '548',
            '579',
            '581',
            '587',
            '604',
            '613',
            '639',
            '647',
            '672',
            '705',
            '709',
            '742',
            '778',
            '780',
            '782',
            '807',
            '819',
            '825',
            '867',
            '873',
            '902',
            '905',
        ],
    ],
    ['Cape Verde (Kabu Verdi)', 'cv', '238'],
    ['Caribbean Netherlands', 'bq', '599', 1, ['3', '4', '7']],
    ['Cayman Islands', 'ky', '1', 12, ['345']],
    ['Central African Republic (République centrafricaine)', 'cf', '236'],
    ['Chad (Tchad)', 'td', '235'],
    ['Chile', 'cl', '56'],
    ['China (中国)', 'cn', '86'],
    ['Christmas Island', 'cx', '61', 2, ['89164']],
    ['Cocos (Keeling) Islands', 'cc', '61', 1, ['89162']],
    ['Colombia', 'co', '57'],
    ['Comoros (‫جزر القمر‬‎)', 'km', '269'],
    ['Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)', 'cd', '243'],
    ['Congo (Republic) (Congo-Brazzaville)', 'cg', '242'],
    ['Cook Islands', 'ck', '682'],
    ['Costa Rica', 'cr', '506'],
    ['Côte d’Ivoire', 'ci', '225'],
    ['Croatia (Hrvatska)', 'hr', '385'],
    ['Cuba', 'cu', '53'],
    ['Curaçao', 'cw', '599', 0],
    ['Cyprus (Κύπρος)', 'cy', '357'],
    ['Czech Republic (Česká republika)', 'cz', '420'],
    ['Denmark (Danmark)', 'dk', '45'],
    ['Djibouti', 'dj', '253'],
    ['Dominica', 'dm', '1', 13, ['767']],
    [
        'Dominican Republic (República Dominicana)',
        'do',
        '1',
        2,
        ['809', '829', '849'],
    ],
    ['Ecuador', 'ec', '593'],
    ['Egypt (‫مصر‬‎)', 'eg', '20'],
    ['El Salvador', 'sv', '503'],
    ['Equatorial Guinea (Guinea Ecuatorial)', 'gq', '240'],
    ['Eritrea', 'er', '291'],
    ['Estonia (Eesti)', 'ee', '372'],
    ['Ethiopia', 'et', '251'],
    ['Falkland Islands (Islas Malvinas)', 'fk', '500'],
    ['Faroe Islands (Føroyar)', 'fo', '298'],
    ['Fiji', 'fj', '679'],
    ['Finland (Suomi)', 'fi', '358', 0],
    ['France', 'fr', '33'],
    ['French Guiana (Guyane française)', 'gf', '594'],
    ['French Polynesia (Polynésie française)', 'pf', '689'],
    ['Gabon', 'ga', '241'],
    ['Gambia', 'gm', '220'],
    ['Georgia (საქართველო)', 'ge', '995'],
    ['Germany (Deutschland)', 'de', '49'],
    ['Ghana (Gaana)', 'gh', '233'],
    ['Gibraltar', 'gi', '350'],
    ['Greece (Ελλάδα)', 'gr', '30'],
    ['Greenland (Kalaallit Nunaat)', 'gl', '299'],
    ['Grenada', 'gd', '1', 14, ['473']],
    ['Guadeloupe', 'gp', '590', 0],
    ['Guam', 'gu', '1', 15, ['671']],
    ['Guatemala', 'gt', '502'],
    ['Guernsey', 'gg', '44', 1, ['1481', '7781', '7839', '7911']],
    ['Guinea (Guinée)', 'gn', '224'],
    ['Guinea-Bissau (Guiné Bissau)', 'gw', '245'],
    ['Guyana', 'gy', '592'],
    ['Haiti', 'ht', '509'],
    ['Honduras', 'hn', '504'],
    ['Hong Kong (香港)', 'hk', '852'],
    ['Hungary (Magyarország)', 'hu', '36'],
    ['Iceland (Ísland)', 'is', '354'],
    ['India (भारत)', 'in', '91'],
    ['Indonesia', 'id', '62'],
    ['Iran (‫ایران‬‎)', 'ir', '98'],
    ['Iraq (‫العراق‬‎)', 'iq', '964'],
    ['Ireland', 'ie', '353'],
    ['Isle of Man', 'im', '44', 2, ['1624', '74576', '7524', '7924', '7624']],
    ['Israel (‫ישראל‬‎)', 'il', '972'],
    ['Italy (Italia)', 'it', '39', 0],
    ['Jamaica', 'jm', '1', 4, ['876', '658']],
    ['Japan (日本)', 'jp', '81'],
    ['Jersey', 'je', '44', 3, ['1534', '7509', '7700', '7797', '7829', '7937']],
    ['Jordan (‫الأردن‬‎)', 'jo', '962'],
    ['Kazakhstan (Казахстан)', 'kz', '7', 1, ['33', '7']],
    ['Kenya', 'ke', '254'],
    ['Kiribati', 'ki', '686'],
    ['Kosovo', 'xk', '383'],
    ['Kuwait (‫الكويت‬‎)', 'kw', '965'],
    ['Kyrgyzstan (Кыргызстан)', 'kg', '996'],
    ['Laos (ລາວ)', 'la', '856'],
    ['Latvia (Latvija)', 'lv', '371'],
    ['Lebanon (‫لبنان‬‎)', 'lb', '961'],
    ['Lesotho', 'ls', '266'],
    ['Liberia', 'lr', '231'],
    ['Libya (‫ليبيا‬‎)', 'ly', '218'],
    ['Liechtenstein', 'li', '423'],
    ['Lithuania (Lietuva)', 'lt', '370'],
    ['Luxembourg', 'lu', '352'],
    ['Macau (澳門)', 'mo', '853'],
    ['Macedonia (FYROM) (Македонија)', 'mk', '389'],
    ['Madagascar (Madagasikara)', 'mg', '261'],
    ['Malawi', 'mw', '265'],
    ['Malaysia', 'my', '60'],
    ['Maldives', 'mv', '960'],
    ['Mali', 'ml', '223'],
    ['Malta', 'mt', '356'],
    ['Marshall Islands', 'mh', '692'],
    ['Martinique', 'mq', '596'],
    ['Mauritania (‫موريتانيا‬‎)', 'mr', '222'],
    ['Mauritius (Moris)', 'mu', '230'],
    ['Mayotte', 'yt', '262', 1, ['269', '639']],
    ['Mexico (México)', 'mx', '52'],
    ['Micronesia', 'fm', '691'],
    ['Moldova (Republica Moldova)', 'md', '373'],
    ['Monaco', 'mc', '377'],
    ['Mongolia (Монгол)', 'mn', '976'],
    ['Montenegro (Crna Gora)', 'me', '382'],
    ['Montserrat', 'ms', '1', 16, ['664']],
    ['Morocco (‫المغرب‬‎)', 'ma', '212', 0],
    ['Mozambique (Moçambique)', 'mz', '258'],
    ['Myanmar (Burma) (မြန်မာ)', 'mm', '95'],
    ['Namibia (Namibië)', 'na', '264'],
    ['Nauru', 'nr', '674'],
    ['Nepal (नेपाल)', 'np', '977'],
    ['Netherlands (Nederland)', 'nl', '31'],
    ['New Caledonia (Nouvelle-Calédonie)', 'nc', '687'],
    ['New Zealand', 'nz', '64'],
    ['Nicaragua', 'ni', '505'],
    ['Niger (Nijar)', 'ne', '227'],
    ['Nigeria', 'ng', '234'],
    ['Niue', 'nu', '683'],
    ['Norfolk Island', 'nf', '672'],
    ['North Korea (조선 민주주의 인민 공화국)', 'kp', '850'],
    ['Northern Mariana Islands', 'mp', '1', 17, ['670']],
    ['Norway (Norge)', 'no', '47', 0],
    ['Oman (‫عُمان‬‎)', 'om', '968'],
    ['Pakistan (‫پاکستان‬‎)', 'pk', '92'],
    ['Palau', 'pw', '680'],
    ['Palestine (‫فلسطين‬‎)', 'ps', '970'],
    ['Panama (Panamá)', 'pa', '507'],
    ['Papua New Guinea', 'pg', '675'],
    ['Paraguay', 'py', '595'],
    ['Peru (Perú)', 'pe', '51'],
    ['Philippines', 'ph', '63'],
    ['Poland (Polska)', 'pl', '48'],
    ['Portugal', 'pt', '351'],
    ['Puerto Rico', 'pr', '1', 3, ['787', '939']],
    ['Qatar (‫قطر‬‎)', 'qa', '974'],
    ['Réunion (La Réunion)', 're', '262', 0],
    ['Romania (România)', 'ro', '40'],
    ['Russia (Россия)', 'ru', '7', 0],
    ['Rwanda', 'rw', '250'],
    ['Saint Barthélemy', 'bl', '590', 1],
    ['Saint Helena', 'sh', '290'],
    ['Saint Kitts and Nevis', 'kn', '1', 18, ['869']],
    ['Saint Lucia', 'lc', '1', 19, ['758']],
    ['Saint Martin (Saint-Martin (partie française))', 'mf', '590', 2],
    ['Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)', 'pm', '508'],
    ['Saint Vincent and the Grenadines', 'vc', '1', 20, ['784']],
    ['Samoa', 'ws', '685'],
    ['San Marino', 'sm', '378'],
    ['São Tomé and Príncipe (São Tomé e Príncipe)', 'st', '239'],
    ['Saudi Arabia (‫المملكة العربية السعودية‬‎)', 'sa', '966'],
    ['Senegal (Sénégal)', 'sn', '221'],
    ['Serbia (Србија)', 'rs', '381'],
    ['Seychelles', 'sc', '248'],
    ['Sierra Leone', 'sl', '232'],
    ['Singapore', 'sg', '65'],
    ['Sint Maarten', 'sx', '1', 21, ['721']],
    ['Slovakia (Slovensko)', 'sk', '421'],
    ['Slovenia (Slovenija)', 'si', '386'],
    ['Solomon Islands', 'sb', '677'],
    ['Somalia (Soomaaliya)', 'so', '252'],
    ['South Africa', 'za', '27'],
    ['South Korea (대한민국)', 'kr', '82'],
    ['South Sudan (‫جنوب السودان‬‎)', 'ss', '211'],
    ['Spain (España)', 'es', '34'],
    ['Sri Lanka (ශ්‍රී ලංකාව)', 'lk', '94'],
    ['Sudan (‫السودان‬‎)', 'sd', '249'],
    ['Suriname', 'sr', '597'],
    ['Svalbard and Jan Mayen', 'sj', '47', 1, ['79']],
    ['Swaziland', 'sz', '268'],
    ['Sweden (Sverige)', 'se', '46'],
    ['Switzerland (Schweiz)', 'ch', '41'],
    ['Syria (‫سوريا‬‎)', 'sy', '963'],
    ['Taiwan (台灣)', 'tw', '886'],
    ['Tajikistan', 'tj', '992'],
    ['Tanzania', 'tz', '255'],
    ['Thailand (ไทย)', 'th', '66'],
    ['Timor-Leste', 'tl', '670'],
    ['Togo', 'tg', '228'],
    ['Tokelau', 'tk', '690'],
    ['Tonga', 'to', '676'],
    ['Trinidad and Tobago', 'tt', '1', 22, ['868']],
    ['Tunisia (‫تونس‬‎)', 'tn', '216'],
    ['Turkey (Türkiye)', 'tr', '90'],
    ['Turkmenistan', 'tm', '993'],
    ['Turks and Caicos Islands', 'tc', '1', 23, ['649']],
    ['Tuvalu', 'tv', '688'],
    ['U.S. Virgin Islands', 'vi', '1', 24, ['340']],
    ['Uganda', 'ug', '256'],
    ['Ukraine (Україна)', 'ua', '380'],
    ['United Arab Emirates (‫الإمارات العربية المتحدة‬‎)', 'ae', '971'],
    ['United Kingdom', 'gb', '44', 0],
    ['United States', 'us', '1', 0],
    ['Uruguay', 'uy', '598'],
    ['Uzbekistan (Oʻzbekiston)', 'uz', '998'],
    ['Vanuatu', 'vu', '678'],
    ['Vatican City (Città del Vaticano)', 'va', '39', 1, ['06698']],
    ['Venezuela', 've', '58'],
    ['Vietnam (Việt Nam)', 'vn', '84'],
    ['Wallis and Futuna (Wallis-et-Futuna)', 'wf', '681'],
    ['Western Sahara (‫الصحراء الغربية‬‎)', 'eh', '212', 1, ['5288', '5289']],
    ['Yemen (‫اليمن‬‎)', 'ye', '967'],
    ['Zambia', 'zm', '260'],
    ['Zimbabwe', 'zw', '263'],
    ['Åland Islands', 'ax', '358', 1, ['18']],
];

export const COUNTRY_LIST = [
    {
        country: 'Afghanistan',
        countryCode: 'AF',
    },
    {
        country: 'Åland Islands',
        countryCode: 'AX',
    },
    {
        country: 'Albania',
        countryCode: 'AL',
    },
    {
        country: 'Algeria',
        countryCode: 'DZ',
    },
    {
        country: 'American Samoa',
        countryCode: 'AS',
    },
    {
        country: 'Andorra',
        countryCode: 'AD',
    },
    {
        country: 'Angola',
        countryCode: 'AO',
    },
    {
        country: 'Anguilla',
        countryCode: 'AI',
    },
    {
        country: 'Antarctica',
        countryCode: 'AQ',
    },
    {
        country: 'Antigua and Barbuda',
        countryCode: 'AG',
    },
    {
        country: 'Argentina',
        countryCode: 'AR',
    },
    {
        country: 'Armenia',
        countryCode: 'AM',
    },
    {
        country: 'Aruba',
        countryCode: 'AW',
    },
    {
        country: 'Australia',
        countryCode: 'AU',
    },
    {
        country: 'Austria',
        countryCode: 'AT',
    },
    {
        country: 'Azerbaijan',
        countryCode: 'AZ',
    },
    {
        country: 'Bahamas',
        countryCode: 'BS',
    },
    {
        country: 'Bahrain',
        countryCode: 'BH',
    },
    {
        country: 'Bangladesh',
        countryCode: 'BD',
    },
    {
        country: 'Barbados',
        countryCode: 'BB',
    },
    {
        country: 'Belarus',
        countryCode: 'BY',
    },
    {
        country: 'Belgium',
        countryCode: 'BE',
    },
    {
        country: 'Belize',
        countryCode: 'BZ',
    },
    {
        country: 'Benin',
        countryCode: 'BJ',
    },
    {
        country: 'Bermuda',
        countryCode: 'BM',
    },
    {
        country: 'Bhutan',
        countryCode: 'BT',
    },
    {
        country: 'Bolivia',
        countryCode: 'BO',
    },
    {
        country: 'Bonaire, Sint Eustatius and Saba',
        countryCode: 'BQ',
    },
    {
        country: 'Bosnia and Herzegovina',
        countryCode: 'BA',
    },
    {
        country: 'Botswana',
        countryCode: 'BW',
    },
    {
        country: 'Bouvet Island',
        countryCode: 'BV',
    },
    {
        country: 'Brazil',
        countryCode: 'BR',
    },
    {
        country: 'British Indian Ocean Territory',
        countryCode: 'IO',
    },
    {
        country: 'British Virgin Islands',
        countryCode: 'VG',
    },
    {
        country: 'Brunei',
        countryCode: 'BN',
    },
    {
        country: 'Bulgaria',
        countryCode: 'BG',
    },
    {
        country: 'Burkina Faso',
        countryCode: 'BF',
    },
    {
        country: 'Burundi',
        countryCode: 'BI',
    },
    {
        country: 'Cambodia',
        countryCode: 'KH',
    },
    {
        country: 'Cameroon',
        countryCode: 'CM',
    },
    {
        country: 'Canada',
        countryCode: 'CA',
    },
    {
        country: 'Cape Verde',
        countryCode: 'CV',
    },
    {
        country: 'Cayman Islands',
        countryCode: 'KY',
    },
    {
        country: 'Central African Republic',
        countryCode: 'CF',
    },
    {
        country: 'Chad',
        countryCode: 'TD',
    },
    {
        country: 'Chile',
        countryCode: 'CL',
    },
    {
        country: 'China',
        countryCode: 'CN',
    },
    {
        country: 'Christmas Island',
        countryCode: 'CX',
    },
    {
        country: 'Cocos (Keeling) Islands',
        countryCode: 'CC',
    },
    {
        country: 'Colombia',
        countryCode: 'CO',
    },
    {
        country: 'Comoros',
        countryCode: 'KM',
    },
    {
        country: 'Congo Republic',
        countryCode: 'CG',
    },
    {
        country: 'Cook Islands',
        countryCode: 'CK',
    },
    {
        country: 'Costa Rica',
        countryCode: 'CR',
    },
    {
        country: "Cote d'Ivoire",
        countryCode: 'CI',
    },
    {
        country: 'Croatia',
        countryCode: 'HR',
    },
    {
        country: 'Cuba',
        countryCode: 'CU',
    },
    {
        country: 'CuraÃ§ao',
        countryCode: 'CW',
    },
    {
        country: 'Cyprus',
        countryCode: 'CY',
    },
    {
        country: 'Czech Republic',
        countryCode: 'CZ',
    },
    {
        country: 'Democratic Republic of the Congo',
        countryCode: 'CD',
    },
    {
        country: 'Denmark',
        countryCode: 'DK',
    },
    {
        country: 'Djibouti',
        countryCode: 'DJ',
    },
    {
        country: 'Dominica',
        countryCode: 'DM',
    },
    {
        country: 'Dominican Republic',
        countryCode: 'DO',
    },
    {
        country: 'Ecuador',
        countryCode: 'EC',
    },
    {
        country: 'Egypt',
        countryCode: 'EG',
    },
    {
        country: 'El Salvador',
        countryCode: 'SV',
    },
    {
        country: 'Equatorial Guinea',
        countryCode: 'GQ',
    },
    {
        country: 'Eritrea',
        countryCode: 'ER',
    },
    {
        country: 'Estonia',
        countryCode: 'EE',
    },
    {
        country: 'Ethiopia',
        countryCode: 'ET',
    },
    {
        country: 'Falkland Islands',
        countryCode: 'FK',
    },
    {
        country: 'Faroe Islands',
        countryCode: 'FO',
    },
    {
        country: 'Fiji',
        countryCode: 'FJ',
    },
    {
        country: 'Finland',
        countryCode: 'FI',
    },
    {
        country: 'France',
        countryCode: 'FR',
    },
    {
        country: 'French Guiana',
        countryCode: 'GF',
    },
    {
        country: 'French Polynesia',
        countryCode: 'PF',
    },
    {
        country: 'French Southern Territories',
        countryCode: 'TF',
    },
    {
        country: 'Gabon',
        countryCode: 'GA',
    },
    {
        country: 'Gambia',
        countryCode: 'GM',
    },
    {
        country: 'Georgia',
        countryCode: 'GE',
    },
    {
        country: 'Germany',
        countryCode: 'DE',
    },
    {
        country: 'Ghana',
        countryCode: 'GH',
    },
    {
        country: 'Gibraltar',
        countryCode: 'GI',
    },
    {
        country: 'Greece',
        countryCode: 'GR',
    },
    {
        country: 'Greenland',
        countryCode: 'GL',
    },
    {
        country: 'Grenada',
        countryCode: 'GD',
    },
    {
        country: 'Guadeloupe',
        countryCode: 'GP',
    },
    {
        country: 'Guam',
        countryCode: 'GU',
    },
    {
        country: 'Guatemala',
        countryCode: 'GT',
    },
    {
        country: 'Guernsey',
        countryCode: 'GG',
    },
    {
        country: 'Guinea',
        countryCode: 'GN',
    },
    {
        country: 'Guinea-Bissau',
        countryCode: 'GW',
    },
    {
        country: 'Guyana',
        countryCode: 'GY',
    },
    {
        country: 'Haiti',
        countryCode: 'HT',
    },
    {
        country: 'Heard and McDonald Islands',
        countryCode: 'HM',
    },
    {
        country: 'Honduras',
        countryCode: 'HN',
    },
    {
        country: 'Hong Kong (HKSAR)',
        countryCode: 'HK',
    },
    {
        country: 'Hungary',
        countryCode: 'HU',
    },
    {
        country: 'Iceland',
        countryCode: 'IS',
    },
    {
        country: 'India',
        countryCode: 'IN',
    },
    {
        country: 'Indonesia',
        countryCode: 'ID',
    },
    {
        country: 'Iran',
        countryCode: 'IR',
    },
    {
        country: 'Iraq',
        countryCode: 'IQ',
    },
    {
        country: 'Ireland',
        countryCode: 'IE',
    },
    {
        country: 'Isle of Man',
        countryCode: 'IM',
    },
    {
        country: 'Israel',
        countryCode: 'IL',
    },
    {
        country: 'Italy',
        countryCode: 'IT',
    },
    {
        country: 'Jamaica',
        countryCode: 'JM',
    },
    {
        country: 'Japan',
        countryCode: 'JP',
    },
    {
        country: 'Jersey',
        countryCode: 'JE',
    },
    {
        country: 'Jordan',
        countryCode: 'JO',
    },
    {
        country: 'Kazakhstan',
        countryCode: 'KZ',
    },
    {
        country: 'Kenya',
        countryCode: 'KE',
    },
    {
        country: 'Kiribati',
        countryCode: 'KI',
    },
    {
        country: 'Kuwait',
        countryCode: 'KW',
    },
    {
        country: 'Kyrgyzstan',
        countryCode: 'KG',
    },
    {
        country: 'Laos',
        countryCode: 'LA',
    },
    {
        country: 'Latvia',
        countryCode: 'LV',
    },
    {
        country: 'Lebanon',
        countryCode: 'LB',
    },
    {
        country: 'Lesotho',
        countryCode: 'LS',
    },
    {
        country: 'Liberia',
        countryCode: 'LR',
    },
    {
        country: 'Libya',
        countryCode: 'LY',
    },
    {
        country: 'Liechtenstein',
        countryCode: 'LI',
    },
    {
        country: 'Lithuania',
        countryCode: 'LT',
    },
    {
        country: 'Luxembourg',
        countryCode: 'LU',
    },
    {
        country: 'Macau',
        countryCode: 'MO',
    },
    {
        country: 'Macedonia',
        countryCode: 'MK',
    },
    {
        country: 'Madagascar',
        countryCode: 'MG',
    },
    {
        country: 'Malawi',
        countryCode: 'MW',
    },
    {
        country: 'Malaysia',
        countryCode: 'MY',
    },
    {
        country: 'Maldives',
        countryCode: 'MV',
    },
    {
        country: 'Mali',
        countryCode: 'ML',
    },
    {
        country: 'Malta',
        countryCode: 'MT',
    },
    {
        country: 'Marshall Islands',
        countryCode: 'MH',
    },
    {
        country: 'Martinique',
        countryCode: 'MQ',
    },
    {
        country: 'Mauritania',
        countryCode: 'MR',
    },
    {
        country: 'Mauritius',
        countryCode: 'MU',
    },
    {
        country: 'Mayotte',
        countryCode: 'YT',
    },
    {
        country: 'Mexico',
        countryCode: 'MX',
    },
    {
        country: 'Micronesia',
        countryCode: 'FM',
    },
    {
        country: 'Moldova',
        countryCode: 'MD',
    },
    {
        country: 'Monaco',
        countryCode: 'MC',
    },
    {
        country: 'Mongolia',
        countryCode: 'MN',
    },
    {
        country: 'Montenegro',
        countryCode: 'ME',
    },
    {
        country: 'Montserrat',
        countryCode: 'MS',
    },
    {
        country: 'Morocco',
        countryCode: 'MA',
    },
    {
        country: 'Mozambique',
        countryCode: 'MZ',
    },
    {
        country: 'Myanmar',
        countryCode: 'MM',
    },
    {
        country: 'Namibia',
        countryCode: 'NA',
    },
    {
        country: 'Nauru',
        countryCode: 'NR',
    },
    {
        country: 'Nepal',
        countryCode: 'NP',
    },
    {
        country: 'Netherlands',
        countryCode: 'NL',
    },
    {
        country: 'New Caledonia',
        countryCode: 'NC',
    },
    {
        country: 'New Zealand',
        countryCode: 'NZ',
    },
    {
        country: 'Nicaragua',
        countryCode: 'NI',
    },
    {
        country: 'Niger',
        countryCode: 'NE',
    },
    {
        country: 'Nigeria',
        countryCode: 'NG',
    },
    {
        country: 'Niue',
        countryCode: 'NU',
    },
    {
        country: 'Norfolk Island',
        countryCode: 'NF',
    },
    {
        country: 'North Korea',
        countryCode: 'KP',
    },
    {
        country: 'Northern Mariana Islands',
        countryCode: 'MP',
    },
    {
        country: 'Norway',
        countryCode: 'NO',
    },
    {
        country: 'Oman',
        countryCode: 'OM',
    },
    {
        country: 'Pakistan',
        countryCode: 'PK',
    },
    {
        country: 'Palau',
        countryCode: 'PW',
    },
    {
        country: 'Palestine',
        countryCode: 'PS',
    },
    {
        country: 'Panama',
        countryCode: 'PA',
    },
    {
        country: 'Papua New Guinea',
        countryCode: 'PG',
    },
    {
        country: 'Paraguay',
        countryCode: 'PY',
    },
    {
        country: 'Peru',
        countryCode: 'PE',
    },
    {
        country: 'Philippines',
        countryCode: 'PH',
    },
    {
        country: 'Pitcairn',
        countryCode: 'PN',
    },
    {
        country: 'Poland',
        countryCode: 'PL',
    },
    {
        country: 'Portugal',
        countryCode: 'PT',
    },
    {
        country: 'Puerto Rico',
        countryCode: 'PR',
    },
    {
        country: 'Qatar',
        countryCode: 'QA',
    },
    {
        country: 'Reunion',
        countryCode: 'RE',
    },
    {
        country: 'Romania',
        countryCode: 'RO',
    },
    {
        country: 'Russia',
        countryCode: 'RU',
    },
    {
        country: 'Rwanda',
        countryCode: 'RW',
    },
    {
        country: 'Saint BarthÃ©lemy',
        countryCode: 'BL',
    },
    {
        country: 'Saint Lucia',
        countryCode: 'LC',
    },
    {
        country: 'Samoa',
        countryCode: 'WS',
    },
    {
        country: 'San Marino',
        countryCode: 'SM',
    },
    {
        country: 'Sao Tome and Principe',
        countryCode: 'ST',
    },
    {
        country: 'Saudi Arabia',
        countryCode: 'SA',
    },
    {
        country: 'Senegal',
        countryCode: 'SN',
    },
    {
        country: 'Serbia',
        countryCode: 'RS',
    },
    {
        country: 'Seychelles',
        countryCode: 'SC',
    },
    {
        country: 'Sierra Leone',
        countryCode: 'SL',
    },
    {
        country: 'Singapore',
        countryCode: 'SG',
    },
    {
        country: 'Sint Maarten (Dutch part)',
        countryCode: 'SX',
    },
    {
        country: 'Slovakia',
        countryCode: 'SK',
    },
    {
        country: 'Slovenia',
        countryCode: 'SI',
    },
    {
        country: 'Solomon Islands',
        countryCode: 'SB',
    },
    {
        country: 'Somalia',
        countryCode: 'SO',
    },
    {
        country: 'South Africa',
        countryCode: 'ZA',
    },
    {
        country: 'South Georgia and South Sandwich Islands',
        countryCode: 'GS',
    },
    {
        country: 'South Korea',
        countryCode: 'KR',
    },
    {
        country: 'South Sudan',
        countryCode: 'SS',
    },
    {
        country: 'Spain',
        countryCode: 'ES',
    },
    {
        country: 'Sri Lanka',
        countryCode: 'LK',
    },
    {
        country: 'St. Helena',
        countryCode: 'SH',
    },
    {
        country: 'St. Kitts and Nevis',
        countryCode: 'KN',
    },
    {
        country: 'St. Martin',
        countryCode: 'MF',
    },
    {
        country: 'St. Pierre and Miquelon',
        countryCode: 'PM',
    },
    {
        country: 'St. Vincent and the Grenadines',
        countryCode: 'VC',
    },
    {
        country: 'Sudan',
        countryCode: 'SD',
    },
    {
        country: 'Suriname',
        countryCode: 'SR',
    },
    {
        country: 'Svalbard and Jan Mayen Islands',
        countryCode: 'SJ',
    },
    {
        country: 'Swaziland',
        countryCode: 'SZ',
    },
    {
        country: 'Sweden',
        countryCode: 'SE',
    },
    {
        country: 'Switzerland',
        countryCode: 'CH',
    },
    {
        country: 'Syria',
        countryCode: 'SY',
    },
    {
        country: 'Taiwan',
        countryCode: 'TW',
    },
    {
        country: 'Tajikistan',
        countryCode: 'TJ',
    },
    {
        country: 'Tanzania',
        countryCode: 'TZ',
    },
    {
        country: 'Thailand',
        countryCode: 'TH',
    },
    {
        country: 'Timor Leste',
        countryCode: 'TL',
    },
    {
        country: 'Togo',
        countryCode: 'TG',
    },
    {
        country: 'Tokelau',
        countryCode: 'TK',
    },
    {
        country: 'Tonga',
        countryCode: 'TO',
    },
    {
        country: 'Trinidad and Tobago',
        countryCode: 'TT',
    },
    {
        country: 'Tunisia',
        countryCode: 'TN',
    },
    {
        country: 'Turkey',
        countryCode: 'TR',
    },
    {
        country: 'Turkmenistan',
        countryCode: 'TM',
    },
    {
        country: 'Turks and Caicos Islands',
        countryCode: 'TC',
    },
    {
        country: 'Tuvalu',
        countryCode: 'TV',
    },
    {
        country: 'U.S. Virgin Islands',
        countryCode: 'VI',
    },
    {
        country: 'Uganda',
        countryCode: 'UG',
    },
    {
        country: 'Ukraine',
        countryCode: 'UA',
    },
    {
        country: 'United Arab Emirates',
        countryCode: 'AE',
    },
    {
        country: 'United Kingdom',
        countryCode: 'GB',
    },
    {
        country: 'United States',
        countryCode: 'US',
    },
    {
        country: 'United States Minor Outlying Islands',
        countryCode: 'UM',
    },
    {
        country: 'Uruguay',
        countryCode: 'UY',
    },
    {
        country: 'Uzbekistan',
        countryCode: 'UZ',
    },
    {
        country: 'Vanuatu',
        countryCode: 'VU',
    },
    {
        country: 'Vatican City',
        countryCode: 'VA',
    },
    {
        country: 'Venezuela',
        countryCode: 'VE',
    },
    {
        country: 'Vietnam',
        countryCode: 'VN',
    },
    {
        country: 'Wallis and Futuna Islands',
        countryCode: 'WF',
    },
    {
        country: 'Western Sahara',
        countryCode: 'EH',
    },
    {
        country: 'Yemen',
        countryCode: 'YE',
    },
    {
        country: 'Zambia',
        countryCode: 'ZM',
    },
    {
        country: 'Zimbabwe',
        countryCode: 'ZW',
    },
];

export const NATIONALITY_LIST = [
    { nationality: 'AFGHAN', nationalityCode: 351 },
    { nationality: 'ALBANIAN', nationalityCode: 201 },
    { nationality: 'ALGERIAN', nationalityCode: 401 },
    { nationality: 'AMERICAN', nationalityCode: 503 },
    { nationality: 'ANDORRAN', nationalityCode: 153 },
    { nationality: 'ANGOLAN', nationalityCode: 451 },
    { nationality: 'ANTARCTICA', nationalityCode: 673 },
    { nationality: 'ANTIGUA', nationalityCode: 641 },
    { nationality: 'ARGENTINIAN', nationalityCode: 601 },
    { nationality: 'ARMENIAN', nationalityCode: 217 },
    { nationality: 'ARUBA', nationalityCode: 625 },
    { nationality: 'AUSTRALIAN', nationalityCode: 701 },
    { nationality: 'AUSTRIAN', nationalityCode: 131 },
    { nationality: 'AZERBAIJANI', nationalityCode: 218 },
    { nationality: 'BAHAMAS', nationalityCode: 642 },
    { nationality: 'BAHRAINI', nationalityCode: 371 },
    { nationality: 'BANGLADESHI', nationalityCode: 352 },
    { nationality: 'BARBADOS', nationalityCode: 643 },
    { nationality: 'BELARUSSIAN', nationalityCode: 211 },
    { nationality: 'BELGIAN', nationalityCode: 101 },
    { nationality: 'BELIZE', nationalityCode: 644 },
    { nationality: 'BENIN', nationalityCode: 452 },
    { nationality: 'BERMUDAN', nationalityCode: 645 },
    { nationality: 'BHUTAN', nationalityCode: 353 },
    { nationality: 'BOLIVIAN', nationalityCode: 646 },
    { nationality: 'BOSNIAN', nationalityCode: 994 },
    { nationality: 'BOTSWANA', nationalityCode: 453 },
    { nationality: 'BOUVET ISLAND', nationalityCode: 139 },
    { nationality: 'BRAZILIAN', nationalityCode: 602 },
    { nationality: 'BRITISH', nationalityCode: 110 },
    { nationality: 'BRITISH DEPENDENT TERR CITIZEN', nationalityCode: 675 },
    { nationality: 'BRITISH INDIAN OCEAN', nationalityCode: 708 },
    { nationality: 'BRITISH SUBJECT', nationalityCode: 886 },
    { nationality: 'BRITISH VIRGIN ISLND', nationalityCode: 671 },
    { nationality: 'BR NAT. OVERSEAS', nationalityCode: 738 },
    { nationality: 'BR OVERSEAS CIT.', nationalityCode: 995 },
    { nationality: 'BR PROTECTED PERS.', nationalityCode: 996 },
    { nationality: 'BRUNEIAN', nationalityCode: 302 },
    { nationality: 'BRITISH', nationalityCode: 110 },
    { nationality: 'BRITISH VIRGIN ISLND', nationalityCode: 671 },
    { nationality: 'BR NAT. OVERSEAS', nationalityCode: 738 },
    { nationality: 'BR OVERSEAS CIT.', nationalityCode: 995 },
    { nationality: 'BR PROTECTED PERS.', nationalityCode: 996 },
    { nationality: 'BRITISH INDIAN OCEAN', nationalityCode: 708 },
    { nationality: 'BRITISH SUBJECT', nationalityCode: 886 },
    { nationality: 'BRITISH DEPENDENT TERR CITIZEN', nationalityCode: 675 },
    { nationality: 'BRUNEIAN', nationalityCode: 302 },
    { nationality: 'BULGARIAN', nationalityCode: 202 },
    { nationality: 'BURKINA FASO', nationalityCode: 454 },
    { nationality: 'BURUNDI', nationalityCode: 455 },
    { nationality: 'CAMBODIAN', nationalityCode: 312 },
    { nationality: 'CAMEROON', nationalityCode: 456 },
    { nationality: 'CANADIAN', nationalityCode: 501 },
    { nationality: 'CAPE VERDE', nationalityCode: 457 },
    { nationality: 'CAYMANESE', nationalityCode: 647 },
    { nationality: 'CENTRAL AFRICAN REP', nationalityCode: 458 },
    { nationality: 'CHILEAN', nationalityCode: 603 },
    { nationality: 'CHINESE', nationalityCode: 336 },
    { nationality: 'CHRISTMAS ISLANDS', nationalityCode: 709 },
    { nationality: 'COLOMBIAN', nationalityCode: 604 },
    { nationality: 'COMOROS', nationalityCode: 460 },
    { nationality: 'CONGO', nationalityCode: 461 },
    { nationality: 'COSTA RICAN', nationalityCode: 648 },
    { nationality: 'CROATIAN', nationalityCode: 232 },
    { nationality: 'CUBAN', nationalityCode: 621 },
    { nationality: 'CYPRUS', nationalityCode: 372 },
    { nationality: 'CZECH', nationalityCode: 234 },
    { nationality: 'CZECHOSLOVAK', nationalityCode: 203 },
    { nationality: 'DEMOCRATIC REP OF THE CONGO', nationalityCode: 674 },
    { nationality: 'DJIBOUTI', nationalityCode: 407 },
    { nationality: 'DOMINICA', nationalityCode: 649 },
    { nationality: 'DOMINICAN REPUBLIC', nationalityCode: 622 },
    { nationality: 'DUTCH', nationalityCode: 991 },
    { nationality: 'EAST TIMORESE', nationalityCode: 887 },
    { nationality: 'ECUADORIAN', nationalityCode: 605 },
    { nationality: 'EGYPTIAN', nationalityCode: 402 },
    { nationality: 'EQUATORIAL GUINEA', nationalityCode: 462 },
    { nationality: 'ERITREA', nationalityCode: 410 },
    { nationality: 'ESTONIAN', nationalityCode: 213 },
    { nationality: 'ETHIOPIAN', nationalityCode: 408 },
    { nationality: 'FALKLAND IS', nationalityCode: 651 },
    { nationality: 'FAEROE ISLANDS', nationalityCode: 141 },
    { nationality: 'FILIPINO', nationalityCode: 305 },
    { nationality: 'FINNISH', nationalityCode: 132 },
    { nationality: 'FRENCH', nationalityCode: 103 },
    { nationality: 'FRENCH GUIANA', nationalityCode: 652 },
    { nationality: 'FRENCH POLYNESIA', nationalityCode: 723 },
    { nationality: 'FRENCH SOUTHERN TERR', nationalityCode: 711 },
    { nationality: 'GABON', nationalityCode: 463 },
    { nationality: 'GAMBIA', nationalityCode: 464 },
    { nationality: 'GEORGIA', nationalityCode: 216 },
    { nationality: 'GERMAN', nationalityCode: 104 },
    { nationality: 'GHANAIAN', nationalityCode: 421 },
    { nationality: 'GIBRALTAR', nationalityCode: 154 },
    { nationality: 'GREEK', nationalityCode: 105 },
    { nationality: 'GREENLAND', nationalityCode: 142 },
    { nationality: 'GRENADIAN', nationalityCode: 653 },
    { nationality: 'GUADELOUPE', nationalityCode: 654 },
    { nationality: 'GUAM', nationalityCode: 724 },
    { nationality: 'GUATEMALA', nationalityCode: 655 },
    { nationality: 'GUINEA', nationalityCode: 465 },
    { nationality: 'GUINES BISSAU', nationalityCode: 466 },
    { nationality: 'GUYANA', nationalityCode: 656 },
    { nationality: 'HAITIAN', nationalityCode: 657 },
    { nationality: 'HEARD MCDONALD ISLND', nationalityCode: 712 },
    { nationality: 'HONDURAN', nationalityCode: 658 },
    { nationality: 'HONG KONG', nationalityCode: 332 },
    { nationality: 'HUNGARIAN', nationalityCode: 205 },
    { nationality: 'ICELAND', nationalityCode: 133 },
    { nationality: 'INDIAN', nationalityCode: 354 },
    { nationality: 'INDONESIAN', nationalityCode: 303 },
    { nationality: 'IRANIAN', nationalityCode: 373 },
    { nationality: 'IRAQI', nationalityCode: 374 },
    { nationality: 'IRISH', nationalityCode: 106 },
    { nationality: 'ISLE OF MAN', nationalityCode: 672 },
    { nationality: 'ISRAELI', nationalityCode: 375 },
    { nationality: 'ITALIAN', nationalityCode: 107 },
    { nationality: 'IVORY COAST', nationalityCode: 422 },
    { nationality: 'JAMAICAN', nationalityCode: 659 },
    { nationality: 'JAPANESE', nationalityCode: 331 },
    { nationality: 'JORDANIAN', nationalityCode: 376 },
    { nationality: 'KAMPUCHEAN', nationalityCode: 676 },
    { nationality: 'KAZAKH', nationalityCode: 221 },
    { nationality: 'KENYAN', nationalityCode: 423 },
    { nationality: 'KIRGHIZ', nationalityCode: 219 },
    { nationality: 'KOREAN, NORTH', nationalityCode: 337 },
    { nationality: 'KOREAN, SOUTH', nationalityCode: 333 },
    { nationality: 'KOSOVAR', nationalityCode: 677 },
    { nationality: 'KUWAITI', nationalityCode: 377 },
    { nationality: 'KYRGHIS', nationalityCode: 990 },
    { nationality: 'KYRGYZSTAN', nationalityCode: 894 },
    { nationality: 'LAOTIAN', nationalityCode: 313 },
    { nationality: 'LATVIAN', nationalityCode: 214 },
    { nationality: 'LEBANESE', nationalityCode: 378 },
    { nationality: 'LESOTHO', nationalityCode: 467 },
    { nationality: 'LIBERIAN', nationalityCode: 424 },
    { nationality: 'LIBYAN', nationalityCode: 403 },
    { nationality: 'LIECHTENSTEIN', nationalityCode: 138 },
    { nationality: 'LITHUANIA', nationalityCode: 215 },
    { nationality: 'LUXEMBOURG', nationalityCode: 108 },
    { nationality: 'MACAO', nationalityCode: 335 },
    { nationality: 'MACEDONIA', nationalityCode: 210 },
    { nationality: 'MADAGASCAR', nationalityCode: 425 },
    { nationality: 'MALAWI', nationalityCode: 468 },
    { nationality: 'MALAYSIAN', nationalityCode: 304 },
    { nationality: 'MALDIVIAN', nationalityCode: 355 },
    { nationality: 'MALI', nationalityCode: 469 },
    { nationality: 'MALTESE', nationalityCode: 155 },
    { nationality: 'MARSHALLES', nationalityCode: 735 },
    { nationality: 'MARTINIQUE', nationalityCode: 661 },
    { nationality: 'MAURITANEAN', nationalityCode: 470 },
    { nationality: 'MAURITIAN', nationalityCode: 426 },
    { nationality: 'MAYOTTE', nationalityCode: 480 },
    { nationality: 'MEXICAN', nationalityCode: 606 },
    { nationality: 'MICRONESIAN', nationalityCode: 736 },
    { nationality: 'MOLDOVIAN', nationalityCode: 222 },
    { nationality: 'MONACO', nationalityCode: 143 },
    { nationality: 'MONGOLIAN', nationalityCode: 338 },
    { nationality: 'MONTSERRAT', nationalityCode: 662 },
    { nationality: 'MOROCCAN', nationalityCode: 404 },
    { nationality: 'MOZAMBIQUE', nationalityCode: 427 },
    { nationality: 'MYANMAR', nationalityCode: 311 },
    { nationality: 'NAMIBIA', nationalityCode: 471 },
    { nationality: 'NAURUAN', nationalityCode: 703 },
    { nationality: 'NEPALESE', nationalityCode: 356 },
    { nationality: 'NETHERLANDS', nationalityCode: 109 },
    { nationality: 'NETHERLANDS ANTIL.', nationalityCode: 623 },
    { nationality: 'NEW CALEDONIA', nationalityCode: 704 },
    { nationality: 'NEW ZEALANDER', nationalityCode: 705 },
    { nationality: 'NICARAGUAN', nationalityCode: 660 },
    { nationality: 'NIGER', nationalityCode: 472 },
    { nationality: 'NIGERIAN', nationalityCode: 428 },
    { nationality: 'NIUE ISLAND', nationalityCode: 726 },
    { nationality: 'NORFOLK ISLAND', nationalityCode: 713 },
    { nationality: 'NORTHERN MARIANA ISL', nationalityCode: 734 },
    { nationality: 'NORWEGIAN', nationalityCode: 134 },
    { nationality: 'OC IN OTHER AFRICA', nationalityCode: 499 },
    { nationality: 'OC IN S E ASIA', nationalityCode: 319 },
    { nationality: 'OC NORTH AMERICA', nationalityCode: 509 },
    { nationality: 'OC OCEANIA', nationalityCode: 799 },
    { nationality: 'OC CTRL STH AMERICA', nationalityCode: 699 },
    { nationality: 'OMAN', nationalityCode: 379 },
    { nationality: 'PAKISTANI', nationalityCode: 357 },
    { nationality: 'PALAU', nationalityCode: 737 },
    { nationality: 'PALESTINIAN', nationalityCode: 386 },
    { nationality: 'PANAMANIAN', nationalityCode: 624 },
    { nationality: 'PAPUA NEW GUINEA', nationalityCode: 706 },
    { nationality: 'PARAGUAY', nationalityCode: 607 },
    { nationality: 'PERUVIAN', nationalityCode: 608 },
    { nationality: 'PITCAIRN', nationalityCode: 727 },
    { nationality: 'POLISH', nationalityCode: 206 },
    { nationality: 'PORTUGUESE', nationalityCode: 111 },
    { nationality: 'PUERTO RICAN', nationalityCode: 502 },
    { nationality: 'QATAR', nationalityCode: 380 },
    { nationality: 'REUNION', nationalityCode: 429 },
    { nationality: 'ROMANIAN', nationalityCode: 207 },
    { nationality: 'RUSSIAN', nationalityCode: 223 },
    { nationality: 'RWANDA', nationalityCode: 473 },
    { nationality: 'SAINT HELENA', nationalityCode: 484 },
    { nationality: 'SAINT KITTS NEVIS', nationalityCode: 663 },
    { nationality: 'SAINT LUCIA', nationalityCode: 664 },
    { nationality: 'SAINT PIERRE MIQUELON', nationalityCode: 505 },
    { nationality: 'SAINT VINCENT', nationalityCode: 665 },
    { nationality: 'SALVADORAN', nationalityCode: 650 },
    { nationality: 'SAMOAN', nationalityCode: 707 },
    { nationality: 'SAN MARINO', nationalityCode: 144 },
    { nationality: 'SAO TOME PRINCI', nationalityCode: 474 },
    { nationality: 'SAUDI ARABIAN', nationalityCode: 381 },
    { nationality: 'SENEGAL', nationalityCode: 475 },
    { nationality: 'SENEGALESE', nationalityCode: 475 },
    { nationality: 'SEYCHELLES', nationalityCode: 476 },
    { nationality: 'SIERRA LEONE', nationalityCode: 477 },
    { nationality: 'SINGAPORE CITIZEN', nationalityCode: 301 },
    { nationality: 'SLOVAK', nationalityCode: 235 },
    { nationality: 'SLOVENIAN', nationalityCode: 233 },
    { nationality: 'SOLOMON ISLANDS', nationalityCode: 728 },
    { nationality: 'SOMALI', nationalityCode: 409 },
    { nationality: 'SOUTH AFRICAN', nationalityCode: 478 },
    { nationality: 'SPANISH', nationalityCode: 112 },
    { nationality: 'SRI LANKAN', nationalityCode: 358 },
    { nationality: 'ST HELENA', nationalityCode: 484 },
    { nationality: 'ST LUCIA', nationalityCode: 664 },
    { nationality: 'ST PIERRE MIQUELON', nationalityCode: 505 },
    { nationality: 'ST VINCENT', nationalityCode: 665 },
    { nationality: 'SUDANESE', nationalityCode: 405 },
    { nationality: 'SURINAME', nationalityCode: 666 },
    { nationality: 'SWAZI', nationalityCode: 480 },
    { nationality: 'SWEDISH', nationalityCode: 136 },
    { nationality: 'SWISS', nationalityCode: 137 },
    { nationality: 'SYRIAN', nationalityCode: 382 },
    { nationality: 'TAIWANESE', nationalityCode: 334 },
    { nationality: 'TADZHIK', nationalityCode: 224 },
    { nationality: 'TAJIKISTANI', nationalityCode: 992 },
    { nationality: 'TANZANIAN', nationalityCode: 430 },
    { nationality: 'THAI', nationalityCode: 306 },
    { nationality: 'TIMORENSE', nationalityCode: 307 },
    { nationality: 'TOGO', nationalityCode: 481 },
    { nationality: 'TONGA', nationalityCode: 730 },
    { nationality: 'TRINIDAD AND TOBAGO', nationalityCode: 667 },
    { nationality: 'TUNISIA', nationalityCode: 406 },
    { nationality: 'TURK', nationalityCode: 152 },
    { nationality: 'TURKMEN', nationalityCode: 225 },
    { nationality: 'TURKS AND CAICOS IS', nationalityCode: 668 },
    { nationality: 'TUVALU', nationalityCode: 731 },
    { nationality: 'U S MINOR ISLANDS', nationalityCode: 504 },
    { nationality: 'UGANDIAN', nationalityCode: 431 },
    { nationality: 'UKRAINIAN', nationalityCode: 212 },
    { nationality: 'UNITED ARAB EM.', nationalityCode: 383 },
    { nationality: 'UNKNOWN', nationalityCode: 889 },
    { nationality: 'UPPER VOLTA', nationalityCode: 993 },
    { nationality: 'URUGUAY', nationalityCode: 609 },
    { nationality: 'US MINOR OUTLYING ISLANDS', nationalityCode: 504 },
    { nationality: 'UZBEK', nationalityCode: 226 },
    { nationality: 'VANUATU', nationalityCode: 732 },
    { nationality: 'VATICAN CITY STATE', nationalityCode: 145 },
    { nationality: 'VENEZUELAN', nationalityCode: 610 },
    { nationality: 'VIETNAMESE', nationalityCode: 314 },
    { nationality: 'VIRGIN ISLANDS US', nationalityCode: 669 },
    { nationality: 'WALLIS AND FUTUNA', nationalityCode: 733 },
    { nationality: 'WESTERN SAHARA', nationalityCode: 479 },
    { nationality: 'YEMEN ARAB REP', nationalityCode: 388 },
    { nationality: 'YEMENI', nationalityCode: 384 },
    { nationality: 'YEMEN, SOUTH', nationalityCode: 387 },
    { nationality: 'YUGOSLAV', nationalityCode: 209 },
    { nationality: 'ZAIRAN', nationalityCode: 482 },
    { nationality: 'ZAMBIA', nationalityCode: 432 },
    { nationality: 'ZIMBABWEAN', nationalityCode: 483 },
];
