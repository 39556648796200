import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ClassicLayoutComponent } from './classic-layout.component';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { RedVerticalNavigationModule } from './navigation';
import { UserModule } from './user/user.module';
import { GroupActionHeaderDirective } from './group-action-header.directive';
import { PortalModule } from '@angular/cdk/portal';
import { LogoHeaderDirective } from './logo-header.directive';

@NgModule({
  declarations: [ClassicLayoutComponent, GroupActionHeaderDirective,LogoHeaderDirective],
  imports: [CommonModule, RouterModule, MatSidenavModule, MatIconModule, MatButtonModule, PortalModule, RedVerticalNavigationModule, UserModule],
  exports: [ClassicLayoutComponent, GroupActionHeaderDirective,LogoHeaderDirective],
})
export class ClassicLayoutModule {}
