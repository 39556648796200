import { UtilValidator } from './util.validator';
import { RedrFQDNValidator } from './redr-fqdn-validator.config';
import { RedrIpValidator } from './redr-ip-validator';

export interface RedrEmailValidatorOption {
    allowDisplayName?: boolean;
    allowIpDomain?: boolean;
    requireDisplayName?: boolean;
    allowUtf8LocalPart?: boolean;
    requireTld?: boolean;
    domainSpecificValidation?: boolean;
    ignoreMaxLength?: boolean;

}

export class RedrEmailValidator {
    private static instance: RedrEmailValidator = new RedrEmailValidator();
    static defaultMaxEmailLength = 254;
    private defaultSettings: RedrEmailValidatorOption = {
        allowDisplayName: false,
        requireDisplayName: false,
        allowUtf8LocalPart: true,
        requireTld: true
    };
    private splitNameAddress = /^([^\x00-\x1F\x7F-\x9F\cX]+)<(.+)>$/i;
    private gmailUserPart = /^[a-z\d]+$/;
    private emailUserPart = /^[a-z\d!#\$%&'\*\+\-\/=\?\^_`{\|}~]+$/i;
    private quotedEmailUser = /^([\s\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e]|(\\[\x01-\x09\x0b\x0c\x0d-\x7f]))*$/i;
    // tslint:disable-next-line: max-line-length
    private quotedEmailUserUtf8 = /^([\s\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|(\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*$/i;
    private emailUserUtf8Part = /^[a-z\d!#\$%&'\*\+\-\/=\?\^_`{\|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+$/i;

    static isEmail(str = '', options: RedrEmailValidatorOption = {}) {
        UtilValidator.assertString(str);
        options = this.instance.merge(options, this.instance.defaultSettings);
        if (options.requireDisplayName || options.allowDisplayName) {
            const displayEmail = str.match(this.instance.splitNameAddress);
            if (displayEmail) {
                let displayName;
                [, displayName, str] = displayName;
                // sometimes need to trim the last space to get the display name
                // because there may be a space between display name and email address
                // eg. myname <address@gmail.com>
                // the display name is `myname` instead of `myname `, so need to trim the last space
                if (displayName.endsWith(' ')) {
                    displayName = displayName.substr(0, displayName.length - 1);
                }

                if (!this.instance.validateDisplayName(displayName)) {
                    return false;
                }
            } else if (options.requireDisplayName) {
                return false;
            }
        }
        if (!options.ignoreMaxLength && str.length > RedrEmailValidator.defaultMaxEmailLength) {
            return false;
        }

        const parts = str.split('@');
        const domain = parts.pop();
        let user = parts.join('@');
        const lowerDomain = domain.toLowerCase();
        if (options.domainSpecificValidation && (lowerDomain === 'gmail.com' || lowerDomain === 'googlemail.com')) {

            //  Previously we removed dots for gmail addresses before validating.
            //  This was removed because it allows `multiple..dots@gmail.com`
            //  to be reported as valid, but it is not.
            //  Gmail only normalizes single dots, removing them from here is pointless,
            //  should be done in normalizeEmail

            user = user.toLowerCase(); // Removing sub-address from username before gmail validation

            // Removing sub-address from username before gmail validation
            const username = user.split('+')[0];

            // Dots are not included in gmail length restriction
            if (!this.instance.isByteLength(username.replace('.', ''), {min: 6, max: 30})) {
                return false;
            }

            const userPartsArr = username.split('.');
            for (let i = 0; i < userPartsArr.length; i++) {
                if (!this.instance.gmailUserPart.test(userPartsArr[i])) {
                    return false;
                }
            }

        }
        if (!this.instance.isByteLength(user, {max: 64}) ||
            !this.instance.isByteLength(domain, {max: 254})) {
            return false;
        }
        if (!RedrFQDNValidator.isFQDN(domain, {requireTld: options.requireTld})) {
            if (!options.allowIpDomain) {
                return false;
            }

            if (!RedrIpValidator.isIP(domain)) {
                if (!domain.startsWith('[') || !domain.endsWith(']')) {
                    return false;
                }

                const noBracketdomain = domain.substr(1, domain.length - 2);

                if (noBracketdomain.length === 0 || !RedrIpValidator.isIP(noBracketdomain)) {
                    return false;
                }
            }
        }
        if (user[0] === '"') {
            user = user.slice(1, user.length - 1);
            return options.allowUtf8LocalPart ?
                this.instance.quotedEmailUserUtf8.test(user) :
                this.instance.quotedEmailUser.test(user);
        }

        const pattern = options.allowUtf8LocalPart ?
            this.instance.emailUserUtf8Part : this.instance.emailUserPart;

        const userParts = user.split('.');
        for (let i = 0; i < userParts.length; i++) {
            if (!pattern.test(userParts[i])) {
                return false;
            }
        }

        return true;
    }

    private merge(obj = {}, defaults: object) {
        for (const key in defaults) {
            if (typeof obj[key] === 'undefined') {
                obj[key] = defaults[key];
            }
        }
        return obj;
    }

    private isByteLength(str, options) {
        UtilValidator.assertString(str);
        let min;
        let max;
        if (typeof (options) === 'object') {
            min = options.min || 0;
            max = options.max;
        } else { // backwards compatibility: isByteLength(str, min [, max])
            min = arguments[1];
            max = arguments[2];
        }
        const len = encodeURI(str).split(/%..|./).length - 1;
        return len >= min && (typeof max === 'undefined' || len <= max);
    }

    private validateDisplayName(displayName: string) {
        const trimQuotes = displayName.match(/^"(.+)"$/i);
        const displayNameWithoutQuotes = trimQuotes ? trimQuotes[1] : displayName;

        // display name with only spaces is not valid
        if (!displayNameWithoutQuotes.trim()) {
            return false;
        }

        // check whether display name contains illegal character
        const containsIllegal = /[\.";<>]/.test(displayNameWithoutQuotes);
        if (containsIllegal) {
            // if contains illegal characters,
            // must to be enclosed in double-quotes, otherwise it's not a valid display name
            if (!trimQuotes) {
                return false;
            }

            // the quotes in display name must start with character symbol \
            const allStartWithBackSlash =
                displayNameWithoutQuotes.split('"').length === displayNameWithoutQuotes.split('\\"').length;
            if (!allStartWithBackSlash) {
                return false;
            }
        }

        return true;
    }
}
