import { Directive, OnInit, OnDestroy, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Directive({
    selector: '[appDebounceClick]'
})
export class DebounceClickDirective implements OnInit, OnDestroy {
    @Input() debounceTime = 2000;
    @Output() appDebounceClick = new EventEmitter();
    private clicks = new Subject();
    unsubscribeAll: Subject<any> = new Subject<any>();
    private isActive = true;
    constructor() { }
    ngOnInit(): void {
        this.clicks
            .pipe(
                debounceTime(this.debounceTime),
                takeUntil(this.unsubscribeAll)
            )
            .subscribe(e => this.isActive = true);
    }
    ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
        this.appDebounceClick.complete();
    }
    @HostListener('click', ['$event'])
    clickEvent(event) {
        event.preventDefault();
        event.stopPropagation();
        if (this.isActive) {
            this.isActive = false;
            this.appDebounceClick.emit(event);
            this.clicks.next(event);
        }
    }

}
