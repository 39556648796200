import { Injectable } from "@angular/core";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, filter, finalize, switchMap, take } from "rxjs/operators";
import { HTTP_STATUS_CODE } from "@core/common/http-status-code";
import { AuthApiService } from "@shared/services/auth-api.service";
import { GlobalService } from "@core/services/global.service";
import { AppConstant } from "@app/app.constant";
import { AuthService } from "@modules/auth/auth.service";
import { Router } from "@angular/router";

@Injectable()
export class HttpRefreshTokenInterceptor implements HttpInterceptor {
  static readonly EXCLUDING_URL_LIST: Array<{
    url: string;
    method?: string;
  }> = [
    {
      url: AuthApiService.REFRESH_TOKEN,
    },
    {
      url: AuthApiService.LOGIN,
      method: "POST",
    },
  ];

  isRefreshing = false;
  refreshToken: string;
  accessToken = new BehaviorSubject(null);

  constructor(
    private authApiService: AuthApiService,
    private globalService: GlobalService,
    private authService: AuthService,
    private router: Router
  ) {
    this.globalService.storage
      .watch(AppConstant.GLOBAL_STORAGE.REFRESH_TOKEN)
      .subscribe((token) => {
        this.refreshToken = token;
      });
  }

  static updateToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // Only refresh when user already logged in
    if (request.headers.get("ILI") !== "true") {
      return next.handle(request);
    }
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse | any) => {
        if (
          !this.isIgnoredRequest(request) &&
          error instanceof HttpErrorResponse
        ) {
          if (error.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            console.log("hello 401");
            return this.handle401Error(request, next, error);
          }
        }
        return throwError(error);
      })
    );
  }

  private handle401Error(
    request: HttpRequest<any>,
    next: HttpHandler,
    error: HttpErrorResponse
  ) {
    console.log("refreshToken -->", this.refreshToken);
    if (this.refreshToken) {
      if (!this.isRefreshing) {
        this.accessToken.next(null);
        this.isRefreshing = true;
        console.log("isRefreshing -->", this.isRefreshing);
        return this.authApiService
          .refreshToken({ refreshToken: this.refreshToken })
          .pipe(
            switchMap((data: any) => {
              console.log("switchMap -->", data);
              // trigger to other requests which requests same time with current one
              this.accessToken.next(data.accessToken);
              // broadcast new token to global
              this.authService.setLoggedIn(data);
              return next.handle(
                HttpRefreshTokenInterceptor.updateToken(
                  request,
                  data.accessToken
                )
              );
            }),
            catchError((err) => {
              console.log("error -->", err);
              if (err.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
                this.accessToken.next(" ");
                this.forcedLogout();

                return throwError(err);
              }
              // Refresh Token Invalid
              if (
                err.statusCode === HTTP_STATUS_CODE.FORBIDDEN &&
                err.errorCode === "AUTH008"
              ) {
                this.accessToken.next(" ");
                this.forcedLogout();

                return throwError(err);
              }
              return throwError(err);
            }),
            finalize(() => {
              this.isRefreshing = false;
            })
          );
      } else {
        return this.accessToken.pipe(
          filter((token) => !!token), // catch new token or refresh request failed
          take(1),
          switchMap((token) => {
            if (token === " ") {
              return throwError(error);
              // throw error when refresh token request got error
            } else {
              return next.handle(
                HttpRefreshTokenInterceptor.updateToken(request, token)
              );
            }
          })
        );
      }
    } else {
      return throwError(error);
    }
  }

  /**
   * Handle all request but some requests will be ignored.
   * The refresh token and login request(POST - in login request 401 is invalid auth information) got error will be ignored.
   *
   * @param request
   * @returns
   */
  private isIgnoredRequest(request: HttpRequest<unknown>) {
    for (const item of HttpRefreshTokenInterceptor.EXCLUDING_URL_LIST) {
      const { url, method } = item;

      if (request.url.includes(url)) {
        console.log("Non handle request: ", request.url, request.method);

        if (method && request.method !== method) {
          /*
                        If have defined the method on excluding list. Check it.
                        Eg: with "/auth" have 2 method GET, POST. GET which is request need to handle. Another hand POST will not.
                    */
          return false;
        }

        return true;
      }

      /* Go to the next url */
    }

    return false;
  }
  private forcedLogout(): void {
    this.authService.logout();
    this.authService.redirectToLogin()
    // this.router.navigate(["/auth", "≈"],{queryParams:{forcedLogin:true}});
  }
}
