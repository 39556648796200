import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserModel } from '../models/user.model';
import { AppConstant } from '@app/app.constant';
import { UserGroupModel } from '@shared/models/user-group.model';

@Injectable({
    providedIn: 'root'
})
export class AuthApiService {
    static LOGIN = 'auth';
    static REFRESH_TOKEN = 'auth/access-token';
    static LOGOUT = 'auth/logout';
    static REGISTER = 'auth/register';
    static VERYFYACCESSTOKEN = 'auth/verify';
    static USER_PROFILE = 'auth';
    static AUTH_CHANGE_PASSWORD = 'auth/change-password';
    static FORGOT_PASSWORD = 'auth/forgot-password';
    static RESET_PASSWORD = 'auth/reset-password';
    static RESEND_VERIFICATION = 'auth/resend-verification';
    static LOGIN_FROM_HUTTON = 'organization-auth/hutton';

    constructor(private apiService: ApiService) {
    }

    login(data: { email; password; }): Observable<any> {
        return this.apiService.post(AuthApiService.LOGIN, data, {
            exposeHeaders: {
                [AppConstant.HTTP_ERROR.HTTP_STATUS_CODE_EXCLUDE]: ['401', '403']
            }
        });
    }

    refreshToken(data: { refreshToken: string; }): Observable<any> {
        return this.apiService.post(AuthApiService.REFRESH_TOKEN, data);
    }

    logout(): Observable<any> {
        return this.apiService.get(AuthApiService.LOGOUT);
    }

    getUserProfile(): Observable<UserGroupModel> {
        return this.apiService.get(AuthApiService.USER_PROFILE).pipe(map(res => UserGroupModel.fromJson(res)));
    }

    updateUserProfile(data: object): Observable<UserModel> {
        return this.apiService.put(AuthApiService.USER_PROFILE, data, { excludeFields: ['lastName', 'profile'] })
            .pipe(map(res => UserModel.fromJson(res)));
    }

    changePassword(data): Observable<any> {
        return this.apiService.put(AuthApiService.AUTH_CHANGE_PASSWORD, data);
    }

    register(data: object): Observable<any> {
        return this.apiService.post(AuthApiService.REGISTER, data, { excludeFields: ['lastName', 'profile'] });
    }

    verifyAccessToken(token: string): Observable<any> {
        return this.apiService.post(AuthApiService.VERYFYACCESSTOKEN, {}, {
            exposeHeaders: {
                [AppConstant.HTTP_ERROR.HTTP_STATUS_CODE_EXCLUDE]: ['403']
            },
            requestOptions: {
                headers: this.apiService.headers.set('Authorization', `Bearer ${token}`)
            }
        });
    }

    resendVerification(data: object): Observable<any> {
        return this.apiService.post(AuthApiService.RESEND_VERIFICATION, data, {
            exposeHeaders: {
                [AppConstant.HTTP_ERROR.HTTP_STATUS_CODE_EXCLUDE]: ['403']
            }
        });
    }

    requestForgotPassword(data: object, token: string): Observable<any> {
        return this.apiService.post(AuthApiService.FORGOT_PASSWORD, data, {
            requestOptions: {
                headers: this.apiService.headers.set('Authorization', `Bearer ${token}`)
            }
        });
    }

    resetPassword(data: object, token: string): Observable<any> {
        return this.apiService.post(AuthApiService.RESET_PASSWORD, data, {
            exposeHeaders: {
                [AppConstant.HTTP_ERROR.HTTP_STATUS_CODE_EXCLUDE]: ['401', '403']
            },
            requestOptions: {
                headers: this.apiService.headers.set('Authorization', `Bearer ${token}`)
            }
        });
    }

    loginFromHutton(data: { grantCode: string; }): Observable<any> {
        return this.apiService.post(AuthApiService.LOGIN_FROM_HUTTON, data, {
            exposeHeaders: {
                [AppConstant.HTTP_ERROR.HTTP_STATUS_CODE_EXCLUDE]: ['401', '403', '404']
            }
        });
    }
}
