import {
    Directive,
    EventEmitter,
    HostListener,
    Input,
    Output,
    ElementRef,
    OnDestroy,
    Renderer2,
} from '@angular/core';
function isHTMLString(str: string): boolean {
    const parser = new DOMParser();
    const doc = parser.parseFromString(str, 'text/html');
    return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
}
export function coerceHtmlStringProperty(val): string {
    let input = '';
    if (val) {
        input = typeof val === 'string' ? val : String(val);
    }
    if (isHTMLString(input)) {
        return input;
    } else {
        return '<span>' + input + '</span>';
    }
}
@Directive({
    selector: '[appAppendChild]',
})
export class AppendChildDirective {
    @Input('appAppendChild') get htmlString() {
        return this._htmlString;
    }
    set htmlString(val) {
        this._htmlString = coerceHtmlStringProperty(val);
        this.render();
    }
    private _htmlString: string;
    constructor(private elementRef: ElementRef, private renderer: Renderer2) {}
    private transformToHTMLElement(value: string): HTMLElement[] | null {
        const template = document.createElement('template');
        template.innerHTML = value.trim();
        return Array.from(template.content.childNodes)  as HTMLElement[];
    }
    private appendHtmlNode(node: HTMLElement | null) {
        if (node) {
            this.renderer.appendChild(this.elementRef.nativeElement, node);
        }
    }
    clear():void{
        this.elementRef.nativeElement.innerHTML = '';
    }
    render() {
        this.clear();
        const nodes = this.transformToHTMLElement(this.htmlString);
        nodes.forEach(node => {
            this.appendHtmlNode(node);
        })
        
    }
}
