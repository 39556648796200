import { DOCUMENT } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, NgZone, inject } from "@angular/core";
import {
  AuthorizationParams,
  IRedocOauth2Config,
  RedocOAuthEvent,
  RedocOAuthInfoEvent,
  RedocOAuthLogger,
  RedocOAuthStorage,
  RedocOauth2ClientConnect,
  RedocOauth2ClientConnectOptionCallback,
} from "@redocco/oauth2-redoc-connect";
import { Observable, Subject, Subscription, of } from "rxjs";
import { REDOC_OAUTH2_CONFIG } from "./token";
import { delay, filter } from "rxjs/operators";

@Injectable()
export class RedocConnectService {
  clientConnect!: RedocOauth2ClientConnect;
  tokenReceivedSubscription?: Subscription;
  accessTokenTimeoutSubscription?: Subscription;
  idTokenTimeoutSubscription?: Subscription;
  automaticRefreshSubscription?: Subscription;
  callbackOption: Partial<RedocOauth2ClientConnectOptionCallback> = {
    onEvent: (e) => {
      this.pushEvent(e);
    },
    onConfigChanged: () => {
      this.setupRefreshTimer();
    },
  };
  protected eventsSubject: Subject<RedocOAuthEvent> =
    new Subject<RedocOAuthEvent>();
  events: Observable<RedocOAuthEvent> = this.eventsSubject.asObservable();
  constructor(
    @Inject(RedocOAuthLogger) private logger: RedocOAuthLogger,
    @Inject(RedocOAuthStorage) private storage: RedocOAuthStorage,
    @Inject(REDOC_OAUTH2_CONFIG)
    private options: Partial<IRedocOauth2Config>,
    @Inject(NgZone) private ngZone: NgZone,
    @Inject(HttpClient) private http: HttpClient,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.clientConnect = new RedocOauth2ClientConnect(
      this.storage,
      this.document,
      this.logger,
      this.callbackOption
    );
    this.clientConnect.configure(this.options);
    // this.setupRefreshTimer();
  }
  configure(config: Partial<IRedocOauth2Config>) {
    this.clientConnect.configure(config);
  }
  loadDiscoveryDocumentAndTryLogin() {
    return this.clientConnect.loadDiscoveryDocumentAndTryLogin();
  }
  tryLogin() {
    return this.clientConnect.tryLogin();
  }
  loginWithRedirect(
    additionalState?: string,
    params: Partial<AuthorizationParams> = {}
  ): void {
    this.clientConnect.initLoginFlow(additionalState, params);
  }
  loginWithPopup(params: Partial<AuthorizationParams> = {}): void {
    this.clientConnect.loginWithPopup(params);
  }
  logOut(): void {
    this.clientConnect.logOut();
  }
  get state() {
    return this.clientConnect.state;
  }
  getRefreshToken() {
    return this.clientConnect.getRefreshToken();
  }
  getAccessToken() {
    return this.clientConnect.getAccessToken();
  }
  getIdentityClaims() {
    return this.clientConnect.getIdentityClaims() as {
      sub: number;
      iat: number;
      iss: string;
      aud: string;
    };
  }
  hasValidAccessToken(): boolean {
    return this.clientConnect.hasValidAccessToken();
  }
  setupSessionCheck(): void {
    // this.events
    //   .pipe(filter((e) => e.type === 'token_received'))
    //   .subscribe((e) => {
    //     this.initSessionCheck();
    //   });
  }
  setupRefreshTimer(): void {
    if (typeof window === "undefined") {
      this.clientConnect.debug("timer not supported on this plattform");
      return;
    }
    if (
      this.clientConnect.hasValidIdToken() ||
      this.clientConnect.hasValidAccessToken()
    ) {
      this.clearAccessTokenTimer();
      this.clearIdTokenTimer();
      this.setupExpirationTimers();
    }

    if (this.tokenReceivedSubscription) {
      this.tokenReceivedSubscription.unsubscribe();
    }

    this.tokenReceivedSubscription = this.events
      .pipe(filter((e) => e.type === "token_received"))
      .subscribe((_) => {
        this.clearAccessTokenTimer();
        this.clearIdTokenTimer();
        this.setupExpirationTimers();
      });
  }
  clearAccessTokenTimer(): void {
    if (this.accessTokenTimeoutSubscription) {
      this.accessTokenTimeoutSubscription.unsubscribe();
    }
  }
  clearIdTokenTimer(): void {
    if (this.idTokenTimeoutSubscription) {
      this.idTokenTimeoutSubscription.unsubscribe();
    }
  }
  clearAutomaticRefreshTimer(): void {
    if (this.automaticRefreshSubscription) {
      this.automaticRefreshSubscription.unsubscribe();
    }
  }
  setupExpirationTimers(): void {
    if (this.clientConnect.hasValidAccessToken()) {
      this.setupAccessTokenTimer();
    }

    if (
      !this.clientConnect.config.disableIdTokenTimer &&
      this.clientConnect.hasValidIdToken()
    ) {
      this.setupIdTokenTimer();
    }
  }
  setupAccessTokenTimer(): void {
    const timeout = this.clientConnect.calcTokenTimeout("access_token");

    this.ngZone.runOutsideAngular(() => {
      this.accessTokenTimeoutSubscription = of(
        new RedocOAuthInfoEvent("token_expires", "access_token")
      )
        .pipe(delay(timeout))
        .subscribe((e) => {
          this.ngZone.run(() => {
            this.eventsSubject.next(e);
          });
        });
    });
  }
  setupIdTokenTimer(): void {
    const timeout = this.clientConnect.calcTokenTimeout("id_token");

    this.ngZone.runOutsideAngular(() => {
      this.idTokenTimeoutSubscription = of(
        new RedocOAuthInfoEvent("token_expires", "id_token")
      )
        .pipe(delay(timeout))
        .subscribe((e) => {
          this.ngZone.run(() => {
            this.pushEvent(e);
          });
        });
    });
  }
  public stopAutomaticRefresh() {
    this.clearAccessTokenTimer();
    this.clearIdTokenTimer();
    this.clearAutomaticRefreshTimer();
  }
  cleanUp(): void {
    this.clientConnect.cleanUpStorage();
  }
  pushEvent(event: RedocOAuthEvent): void {
    this.eventsSubject.next(event);
  }
}
