import { Directive, HostListener, ElementRef } from '@angular/core';
import { AppConstant } from '@app/app.constant';

@Directive({
    selector: 'input[appQuantityNumber]'
})
export class QuantityNumberDirective {

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        this.run(this.inputElement.nativeElement.value);
    }
    constructor(
        private inputElement: ElementRef
    ) {
    }
    private check(value: string) {
        // tslint:disable-next-line: radix
        return /^\d*$/.test(value) && (value === '' || (parseInt(value) > 0 && parseInt(value) <= AppConstant.MAX_QUANTITY_INPUT));
    }

    private run(oldValue) {
        setTimeout(() => {
            const currentValue: string = this.inputElement.nativeElement.value;
            if (currentValue !== '' && !this.check(currentValue)) {
                this.inputElement.nativeElement.value = oldValue;
            }
        });
    }
}
