import { Injectable } from "@angular/core";
import { GlobalService } from "@core/services/global.service";
import { GroupModel } from "@shared/models/group.model";
import { AppConstant } from "@app/app.constant";
import { GroupApiService } from "@app/shared/services/group-api.service";
import { UserModel } from "@shared/models/user.model";

@Injectable({ providedIn: "root" })
export class PermissionService {
  groups: GroupModel[] = [];
  permissions: Array<string> = [];
  policies: Array<string> = [];
  user: UserModel;

  constructor(
    private globalService: GlobalService,
    private groupApiService: GroupApiService
  ) {
    this.globalService.storage
      .watch(AppConstant.GLOBAL_STORAGE.GROUPS_PERMISSIONS)
      .subscribe((data) => {
        this.permissions = [];
        this.policies = [];
        if (data) {
          this.groups = data;
          this.groups.forEach((group) => {
            this.permissions = [
              ...this.permissions,
              ...group.permissions.map((permission) => permission.slug),
            ];
            if (
              group.viewPolicy &&
              group.viewPolicy.policy &&
              Array.isArray(group.viewPolicy.policy)
            ) {
              this.policies = [...this.policies, ...group.viewPolicy.policy];
            }
          });
          this.permissions = [...new Set(this.permissions)];
          this.policies = [...new Set(this.policies)];
        }
      });
    this.globalService.storage
      .watch(AppConstant.GLOBAL_STORAGE.USER)
      .subscribe((data) => {
        this.user = data;
      });
  }

  getGroups() {
    return this.groups;
  }

  hasPermissions(permissions: string | Array<string>): boolean {
    if (Array.isArray(permissions)) {
      let flat = true;
      permissions.forEach((permission) => {
        flat = flat && this.permissions.includes(permission);
      });

      return flat;
    }

    return this.permissions.includes(permissions);
  }

  hasPolicy(policy: string): boolean {
    return this.policies.includes(policy);
  }
}
