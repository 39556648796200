import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { AppConstant } from '@app/app.constant';

@Injectable()
export class HttpLogInterceptor implements HttpInterceptor {

    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        // Just for some tracking from backend
        if (request.url.startsWith(AppConstant.API_HOST)) {
            request = request.clone({
                headers: request.headers.set('X-Request-ID', uuidv4()).set('X-App-ID', AppConstant.APP_ID)
            });
        }

        return next.handle(request);
    }
}
