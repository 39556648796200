import { Component, Inject, Input, ViewChild } from "@angular/core";
import { IVendor, VendorModel } from "../../../shared/models/vendor.model";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { VendorApiService } from "@app/shared/services/vendor-api.service";
import { VendorFormComponent } from "../vendor-form/vendor-form.component";
import { DevUtil } from "@app/core/common/dev.utils";
import { VendorCreateDto, VendorEditDto } from "@app/shared/dtos/vendor.dto";
import { GlobalService } from "@app/core/services/global.service";
import { MESSAGE_TYPE } from "@app/shared/models/message.model";

@Component({
    selector: 'app-vendor-create-or-update-dialog',
    templateUrl: './vendor-create-or-update-dialog.component.html',
    styleUrls: ['./vendor-create-or-update-dialog.component.scss'],
})
export class VendorCreateOrUpdateDialogComponent{
    @ViewChild(VendorFormComponent) form:VendorFormComponent;
    item: VendorModel;
    constructor(
        private dialogRef: MatDialogRef<VendorCreateOrUpdateDialogComponent>,
        private _apiService: VendorApiService,
        private globalService:GlobalService,
        @Inject(MAT_DIALOG_DATA)  data?: { item: VendorModel }
    ) {
        if(data?.item){
            this.item = data.item
        }
    }
    close():void{
        this.dialogRef.close()
    }
    save(){
        if(this.form.invalid){
            this.form.markAllTouched();
            DevUtil.scrollToElement()
            return;
        }
        const payload = this.form.getPayload();
        if(this.item && this.item.id){
            const dto = VendorEditDto.fromJson(payload);
            console.log('payload --> ',{payload,dto});
            // return;
            this._apiService.update(dto).subscribe((res) => {
                this.globalService.message.next({
                    type: MESSAGE_TYPE.success,
                    message:'common.update_successfully'
                });
               this.initBeforeClose(res.id)
            })
        }else{
            const payload = this.form.getPayload();
            const dto = VendorCreateDto.fromJson(payload);
            console.log('payload --> ',{payload,dto});
            // return;
            this._apiService.create(dto).subscribe((res:IVendor) => {
                this.globalService.message.next({
                    type: MESSAGE_TYPE.success,
                    message:'common.create_successfully'
                });
                this.initBeforeClose(res.id)
            })
        }
       
    }
    initBeforeClose(id:number):void{
        this._apiService.findById(id).subscribe(item => {
            this.dialogRef.close(item)
        })
    }
}