<section class="flex-column-center card">
    <img
        class="card-logo"
        src="../../../../assets/images/icons/illustrations/error.svg"
    />
    <div class="card-title">{{ 'common.error' | translate }}</div>
    <p class="card-des">
        {{ 'common.something_went_wrong' | translate }}
    </p>
    <button
        class="full-width mat-elevation-z"
        (click)="backToHomePage()"
        color="primary"
        mat-flat-button
        type="button"
    >
        {{ 'common.back_to_home' | translate }}
    </button>
</section>
