import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { RouterService } from '@app/core/services/router.service';
import { filter, take } from 'rxjs/operators';
import { Location } from '@angular/common';
@Component({
    selector: 'app-service-unavailable',
    templateUrl: './service-unavailable.component.html',
    styleUrls: ['./service-unavailable.component.scss']
})
export class ServiceUnavailableComponent implements OnInit {

    history: Array<string>;

    constructor(
        private router: Router,
        private location: Location,
        private routerService: RouterService
    ) {
        this.history = this.routerService.getHistory();

        if (this.history[this.history.length - 1]) { // for manual navigate
            this.location.replaceState(this.history[this.history.length - 1]);
        } else {
            this.router.events
                .pipe(
                    filter(event => event instanceof NavigationEnd),
                    take(1)
                )
                .subscribe((event) => {
                    this.location.replaceState(event['url']);
                });
        }
    }

    ngOnInit() {
    }

}
