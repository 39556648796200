import { BaseModel } from '@core/models/base.model';
import { Type } from 'class-transformer';
import { PermissionModel } from '@shared/models/permission.model';
import { Default } from '@core/decorators/default.decorator';

export interface GroupInterface {
    id: number;
    name: string;
    slug: string;
    scope: string;
    scopeId: string;
    createBy: any;
    uuid: string;
    description: string;
    deletable: boolean;
    tag: any;
    permissions: PermissionModel[];
    viewPolicy: {
        policy: string[];
    };
}

export class GroupModel extends BaseModel implements GroupInterface {
    id: number;
    name: string;
    slug: string;
    scope: string;
    scopeId: string;
    createBy: any;
    uuid: string;
    description: string;
    deletable: boolean;
    tag: any;

    viewPolicy: {
        policy: string[];
    };

    @Default([])
    @Type(() => PermissionModel)
    permissions: PermissionModel[];
}
