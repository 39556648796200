<div class="flex flex-col flex auto" [formGroup]="form">
    <div class="h-10 mb-4">
        <mat-form-field
        class="h-full w-full custom-height-form-field "
      >
        <span class="text-color-gray" matPrefix>
          <mat-icon>search</mat-icon>
        </span>
        <input
          matInput
          [formControl]="
            searchCtr
          "
          type="text"
          placeholder="Search by postal code, address..."
          [matAutocomplete]="postalCode"
        />
        <mat-autocomplete
        autoActiveFirstOption
        #postalCode="matAutocomplete"
        [displayWith]="displayWith"
    >
        <mat-option
            *ngFor="let option of oneMapResults"
            [value]="option"
            [matTooltip]="option.address"
        >
            {{ option.address }}
        </mat-option>
    </mat-autocomplete>
      </mat-form-field>  
    </div>
    
                  
    <!-- <mat-form-field class="full-width">
        <mat-label>{{
            'singapore_address.postal_code'
                | translate
                | titlecase
        }}</mat-label>
        <input
            autocomplete="off"
            [formControl]="searchCtr"
            matInput
            placeholder="{{
                'singapore_address.enter_postal_code'
                    | translate
            }}"
            required
            type="text"
            [matAutocomplete]="postalCode"
        />

        <mat-autocomplete
            autoActiveFirstOption
            #postalCode="matAutocomplete"
            [displayWith]="displayWith"
        >
            <mat-option
                *ngFor="let option of oneMapResults"
                [value]="option"
                [matTooltip]="option.address"
            >
                {{ option.address }}
            </mat-option>
        </mat-autocomplete>
        <mat-error
            *ngIf="
                form.controls['postalCode'].hasError('required')
            "
        >
            {{ 'validator.required' | translate }}
        </mat-error>
    </mat-form-field> -->
    <div class="grid md:grid-cols-{{numOfGrids}} grid-cols-1 md:gap-4">
        <mat-form-field class="full-width">
            <mat-label>{{
                'singapore_address.block_number'
                    | translate
                    | titlecase
            }}</mat-label>
            <input
                autocomplete="off"
                formControlName="blockNumber"
                matInput
                required
                placeholder="{{
                    'singapore_address.enter_block_number'
                        | translate
                }}"
                type="text"
            />

            <mat-error
                *ngIf="
                    form.controls['blockNumber'].hasError(
                        'required'
                    )
                "
            >
                {{ 'validator.required' | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field class="full-width">
            <mat-label>{{
                'singapore_address.street_name'
                    | translate
                    | titlecase
            }}</mat-label>
            <input
                autocomplete="off"
                formControlName="streetName"
                matInput
                required
                placeholder="{{
                    'singapore_address.enter_street_name'
                        | translate
                }}"
                type="text"
            />

            <mat-error
                *ngIf="
                    form.controls['streetName'].hasError('required')
                "
            >
                {{ 'validator.required' | translate }}
            </mat-error>
        </mat-form-field>
    </div>
    <div class="grid md:grid-cols-{{numOfGrids}} grid-cols-1 md:gap-4">
        <mat-form-field class="full-width">
            <mat-label>{{
                'singapore_address.floor_number'
                    | translate
                    | titlecase
            }}</mat-label>
            <input
                autocomplete="off"
                formControlName="floorNumber"
                matInput
                placeholder="{{
                    'singapore_address.enter_floor_number'
                        | translate
                }}"
                type="text"
            />
        </mat-form-field>
        <mat-form-field class="full-width">
            <mat-label>{{
                'singapore_address.unit_number'
                    | translate
                    | titlecase
            }}</mat-label>
            <input
                autocomplete="off"
                formControlName="unitNumber"
                matInput
                placeholder="{{
                    'singapore_address.enter_unit_number'
                        | translate
                }}"
                type="text"
            />
        </mat-form-field>
        
    </div>
    <div class="grid md:grid-cols-{{numOfGrids}} grid-cols-1 md:gap-4">
        <mat-form-field class="full-width">
            <mat-label
                >{{
                    'singapore_address.building_name'
                        | translate
                        | titlecase
                }}
            </mat-label>
            <input
                autocomplete="off"
                formControlName="buildingName"
                matInput
                placeholder="{{
                    'singapore_address.enter_building_name'
                        | translate
                }}"
                type="text"
            />
        </mat-form-field>
        <mat-form-field class="full-width">
            <mat-label>{{
                'singapore_address.postal_code'
                    | translate
                    | titlecase
            }}</mat-label>
            <input
                autocomplete="off"
                formControlName="postalCode"
                matInput
                placeholder="{{
                    'singapore_address.enter_postal_code'
                        | translate
                }}"
                required
                type="text"
            />
            <mat-error
            *ngIf="
                form.controls['postalCode'].hasError('required')
            "
        >
            {{ 'validator.required' | translate }}
        </mat-error>
        </mat-form-field>
    </div>
</div>