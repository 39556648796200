<div class="box-header" fxHide [fxShow]="isShowHeader">
    <h1 class="box-title">{{title | translate }}</h1>
    <div class="box-action__upload">
        <input class="input-file" type="file" #input [accept]="allowedMimeType" [multiple]="isMultiple" appUpload
            [uploadToFolder]="uploadToFolder" [disabled]="disabled" [isUploadImmediately]="isUploadImmediately"
            [maxFiles]="maxFiles" [maxFileSize]="maxFileSize" [allowedMimeType]="allowedMimeType"
            [isReplaced]="isReplaced" [existFileKeys]="existFileKeys" (all)="onAllFiles($event)"
            (added)="onAdded($event)" (removed)="onRemoved($event)" (lastUploaded)="onLastUpdated($event)"
            (begin)="onBegin()" (finished)="onFinished()" (beginFile)="onBeginFile($event)"
            (finishedFile)="onFinishedFile($event)" (fullSlot)="onFullSlot()" [allowExtensions]="allowExtensions">
    </div>
    <button *ngIf="!dragDropMode" type="button" (click)="openSelectBox()" mat-icon-button color="primary">
        <mat-icon class="icon">add</mat-icon>
    </button>
</div>
<div *ngIf="dragDropMode" class="upload-file-container" appFileDrop (fileOver)="fileOver($event)"
    (fileDrop)="fileDrop($event)" [ngClass]="{ 'file-over': isFileOver }">
    <div class="drag-drop-container">
        <div class="icon-cloud">
            <mat-icon>cloud_upload</mat-icon>
        </div>
        <div class="title">
            {{ uploadTitle | translate }}
        </div>
        <div class="description">
            {{ message | translate }}
            <span *ngIf="isShowMaximunFileText">{{
                'upload_file.maximum_files'
                    | translate: { maxFiles: maxFiles }
            }}
            </span>
        </div>
        <div class="description">or <a class="browse-btn" (click)="openSelectBox()">
                {{ 'upload_file.browse' | translate }}
            </a></div>

    </div>

</div>


<ng-container *ngIf="preview && allFiles.length">
    <div class="preview-container">
        <div class="img-preview-container" *ngFor="let fileUploader of allFiles">
            <div class="preview">
                <div class="info-preview">
                    <div class="img-preview">
                        <img *ngIf="fileUploader.isExistFile" appLoadImage [key]="fileUploader?.key"
                            (info)="loadImgInfo(fileUploader, $event)">
                        <img *ngIf="!fileUploader.isExistFile && fileUploader.isPreview && fileUploader.data"
                            [src]="fileUploader.data">
                        <mat-icon *ngIf="!fileUploader.isExistFile && !fileUploader.isPreview">insert_drive_file
                        </mat-icon>
                    </div>
                    <div class="img-info">
                        <div class="img-info__item img-name">
                            <p>{{fileUploader.file.name}}</p>
                        </div>
                        <ng-container *ngIf="!fileUploader.isExistFile">
                            <mat-progress-bar class="img-info__item pending" *ngIf="fileUploader.uploading"
                                mode="indeterminate"></mat-progress-bar>
                            <mat-progress-bar class="img-info__item danger" *ngIf="fileUploader.error.status"
                                mode="determinate" value="5"></mat-progress-bar>
                            <mat-progress-bar class="img-info__item success" *ngIf="fileUploader.completed"
                                mode="determinate" value="100"></mat-progress-bar>
                            <div class="img-info__item img-info__group">
                                <span class="img-size">
                                    {{fileUploader.file.size | fileSize}}
                                </span>
                                <span class="img-status"
                                    [ngClass]="{'success': fileUploader.completed,'pending':fileUploader.uploading, 'danger':fileUploader.error?.status}">
                                    {{fileUploader.completed ? 'Done' : ''}}
                                    {{fileUploader.uploading ? 'Uploading' :''}}
                                    {{fileUploader.error.status ? 'Error': ''}}
                                </span>
                            </div>
                        </ng-container>

                    </div>
                </div>
                <div class="remove-img-btn" (click)="deleteFile(fileUploader.id)">
                    <mat-icon>delete_forever</mat-icon>
                </div>

            </div>

        </div>
    </div>
</ng-container>
