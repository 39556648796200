import { CdkPortal } from "@angular/cdk/portal";
import { Directive, TemplateRef, ViewContainerRef } from "@angular/core";

@Directive({
    selector:'[redLayoutPublicHeader]'
  })
  export class LayoutPublicHeaderDirective  extends CdkPortal{
    constructor(
      templateRef: TemplateRef<any>,
      viewContainerRef: ViewContainerRef,
    ){
      super(templateRef,viewContainerRef);
    }
  } 