import { DateMoment } from '@app/core/common/date-moment';
import { Helper } from '@app/core/common/helper';
import { BaseModel } from '@app/core/models/base.model';
import { Moment } from 'moment';
import { BusinessUnitModel } from './business-unit.model';
import { UserModel } from './user.model';

export enum ReminderStatus {
    Enabled = 'enabled',
    Disabled = 'disabled'
}

export enum ReminderStage {
    Pending = 'pending',
    Processing = 'processing',
    Error = 'error',
    Pause = 'pause',
    Done = 'done',
}

export enum ReminderChannel {
    Email = 'email',
    Notification = 'notification',
}

export enum ReminderOperators {
    Before = '<',
    After = '>'
}

type ReminderCondtions = {
    [key in ReminderOperators]?: number;
};

export interface ReminderScheduleSettingInterface {
    isEnabledIcal: boolean;
    isRepeated?: boolean;
    type: string;
    startDate: string;
    conditions: ReminderCondtions;
    time?: string;
}

export class ReminderScheduleSetting extends BaseModel implements ReminderScheduleSettingInterface {
    isEnabledIcal = false;
    isRepeated?= false;
    type: string;
    startDate: string;
    conditions: ReminderCondtions;
    time?: string;

    get days() {
        const days = Object.values(this.conditions);

        return days[0] || 0;
    }

    get operator() {
        const operators = Object.keys(this.conditions);

        return operators[0];
    }
}

export interface ReminderInterface {
    id: number;
    name: string;
    status: ReminderStatus;
    stage: ReminderStage;
    channel: string;
    triggerAt: string;
    schedule: ReminderScheduleSetting;
    remark: string;
    metadata?: object;
    sharedEmail?: ShareReminderUser[];
    businessUnitId: number;
    businessUnit: BusinessUnitModel;
    createdById: number;
    createdBy: UserModel;
    createdAt: string;
    updatedAt: string;
    deletedAt?: string;
    restoredAt?: string;
}

export interface ReminderCalculatorInterface {
    days: number;
    operator: ReminderOperators;
    startDate: Date | string | Moment;
    time: string;
    triggerAt?: Moment;
}

export class ReminderCalculatorModel extends BaseModel implements ReminderCalculatorInterface {
    days: number;
    operator: ReminderOperators;
    startDate: string | Date | Moment;
    time: string;
    triggerAt: Moment;

    get isValidData(): boolean {
        return (
            !Helper.isEmpty(this.days) &&
            !Helper.isEmpty(this.operator) &&
            !Helper.isEmpty(this.startDate) &&
            DateMoment.isFormatted(DateMoment.date(this.startDate))
        );
    }

    calculate() {
        if (!this.isValidData) {
            this.triggerAt = null;
            return this;
        }

        const startDate = DateMoment.date(this.startDate) as Moment;
        const time = DateMoment.inFormat(this.time, 'HH:mm');
        let triggerDate: Moment = null;

        switch (this.operator) {
            case ReminderOperators.After: {
                triggerDate = startDate.clone().add(this.days, 'd');
                break;
            }
            case ReminderOperators.Before: {
                triggerDate = startDate.clone().subtract(this.days, 'd');
                break;
            }
        }

        if (this.time) {
            triggerDate.set({
                hours: time.hours(),
                minutes: time.minutes()
            });
        }

        this.triggerAt = triggerDate;
        return this;
    }
}

export class ShareReminderUser extends BaseModel {
    name: string;
    email: string;
}
