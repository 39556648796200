import {
  CdkFixedSizeVirtualScroll,
  CdkVirtualScrollViewport,
  FixedSizeVirtualScrollStrategy,
  VIRTUAL_SCROLL_STRATEGY,
  ViewportRuler,
} from "@angular/cdk/scrolling";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  HostBinding,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Self,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatSelect } from "@angular/material/select";
import { PaginationAdapter } from "@app/core/adapters/pagination.adapter";
import { concat } from "lodash-es";
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  Subscription,
  animationFrameScheduler,
  asapScheduler,
} from "rxjs";
import {
  auditTime,
  debounceTime,
  delay,
  startWith,
  takeUntil,
  tap,
} from "rxjs/operators";
const SCROLL_SCHEDULER =
  typeof requestAnimationFrame !== "undefined"
    ? animationFrameScheduler
    : asapScheduler;
export interface IQuerySearch {
  [x: string]: unknown;
}
export class CustomVirtualScrollStrategy extends FixedSizeVirtualScrollStrategy {
  constructor() {
    super(42, 128, 208);
  }
}
@Directive({
  selector: "cdk-virtual-scroll-viewport[redVirtualScrollController]",
  exportAs: "virtualScrollController",
  providers: [
    { provide: VIRTUAL_SCROLL_STRATEGY, useClass: CustomVirtualScrollStrategy },
    CdkFixedSizeVirtualScroll,
  ],
})
export class VirtualScrollControllerDirective
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() limit = 10;
  @Input() searchWith!: (
    filters: IQuerySearch
  ) => Observable<any[] | PaginationAdapter<any>>;
  @HostBinding("style.height.px") height = this._fixedSize._itemSize * 4;
  searchCtrl = new FormControl("");
  results = new BehaviorSubject<any[]>([]);
  length = 0;
  total = 0;
  page = 1;
  loading!: boolean;
  waitToFetch!: Subscription;
  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();
  // searcher:
  constructor(
    private _cdr: ChangeDetectorRef,
    @Inject(MatSelect) private _matSelect: MatSelect,
    private ngZone: NgZone,
    viewportRuler: ViewportRuler,
    @Inject(VIRTUAL_SCROLL_STRATEGY)
    private _fixedSize: CdkFixedSizeVirtualScroll,
    @Inject(CdkVirtualScrollViewport)
    private _scrollViewport: CdkVirtualScrollViewport
  ) {
    // this._scrollViewport
    // console.log('_fixedSize', this._fixedSize);
    // this._scrollViewport.scrollable.elementScrolled();
  }
  ngAfterViewInit(): void {
    this.ngZone.runOutsideAngular(() =>
      Promise.resolve().then(() => {
        this._scrollViewport
          .elementScrolled()
          .pipe(
            // Start off with a fake scroll event so we properly detect our initial position.
            startWith(null),
            // Collect multiple events into one until the next animation frame. This way if
            // there are multiple scroll events in the same frame we only need to recheck
            // our layout once.
            auditTime(0, SCROLL_SCHEDULER),
            takeUntil(this._onDestroy)
          )
          .subscribe(() => {
            this.onContentScrol();
          });
      })
    );
  }
  onContentScrol(): void {
    const renderedRange = this._scrollViewport.getRenderedRange();
    const newRange = { start: renderedRange.start, end: renderedRange.end };
    const viewportSize = this._scrollViewport.getViewportSize();
    const dataLength = this._scrollViewport.getDataLength();
    let scrollOffset = this._scrollViewport.measureScrollOffset();
    const results = this.results.getValue();
    // Prevent NaN as result when dividing by zero.
    let firstVisibleIndex =
      this._fixedSize._itemSize > 0
        ? scrollOffset / this._fixedSize._itemSize
        : 0;
    // console.log('onContentScrol --> ',this._fixedSize._itemSize, newRange, results, dataLength,scrollOffset, firstVisibleIndex);
    // If user scrolls to the bottom of the list and data changes to a smaller list
    if (newRange.end >= dataLength) {
      // console.log('render end');
      if (this.length < this.total) {
        // if (index + 5 > this.length && this.length < this.total) {
        const currentPage = Math.ceil(newRange.end / this.limit);
        // console.log({ currentPage, previous: this.page });
        if (currentPage === this.page) {
          this.page += 1;
          this.triggerSearch();
        }
      }
    }
  }
  ngOnInit(): void {
    // this.triggerSearch();
    // when the select dropdown panel is opened or closed
    this._matSelect.openedChange
      .pipe(delay(1), takeUntil(this._onDestroy))
      .subscribe((opened) => {
        // console.log('_matSelect openedChange', opened);
        if (opened) {
          this.page = 1;
          this.results.next([]);
          this.triggerSearch();
          // focus the search field when opening;
          this._cdr.markForCheck();
        } else {
          // clear it when closing
          this._scrollViewport.scrollToIndex(0);
        }
      });
    this.onSearchChange()
      .pipe(
        tap(() => {
          this.page = 1;
          this.results.next([]);
        }),
        takeUntil(this._onDestroy)
      )
      .subscribe(() => {
        this.triggerSearch();
      });

    // this._scrollViewport.scrolledIndexChange.pipe(takeUntil(this._onDestroy)).subscribe(index => {
    //   const results = this.results.getValue();
    //   const renderedRange = this._scrollViewport.getRenderedRange();
    //   const newRange = { start: renderedRange.start, end: renderedRange.end };
    //   console.log('_scrollViewport  --> ', newRange, index, results, results[index + 4] === results[results.length] && this.length < this.total);
    //   if (results[index + 4] === results[results.length] && this.length < this.total) {
    //     // if (index + 5 > this.length && this.length < this.total) {
    //     const currentPage = Math.ceil(index / this.limit);
    //     // console.log({ currentPage, previous: this.page });
    //     if (currentPage === this.page) {
    //       this.page += 1;
    //       this.triggerSearch();
    //     }
    //   }
    //   this._cdr.markForCheck();
    // });

    // this.results.pipe(takeUntil(this._onDestroy)).subscribe(res => {
    //   console.log('resultsChange', res);
    // });
  }
  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  onSearchChange(): Observable<string | null> {
    return this.searchCtrl.valueChanges.pipe(debounceTime(200));
  }
  triggerSearch(): void {
    this.loading = true;
    if (this.waitToFetch && !this.waitToFetch.closed) {
      this.waitToFetch.unsubscribe();
    }
    const filters = {
      key: this.searchCtrl.value,
      limit: this.limit,
      page: this.page,
    };
    this.waitToFetch = this.searchWith(filters).subscribe((data) => {
      const newItems = Array.isArray(data) ? data : data.results;
      const list = concat(this.results.getValue(), newItems);
      this.length = list.length;
      this.total = Array.isArray(data) ? this.length : data.pagination.total;
      this.results.next(list);
      // this._scrollViewport.scrollToIndex(0);
      this.loading = false;
    });
  }
}
