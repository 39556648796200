<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full" *ngIf="layout === 'empty'">

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>
  
  </div>
  <red-classic-layout *ngIf="layout === 'classic'" >
    <ng-template redocLogoHeader>
        <img class="h-6 w-auto"
                            alt="redr logo"
                            src="../../../../../assets/images/red-logo-black.svg"
                        />
        <!-- <img class="h-6 w-auto" alt="logo" appLoadImage [key]="organization.logo" urlDefault="assets/images/red-logo-black.svg"> -->
    </ng-template>
  <ng-template redGroupActionHeader>
    <div class="flex flex-row flex-auto">
      <ng-container *ngTemplateOutlet="templateLabel"> </ng-container>
    </div>
    
  </ng-template>
  
</red-classic-layout>