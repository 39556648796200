import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { PortalModule } from '@angular/cdk/portal';
import { LayoutPublicComponent } from './layout-public.component';
import { ClassicLayoutModule } from '../classic-layout/classic-layout.module';
import { NAVIGATION_SERVICE } from '../classic-layout/navigation';
import { NavigationService } from '../navigation.service';
import { LayoutPublicHeaderDirective } from './layout-public-header.directive';

@NgModule({
  declarations: [LayoutPublicComponent,LayoutPublicHeaderDirective],
  imports: [CommonModule, RouterModule, MatIconModule, MatButtonModule, PortalModule,ClassicLayoutModule],
  exports: [LayoutPublicComponent,LayoutPublicHeaderDirective],
  providers:[

    {
        provide: NAVIGATION_SERVICE,
        useClass: NavigationService,
      },
  ]
})
export class LayoutPublicModule {}