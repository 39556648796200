import { Default } from '@app/core/decorators/default.decorator';
import { BaseModel } from '@app/core/models/base.model';
import { Expose, Transform } from 'class-transformer';
export const RepeatTypeList = ['one-time', 'every-day', 'every-week', 'every-month'] as const;
export type RepeatType = typeof RepeatTypeList[number];

export interface DocumentReminderSettingInterface {
    enableReminder: boolean;
    startAfter: number;
    repeat: RepeatType;
    startDate: string;
}

export class DocumentReminderSettingModel extends BaseModel implements DocumentReminderSettingInterface {
    @Expose()
    enableReminder: boolean;
    @Expose()
    @Transform((data) => {
        if (typeof data === 'number' && data !== 0) {
            return data;
        } else {
            return '';
        }
    })
    startAfter: number;
    @Expose()
    @Transform((data) => {
        if (RepeatTypeList.includes(data)) {
            return data;
        } else {
            return '';
        }
    })
    repeat: RepeatType;
    @Expose()
    startDate: string;
}
