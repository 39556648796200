export const DOCUMENT_STATUS = [
    { id: 'draft', name: 'Draft' },
    // { id: 'collaborating', name: 'Collaborating' },
    // { id: 'sent', name: 'Sent' },
    // { id: 'declined', name: 'Declined' },
    { id: 'expired', name: 'Expired' },
    // { id: 'manual_signed', name: 'Manual Signed' },
    // { id: 'electronic_signed', name: 'Electronic Signed' },
    { id: 'processing', name: 'Processing' },
    { id: 'canceled', name: 'Canceled' },
    { id: 'completed', name: 'Completed' },
    { id: 'aborted', name: 'Aborted' }
];

export const DOCUMENT_STATUS_KEY = {
    DRAFT: 'draft',
    PROCESSING: 'processing',
    CANCELED: 'canceled',
    COMPLETED: 'completed',
    ABORTED: 'aborted'
};
