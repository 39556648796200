import {
    AfterViewInit,
    Component,
    DoCheck,
    ElementRef,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    QueryList,
    Self,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {
    ControlContainer,
    ControlValueAccessor,
    FormBuilder,
    FormControl,
    FormGroup,
    FormGroupDirective,
    NgControl,
    NgForm,
    Validators
} from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Subject, combineLatest } from 'rxjs';
import { MetadataApiService } from '@app/shared/services/metadata-api.service';
import { CountryModel } from '@app/core/models/country.model';
import { FloatPanelComponent } from '../float-panel/float-panel.component';
import { ActiveDescendantKeyManager, FocusMonitor } from '@angular/cdk/a11y';
import { ENTER } from '@angular/cdk/keycodes';
import { takeUntil } from 'rxjs/operators';
import { Platform } from '@angular/cdk/platform';
import { CountryItemComponent } from './country-item/country-item.component';
import { ErrorStateMatcher } from '@angular/material/core';

export interface PhoneNumberInterface {
    countryCode: string;
    phoneNumber: string;
}

export class PhoneNumber implements PhoneNumberInterface {
    countryCode: string;
    phoneNumber: string;

    constructor(data: PhoneNumberInterface) {
        this.countryCode = data && data.countryCode ? data.countryCode : '65';
        this.phoneNumber = data && data.phoneNumber ? data.phoneNumber : '';
    }
}

@Component({
    selector: 'app-phone-number',
    templateUrl: './phone-number.component.html',
    styleUrls: ['./phone-number.component.scss'],
    providers: [
        {
            provide: MatFormFieldControl,
            useExisting: PhoneNumberComponent
        }
    ]
})
export class PhoneNumberComponent implements ControlValueAccessor,
    MatFormFieldControl<PhoneNumber>, OnInit, DoCheck, AfterViewInit, OnDestroy {

    @Input()
    get value(): PhoneNumber | null {
        const formData = this.form.value;
        if (formData.countryCode && formData.phoneNumber.length > 6) {
            return new PhoneNumber(formData);
        }
        return null;
    }

    set value(tel: PhoneNumber | null) {
        tel = tel || new PhoneNumber({ phoneNumber: '', countryCode: '' });
        this.form.setValue({ phoneNumber: tel.phoneNumber, countryCode: tel.countryCode });

        this.stateChanges.next();
    }

    @Input()
    get placeholder() {
        return this.phoneNumberPlaceholder;
    }

    set placeholder(plh) {
        this.phoneNumberPlaceholder = plh;
        this.stateChanges.next();
    }

    @Input()
    get disabled(): boolean {
        return this.isDisabled;
    }

    set disabled(value: boolean) {
        this.isDisabled = coerceBooleanProperty(value);
        this.isDisabled ? this.form.disable() : this.form.enable();
        this.stateChanges.next();
    }

    @Input()
    get required(): boolean {
        return this.isRequired;
    }

    set required(value: boolean) {
        this.isRequired = coerceBooleanProperty(value);
        this.stateChanges.next();
    }

    get empty(): boolean {
        const formData = this.form.value;

        return !formData.countryCode && !formData.phoneNumber;
    }

    get focused(): boolean {
        return this.isFocused;
    }

    set focused(value: boolean) {
        this.isFocused = value;
        this.stateChanges.next();
    }

    constructor(
        private formBuilder: FormBuilder,
        private metaDataService: MetadataApiService,
        private focusMonitor: FocusMonitor,
        protected platform: Platform,
        @Optional() @Self() public ngControl: NgControl,
        private elementRef: ElementRef<HTMLElement>,
        public parentFormGroup: FormGroupDirective,
        public defaultErrorStateMatcher: ErrorStateMatcher,
        @Optional() public parentForm: NgForm,
    ) {
        this.form = this.createForm();
        // Replace the provider from above with this.
        if (this.ngControl != null) {
            // Setting the value accessor directly (instead of using
            // the providers) to avoid running into a circular import.
            this.ngControl.valueAccessor = this;
        }
        combineLatest([
            this.form.valueChanges,
            this.metaDataService.getCountries()
        ]).pipe(takeUntil(this.unsubscribeAll)).subscribe(([formData, countries]) => {
            this.countries = countries;
            const countrySelected = countries.find(item => {
                if (item.dialCode === (formData.countryCode || this.countryCodeDefault)) {
                    return true;
                }
                return false;
            });
            this.countrySelected = countrySelected;
            // this.selectCountry(countrySelected);
        });
        focusMonitor.monitor(elementRef.nativeElement, true).subscribe(origin => {
            this.focused = !!origin;
            this.stateChanges.next();
        });
        if (this.parentFormGroup) {
            this.listenParentFormSubmit();
        }

    }

    @HostBinding('class.floating')
    get shouldLabelFloat(): boolean {
        return this.focused || !this.empty;
    }

    static nextId = 0;

    touched = false;

    @ViewChildren(CountryItemComponent) countryItems: QueryList<CountryItemComponent>;
    private keyManager: ActiveDescendantKeyManager<CountryItemComponent>;

    errorState = false;

    /*
     * unsubscribe from all events
     * Set the private defaults
     */
    unsubscribeAll: Subject<any> = new Subject<any>();

    private phoneNumberPlaceholder = '';
    private isDisabled = false;
    private isFocused = false;
    private isRequired = false;
    private iso2CodeDefault = 'sg'; // Singapore
    private countryCodeDefault = '65';
    private phoneRegex: RegExp = new RegExp(/^-?[0-9]+(\.[0-9]*){0,1}$/g);
    private specialKeys: Array<string> = ['Backspace', 'Tab'];
    form: FormGroup;
    countries: CountryModel[] = [];
    isFlagBtnClick = false;
    countrySelected: CountryModel;

    @ViewChild('floatingFlagPanel', {
        static: false
    }) floatingFlagPanel: FloatPanelComponent;
    @ViewChild('phoneInput', { static: false, read: ElementRef }) phoneInput: ElementRef;
    @ViewChild('searchInput', { static: false, read: ElementRef }) searchInput: ElementRef;
    // mat-form-field

    stateChanges: Subject<void> = new Subject<void>();

    @HostBinding() id = `tel-input-${PhoneNumberComponent.nextId++}`;

    @HostBinding('attr.aria-describedby')
    describedBy = '';
    controlType = 'tel-input';
    autofilled = false;

    private listenParentFormSubmit(): void {
        this.parentFormGroup.ngSubmit.pipe(
            takeUntil(this.unsubscribeAll),
        ).subscribe(() => {
            this.form.markAllAsTouched();
        });
    }


    writeValue(value: PhoneNumber): void {
        value = new PhoneNumber(value);

        // Set default country code if value.countryCode is not include in array countries
        let countryCode = value.countryCode;
        if (this.countries && this.countries.length) {
            const countryCodes = this.countries.map(v => v.dialCode);
            if (!countryCodes.includes(countryCode)) {
                countryCode = this.countryCodeDefault;
            }
        }

        this.form.setValue({ phoneNumber: value.phoneNumber, countryCode: countryCode });
    }

    registerOnChange(onChange: (value: PhoneNumber | null) => void): void {
        this.form.valueChanges.pipe(
            takeUntil(this.unsubscribeAll),
        ).subscribe(() => {
            const formData = this.form.getRawValue();

            if (formData.phoneNumber) {
                onChange(new PhoneNumber(formData as PhoneNumberInterface));
            } else {
                onChange(null);
            }
        });
    }

    onTouched(): void {
    }

    registerOnTouched(onTouched: () => void): void {
        this.onTouched = onTouched;
    }

    setDisabledState?(isDisabled: boolean): void {
        if (isDisabled) {
            this.form.disable();
        } else {
            this.form.enable();
        }
        this.disabled = isDisabled;
    }

    setDescribedByIds(ids: string[]): void {
        this.describedBy = ids.join(' ');
    }

    onContainerClick(event: MouseEvent): void {
        // if ((event.target as Element).tagName.toLowerCase() !== 'input') {
        //     this.focusMonitor.focusVia(this.phoneInput.nativeElement, 'mouse');
        //     this.form.markAsDirty();
        // }
    }

    ngOnInit(): void {
        // this.metaDataService.getCountries().pipe(takeUntil(this.unsubscribeAll)).subscribe(data => {
        //     this.countries = data;
        //     const formData = this.form.getRawValue();
        //     if (formData.countryCode) {
        //         const countrySelected = data.find(item => item.dialCode === formData.countryCode);
        //         this.selectCountry(countrySelected);
        //     } else {
        //         const countrySelected = data.find(item => item.iso2Code === this.iso2CodeDefault);
        //         if (countrySelected) {
        //             this.selectCountry(countrySelected);
        //         }
        //     }

        // });

    }

    ngAfterViewInit() {
        this.keyManager = new ActiveDescendantKeyManager(this.countryItems).withWrap()
            .withTypeAhead();

        // this.metaDataService.getCountries().pipe(takeUntil(this.unsubscribeAll)).subscribe(data => {
        //     this.countries = data;
        //     const formData = this.form.getRawValue();
        //     if (formData.countryCode) {
        //         const countrySelected = data.find(item => item.dialCode === formData.countryCode);
        //         this.selectCountry(countrySelected);
        //     } else {
        //         const countrySelected = data.find(item => item.iso2Code === this.iso2CodeDefault);
        //         if (countrySelected) {
        //             this.selectCountry(countrySelected);
        //         }
        //     }

        // });
        // console.log( this.countrySelected )
    }



    ngDoCheck(): void {
        // if (this.ngControl) {
        //     this.errorState = this.form.invalid && this.touched;
        // }
        // this.stateChanges.next();
        if (this.ngControl) {
            // We need to re-evaluate this on every change detection cycle, because there are some
            // error triggers that we can't subscribe to (e.g. parent form submissions). This means
            // that whatever logic is in here has to be super lean or we risk destroying the performance.
            this.updateErrorState();
        }
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
        this.stateChanges.complete();
        this.focusMonitor.stopMonitoring(this.elementRef.nativeElement);
    }

    createForm(): FormGroup {
        return this.formBuilder.group({
            countryCode: [''],
            phoneNumber: ['']
        });
    }

    selectCountry(country: CountryModel): void {
        this.countrySelected = country;
        this.form.controls.countryCode.setValue(country.dialCode);

        // check panel is opening? if true, then close it
        if (this.floatingFlagPanel) {
            this.floatingFlagPanel.closePanel();
        }
    }

    onPanelOpen(): void {
        this.isFlagBtnClick = true;
        this.searchInput.nativeElement.focus();
    }

    onPanelClose(): void {
        this.isFlagBtnClick = false;
        this.phoneInput.nativeElement.focus();
    }


    onKeydown(event) {
        if (event.keyCode === ENTER) {
            this.selectCountry(this.keyManager.activeItem.item);
        } else {
            this.keyManager.onKeydown(event);
        }
    }

    onInputPhoneKeyPress(event: KeyboardEvent) {
        if (this.specialKeys.indexOf(event.key) !== -1) {
            event.preventDefault();
        }
        const current: string = this.form.controls.phoneNumber.value;
        const next: string = current.concat(event.key);
        if ((next && !String(next).match(this.phoneRegex))) {
            event.preventDefault();
        }
    }

    onFocusIn(event: FocusEvent) {
        if (!this.focused) {
            this.focused = true;
            this.stateChanges.next();
        }
    }

    onFocusOut(event: FocusEvent) {
        if (!this.elementRef.nativeElement.contains(event.relatedTarget as Element)) {
            this.touched = true;
            this.focused = false;
            this.onTouched();
            this.stateChanges.next();
        }
    }

    updateErrorState() {
        const oldState = this.errorState;
        const parent = this.parentFormGroup || this.parentForm;
        const matcher = this.defaultErrorStateMatcher;
        const control = this.ngControl ? this.ngControl.control as FormControl : null;
        const newState = matcher.isErrorState(control, parent);

        if (newState !== oldState) {
            this.errorState = newState;
            this.stateChanges.next();
        }
        if(this.ngControl.touched && this.form.untouched){
            this.form.markAllAsTouched()
            this.stateChanges.next();
        }
    }
}
