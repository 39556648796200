import { Injectable } from '@angular/core';
import { ApiOptionInterface } from '@app/core/models/api-option.interface';
import { ApiService } from '@core/services/api.service';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClientPaginationAdapter } from '../adapters/client-pagination.adapter';
import { DocumentByClientPaginationAdapter, DocumentPaginationAdapter } from '../adapters/document-pagination.adapter';
import { ItemPaginationAdapter } from '../adapters/item-pagination.adapter';
import { AttachmentModel } from '../models/attachment.model';
import { ClientModel } from '../models/client.model';
import { DocumentModel } from '../models/document.model';
import { ClientItemModel, ItemModel } from '../models/item.model';
import { DocumentApiService } from './document-api.service';
import { ItemApiService } from './item-api.service';

@Injectable({
    providedIn: 'root'
})
export class ClientApiService {
    static CLIENT = 'tenants';
    static CUSTOMER = 'customers';
    static CUSTOMER_DOCUMENT = 'customers-documents';
    static CUSTOMER_ATTACHMENT_DETAIL = 'customers/:id/attachment';
    static CUSTOMER_ITEM = 'customers-items';
    static CLIENT_EXPORT = 'tenants-export';
    static CLIENT_IMPORT = 'tenants-import';
    static CLIENT_EXPORT_EXAMPLE_FILE = 'tenants-import/download-example/defaultHeader';

    constructor(
        private apiService: ApiService,
    ) { }

    create(data): Observable<ClientModel> {
        return this.apiService.post(ClientApiService.CLIENT, data, { excludeFields: ['contacts'] })
            .pipe(map(res => ClientModel.fromJson(res) as ClientModel));
    }

    update(data) {
        return this.apiService.put(ClientApiService.CLIENT, data);
    }

    updatePartial(data, options?: ApiOptionInterface) {
        return this.apiService.patch(ClientApiService.CLIENT, data, options);
    }

    search(query, option?: ApiOptionInterface): Observable<ClientPaginationAdapter> {
        return this.apiService
            .get(ClientApiService.CLIENT, query, option)
            .pipe(map(data => new ClientPaginationAdapter(data)));
    }

    getCustomerDetail(customerId: number): Observable<ClientModel> {
        return this.apiService
            .get(ClientApiService.CLIENT + '/' + customerId)
            .pipe(map(res => ClientModel.fromJson(res) as ClientModel));
    }

    invite(data: { name: string; email: string, portalUrl: string }): Observable<unknown> {
        return this.apiService.post(
            [ClientApiService.CLIENT, 'invite'].join('/'),
            data
        );
    }

    getDocumentsById(id: string, filters = {}): Observable<DocumentByClientPaginationAdapter> {
        return this.apiService
            .get(ClientApiService.CUSTOMER_DOCUMENT + '/' + id, filters)
            .pipe(map(res => new DocumentByClientPaginationAdapter(res)));

    }
    // Api Items
    getItemsById(id: string): Observable<ClientItemModel[]> {
        return this.apiService
            .get(ClientApiService.CUSTOMER_ITEM + '/' + id)
            .pipe(map(res => res.map(item => ClientItemModel.fromJson(item))));

    }

    createOrUpdateItems(data): Observable<any> {
        return this.apiService
            .patch(ClientApiService.CUSTOMER_ITEM, data);
    }

    removeItems(customerId: number, customerRole: string, itemIds: number[]): Observable<any> {
        return this.apiService.delete(ClientApiService.CUSTOMER_ITEM, { customerId, customerRole, itemIds });
    }
    // End Api Items
    // Api Attacment
    getAttacmentsById(id: string): Observable<AttachmentModel[]> {
        return this.apiService
            .get(ClientApiService.CUSTOMER_ATTACHMENT_DETAIL, { id }).pipe(
                map(res => res.map(item => AttachmentModel.fromJson(item)))
            );
    }

    addAttachment(data): Observable<any> {
        return this.apiService.post(ClientApiService.CUSTOMER_ATTACHMENT_DETAIL, data).pipe(
            map(res => AttachmentModel.fromJson(res))
        );
    }

    deleteAttachment(data): Observable<any> {
        return this.apiService.delete(ClientApiService.CUSTOMER_ATTACHMENT_DETAIL + '/:attacmentId', data);
    }
    // End Api Attacment

    export(query) {
        return this.apiService.post(ClientApiService.CLIENT_EXPORT, query, {
            pretreatmentResponse: false,
            requestOptions: { responseType: 'arraybuffer' }
        })
            .pipe(
                map(res => {
                    const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    saveAs(blob, `Exported Items ${moment().format('(DD-MM-YYYY)')}.xlsx`);
                    return res;
                })
            );
    }

    import(data: { keyFileName: string }) {
        return this.apiService.post(ClientApiService.CLIENT_IMPORT, data);
    }

    exportExampleFile() {
        return this.apiService.get(ClientApiService.CLIENT_EXPORT_EXAMPLE_FILE, {}, {
            pretreatmentResponse: false,
            requestOptions: { responseType: 'arraybuffer' }
        })
            .pipe(
                map(res => {
                    const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    saveAs(blob, `example-import-client.xlsx`);
                    return res;
                })
            );
    }
}
