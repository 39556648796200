<div class="app-select-template">
    <div class="header flex flex-row">
        <div class="flex flex-auto flex-row overflow-hidden" >
            <nav mat-tab-nav-bar  class="w-full">
                <a mat-tab-link (click)="activeTemplate('my-template')" [active]="activeLink == 'my-template'">
                    <div style="margin-right: 8px;">{{ 'document_template.my_templates' | translate}}</div>
                    <app-badge color="violet" size="small">
                        {{ activeLink !== 'my-template' ? totalPrivateTemplates : totalTemplates }}
                    </app-badge>
                </a>
                <a mat-tab-link (click)="activeTemplate('org')" [active]="activeLink == 'org'">
                    <div style="margin-right: 8px;">{{ 'document_template.organization_library' | translate}}</div>
                    <app-badge color="violet" size="small">
                        {{ activeLink !== 'org' ? totalOrgTemplates : totalTemplates }}
                    </app-badge>
                </a>
                <a mat-tab-link (click)="activeTemplate('public')" [active]="activeLink == 'public'" appPolicy [policy]="'template::viewSystemTemplate'">
                    <div style="margin-right: 8px;">{{ 'document_template.library' | translate}}</div>
                    <app-badge color="violet" size="small">
                        {{ activeLink !== 'public' ? totalPublicTemplates : totalTemplates }}
                    </app-badge>
                </a>
            </nav>
        </div>
        
        <button type="button" mat-icon-button (click)="close()">
            <mat-icon class="icon-close">close</mat-icon>
        </button>
    </div>
    <div class="container">
        <div class="content flex flex-col md:flex-row">
            <div class="left-block flex flex-col w-full md:max-w-1/4"  [formGroup]="filterForm">
                <div class="search-field-wrapper">
                    <mat-form-field class="search-field">
                        <input type="text" placeholder="{{ 'document.search_template' | translate }}"
                            aria-label="select-item" matInput formControlName="key" />
                        <mat-icon matSuffix *ngIf="!isShowDeleteSearchText">search</mat-icon>
                        <button matSuffix type="button" mat-icon-button *ngIf="isShowDeleteSearchText"
                            (click)="resetItemSearchValue()">
                            <mat-icon class="icon-color-gray">cancel</mat-icon>
                        </button>
                    </mat-form-field>
                </div>

                <div class="category hidden md:flex md:flex-col overflow-hidden">
                    <div class="category__title">{{ 'common.category' | translate}}</div>
                    <mat-selection-list [multiple]="false" class="list-category category__content"
                        formControlName="category">
                        <mat-list-option value="all">
                            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                                <div>{{ 'common.all_categories' | translate}}</div>
                                <app-badge [color]="'gray'">{{ totalTemplates }}</app-badge>
                            </div>
                        </mat-list-option>
                        <mat-list-option *ngFor="let data of categories" [value]="data.name">
                            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                                <app-text-truncate [matTooltip]="data.name">{{data.name}}</app-text-truncate>
                                <app-badge [color]="'gray'">{{ data.totalItems }}</app-badge>
                            </div>
                        </mat-list-option>
                    </mat-selection-list>

                </div>
            </div>
            <div class="right-block flex flex-col flex-auto overflow-auto">
                <div class="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4 grid-content p-4" *ngIf="templates.length; else empty">
                    <div class=" flex flex-col flex-auto rounded overflow-hidden cursor-pointer bg-white" (click)="navigateToCreateDocument(item)" *ngFor="let item of templates">
                        <div class="relative h-60">
                            <div class="absolute inset-0  flex flex-row justify-center items-center overflow-hidden bg-transparent">
                                <img class="w-full h-full object-cover" appLoadImage [key]="item?.previewPhoto" />
                            </div>
                        </div>
                        <div class="p-4 pt-0 border-t  border-solid border-default flex flex-col ">
                            <div >
                                <p class="text-s2 m-0 p-0 text-theme-gray text-overflow-1-line ">{{ item.category }}</p>
                            </div>
                            <p class="text-s1 m-0 p-0 text-theme-dark text-overflow-2-line">
                                {{ item.name }}
                            </p>
                            <span class="text-b2 text-primary text-overflow-1-line">
                                {{ item.createdBy?.name }}
                            </span>
                        </div>
                    </div>
                </div>
                <ng-template #empty>
                    <app-empty [style]="{'background-color': '#f3f3f3'}">
                        <div class="empty-title">
                            {{ 'document_template.no_template_records' | translate }}
                        </div>
                    </app-empty>
                </ng-template>
            </div>
        </div>
    </div>

</div>
