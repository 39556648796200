export const SYSTEM_FIELDS: any[] = [
    {
        key: 'companyName',
        label: 'Company Name',
        type: 'text',
        controlType: 'textbox',
        fieldType: 'dynamicField',
        required: true,
        readonly: true,
        disable: true,
        order: 1,
        count: 0,
        config: {
            keyType: 'systemField',
            defaultValue: 'Real Estate Doc'
        }
    }, {
        key: 'companyFax',
        label: 'Company Fax',
        type: 'text',
        controlType: 'textbox',
        fieldType: 'dynamicField',
        required: true,
        readonly: true,
        disable: true,
        order: 1,
        count: 0,
        config: {
            keyType: 'systemField',
            defaultValue: 'Company Fax'
        }
    }, {
        key: 'companyPhoneNumber',
        label: 'Company Phone Number',
        type: 'text',
        controlType: 'textbox',
        fieldType: 'dynamicField',
        required: true,
        readonly: true,
        disable: true,
        order: 1,
        count: 0,
        config: {
            keyType: 'systemField',
            defaultValue: '+65 9180 1197'
        }
    }
];
