<div class="upload-pdf-dialog layout__main-modal">
    <div mat-dialog-title class="dialog-title">
        <div class="title">Upload Document</div>
        <button type="button" mat-icon-button (click)="close()">
            <mat-icon class="icon-close">close</mat-icon>
        </button>
    </div>
    <mat-dialog-content class="dialog-content">
        <app-upload-file
            #appUploadFile
            [maxFileSize]="maxFileSize"
            (removed)="removed($event)"
            title="Drag & Drop PDF or Docx"
            message="Support PDF, Doc, Docx files under 5MB"
            [preview]="true"
            [isShowUploadBox]="isShowUploadBox"
            [isMultiple]="false"
            [isReplaced]="true"
            [isUploadImmediately]="true"
            [maxFiles]="1"
            [allowExtensions]="allowExtensions"
            [allowedMimeType]="allowedMimeType"
            [uploadToFolder]="'asset'"
            [isHidenButtonClearAll]="true"
            (finishedFile)="uploaded($event)"
        >
        </app-upload-file>
        <div class="action flex flex-row justify-end pt-4" >
            <button
                style="width: 88px; height: 36px;"
                *ngIf="file"
                [disabled]="isConverting"
                type="button"
                color="primary"
                mat-flat-button
                (click)="submit()"
            >
                <span *ngIf="!isConverting">Proceed</span>
                <span class="inline-block" *ngIf="isConverting">
                    <mat-progress-spinner
                        [diameter]="24"
                        [mode]="'indeterminate'"
                    ></mat-progress-spinner>
                </span>
            </button>
        </div>
    </mat-dialog-content>
</div>
