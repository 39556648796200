import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';


@Injectable({
    providedIn: 'root'
})
export class FeedbackApiService {
    static FEEDBACK_SERVICE = 'feedback';

    constructor(private apiService: ApiService) { }

    send(data: { subject: string; content: string; category?: string; attachFiles?: string[]; metadata?: any }) {
        return this.apiService.post(FeedbackApiService.FEEDBACK_SERVICE, data);
    }
}
