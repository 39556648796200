import { Injectable } from '@angular/core';
import { BasePolicyCollection } from '@modules/permission/base.policy-collection';
import { Observable } from 'rxjs';
import { Policies } from '../permission/policy.constant';

@Injectable()
export class SupplierInvoicePolicyCollection extends BasePolicyCollection {
    readonly policyCollectionKey = 'supplier-invoice';
    view(): boolean | Promise<boolean> | Observable<boolean> {
        const viewRes = super.view();
        return viewRes;
    }
    create(): boolean | Promise<boolean> | Observable<boolean> {
        const createRes = super.create();
        return createRes;
    }
    update(): boolean | Promise<boolean> | Observable<boolean> {
        const updateRes = super.update();
        return updateRes;
    }
    accessUrl() {
        const accessUrlRes = super.accessUrl();
        return accessUrlRes;
    }
}
