import { ChangeDetectorRef, Component, forwardRef, Host, Input, OnDestroy, Optional } from '@angular/core';
import {
    ControlContainer,
    ControlValueAccessor,
    FormBuilder,
    FormControl,
    FormGroup,
    FormGroupDirective,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validators
} from '@angular/forms';
import * as moment from 'moment';
import { Subject, Subscription } from 'rxjs';
import { AppConstant } from '@app/app.constant';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-datetime-picker',
    templateUrl: './datetime-picker.component.html',
    styleUrls: ['./datetime-picker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DatetimePickerComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => DatetimePickerComponent),
            multi: true
        }
    ],
})
export class DatetimePickerComponent implements ControlValueAccessor, OnDestroy {
    @Input() layout: 'column' | 'row' = 'column';
    @Input() layoutGap = '0';
    @Input() dateLabel = 'datetime_picker.label.date';
    @Input() datePlaceholder = 'datetime_picker.placeholder.date';
    @Input() timeLabel = 'datetime_picker.label.time';
    @Input() timePlaceholder = 'datetime_picker.placeholder.time';
    @Input() format = 'hh:mm A DD/MM/YYYY';
    @Input() required = false;
    form: FormGroup;
    subscriptions: Subscription[] = [];
    unsubscribeAll = new Subject<any>();

    private formControl: FormControl;

    constructor(
        private readonly formBuilder: FormBuilder,
        @Optional() @Host() parent: ControlContainer,
        private readonly cdr: ChangeDetectorRef
    ) {
        this.form = this.createForm();
        this.onFormChange();
        if (parent) {
            if (parent['_parent']) {
                parent = parent['_parent'];
            }
            this.onParentSubmit(parent as FormGroupDirective);
        }

    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    createForm(): FormGroup {
        return this.formBuilder.group({
            date: ['', Validators.required],
            time: ['', Validators.required]
        });
    }

    onFormChange(): void {
        this.form.valueChanges.pipe(debounceTime(300), takeUntil(this.unsubscribeAll)).subscribe(data => {
            let date;
            const time = data.time;
            if (!!data.time && !!data.date) {
                if (data.date) {
                    date = moment(data.date);
                } else {
                    date = moment();
                }
                const dateFormat = `${(date.format(AppConstant.FORMAT_DATE))} ${time}`;
                const final = moment(dateFormat, 'DD/MM/YYYY hh:mm a').format(this.format);
                this.propagateChange(final);
                this.onTouched();
            } else {
                this.propagateChange(null);
                this.onTouched();
            }
        });

    }

    onParentSubmit(parent: FormGroupDirective): void {
        parent.ngSubmit.pipe(takeUntil(this.unsubscribeAll)).subscribe(() => {
            if (this.required) {
                this.form.markAllAsTouched();
            } else {
                const formData = this.form.getRawValue();
                if (this.formControl && this.formControl.value) {
                    Object.keys(formData).forEach(key => {
                        if (!formData[key]) {
                            this.form.controls[key].markAsTouched();
                        }
                    });
                }
            }
        });
    }

    propagateChange = (_: any) => {
    }
    onTouched: any = () => {
    }

    writeValue(value: string): void {
        if (value) {
            console.log('value -->', value);
            const date = moment(value, this.format);
            const time = date.format('hh:mm a');
            this.form.patchValue({date, time});
        } else {
            this.form.reset();
        }
    }

    // registers 'fn' that will be fired wheb changes are made
    // this is how we emit the changes back to the form
    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    // not used, used for touch input
    registerOnTouched(fn) {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        isDisabled ? this.form.disable() : this.form.enable();
    }

    validate(formControl: FormControl): ValidationErrors | null {
        this.formControl = formControl;
        this.required = formControl.hasError('required');
        return (this.form.dirty && this.form.invalid) ? {required: true} : null;
    }

}
