import { ModuleWithProviders, NgModule } from '@angular/core';
import { GrecaptchaService } from './grecaptcha.service';
import { GRECAPTCHA_SITE_KEY } from './grecaptcha.token';

@NgModule()
export class GrecaptchaModule {
  constructor(private grecaptchaService: GrecaptchaService){

    this.grecaptchaService.embeddedScript();
  }
  static forRoot(config: { siteKey: string }): ModuleWithProviders<GrecaptchaModule> {
    return {
      ngModule: GrecaptchaModule,
      providers: [GrecaptchaService, { provide: GRECAPTCHA_SITE_KEY, useValue: config.siteKey }],
    };
  }
}
