import { transformAddressObjectToString } from '@app/core/common/common.utils';
import { Default } from '@app/core/decorators/default.decorator';
import { PhoneNumber } from '@app/modules/shared/phone-number/phone-number.component';
import { BaseModel } from '@core/models/base.model';
import { Expose, Exclude } from 'class-transformer';
import { BusinessUnitModel } from './business-unit.model';
import { SalespersonDataFromGovInterface } from './data-gov.model';

export interface UserProfileInterface
    extends Partial<Omit<SalespersonDataFromGovInterface, 'rank'>> {
    jobTitle?: string;
    countryCode?: string;
    phoneNumber?: string;
    isFirstLogin?: boolean;
    tourCompleted?: string[];
    color?: string;
    isWatchedWhatNewPopup?: boolean;
    registrationNumber?: string;
    estateAgentName?: string;
    estateAgentLicenceNumber?: string;
    estateAgentEmail?: string;
    estateAgentPhoneNumber?: string;
    estateAgentCountryCode?: string;
    estateAgentAddress?: {
        postalCode: string;
        unitNumber: string;
        blockNumber: string;
        streetName: string;
        buildingName: string;
    };
    upline1?: string;
    upline2?: string;
    subscription:{
        planId:number;
        interval:string
    }
}

@Exclude()
export class UserProfileModel
    extends BaseModel
    implements UserProfileInterface
{
    @Expose()
    jobTitle: string;
    @Expose()
    countryCode: string;
    @Expose()
    phoneNumber: string;
    @Expose()
    isFirstLogin: boolean;
    @Expose()
    tourCompleted: string[];
    @Expose()
    color: string;

    @Expose()
    @Default(false)
    isWatchedWhatNewPopup: boolean;

    @Expose()
    registrationNumber: string;

    @Expose()
    estateAgentName: string;

    @Expose()
    estateAgentLicenceNumber: string;
    @Expose()
    estateAgentEmail: string;
    @Expose()
    estateAgentPhoneNumber: string;
    @Expose()
    estateAgentCountryCode: string;
    @Expose()
    estateAgentAddress: {
        postalCode: string;
        unitNumber: string;
        blockNumber: string;
        streetName: string;
        buildingName: string;
        floorNumber: string;
        country: string;
    };
    @Expose()
    upline1: string;
    @Expose()
    upline2: string;

    @Expose()
    subscription:{
        planId:number;
        interval:string
    }
    @Expose()
    get estateAgentFullAddress(): string {
        if (!this.estateAgentAddress) {
            return '';
        }

        return transformAddressObjectToString(this.estateAgentAddress);
    }

    buildProfileByBUInfo(bu: BusinessUnitModel) {
        if (bu) {
            this.estateAgentName = bu.name;
            this.estateAgentLicenceNumber = bu.metadata?.licenseNumber;
            this.estateAgentPhoneNumber = bu.metadata?.phoneNumber;
            this.estateAgentCountryCode = bu.metadata?.countryCode;

            this.estateAgentAddress = {
                postalCode: bu.metadata?.postalCode,
                unitNumber: bu.metadata?.unitNumber,
                blockNumber: bu.metadata?.blockNumber,
                streetName: bu.metadata?.streetName,
                buildingName: bu.metadata?.buildingName,
                floorNumber: bu.metadata?.floorNumber,
                country: bu.country,
            };
        }

        return this;
    }

    @Expose()
    get phone(): string {
        return `+${this.countryCode} ${this.phoneNumber}`;
    }
}
