import { Directive, EventEmitter, HostListener, Input, Output, ElementRef, OnDestroy } from '@angular/core';

@Directive({
    selector: '[appClickOut]'
})
export class ClickOutDirective implements OnDestroy {
    @Output() appClickOut: EventEmitter<void> = new EventEmitter<void>();
    constructor(
        private elementRef: ElementRef
    ) { }
    ngOnDestroy(): void {
        this.appClickOut.complete();
    }
    @HostListener('document:click', ['$event']) onDocumentClick(event: MouseEvent) {
        if (!this.elementRef.nativeElement.contains(event.target)) {
            this.appClickOut.emit();
        }
    }
}
