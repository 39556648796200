import {
    Component,
    OnInit,
    ViewEncapsulation,
    Input,
    HostBinding
} from '@angular/core';
import { ComponentStatus } from '@app/core/types/component-status';

@Component({
    selector: 'app-status-label',
    template: '<span><ng-content></ng-content></span>',
    styleUrls: ['./status-label.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class StatusLabelComponent implements OnInit {
    @Input() color: ComponentStatus = 'success';
    @Input() size: 'tiny' | 'medium' = 'medium';

    @HostBinding('class')
    get getClasses(): string {
        return [
            this.color,
            this.size
        ].join(' ');
    }


    constructor() { }

    ngOnInit(): void { }
}
