import { FormFieldBase } from '@app/core/models/form-field-base.model';
import { Helper } from '@app/core/common/helper';
import { DynamicField } from './dynamic-field.model';
export class DynamicFormNumberConfig {
    constructor(
        public decimalLimit = 2,
        public includeThousandsSeparator = true,
        public integerLimit = 9,
        public suffix = ''
    ) {

    }
}

export function getDynamicFormNumberConfig(type: string, config: { [x in string]: any }): DynamicFormNumberConfig {
    if (type === 'percent') {
        return new DynamicFormNumberConfig(
            config.decimalLimit,
            config.includeThousandsSeparator,
            config.integerLimit,
            config.suffix || '%'
        );
    }

    return new DynamicFormNumberConfig(
        config.decimalLimit,
        config.includeThousandsSeparator,
        config.integerLimit
    );
}

export class DynamicFormNumber extends DynamicField {
    controlType = 'number';
    config: DynamicFormNumberConfig;
    constructor(options: { [x in string]: any } = {}) {
        super(options);
        this.options = options['options'] || [];
        this.config = getDynamicFormNumberConfig(options.type, options.config);
    }

    get mask() {
        return Helper.customNumberMask(
            this.config.decimalLimit,
            this.config.includeThousandsSeparator,
            this.config.includeThousandsSeparator,
            this.config.suffix
        );
    }
}
