import { Pipe, PipeTransform } from '@angular/core';
import { CommissionDistributionStatus } from '../models/commission-distribution.model';
import { ECDD_STATUS, EcddStatus } from '../data/ecdd.data';

@Pipe({
    name: 'clientStatuslabel',
})
export class ClientStatusLabelPipe implements PipeTransform {
    transform(value: string): string {
        switch (value) {
            case 'in-active':
                return 'inactive';
            case 'active':
                return 'active';
            default:
                return value;
        }
    }
}

@Pipe({ name: 'verbClientStatusLabel' })
export class VerbClientStatusLabelPipe implements PipeTransform {
    transform(value: any): any {
        switch (value) {
            case 'in-active':
                return 'deactivate';
            case 'active':
                return 'activate';
            default:
                return value;
        }
    }
}

@Pipe({
    name: 'caseStatuslabel',
})
export class CaseStatusLabelPipe implements PipeTransform {
    transform(value: string): string {
        switch (value) {
            case 'draft':
                return 'new';
            case 'submitted':
                return 'processing';
            case 'confirmed':
                return 'approved';
            case 'rejected':
                return 'rework';
            case 'confirmed_and_generated':
                return 'approved & generated invoice';
            default:
                return value;
        }
    }
}

@Pipe({
    name: 'commissionDistributionStatusLabel',
})
export class CommissionDistributionStatusLabelPipe implements PipeTransform {
    transform(value: CommissionDistributionStatus): string {
        switch (value) {
            case 'approve':
                return 'approved';
            default:
                return value;
        }
    }
}

@Pipe({ name: 'ecddStatusLabel' })
export class EcddStatusLabelPipe implements PipeTransform {
    transform(value: EcddStatus): string {
        return ECDD_STATUS.find((status) => status.id === value).name;
    }
}
