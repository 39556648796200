import { Default } from '@app/core/decorators/default.decorator';
import { BaseModel } from '@app/core/models/base.model';
import { Expose } from 'class-transformer';

import * as moment from 'moment';
export interface DocumentExpirationSettingInterface {
    enableExpiration: boolean;
    expireAfter: number; // the number of day before expire
    notifyBefore: number; // the number of day prior to expiration to warn signer
    expireAfterUnit: string;
    startDate: string;
}

export class DocumentExpirationSettingModel extends BaseModel implements DocumentExpirationSettingInterface {
    @Expose()
    enableExpiration: boolean;
    @Expose()
    expireAfter: number;
    @Expose()
    @Default('days')
    expireAfterUnit: string;
    @Expose()
    notifyBefore: number;
    @Expose()
    startDate: string;


    @Expose()
    get expirationDate() {
        if (!this.startDate || !this.expireAfter) {
            return null;
        }
        return moment(this.startDate).add(this.expireAfter + 1, this.expireAfterUnit as moment.unitOfTime.DurationConstructor);
    }
}
