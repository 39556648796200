import { ContentBuilderFieldType } from '@app/core/types/content-builder-field-type';
import { ParticipantField } from './participant-field.model';
export class ParticipantDateField extends ParticipantField {
    controlType = 'date';
    fieldType: ContentBuilderFieldType = 'participantField';
    constructor(options: { [x in string]: any } = {}) {
        super(options);
        this.type = options.type ? options.type : 'shortDate';
        this.config.format = options.config?.format || 'DD/MM/YYYY';
    }

    static getTemplate(data:{label:string; owner?:string}):string{
        return `<label class="redr-inline-date-field redr-inline-field redr-handlebar-field" label="Date Field"  ><span class="inline-field"></span></label>`
    }
    static default():ParticipantDateField{
        const instance = new ParticipantDateField();
        instance.required = true;
        return instance
    }
}
