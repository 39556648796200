export const RECEIPT_TYPES = [
    {
        id: 'OneTimeReceipt',
        name: 'One Time Receipt',
        disabled: false,
    },
    {
        id: 'CustomerReceipt',
        name: 'Customer Receipt',
        disabled: true,
    },
] as const;

export const PAYMENT_METHODS = [
    { id: 'giro', name: 'giro', displayName: 'GIRO' },
    { id: 'cheque', name: 'cheque', displayName: 'Cheque' },
    { id: 'bankTransfer', name: 'bankTransfer', displayName: 'Bank Transfer' },
    { id: 'cash', name: 'cash', displayName: 'Cash' },
    { id: 'creditCard', name: 'creditCard', displayName: 'Credit Card' },
    { id: 'nets', name: 'nets', displayName: 'NETS' },
    { id: 'other', name: 'other', displayName: 'Other' }
] as const;
