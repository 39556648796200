import { Component, OnInit, Output, ViewChild, Input, OnDestroy, EventEmitter, TemplateRef, ElementRef } from '@angular/core';

@Component({
    selector: 'app-float-panel',
    templateUrl: './float-panel.component.html',
    styleUrls: ['./float-panel.component.scss']
})
export class FloatPanelComponent implements OnInit, OnDestroy {

    @Output() readonly closed: EventEmitter<void | 'click' | 'keydown' | 'tab'> =
        new EventEmitter<void | 'click' | 'keydown' | 'tab'>();
    @Output() readonly opened: EventEmitter<void | 'click'> =
        new EventEmitter<void | 'click'>();
    @ViewChild(TemplateRef, { static: true }) panelTemplateRef: TemplateRef<any>;
    @ViewChild('ElementRef', { static: true }) panelContent: ElementRef;
    @Input() panelClass = 'editor-panel';
    @Input() panelContentClass = 'editor-panel__content';
    @Input() closeIfHasClick = false;

    constructor() { }
    ngOnDestroy(): void {
        this.closed.complete();
    }

    ngOnInit(): void {
    }

    onClickPanel(): void {
        if (this.closeIfHasClick) {
            this.closePanel();
        }
    }

    closePanel(): void {
        this.closed.emit('click');
    }

    openPanel(): void {
        this.opened.emit('click');
    }

}
