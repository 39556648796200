import { Default } from '@app/core/decorators/default.decorator';
import { BaseModel } from '@app/core/models/base.model';
import { Exclude, Expose, Type } from 'class-transformer';
export interface ContactFieldInterface {
    designation?: string;
    postalCode: string;
    blockNumber: string;
    streetName: string;
    unitNumber: string;
    uenNumber: string;
    buildingName: string;
    state?: string;
    myInfoFields: string[];
}

export class ContactFieldModel
    extends BaseModel
    implements ContactFieldInterface
{
    @Expose()
    designation?: string;
    @Expose()
    postalCode: string;
    @Expose()
    blockNumber: string;
    @Expose()
    streetName: string;
    @Expose()
    unitNumber: string;
    @Expose()
    uenNumber: string;
    @Expose()
    buildingName: string;
    @Expose()
    state?: string;
    @Expose()
    nationality: string;
    @Expose()
    dictrict: string;
    @Expose()
    @Default('Singapore')
    country: string;
    @Expose()
    myInfoFields: string[];
    @Expose()
    gender?: string;
    @Expose()
    citizenship: string;
    @Expose()
    landline: string;
    @Expose()
    fax:string;
    @Expose()
    residencyStatus: string;
    @Expose()
    salutation: string;
    @Expose()
    jobTitle: string;
    @Expose()
    companyName: string;
}

export interface ContactInterface {
    id: string;
    name: string;
    nricNumber: string;
    passportNumber: string;
    phone: string;
    dateOfBirth: string;
    countryPhoneCode: string;
    tenantId: number;
    avatar: string;
    email: string;
    nationality: string;
    createdAt: string;
    updatedAt: string;
    fields: ContactFieldInterface;
}

export class ContactModel extends BaseModel implements ContactInterface {
    @Expose()
    id: string;
    @Expose()
    @Default('')
    name: string;
    @Expose()
    nricNumber: string;
    @Expose()
    passportNumber: string;
    @Expose()
    phone: string;
    @Expose()
    dateOfBirth: string;
    @Expose()
    countryPhoneCode: string;
    @Expose()
    tenantId: number;
    @Expose()
    avatar: string;
    @Expose()
    @Default('')
    email: string;
    @Expose()
    nationality: string;
    @Expose()
    createdAt: string;
    @Expose()
    updatedAt: string;

    @Expose()
    @Type(() => ContactFieldModel)
    fields: ContactFieldModel;

    @Expose()
    get phoneNumber(): string {
        return [!!this.countryPhoneCode ? `+${this.countryPhoneCode}`: '',this.phone].filter(item => !!item).join(' ');
    }
}

@Exclude()
export class ContactCreateDto extends BaseModel {
    @Expose()
    name: string;
    @Expose()
    nricNumber: string;
    @Expose()
    passportNumber: string;
    @Expose()
    phone: string;
    @Expose()
    dateOfBirth: string;
    @Expose()
    countryPhoneCode: string;
    @Expose()
    avatar: string;
    @Expose()
    @Default('')
    email: string;
    @Expose()
    nationality: string;
    @Expose()
    @Type(() => ContactFieldModel)
    fields: ContactFieldModel;
}
