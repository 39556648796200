export const ECDD_TYPE = [
    {
        id: 'individual',
        name: 'For Individual',
        disabled: false,
    },
    {
        id: 'entity',
        name: 'For Entity',
        disabled: false,
    },
] as const;
export type EcddType = (typeof ECDD_TYPE)[number]['id'];

export const ECDD_TRANSACTION_TYPE = [
    {
        id: 'rental',
        name: 'Rental',
    },
    {
        id: 'sales',
        name: 'Sales and Purchase',
    },
] as const;
export type EcddTransactionType = (typeof ECDD_TRANSACTION_TYPE)[number]['id'];

export const ECDD_CUSTOMER_TYPE = [
    {
        id: 'tenant',
        name: 'Tenant',
    },
    {
        id: 'landlord',
        name: 'Landlord',
    },
    {
        id: 'seller',
        name: 'Seller',
    },
    {
        id: 'buyer',
        name: 'Buyer',
    },
] as const;
export type EcddCustomerType = (typeof ECDD_CUSTOMER_TYPE)[number]['id'];

export const ECDD_CUSTOMER_RENTAL_TYPE = ECDD_CUSTOMER_TYPE.slice(0, 2);
export const ECDD_CUSTOMER_SALES_TYPE = ECDD_CUSTOMER_TYPE.slice(2);

export const ECDD_IDENTIFYING_TYPE = [
    {
        id: 'nric',
        name: 'NRIC',
    },
    {
        id: 'passport',
        name: 'Passport',
    },
    {
        id: 'workpass',
        name: 'Work Pass',
    },
    {
        id: 'other',
        name: 'Other',
    },
] as const;

export const ECDD_STATUS = [
    {
        id: 'draft',
        name: 'Draft',
    },
    {
        id: 'pending',
        name: 'Pending Approval',
    },
    {
        id: 'approved',
        name: 'Manually Approved',
    },
    {
        id: 'rejected',
        name: 'Rejected',
    },
    {
        id: 'clientSigned',
        name: 'Client Signed',
    },
    {
        id: 'auto-approved',
        name: 'Approved',
    },
] as const;
export type EcddStatus = (typeof ECDD_STATUS)[number]['id'];
