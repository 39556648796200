import { Pipe, PipeTransform } from '@angular/core';
import { Helper } from '@app/core/common/helper';

@Pipe({
    name: 'toNumber'
})
export class ToNumberPipe implements PipeTransform {
    transform(value: string): number {

        const formated = Helper.removeCommaFromText(value);
        return formated;
    }
}

