import { AppConstant } from '@app/app.constant';
import { Helper } from '@core/common/helper';

export class LocalStorageService {
    static getItem(key: string) {
        try {
            return JSON.parse(localStorage.getItem(key));
        } catch (e) {
            return localStorage.getItem(key);
        }
    }

    static setItem(key: string, value: any) {
        return localStorage.setItem(key, value);
    }

    static removeItem(key: string) {
        return localStorage.removeItem(key);
    }

    static clear() {
        localStorage.clear();
    }

    static getAccessToken() {
        // return Helper.removeAt(LocalStorageService.getItem(AppConstant.LOCAL_STORAGE_KEYS.TOKEN), 10);
        return LocalStorageService.getItem(
            AppConstant.LOCAL_STORAGE_KEYS.TOKEN
        );
    }

    static setAccessToken(token) {
        // LocalStorageService.setItem(AppConstant.LOCAL_STORAGE_KEYS.TOKEN, Helper.insertAt(token, 'a', 10));
        LocalStorageService.setItem(
            AppConstant.LOCAL_STORAGE_KEYS.TOKEN,
            token
        );
    }

    static getRefreshToken() {
        return LocalStorageService.getItem(
            AppConstant.LOCAL_STORAGE_KEYS.REFRESH_TOKEN
        );
    }

    static setRefreshToken(token) {
        LocalStorageService.setItem(
            AppConstant.LOCAL_STORAGE_KEYS.REFRESH_TOKEN,
            token
        );
    }

    static getUserInfo() {
        return JSON.parse(
            LocalStorageService.getItem(
                AppConstant.LOCAL_STORAGE_KEYS.USER_INFO
            )
        );
    }

    static setUserInfo(data) {
        LocalStorageService.setItem(
            AppConstant.LOCAL_STORAGE_KEYS.USER_INFO,
            data
        );
    }

    static getLogInStatus() {
        return JSON.parse(
            LocalStorageService.getItem(
                AppConstant.LOCAL_STORAGE_KEYS.LOGIN_STATUS
            )
        );
    }

    static setLogInStatus(data) {
        LocalStorageService.setItem(
            AppConstant.LOCAL_STORAGE_KEYS.LOGIN_STATUS,
            data
        );
    }

    static getRawMyInfo() {
        return LocalStorageService.getItem(
            AppConstant.LOCAL_STORAGE_KEYS.RAW_MY_INFO
        );
    }

    static setRawMyInfo(data) {
        LocalStorageService.setItem(
            AppConstant.LOCAL_STORAGE_KEYS.RAW_MY_INFO,
            data
        );
    }

    static getTokenInvite() {
        return LocalStorageService.getItem(
            AppConstant.LOCAL_STORAGE_KEYS.TOKEN_INVITE
        );
    }

    static setTokenInvite(data) {
        LocalStorageService.setItem(
            AppConstant.LOCAL_STORAGE_KEYS.TOKEN_INVITE,
            data
        );
    }
}
