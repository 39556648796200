import { Pipe, PipeTransform } from '@angular/core';
import { differenceWith } from 'lodash-es';

@Pipe({
    name: 'diff'
})
export class DifferencePipe implements PipeTransform {

    transform(items: any[], value: any[],compareWith?:(o1,o2)=>boolean): any[] {
        console.log('DifferencePipe',items)
        if (!items) { return []; }
        if (!value || value.length === 0) { return items; };
        const compareFn = compareWith ? compareWith : (o1,o2)=> o1 === o2;
        return differenceWith(items,value,compareFn)

    }

}
