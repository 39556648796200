import { FormFieldBase } from '@app/core/models/form-field-base.model';
import { ContentBuilderFieldType } from '@app/core/types/content-builder-field-type';
import { ParticipantField } from './participant-field.model';
export class ParticipantTextField extends ParticipantField {
    controlType = 'textbox';
    fieldType: ContentBuilderFieldType = 'participantField';

    static getTemplate(data:{label:string; owner?:string}):string{
        return `<label class="redr-inline-text-field redr-inline-field redr-handlebar-field" label="Text Field" uuid type owner metadata><span class="inline-field"></span></label>`
    }
    static default():ParticipantTextField{
        const instance = new ParticipantTextField();
        instance.required = true;
        return instance
    }
}
