import { transformAddressObjectToString } from '@app/core/common/common.utils';
import { Default } from '@app/core/decorators/default.decorator';
import { BaseModel } from '@app/core/models/base.model';
import { Exclude, Expose, Transform, Type } from 'class-transformer';
import {
    BusinessUnitModel,
    BusinessUnitInterface,
} from './business-unit.model';
import {
    ContactCreateDto,
    ContactFieldInterface,
    ContactFieldModel,
    ContactInterface,
    ContactModel,
} from './contact.model';
import { orderBy } from 'lodash-es';
import { IVendor, VendorModel } from './vendor.model';
import { SafeHtml } from '@angular/platform-browser';

// export interface ClientFieldInterface {
//     designation?: string;
//     postalCode: string;
//     blockNumber: string;
//     streetName: string;
//     unitNumber: string;
//     uenNumber: string;
//     buildingName: string;
//     state?: string;
// }

// export class ClientFieldModel extends BaseModel implements ClientFieldInterface {
//     designation?: string;
//     postalCode: string;
//     blockNumber: string;
//     streetName: string;
//     unitNumber: string;
//     uenNumber: string;
//     buildingName: string;
//     state?: string;
// }

export interface ClientRefEntityInterface {
    createdAt?: string;
    id?: number;
    metadata?: {
        code?: string;
        externalRefCode?: string;
        status?: string;
    };
    refEntityId?: number;
    refEntityName?: number;
}

export interface ClientInterface {
    id: number;
    name: string;
    type: string;
    roles: string[];
    addressLine1: string;
    addressLine2: string;
    businessUnitId: number;
    businessUnit?: BusinessUnitInterface;
    createdBy: number;
    logo: string;
    website: string;
    contacts: ContactInterface[];
    createdAt: string;
    updatedAt: string;
    mainContact: ContactInterface;
    status: string;
    note: string;
    code: string;
    fields: ContactFieldInterface;
    refEntities: ClientRefEntityInterface[];
    ecddId?: string;
    vendorId?: number;
    vendor?: IVendor;
}

export class ClientRefEntityModel
    extends BaseModel
    implements ClientRefEntityInterface
{
    @Expose()
    createdAt: string;

    @Expose()
    id: number;

    @Expose()
    metadata: {
        code: string;
        externalRefCode: string;
        status: string;
    };

    @Expose()
    refEntityId: number;

    @Expose()
    refEntityName: number;
}

@Exclude()
export class ClientModel extends BaseModel implements ClientInterface {
    @Expose()
    id: number;

    @Expose()
    name: string;

    @Expose()
    type: string;

    @Expose()
    @Default(['tenant'])
    roles: string[];

    @Expose()
    addressLine1: string;

    @Expose()
    addressLine2: string;

    @Expose()
    businessUnitId: number;

    @Expose()
    @Type(() => BusinessUnitModel)
    businessUnit: BusinessUnitModel;

    @Expose()
    createdBy: number;

    @Expose()
    logo: string;

    @Expose()
    website: string;

    @Expose()
    @Type(() => ContactModel)
    contacts: ContactModel[];

    @Expose()
    @Default({})
    @Type(() => ContactModel)
    mainContact: ContactModel;

    @Expose()
    createdAt: string;

    @Expose()
    updatedAt: string;

    @Expose()
    code: string;

    @Expose()
    @Default('active')
    status: string;

    @Expose()
    note: string;

    @Expose()
    @Default({})
    @Type(() => ContactFieldModel)
    fields: ContactFieldModel;

    @Expose()
    @Transform((val)=> {
        if(val){
            return orderBy(val,'refEntityId','desc')
        }
    })
    @Type(() => ClientRefEntityModel)
    refEntities: ClientRefEntityModel[];

    @Expose()
    vendorId: number;

    @Expose()
    @Type(() => VendorModel)
    vendor: VendorModel;
    @Expose()
    ecddId?: string;

    @Expose()
    passSecurityTrustNote?: SafeHtml;

    get latestEcddRefNo(): string | null {
       
        const selected = this.latestAvailableEcdd;
        return  selected?.metadata?.externalRefCode || selected?.metadata?.code || null
        
    }
    get latestAvailableEcdd(): ClientRefEntityModel | null {
        if (!this.refEntities || this.refEntities.length < 1) return null;
        const selected = this.refEntities.find(item => ['auto-approved','approved'].some(status => status ===item.metadata.status) )
        return  selected || null        
    }
    
    get latestEcdd(): ClientRefEntityModel | null {
        if (!this.refEntities || this.refEntities.length < 1) return null;
        const selected = this.refEntities.find(item => ['draft','pending','auto-approved','approved'].some(status => status ===item.metadata.status) )
        console.log('selected',selected)
        return  selected || null        
    }
    isCompany(): boolean {
        return this.type === 'company';
    }

    @Expose()
    get address(): string {
        if (!this.fieldInfo) {
            return '';
        }

        return transformAddressObjectToString({
            postalCode: this.fieldInfo.postalCode,
            unitNumber: this.fieldInfo.unitNumber,
            blockNumber: this.fieldInfo.blockNumber,
            streetName: this.fieldInfo.streetName,
            buildingName: this.fieldInfo.buildingName,
            country: this.fieldInfo.nationality,
        });
    }

    get fieldInfo() {
        return this.isCompany() ? this.fields : this.mainContact?.fields;
    }

    get companyName(): string {
        return this.isCompany() ? this.name : '';
    }

    toSnapshot(): Partial<ClientInterface> {
        return {
            id: this.id,
            fields: this.fields,
            type: this.type,
            mainContact: this.mainContact,
            code: this.code,
        };
    }
}

@Exclude()
export class ClientCreateDto extends BaseModel {
    @Expose()
    type: string;

    @Expose()
    name: string;

    @Expose()
    roles: string[];

    @Expose()
    addressLine1: string;

    @Expose()
    @Type(() => ContactCreateDto)
    contacts: ContactCreateDto[];
}

export function getClientFieldInfoByType(client: ClientInterface) {
    if (client.type === 'company') {
        return client.fields || {};
    }
    return client.mainContact?.fields || {};
}
