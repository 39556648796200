import { PaginationModel } from '../models/pagination.model';

export class PaginationAdapter<T> {
    pagination: PaginationModel;
    results: T[];
    constructor(data) {
        this.pagination = PaginationModel.fromJson(data.meta) as PaginationModel;
        this.results = data.items;
    }
}
