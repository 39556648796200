import { ModuleWithProviders, NgModule, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Optional } from '@angular/core';
import { RedrConfig } from '@app/core/models/redr-config.model';
import { REDR_CONFIG } from '@app/core/services/config.service';
import { LocalStorageService } from '@app/core/services/local-storage.service';
import { redrConfig } from '@app/core/data/redr-config.data';



@NgModule()
export class RedrConfigModule {
    constructor(@Optional() @SkipSelf() parentModule: RedrConfigModule) {
        if (parentModule) {
            throw new Error('RedrConfigModule is already loaded. Import it in the AppModule only!');
        }
    }
    static forRoot(): ModuleWithProviders<RedrConfigModule> {

        const config = LocalStorageService.getItem('redrConfig') || redrConfig;
        return {
            ngModule: RedrConfigModule,
            providers: [
                {
                    provide: REDR_CONFIG,
                    useValue: config
                }
            ]
        };
    }
}
