import { InjectionToken, ModuleWithProviders, NgModule, ValueProvider } from '@angular/core';
export const EXCELJS_PREFIX_URL = new InjectionToken<string>('EXCELJS_PREFIX_URL');
export const EXCELJS_ORIGIN = new InjectionToken<string>('EXCELJS_ORIGIN');
@NgModule({
  declarations: [],
  imports: [ ],
  exports: [],
})
export class ExceljsViewerModule {
  static forRoot(configs?: { prefix?: string; origin?: string }): ModuleWithProviders<ExceljsViewerModule> {
    const valueProviders: ValueProvider[] = [
      {
        provide: EXCELJS_PREFIX_URL,
        useValue: configs?.prefix || '',
      },
      {
        provide: EXCELJS_ORIGIN,
        useValue: configs?.origin || '',
      },
    ];
    return {
      ngModule: ExceljsViewerModule,
      providers: [...valueProviders],
    };
  }
  static forFeature(configs: { prefix: string }): ModuleWithProviders<ExceljsViewerModule> {
    const valueProviders: ValueProvider[] = [
      {
        provide: EXCELJS_PREFIX_URL,
        useValue: configs?.prefix || '',
      },
    ];
    return {
      ngModule: ExceljsViewerModule,
      providers: [...valueProviders],
    };
  }
}
