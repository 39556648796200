import { AbstractControl, FormGroup } from '@angular/forms';
import { Observable, Subject, isObservable } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { PaginationAdapter } from '../adapters/pagination.adapter';

export class DevUtil {
    static registryChangeDependency(
        formGroup: FormGroup,
        unsubscribeAll: Subject<void>,
        sourceName: string,
        distName: string,
        fn: (value: any) => any | Observable<any>
    ): void {
        formGroup
            .get(sourceName)
            ?.valueChanges.pipe(takeUntil(unsubscribeAll))
            .subscribe((val) => {
                if (isObservable(fn(val))) {
                    fn(val)
                        .pipe(take(1))
                        .subscribe((data: any) => {
                            formGroup.get(distName)?.setValue(data);
                        });
                } else {
                    formGroup.get(distName)?.setValue(fn(val));
                }
            });
    }

    static valueChangeDependOn(
        source: AbstractControl,
        dist: AbstractControl | null,
        unsubscribeAll: Subject<void>,
        fn: (value: any) => any | Observable<any>
    ): void {
        source?.valueChanges
            .pipe(takeUntil(unsubscribeAll))
            .subscribe((val) => {
                if (isObservable(fn(val))) {
                    fn(val)
                        .pipe(take(1))
                        .subscribe((data: any) => {
                            dist?.patchValue(data);
                        });
                } else {
                    dist?.patchValue(fn(val));
                }
            });
    }
    static paginationAdapterToList<T>(
        pagination: Observable<PaginationAdapter<T>>
    ): Observable<T[]> {
        return pagination.pipe(map((data) => data.results));
    }

    static   scrollToElement(querySelectorString:string | string[] = '.ng-invalid[formControlName]'): void {
        const selectorStringList = Array.isArray(querySelectorString)?querySelectorString:[querySelectorString]
        setTimeout(()=> {
            let firstElementWithError; ;
            for (let index = 0; index < selectorStringList.length; index++) {
                const selectorString = selectorStringList[index];
                firstElementWithError = document.querySelector(
                    selectorString
                )
                if(firstElementWithError){
                    break;
                }
            }
            console.log('hello error -> ',firstElementWithError)
            if (firstElementWithError) {
                firstElementWithError.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        },0)
       
    }
}
