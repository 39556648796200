import { Injectable } from '@angular/core';
import { BasePolicyCollection } from '@modules/permission/base.policy-collection';
import { Observable } from 'rxjs';
import { Policies } from '../permission/policy.constant';

@Injectable()
export class InvoicePolicyCollection extends BasePolicyCollection {
    readonly policyCollectionKey = 'invoice';
    viewReceipt(): boolean | Promise<boolean> | Observable<boolean> {
        return (
            (this.permissionService.hasPolicy('receipt::view') ||
                this.permissionService.hasPolicy(
                    this.policyCollectionKey + 'receipt::viewOwner'
                )) &&
            this.permissionService.hasPermissions(Policies['receipt'].view)
        );
    }
}
