<div class="red-vertical-navigation-item-wrapper" [class.red-vertical-navigation-item-has-subtitle]="!!item.subtitle" [ngClass]="item.classes?.wrapper">
  <ng-container *ngIf="item.link">
    <a
      [routerLink]="item.link"
      class="red-vertical-navigation-item"
      [ngClass]="{
        'red-vertical-navigation-item-active': active,
        'red-vertical-navigation-item-disabled': item.disabled,
        'red-vertical-navigation-item-active-forced': item.active
      }"
      [matTooltip]="(item.tooltip | translate) || ''"
    >
      <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </a>
  </ng-container>
  <ng-container *ngIf="!item.link">
    <div
      class="red-vertical-navigation-item"
      [ngClass]="{
        'red-vertical-navigation-item-active': active,
        'red-vertical-navigation-item-disabled': item.disabled,
        'red-vertical-navigation-item-active-forced': item.active
      }"
      [matTooltip]="item.tooltip || ''"
    >
      <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </div>
  </ng-container>
</div>
<ng-template #itemTemplate>
  <!-- Icon -->
  <ng-container *ngIf="item.icon">
    <mat-icon *ngIf="item.isSvgIcon" class="red-vertical-navigation-item-icon" [ngClass]="item.classes?.icon!" [svgIcon]="item.icon"></mat-icon>
    <mat-icon *ngIf="!item.isSvgIcon" class="red-vertical-navigation-item-icon" [ngClass]="item.classes?.icon!">{{ item.icon }}</mat-icon>
  </ng-container>

  <!-- Title & Subtitle -->
  <div class="red-vertical-navigation-item-title-wrapper">
    <div class="red-vertical-navigation-item-title">
      <span [ngClass]="item.classes?.title!">
        {{ item.title  | navItemTitle:translatePipe: titleCasePipe}}
      </span>
    </div>
    <ng-container *ngIf="item.subtitle">
      <div class="red-vertical-navigation-item-subtitle">
        <span [ngClass]="item.classes?.subtitle">
          {{ item.subtitle | translate}}
        </span>
      </div>
    </ng-container>
  </div>

  <!-- Badge -->
  <ng-container *ngIf="item.badge">
    <div class="red-vertical-navigation-item-badge">
      <div class="red-vertical-navigation-item-badge-content" [ngClass]="item.badge.classes!">
        {{ item.badge.title }}
      </div>
    </div>
  </ng-container>
</ng-template>
<ng-container *ngIf="!skipChildren">
  <div class="red-vertical-navigation-item-children">
    <ng-container *ngFor="let item of item.children; trackBy: trackByFn">
      <!-- Skip the hidden items -->
      <ng-container *ngIf="(item.hidden && !item.hidden(item)) || !item.hidden">
        <!-- Basic -->
        <ng-container *ngIf="item.type === 'basic'">
          <red-vertical-navigation-basic-item [item]="item" [name]="name"></red-vertical-navigation-basic-item>
        </ng-container>

        <!-- Collapsable -->
        <ng-container *ngIf="item.type === 'collapsable'">
          <red-vertical-navigation-collapsable-item [item]="item" [name]="name" [autoCollapse]="autoCollapse"></red-vertical-navigation-collapsable-item>
        </ng-container>

        <!-- Divider -->
        <ng-container *ngIf="item.type === 'divider'">
          <red-vertical-navigation-divider-item [item]="item" [name]="name"></red-vertical-navigation-divider-item>
        </ng-container>

        <!-- Group -->
        <ng-container *ngIf="item.type === 'group'">
          <red-vertical-navigation-group-item [item]="item" [name]="name"></red-vertical-navigation-group-item>
        </ng-container>

        <!-- Spacer -->
        <ng-container *ngIf="item.type === 'spacer'">
          <red-vertical-navigation-spacer-item [item]="item" [name]="name"></red-vertical-navigation-spacer-item>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
