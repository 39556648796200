import { Injectable } from "@angular/core";
import { NavigationItem } from "./navigation.types";
import { ClassProvider, InjectionToken, Type } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, ReplaySubject } from "rxjs";
import { NavigationGroup } from "./navigation.types";

export const NAVIGATION_SERVICE = new InjectionToken<NavigationBaseService>(
  "NAVIGATION_SERVICE"
);

@Injectable()
export abstract class NavigationBaseService {
  protected _navigation: ReplaySubject<NavigationGroup> =
    new ReplaySubject<NavigationGroup>(1);
  /**
   * Constructor
   */
  constructor(protected _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for navigation
   */
  get navigation$(): Observable<NavigationGroup> {
    return this._navigation.asObservable();
  }
  /**
   * Settet for navigation
   */
  set navigation(val: NavigationGroup) {
    this._navigation.next(val);
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get all navigation data
   */
  abstract get(): Observable<NavigationGroup>;
}

export const getNavigationService = (
  instance: Type<NavigationBaseService>
): ClassProvider => ({
  provide: NAVIGATION_SERVICE,
  useClass: instance,
  multi: false,
});

@Injectable({
  providedIn: "root",
})
export class RedNavigationService {
  private _componentRegistry: Map<string, any> = new Map<string, any>();
  private _navigationStore: Map<string, NavigationItem[]> = new Map<
    string,
    any
  >();

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Register navigation component
   *
   * @param name
   * @param component
   */
  registerComponent(name: string, component: any): void {
    this._componentRegistry.set(name, component);
  }

  /**
   * Deregister navigation component
   *
   * @param name
   */
  deregisterComponent(name: string): void {
    this._componentRegistry.delete(name);
  }

  /**
   * Get navigation component from the registry
   *
   * @param name
   */
  getComponent<T>(name: string): T {
    return this._componentRegistry.get(name);
  }

  /**
   * Store the given navigation with the given key
   *
   * @param key
   * @param navigation
   */
  storeNavigation(key: string, navigation: NavigationItem[]): void {
    // Add to the store
    this._navigationStore.set(key, navigation);
  }

  /**
   * Get navigation from storage by key
   *
   * @param key
   */
  getNavigation(key: string): NavigationItem[] {
    return this._navigationStore.get(key) ?? [];
  }

  /**
   * Delete the navigation from the storage
   *
   * @param key
   */
  deleteNavigation(key: string): void {
    // Check if the navigation exists
    if (!this._navigationStore.has(key)) {
      console.warn(
        `Navigation with the key '${key}' does not exist in the store.`
      );
    }

    // Delete from the storage
    this._navigationStore.delete(key);
  }

  /**
   * Utility function that returns a flattened
   * version of the given navigation array
   *
   * @param navigation
   * @param flatNavigation
   */
  getFlatNavigation(
    navigation: NavigationItem[],
    flatNavigation: NavigationItem[] = []
  ): NavigationItem[] {
    for (const item of navigation) {
      if (item.type === "basic") {
        flatNavigation.push(item);
        continue;
      }

      if (
        item.type === "aside" ||
        item.type === "collapsable" ||
        item.type === "group"
      ) {
        if (item.children) {
          this.getFlatNavigation(item.children, flatNavigation);
        }
      }
    }

    return flatNavigation;
  }

  /**
   * Utility function that returns the item
   * with the given id from given navigation
   *
   * @param id
   * @param navigation
   */
  getItem(id: string, navigation: NavigationItem[]): NavigationItem | null {
    for (const item of navigation) {
      if (item.id === id) {
        return item;
      }

      if (item.children) {
        const childItem = this.getItem(id, item.children);

        if (childItem) {
          return childItem;
        }
      }
    }

    return null;
  }

  /**
   * Utility function that returns the item's parent
   * with the given id from given navigation
   *
   * @param id
   * @param navigation
   * @param parent
   */
  getItemParent(
    id: string,
    navigation: NavigationItem[],
    parent: NavigationItem[] | NavigationItem
  ): NavigationItem[] | NavigationItem | null {
    for (const item of navigation) {
      if (item.id === id) {
        return parent;
      }

      if (item.children) {
        const childItem = this.getItemParent(id, item.children, item);

        if (childItem) {
          return childItem;
        }
      }
    }

    return null;
  }
}
