import { PaymentInfoInterface, PaymentInfoModel } from './payment-info.model';
import { BaseModel } from '@app/core/models/base.model';
import { Exclude, Expose, Transform, Type } from 'class-transformer';
import { SettingModel } from '@shared/models/setting.model';

export interface BusinessUnitMetaDataInterface {
    countryCode?: string;
    phoneNumber?: string;
    licenseNumber?: string;
    paymentInfo?: PaymentInfoInterface;
}

@Exclude()
export class BusinessUnitMetaData extends BaseModel implements BusinessUnitMetaDataInterface {
    @Expose()
    countryCode: string;
    @Expose()
    phoneNumber: string;
    @Expose()
    licenseNumber: string;
    @Expose()
    @Type(() => PaymentInfoModel)
    paymentInfo: PaymentInfoModel;
    @Expose()
    postalCode: string;
    @Expose()
    unitNumber: string;
    @Expose()
    blockNumber: string;
    @Expose()
    streetName: string;
    @Expose()
    buildingName: string;
    @Expose()
    floorNumber: string;
    @Expose()
    gstRegNo: string;
}

export interface BusinessUnitInterface {
    id: string;
    name: string;
    status: string;
    logo: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    country: string;
    state: string;
    zipCode: string;
    language: string;
    currencyCode: string;
    isActive: boolean;
    metadata: BusinessUnitMetaDataInterface;
    timeZone: string;
    createdBy: number;
    organizationId: number;
    settings?: Array<SettingModel>;
}

@Exclude()
export class BusinessUnitModel extends BaseModel implements BusinessUnitInterface {

    @Expose()
    id: string;
    @Expose()
    name: string;
    @Expose()
    code:string; // field to config specified agency partner
    @Expose()
    status: string;
    @Expose()
    logo: string;
    @Expose()
    addressLine1: string;
    @Expose()
    addressLine2: string;
    @Expose()
    city: string;
    @Expose()
    country: string;
    @Expose()
    state: string;
    @Expose()
    zipCode: string;
    @Expose()
    language: string;
    @Expose()
    currencyCode: string;
    @Expose()
    isActive: boolean;
    @Expose()
    timeZone: string;
    @Expose()
    createdBy: number;
    @Expose()
    organizationId: number;
    @Expose()
    @Type(() => BusinessUnitMetaData)
    metadata: BusinessUnitMetaData;

    @Expose()
    settings: Array<SettingModel>;

    hasGst(): boolean {
        return !!this.metadata.gstRegNo;
    }
    @Expose()
    @Transform((val,obj)=>{
        if(obj.code === 'HUTTON'){
            return 'huttons'
        }
        return ''
    })
    signingPortalPrefix:string
}
