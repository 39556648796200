import { BaseModel } from '@core/models/base.model';
import { Exclude, Expose } from 'class-transformer';
import { SettingEntityModel } from './setting-entity.model';
export const fieldTypeMappingConst = [
    'dynamicField',
    'signatureField',
    'dealTable',
] as const;
export type FieldTypeMapping = (typeof fieldTypeMappingConst)[number];

export type FieldBinding = {
    [P in FieldTypeMapping]?: { [x in string]: SettingEntityModel };
};
export interface SettingInterface {
    keyName: string;
    displayName: string;
    description: string;
    value: any;
}

@Exclude()
export class SettingModel extends BaseModel implements SettingInterface {
    @Expose()
    keyName: string;

    @Expose()
    displayName: string;

    @Expose()
    description: string;

    @Expose()
    value: any;
}
