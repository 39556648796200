<mat-form-field class="full-width custom-height-form-field">
    <mat-icon matPrefix>search</mat-icon>
    <input #inputSearch matInput aria-label="State" [matAutocomplete]="auto" [formControl]="itemCtrl" type="text"
        placeholder="Search..." #autocompleteTrigger="matAutocompleteTrigger">
    <button class="btn-clear" *ngIf="inputSearch.value" mat-icon-button matSuffix (click)="clear($event)">
        <mat-icon>clear</mat-icon>
    </button>
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayWith" class="redr-autocomplete">
        <div class="autocomplete-content">
            <mat-option *ngFor="let item of filteredItems | async" [value]="item">
                <img class="example-option-img" [key]="item.photoCover?.key" appLoadImage [enableLoader]="false" height="25"
                    width="25">
    
                <span>{{item.name}}</span>
            </mat-option>
        </div>
        <div class="autocomplete-action" style="height: unset;" *ngIf="allowCreate">
            <mat-option style="line-height: unset; padding: 0;">
                <button type="button" mat-flat-button color="primary"
                    (click)="autocompleteTrigger.closePanel(); create()" class="full-width">
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                        <mat-icon> add_circle </mat-icon>
                        <div>{{ 'common.add_new' | translate }}</div>
                    </div>
                </button>
            </mat-option>
        </div>
    </mat-autocomplete>

   
</mat-form-field>
