import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GroupUserModel } from '../models/group-user.model';
import { ViewGroupModel } from '../models/view-group.model';

@Injectable({
    providedIn: 'root'
})
export class GroupApiService {
    static GROUPS = 'groups';
    static GROUP_DETAIL = 'groups/:id';
    static ADD_USER_TO_GROUP = 'groups/add-users';
    static REMOVE_USER_TO_GROUP = 'groups/remove-users';
    static ASSIGN_PERMISSION_TO_GROUP = 'groups/assign-permissions';
    static REVOKE_PERMISSION_TO_GROUP = 'groups/revoke-permissions';
    static VIEW_GROUPS = 'view-groups';
    static VIEW_GROUP_DETAIL = 'view-groups/:id';
    static REMOVE_USER_TO_GROUPS = 'groups/remove-user';

    constructor(
        private apiService: ApiService) {
    }

    getGroups(): Observable<GroupUserModel[]> {
        return this.apiService
            .get(GroupApiService.GROUPS)
            .pipe(
                map(res => res.map(group => GroupUserModel.fromJson(group)))
            );
    }

    findById(query: { id: number }): Observable<GroupUserModel> {
        return this.apiService
            .get(GroupApiService.GROUP_DETAIL, query)
            .pipe(map(res => GroupUserModel.fromJson(res) as GroupUserModel));
    }

    addUsers(data): Observable<any> {
        return this.apiService.post(GroupApiService.ADD_USER_TO_GROUP, data);
    }

    removeUsers(data): Observable<any> {
        return this.apiService.post(GroupApiService.REMOVE_USER_TO_GROUP, data);
    }

    assignPermission(data: { groupId: number, permissions: string[] }): Observable<any> {
        return this.apiService.post(GroupApiService.ASSIGN_PERMISSION_TO_GROUP, data);
    }

    removePermission(data: { groupId: number, permissions: string[] }): Observable<any> {
        return this.apiService.post(GroupApiService.REVOKE_PERMISSION_TO_GROUP, data);
    }

    createGroup(data): Observable<any> {
        return this.apiService.post(GroupApiService.GROUPS, data);
    }

    updateGroup(data): Observable<any> {
        return this.apiService.put(GroupApiService.GROUPS, data, { excludeFields: ['viewPolicy'] });
    }

    createViewGroup(data): Observable<any> {
        return this.apiService.post(GroupApiService.VIEW_GROUPS, data);
    }

    getViewGroups(): Observable<ViewGroupModel[]> {
        return this.apiService
            .get(GroupApiService.VIEW_GROUPS)
            .pipe(
                map(res => res.map(group => ViewGroupModel.fromJson(group)))
            );
    }

    findViewGroupById(query: { id: number }): Observable<ViewGroupModel> {
        return this.apiService
            .get(GroupApiService.VIEW_GROUP_DETAIL, query)
            .pipe(map(res => ViewGroupModel.fromJson(res) as ViewGroupModel));
    }

    removeUserFromMultipleGroups(data: { userId: number; groupIds: number[] }): Observable<any> {
        return this.apiService.post(GroupApiService.REMOVE_USER_TO_GROUPS, data);
    }

    updateViewGroup(data): Observable<any> {
        return this.apiService.put(GroupApiService.VIEW_GROUPS, data);
    }
}
