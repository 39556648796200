import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, of } from 'rxjs';
import { IQuickAction, IQuickActions } from './quick-action.interface';
import { tap } from 'rxjs/operators';
import { DocumentUploadAndSignDialogService } from '@app/modules/document/document-upload-and-sign-dialog/document-upload-and-sign-dialog.service';
import { SelectTemplateDialogService } from '@app/modules/document/select-template/select-template-dialog.service';

@Injectable({
    providedIn:'root'
})
export class QuickActionService {
    constructor(
        private uploadAndSignService:DocumentUploadAndSignDialogService,
        private selectTemplateService:SelectTemplateDialogService
    ){}
    protected _actions: ReplaySubject<IQuickActions> =
        new ReplaySubject<IQuickActions>(1);
    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get actions$(): Observable<IQuickActions> {
        return this._actions.asObservable();
    }
    /**
     * Settet for navigation
     */
    set actions(val: IQuickActions) {
        this._actions.next(val);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get(): Observable<IQuickActions> {
        return of([
            {
                label:'Upload & Sign',
                description:'Allow to sign with Singpass',
                icon:'assets/images/icons/quick-action/upload-document.svg',
                click:(event:any) => {
                    this.uploadAndSignService.openDialog()
                }
            },
            {
                label:'New Document', 
                icon:'assets/images/icons/quick-action/new-document.svg',
                children:[
                    {
                        label:'Blank Document',
                        link:'document/create'
                    },
                    {
                        label:'From Template',
                        click:(event:any) => {
                            this.selectTemplateService.openDialog()
                        }
                    }
                ]
            },
            {
                label:'Client',
                icon:'assets/images/icons/quick-action/client.svg',
                children:[
                    {
                        label:'Individual',
                        link:'client/create/individual'
                    },
                    {
                        label:'Company',
                        link:'client/create/company'
                    }
                ]
            },
            {
                label:'Property',
                icon:'assets/images/icons/quick-action/property.svg',
                link:'item/create'
            },
        ]).pipe(
            tap((actions) => {
              this._actions.next(actions);
            })
          )
    }
}
