import { Directive, EventEmitter, HostListener, Input, Output, ElementRef, OnDestroy } from '@angular/core';

@Directive({
    selector: '[appHovered]'
})
export class HoveredDirective implements OnDestroy {
    @Output() appHovered: EventEmitter<void> = new EventEmitter<void>();
    constructor(
        private elementRef: ElementRef
    ) { }
    ngOnDestroy(): void {
        this.appHovered.complete();
    }
    @HostListener('mouseenter',) onEnter() {
        console.log('heelp mouseenter');
        this.elementRef.nativeElement.classList.add('app-hovered');
    }

    @HostListener('mouseleave',) onLeave() {
        console.log('heelp mouseenter');
        this.elementRef.nativeElement.classList.remove('app-hovered');
    }
}
