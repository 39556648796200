import { Pipe, PipeTransform } from '@angular/core';
type ValueOrFunction = string | ((value: any)=> boolean);
@Pipe({
    name: 'filter'
})
export class FilterPipe implements PipeTransform {

    transform(items: any[], field: string | string[], valueOrCompareWithFn: ValueOrFunction): any[] {
        if (!items) { return []; }
        if (typeof valueOrCompareWithFn !== 'function' && !valueOrCompareWithFn || valueOrCompareWithFn.length === 0) { 
            return items; 
        }
        const compareWithFn = typeof valueOrCompareWithFn === 'function' ? valueOrCompareWithFn : (value:any) =>  value.toLocaleLowerCase().includes(valueOrCompareWithFn.toLocaleLowerCase())
        
        if (typeof field === 'string') {
            return items.filter(it => compareWithFn(it[field]))
                // it[field].toLowerCase().indexOf(value.toLowerCase()) !== -1);
        } else {
            return items.filter(it =>
                field.reduce((sum, next) => sum || compareWithFn(it[next]) , false));
        }

    }

}
