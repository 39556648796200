import { Injectable } from '@angular/core';
import { BasePolicyCollection } from '../permission/base.policy-collection';
import { Policies } from '../permission/policy.constant';

@Injectable()
export class EcddPolicyCollection extends BasePolicyCollection {
    readonly policyCollectionKey = 'ecdd-hutton';

    accessMyUrl() {
        return (
            this.accessUrl() ||
            this.permissionService.hasPolicy(
                this.policyCollectionKey + '::viewOwner'
            )
        );
    }
}
