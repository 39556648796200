import { Expose, Exclude, Type } from 'class-transformer';
import { Default } from '@app/core/decorators/default.decorator';
import { GroupModel } from '@shared/models/group.model';
import { UserModel } from '@shared/models/user.model';

export interface UserGroupInterface {
    groups: GroupModel[];
}

export class UserGroupModel extends UserModel implements UserGroupInterface {
    @Default([])
    @Type(() => GroupModel)
    groups: GroupModel[];
}
