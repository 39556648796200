import { Pipe, PipeTransform } from "@angular/core";
import { NavigationItemTitle } from "./navigation.types";

@Pipe({
    name: 'navItemTitle',
})
export class NavigationItemTitlePipe implements PipeTransform{
    transform(value: NavigationItemTitle, ...pipeChains: PipeTransform[]) {
        let text =value;
        let canTransform = true
        if(typeof value === 'object'){
            text = value.text;
            canTransform = !value.disableTransform
        }
        return canTransform ? pipeChains.reduce((currentText,pipe)=> {
            return pipe.transform(currentText)
        },text) : text
    }

}