import {
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    Renderer2,
    OnInit, HostListener
} from '@angular/core';
import { ApiOptionInterface } from '@app/core/models/api-option.interface';
import { StorageApiService } from '@shared/services/storage-api.service';

export interface ImageRender {
    element: ElementRef;
    renderer: Renderer2;
    attribute: string;
    render: (url: string) => void;
}

export class ImageRenderModel implements ImageRender {
    element: ElementRef<any>;
    renderer: Renderer2;
    attribute: string;

    constructor(el: ElementRef, renderer: Renderer2) {
        this.element = el;
        this.renderer = renderer;
    }

    render(url: string): void {
    }

}

export class HTMLImageRender extends ImageRenderModel {
    attribute = 'src';

    render(url: string): void {
        this.renderer.setAttribute(this.element.nativeElement, this.attribute, url);
    }
}

export class HTMLDivRender extends ImageRenderModel {
    attribute = 'background-image';

    render(url: string): void {
        this.renderer.setStyle(this.element.nativeElement, this.attribute, `url(${url})`);
    }
}

@Directive({
    selector: '[appLoadImage]'
})
export class LoadImageDirective implements OnChanges {
    @Input() key: string;
    @Input() enableLoader = false;
    @Output() info: EventEmitter<any> = new EventEmitter<any>();
    @Input() urlDefault = 'assets/images/icons/picture.svg';
    private imageLoader: ImageRenderModel;

    @HostListener('error')
    onLoadImageError() {
        this.imageLoader.render(this.urlDefault);
    }

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private storageApiService: StorageApiService
    ) {
        if (this.elementRef.nativeElement instanceof HTMLImageElement) {
            this.imageLoader = new HTMLImageRender(this.elementRef, this.renderer);
        } else {
            this.imageLoader = new HTMLDivRender(this.elementRef, this.renderer);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.key.currentValue && changes.key.currentValue !== changes.key.previousValue) {
            const requestOption: ApiOptionInterface = {
                loader: this.enableLoader
            };
            this.storageApiService.getPublicLink({ keyName: changes.key.currentValue }, requestOption).subscribe((info => {
                this.imageLoader.render(info);
                this.info.emit(info);
            }),
                error => {
                    this.imageLoader.render(this.urlDefault);
                });
        } else {
            this.imageLoader.render(this.urlDefault);
        }
    }
}
