import { Injectable } from '@angular/core';
import { ApiOptionInterface } from '@app/core/models/api-option.interface';
import { ApiService } from '@app/core/services/api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    VendorPaginationAdapter
} from '../adapters/vendor-pagination.adapter';
import {
    VendorModel
} from '../models/vendor.model';

@Injectable({
    providedIn: 'root',
})
export class VendorApiService {
    static VENDOR = 'vendors';

    constructor(private apiService: ApiService) {}

    search(query = {}): Observable<VendorPaginationAdapter> {
        return this.apiService
            .get(VendorApiService.VENDOR, query)
            .pipe(map((data) => new VendorPaginationAdapter(data)));
    }
    create(data: any): Observable<VendorModel> {
        return this.apiService
            .post(VendorApiService.VENDOR, data)
            .pipe(
                map(
                    (res) =>
                        VendorModel.fromJson(
                            res
                        ) as VendorModel
                )
            );
    }
   
    findById(
        id: number,
        option?: ApiOptionInterface
    ): Observable<VendorModel> {
        return this.apiService
            .get(
                VendorApiService.VENDOR + '/' + id,
                {},
                option
            )
            .pipe(
                map(
                    (res) =>
                        VendorModel.fromJson(
                            res
                        ) as VendorModel
                )
            );
    }

    update(data): Observable<any> {
        return this.apiService.patch(
            VendorApiService.VENDOR,
            data
        );
    }

    delete(id: number, option?: ApiOptionInterface): Observable<any> {
        return this.apiService.delete(
            VendorApiService.VENDOR,
            { vendorId: id },
            option
        );
    }
   
}
