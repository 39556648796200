import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DocumentPaginationAdapter } from '../adapters/document-pagination.adapter';
import { DocumentInterface, DocumentModel, DocumentPublicLink } from '../models/document.model';
import { TemplateMappingModel} from '../models/template-mapping.model';
import { AuditTrailModel } from '@shared/models/audit-trail.model';
import { AppConstant } from '@app/app.constant';
import { ApiOptionInterface } from '@app/core/models/api-option.interface';
import { SettingModel } from '../models/setting.model';
import { ContractGeneralReminderModel } from '../models/contract-reminder.model';
import { HTTP_STATUS_CODE } from '@app/core/common/http-status-code';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class DocumentApiService {
    constructor(protected apiService: ApiService,private httpClient:HttpClient) {}

    static DOCUMENT_SERVICE = 'contracts';
    static DOCUMENT_UPLOAD = 'contracts/upload';
    static DOCUMENT_UPDATE_URL = 'contracts/:id';
    static PUBLIC_LINKS = 'contracts/:id/signing-links'
    static ADD_ATTACHMENT = 'contracts/:id/attachment';
    static DELETE_ATTACHMENT = 'contracts/:contractId/attachment/:attachmentId';
    static START = 'contracts/:id/start';
    static DOCUMENT_PREVIEW = 'contracts/preview/:id/:type';
    static DOCUMENT_PREVIEW_ORIGIN =
        'contracts/preview/:id/pdf/e-signing/origin';
    static ABORT = 'contracts/:id/abort';
    static AUDIT_TRAIL = 'contracts-audit-trails/:id';
    static FORCE_COMPLETE = 'contracts/:id/manual-complete';
    static CONTRACTS_SETTINGS = 'contracts-settings';
    static CONTRACTS_SHARE = 'contracts/:id/share';
    static RESEND_CONTRACT = 'contracts/:contractId/resend-invitation';
    static CONTRACT_AUDIT = 'contracts-audit-trails/manual-audit';
    static CONTRACT_REMINDER_OF_CONTRACT =
        'contracts-reminders/contract/:contractId';
    static CONTRACT_REMINDER = 'contracts-reminders';
    static CONTRACT_REMINDER_ID = 'contracts-reminders/:id';
    static DELETE_CONTRACT = 'contracts/:id/delete';
    static UPDATE_PARTICIPANT = 'contracts/:contractId/participants/:participantId';


    getDocuments(
        query = {},
        option?: ApiOptionInterface
    ): Observable<DocumentPaginationAdapter> {
        return this.apiService
            .get(DocumentApiService.DOCUMENT_SERVICE, query, option)
            .pipe(map((data) => new DocumentPaginationAdapter(data)));
    }

    getAuditTrail(documentId: string): Observable<AuditTrailModel[]> {
        return this.apiService
            .get(DocumentApiService.AUDIT_TRAIL, { id: documentId })
            .pipe(
                map((res) =>
                    res.map(
                        (v) => AuditTrailModel.fromJson(v) as AuditTrailModel
                    )
                )
            );
    }

    findById(documentId: string): Observable<DocumentModel> {
        return this.apiService
            .get(DocumentApiService.DOCUMENT_SERVICE + '/' + documentId + '/*')
            .pipe(map((res) => DocumentModel.fromJson(res) as DocumentModel));
    }

    update(data): Observable<any> {
        return this.apiService.patch(
            DocumentApiService.DOCUMENT_UPDATE_URL,
            data,
            {
                excludeFields: [
                    'fields',
                    'participants',
                    'metadata',
                    'items',
                    'content',
                ],
            }
        );
    }
    getPublicLinks(documentId:string):Observable<DocumentPublicLink[]>{
        return this.apiService.post(DocumentApiService.PUBLIC_LINKS,{id:documentId}).pipe(
            map(links => links.map(link => DocumentPublicLink.fromJson(link)))
        )
    }

    addAttachment(data): Observable<any> {
        return this.apiService.post(DocumentApiService.ADD_ATTACHMENT, data);
    }

    create(data): Observable<DocumentModel> {
        // Config signing's url when send document for signer
        // data.signingPortal = AppConstant.APP_BASE_URL + '/document-signing';
        // console.log(data.signingPortal)
        return this.apiService
            .post(DocumentApiService.DOCUMENT_SERVICE, data, {
                excludeFields: [
                    'fields',
                    'participants',
                    'metadata',
                    'items',
                    'content',
                ],
            })
            .pipe(map((res: DocumentInterface) => DocumentModel.fromJson(res)));
    }

    upload(data): Observable<DocumentModel> {
        const newHeader = this.apiService.headers.delete('Content-Type');
        console.log('data --> ',data)
        return this.apiService
            .post(DocumentApiService.DOCUMENT_UPLOAD, data, {
                excludeFields: [
                    'fields',
                    'participants',
                    'metadata',
                    'items',
                    'content',
                ],
                requestOptions: {
                    headers: newHeader,
                },
            })
            .pipe(map((res: DocumentInterface) => DocumentModel.fromJson(res)));
    }

    start(data): Observable<any> {
        return this.apiService.post(DocumentApiService.START, data);
    }

    previewDocument(
        data: { id?: string | number; type?: 'pdf' | 'html' },
        options: ApiOptionInterface = {}
    ): Observable<any> {
        return this.apiService.get(DocumentApiService.DOCUMENT_PREVIEW, data, {
            ...options,
            pretreatmentResponse: false,
            requestOptions: {
                responseType: data.type === 'pdf' ? 'arraybuffer' : 'text',
            },
        });
    }

    previewPdfOrigin(
        data: { id?: string },
        options: ApiOptionInterface = {}
    ): Observable<any> {
        return this.apiService.get(
            DocumentApiService.DOCUMENT_PREVIEW_ORIGIN,
            data,
            {
                ...options,
                pretreatmentResponse: false,
                requestOptions: { responseType: 'arraybuffer' },
            }
        );
    }

    deleteAttachment(data): Observable<any> {
        return this.apiService.delete(
            DocumentApiService.DELETE_ATTACHMENT,
            data
        );
    }

    abort(data: { id: string }) {
        return this.apiService.post(DocumentApiService.ABORT, data);
    }

    forceComplete(data: { id: string; reason: string }): Observable<any> {
        return this.apiService.post(DocumentApiService.FORCE_COMPLETE, data);
    }

    createOrUpdateSetting(data: any): Observable<any> {
        return this.apiService.post(
            DocumentApiService.CONTRACTS_SETTINGS,
            data
        );
    }

    getSettingByKey(keyName: string): Observable<any> {
        // return this.apiService.get(
        //     DocumentApiService.CONTRACTS_SETTINGS + `/${keyName}`,
        //     {},
        //     {
        //         exposeHeaders: {
        //             [AppConstant.HTTP_ERROR.HTTP_STATUS_CODE_EXCLUDE]: ['404'],
        //         },
        //     }
        // );

        return this.apiService.get(
            DocumentApiService.CONTRACTS_SETTINGS + `/${keyName}`,
            {},
            {
                exposeHeaders: {
                    [AppConstant.HTTP_ERROR.HTTP_STATUS_CODE_EXCLUDE]: [
                        '404',
                    ],
                },
            }
        )
        .pipe(
            map((res) =>
                TemplateMappingModel.fromJson(res)
                ),
            catchError((error) => {
                console.log('party getSettingByKey --> ',error)
                if (error.statusCode === HTTP_STATUS_CODE.NOT_FOUND) {
                    return this.httpClient.get(`assets/data/${keyName}.json`).pipe(
                        map(res => TemplateMappingModel.fromJson(res))
                    );
                }
                return throwError(error);
            }),
           
        );
    }

    getSettingFromJson(): Observable<any> {
        return this.apiService
            .get(
                DocumentApiService.CONTRACTS_SETTINGS +
                    `/system-field-mapping.json`
            )
            .pipe(map((data) => SettingModel.fromJson(data)));
    }

    share(data: {
        id: string;
        users: {
            email: string;
            name: string;
            role: string; // view
            metadata: {
                emailSubject: string;
                emailBody: string;
            };
        }[];
    }): Observable<any> {
        return this.apiService.post(DocumentApiService.CONTRACTS_SHARE, data);
    }

    resend(data: {
        contractId: number;
        participantId: number;
        emailSubject?: string;
        emailBody?: string;
    }): Observable<any> {
        return this.apiService.post(DocumentApiService.RESEND_CONTRACT, data);
    }

    manualAudit(data) {
        return this.apiService.post(DocumentApiService.CONTRACT_AUDIT, data);
    }

    getReminders(contractId: number | string) {
        return this.apiService
            .get(DocumentApiService.CONTRACT_REMINDER_OF_CONTRACT, {
                contractId,
            })
            .pipe(
                map((data: any[]) =>
                    data.map((item) =>
                        ContractGeneralReminderModel.fromJson(item)
                    )
                )
            );
    }

    addReminder(reminder: any) {
        return this.apiService
            .post(DocumentApiService.CONTRACT_REMINDER, reminder)
            .pipe(map((data) => ContractGeneralReminderModel.fromJson(data)));
    }
    updateParticipantEmail(contractId:number,participantId:number, email:string):Observable<any>{
        return this.apiService.post(DocumentApiService.UPDATE_PARTICIPANT,{contractId,participantId,email})
    }
    updateReminder(reminderId: string | number, info: any) {
        return this.apiService.patch(DocumentApiService.CONTRACT_REMINDER_ID, {
            id: reminderId,
            ...info,
        });
    }

    deleteReminder(reminderId: string | number) {
        return this.apiService.delete(DocumentApiService.CONTRACT_REMINDER_ID, {
            id: reminderId,
        });
    }

    deleteContract(contractId: string | number) {
        return this.apiService.delete(DocumentApiService.DELETE_CONTRACT, {
            id: contractId,
        });
    }
}
