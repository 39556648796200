<div class="app-log-out-modal layout__main-modal-2">
    <img src="../../../../assets/images/icons/sign_out.svg" class="log-out-img">
    <div class="dialog-title">
        <div class="title">{{'common.sign_out' | translate}}</div>
        <div class="sub-title">{{'common.sign_out_message' | translate}}</div>
    </div>
    <div>
        <button mat-flat-button type="button" class="margin-right-40"
                (click)="logout()">{{'common.sign_out' | translate}}</button>
        <button (click)="cancel()" mat-flat-button color="primary" type="button"
                matDialogClose>{{'common.cancel' | translate}}</button>
    </div>
</div>
