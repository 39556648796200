import { BaseModel } from '@core/models/base.model';
import { ItemPriceLabelInterface, ItemPriceLabelModel } from './item-price-label.model';
import { Expose, Exclude } from 'class-transformer';

export interface ItemPriceInterface {
    id: number;
    price: number;
    priority: number;
    status: string;
    priceLabel: ItemPriceLabelInterface;
}
@Exclude()
export class ItemPriceModel extends BaseModel implements ItemPriceInterface {
    @Expose()
    id: number;
    @Expose()
    price: number;
    @Expose()
    priority: number;
    @Expose()
    status: string;
    @Expose()
    priceLabel: ItemPriceLabelModel;
}
