
export const DICTRICT = { 
    d1: {
        value: ['01', '02', '03', '04', '05', '06'],
        name: '01',
    },
    d2: {
        value: ['07', '08'],
        name: '02',
    },
    d3: {
        value: ['14', '15', '16'],
        name: '03',
    },
    d4: {
        value: ['09', '10'],
        name: '04',
    },
    d5: {
        value: ['11', '12', '13'],
        name: '05',
    },
    d6: {
        value: ['17'],
        name: '06',
    },
    d7: {
        value: ['18', '19'],
        name: '07',
    },
    d8: {
        value: ['20', '21'],
        name: '08',
    },
    d9: {
        value: ['22', '23'],
        name: '09',
    },
    d10: {
        value: ['24', '25', '26', '27'],
        name: '10',
    },
    d11: {
        value: ['28', '29', '30'],
        name: '11',
    },
    d12: {
        value: ['31', '32', '33'],
        name: '12',
    },
    d13: {
        value: ['34', '35', '36', '37'],
        name: '13',
    },
    d14: {
        value: ['38', '39', '40', '41'],
        name: '14',
    },
    d15: {
        value: ['42', '43', '44', '45'],
        name: '15',
    },
    d16: {
        value: ['46', '47', '48'],
        name: '16',
    },
    d17: {
        value: ['49', '50', '81'],
        name: '17',
    },
    d18: {
        value: ['51', '52'],
        name: '18',
    },
    d19: {
        value: ['53', '54', '55', '82'],
        name: '19',
    },
    d20: {
        value: ['56', '57'],
        name: '20',
    },
    d21: {
        value: ['58', '59'],
        name: '21',
    },
    d22: {
        value: ['60', '61', '62', '63','64'],
        name: '22',
    },
    d23: {
        value: ['65', '66', '67', '68'],
        name: '23',
    },
    d24: {
        value: ['69', '70', '71'],
        name: '24',
    },
    d25: {
        value: ['72', '73'],
        name: '25',
    },
    d26: {
        value: ['77', '78'],
        name: '26',
    },
    d27: {
        value: ['75', '76'],
        name: '27',
    },
    d28: {
        value: ['79', '80'],
        name: '28',
    }
};
