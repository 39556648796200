import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged, filter, map, mergeMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SEOService {

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private title: Title
    ) {
    }

    listenRouterChange(): void {
        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map(() => this.activatedRoute),
            map((route) => {
                while (route.firstChild) {
                    route = route.firstChild;
                }
                return route;
            }),
            filter((route) => route.outlet === 'primary'),
            mergeMap((route) => route.data),
            distinctUntilChanged((prev, curr) => {
                const prevKeys = Object.keys(prev);
                const currKeys = Object.keys(curr);
                if (prevKeys.length !== currKeys.length) {
                    return false;
                }
                for (let i = 0; i < currKeys.length; i++) {
                    const key = currKeys[i];
                    if (prev[key] !== curr[key]) {
                        return false;
                    }
                }
                return true;
            })
        ).subscribe(event => {
            // console.log('route event --->', event);
        });
    }

    updateTitle(title: string): void {
        this.title.setTitle(title);
    }
}
