import { StatusLabelModule } from './status-label/status-label.module';
import { NgModule } from '@angular/core';
import { ComingSoonComponent } from '@modules/shared/coming-soon/coming-soon.component';
import { ForbiddenComponent } from '@modules/shared/forbidden/forbidden.component';
import { NotFoundComponent } from '@modules/shared/not-found/not-found.component';
import { EmptyComponent } from '@modules/shared/empty/empty.component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LogOutModalComponent } from '@modules/shared/log-out-modal/log-out-modal.component';
import { ConfirmModalComponent } from '@modules/shared/confirm-modal/confirm-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { BreadcrumbComponent } from '@modules/shared/breadcumb/breadcrumb.component';
import { SearchComponent } from './search/search.component';
import { BadgeComponent } from './badge/badge.component';
import { TextTruncateComponent } from './text-truncate/text-truncate.component';
import { StatusLabelComponent } from './status-label/status-label.component';
import { AttachmentTableComponent } from './attachment-table/attachment-table.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DatetimePickerComponent } from './datetime-picker/datetime-picker.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SmallEmptyComponent } from './small-empty/small-empty.component';
import { InputAutoVerticalComponent } from './input-auto-vertical/input-auto-vertical.component';
import { CustomToastComponent } from '@modules/shared/custom-toast/custom-toast.component';
import { WalkthroughModalComponent } from './walkthrough-modal/walkthrough-modal.component';
import { WelcomeModalComponent } from './welcome-modal/welcome-modal.component';
import { PipesModule } from '@app/shared/pipes/pipes.module';
import { MatDialogModule } from '@angular/material/dialog';
import { WhatNewModalComponent } from './what-new-modal/what-new-modal.component';
import { FeedbackModalComponent } from './feedback-modal/feedback-modal.component';
import { UploadModule } from '../upload/upload.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Forbidden2Component } from './forbidden-2/forbidden-2.component';
import { ImportSuccessModalComponent } from './import-success-modal/import-success-modal.component';
import { RatingBarComponent } from './rating-bar/rating-bar.component';
import { ServiceUnavailableComponent } from './service-unavailable/service-unavailable.component';
import { RequestSubscriptionModalComponent } from './request-subscription-modal/request-subscription-modal.component';
import { CaseSubmissionCardComponent } from './case-submission-card/case-submission-card.component';
import { PropertySearchComponent } from './property-search/property-search.component';
import { ClientSearchComponent } from './client-search/client-search.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DirectivesModule } from '@app/shared/directives/directives.module';
import { ErrorComponent } from './error/error.component';
import { MatMenuModule } from '@angular/material/menu';
import { EmptyComponentModule } from './empty/empty.module';
import { BadgeModule } from './badge/badge.module';
import { SearchModule } from './search/search.module';
import { ConfirmModalModule } from './confirm-modal/confirm-modal.module';
import { DatetimePickerModule } from './datetime-picker/datetime-picker.module';
import { MatDividerModule } from '@angular/material/divider';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        TranslateModule,
        FlexLayoutModule,
        MatIconModule,
        MatButtonModule,
        MatTableModule,
        MatSortModule,
        MatDatepickerModule,
        NgxMaterialTimepickerModule,
        MatInputModule,
        MatFormFieldModule,
        PipesModule,
        MatDialogModule,
        UploadModule,
        MatTooltipModule,
        MatAutocompleteModule,
        DirectivesModule,
        MatMenuModule,
        EmptyComponentModule,
        BadgeModule,
        SearchModule,
        StatusLabelModule,
        ConfirmModalModule,
        DatetimePickerModule,
        MatDividerModule,
    ],
    declarations: [
        ComingSoonComponent,
        ForbiddenComponent,
        NotFoundComponent,

        // Modals
        LogOutModalComponent,
        BreadcrumbComponent,
        TextTruncateComponent,
        AttachmentTableComponent,
        SmallEmptyComponent,
        InputAutoVerticalComponent,
        CustomToastComponent,
        WalkthroughModalComponent,
        WelcomeModalComponent,
        WhatNewModalComponent,
        FeedbackModalComponent,
        Forbidden2Component,
        ImportSuccessModalComponent,
        RatingBarComponent,
        ServiceUnavailableComponent,
        RequestSubscriptionModalComponent,
        CaseSubmissionCardComponent,
        PropertySearchComponent,
        ClientSearchComponent,
        ErrorComponent,
    ],
    exports: [
        ComingSoonComponent,
        ForbiddenComponent,
        NotFoundComponent,
        EmptyComponent,
        BreadcrumbComponent,
        SearchComponent,
        BadgeComponent,
        TextTruncateComponent,
        StatusLabelComponent,
        AttachmentTableComponent,
        SmallEmptyComponent,
        InputAutoVerticalComponent,
        CustomToastComponent,
        FeedbackModalComponent,
        Forbidden2Component,
        ImportSuccessModalComponent,
        RatingBarComponent,
        RequestSubscriptionModalComponent,
        CaseSubmissionCardComponent,
        PropertySearchComponent,
        ClientSearchComponent,
        ErrorComponent,
        DatetimePickerModule,
    ],
    providers: [],
})
export class SharedComponentModule {}
