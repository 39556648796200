import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-confirm-modal',
    templateUrl: './confirm-modal.component.html',
    styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {
    title = '';
    message = '';
    action: 'confirm' | 'delete' | 'error' | 'notify';
    cancelLabel: string;
    confirmLabel: string;
    disableCancel: boolean;
    disableConfirm: boolean;

    constructor(
        protected dialogRef: MatDialogRef<ConfirmModalComponent>,
        @Inject(MAT_DIALOG_DATA) public modalData: any
    ) {
        this.title = this.modalData.title;
        this.message = this.modalData.message;
        this.cancelLabel = this.modalData.cancelLabel
            ? this.modalData.cancelLabel
            : 'common.cancel';
        this.confirmLabel = this.modalData.confirmLabel
            ? this.modalData.confirmLabel
            : 'common.confirm';
        this.disableCancel = this.modalData.disableCancel ? this.modalData.disableCancel : false;
        this.disableConfirm = this.modalData.disableConfirm ? this.modalData.disableConfirm : false;
        this.action = this.modalData.action ? this.modalData.action : 'confirm';
    }

    ngOnInit() { }

    close(confirm?) {
        this.dialogRef.close(confirm);
    }
}
