import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DocumentUploadAndSignDialogComponent } from "./document-upload-and-sign-dialog.component";
import { DocumentModel } from "@app/shared/models/document.model";
import { Router } from "@angular/router";
import { DocumentTemplateModel } from "@app/shared/models/document-template.model";

@Injectable()
export class DocumentUploadAndSignDialogService {
  constructor(private dialog: MatDialog, private router: Router) {}
  openDialog(afterClosed?: (res: DocumentModel) => void): void {
    const dialogRef = this.dialog.open(DocumentUploadAndSignDialogComponent, {
      maxWidth: 600,
      width: "100%",
      autoFocus: false,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((res: DocumentModel) => {
      if (!res) {
        return;
      }
      if (afterClosed) {
        afterClosed(res);
      } else {
        if (res.contentType === "jsonM") {
          this.router.navigate(["/document", res.id, "edit"]);
        } else {
          this.router.navigate(["/document/pdf/" + res.id]);
        }
      }

      // if (res) {
      //     this.router.navigate(['/document/pdf/' + res.id]);
      // }
    });
  }
}
