import { DynamicField } from './dynamic-field.model';

export class DynamicFormDropDown extends DynamicField {
    controlType = 'dropdown';
    options: string[] = [];

    constructor(options: {} = {}) {
        super(options);
        this.options = options['options'] || [];
    }

    getAttributePayloadForCk(): { attributeKey: string; value: string }[] {
        const payloadDefault = super.getAttributePayloadForCk();
        payloadDefault.push(
            {
                attributeKey: 'metadata',
                value: this.options ? this.options.join('||') : ''
            }
        );
        return payloadDefault;
    }
}
