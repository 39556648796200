import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AppConstant } from '@app/app.constant';
import { of } from 'rxjs';
import { GlobalService } from '../services/global.service';

@Injectable({ providedIn: 'root' })
export class NonAuthResolver implements Resolve<any> {

    constructor(
        private globalService: GlobalService,
    ) { }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        /* Prevent logout when another tab logout */
        this.globalService.storage.dispatch(AppConstant.GLOBAL_STORAGE.LOGIN_STATUS, false);
        return of(true);
    }
}
