import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { AppConstant } from '@app/app.constant';
import { OrganizationApiService } from '@shared/services/organization-api.service';
import { GlobalService } from '../services/global.service';
import { map, catchError } from 'rxjs/operators';
import { AuthService } from '@app/modules/auth/auth.service';
import { Location } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class OrganizationResolver implements Resolve<any> {
    constructor(
        private router: Router,
        private globalService: GlobalService,
        private organizationApiService: OrganizationApiService,
        private authService: AuthService,
        private location: Location,
    ) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        console.log('OrganizationResolver')
        return this.organizationApiService.getDetail().pipe(map(data => {
            this.globalService.storage.dispatch(AppConstant.GLOBAL_STORAGE.ORGANIZATION, data);
            this.globalService.storage.add(AppConstant.GLOBAL_STORAGE.BUSINESS_UNITS, data.businessUnits);
            const currentBU = data.businessUnits.find(item => item.isActive);
            if (currentBU) {
                this.globalService.storage.add(AppConstant.GLOBAL_STORAGE.BUSINESS_UNIT, currentBU);
            }
        }), catchError(error => {
            if(error && typeof error === 'object'){
                if (error.errorCode === 'ORG001') {
                    this.router.navigate(['403']);
                    /* Should set a timeout in here. Avoid effect to requests which are run parallel with it */
                    setTimeout(() => {
                        this.authService.logout();
                        this.location.replaceState('/');
                    }, 1000);
                } else {
                    return this.router.navigateByUrl('initiation/setup-account');
                }
            }else{
                 return throwError( error)
            }
            
        }));
    }
}
