export const ITEM_TYPES = [
    { key: 'condominium-or-apartment', value: 'Condominium or Apartment' },
    { key: 'landed', value: 'Landed' },
    { key: 'strata-landed', value: 'Strata Landed' },
    { key: 'executive-condominium', value: 'Executive Condominium' },
    { key: 'hdb', value: 'HDB' },
    { key: 'others', value: 'Others' },
    { key: 'residential-land', value: 'Residential Land' },
    { key: 'industrial', value: 'Industrial' },
    { key: 'commercial-land', value: 'Commercial Land' },
    { key: 'retail', value: 'Retail' },
    { key: 'office', value: 'Office' },
    { key: 'mixed-development', value: 'Mixed Development' },
];

export const SQUAREFOOT_PROPERTY_TYPES = {
    'condominium-or-apartment': 'r',
    landed: 'l',
    'executive-condominium': 'r',
    'strata-landed': 'r',
    hdb: 'h'
};
