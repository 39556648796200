import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "templateType",
})
export class TemplateTypePipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case "private":
        return "document_template.private";
      case "o::ro":
        return "document_template.org_read_only";
      case "o::r,e":
        return "document_template.org_editable";
      default:
        return value;
    }
  }
}
