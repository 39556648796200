import { Injectable } from '@angular/core';
import { AppConstant } from '@app/app.constant';
import { OrganizationModel } from '@app/shared/models/organization.model';
import { SubscriptionModel } from '@app/shared/models/subscription.model';
import { UserModel } from '@app/shared/models/user.model';
import { GlobalService } from './global.service';


@Injectable({
    providedIn: 'root'
})
export class SubscriptionService {
    private organization: OrganizationModel;
    private user: UserModel;
    private subscription: SubscriptionModel;

    constructor(
        private globalService: GlobalService,
    ) {
        this.globalService.storage.watch(AppConstant.GLOBAL_STORAGE.ORGANIZATION)
            .subscribe(data => {
                this.organization = data;
            });

        this.globalService.storage.watch(AppConstant.GLOBAL_STORAGE.USER)
            .subscribe(data => {
                this.user = data;
            });

        this.globalService.storage.watch(AppConstant.GLOBAL_STORAGE.MY_SUBSCRIPTION)
            .subscribe(data => {
                this.subscription = data;
            });
    }

    free(): boolean {
        if (this.subscription?.isFreePlan && this.subscription?.organizationId === +this.organization?.id) {
            return true;
        }

        return false;
    }

    // TODO: Inspect the logic later to get better understanding [HUY]
    own(): boolean {
        if (!this.subscription || (this.subscription && (this.subscription.ownerId !== this.user?.id
            || this.subscription.organizationId !== +this.organization?.id))) {
            return false;
        }

        return true;
    }

    getPaymentSuccessUrl(isUpgradePlan = false, interval: 'month' | 'year' = 'month'): string {
        if (isUpgradePlan) {
            return AppConstant.APP_BASE_URL + '/setting/add-payment-success?source=fp&interval=' + interval;
        }

        return AppConstant.APP_BASE_URL + '/setting/add-payment-success';
    }
}
