import { BaseModel } from '@core/models/base.model';
import { Expose, Exclude } from 'class-transformer';

export interface ItemPhotoInterface {
    id?: number;
    type: 'photo' | 'floorPlan';
    order?: number;
    key: string;
}
@Exclude()
export class ItemPhotoModel extends BaseModel implements ItemPhotoInterface {
    @Expose()
    id: number;
    @Expose()
    type: 'photo' | 'floorPlan';
    @Expose()
    order: number;
    @Expose()
    key: string;
}
