import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { LoggerService } from '@core/services/logger.service';

@Injectable({
    providedIn: 'root'
})
export class ErrorService extends ErrorHandler {

    constructor(private loggerService: LoggerService) {
        super();
    }

    static parseMessage(error) {
        if (error && error.statusCode === 404) {
            return ErrorService.joinMessages(error.message);
        } else if (error && error.statusCode === 403) {
            return ErrorService.joinMessages(error.error);
        } else if (error && error.statusCode === 400) {
            return ErrorService.joinMessages(error.error);
        }

        return error.message || error.error;
    }

    static joinMessages(error) {
        let message = '';
        if (typeof error === 'string') {
            return error + '<br>';
        } else if (typeof error === 'object') {
            Object.keys(error).forEach((key) => {
                message += ErrorService.joinMessages(error[key]);
            });
        }

        return message;
    }

    handleError(error) {
        this.handleChunkLoadError(error);
        super.handleError(error);
        this.loggerService.error(error);
    }

    handleChunkLoadError(error) {
        if (error?.message?.includes('ChunkLoadError')) {
            location.reload();
        }
    }

    extractError(error) {
        // Try to unwrap zone.js error.
        // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
        if (error && error.ngOriginalError) {
            error = error.ngOriginalError;
        }

        if (error instanceof HttpErrorResponse) {
            if (error.error instanceof Error) {
                return error.error;
            }

            if (error.error instanceof ErrorEvent && error.error.message) {
                return error.error.message;
            }

            if (typeof error.error === 'string') {
                return 'Server returned code ' + error.status + ' with body "' + error.error + '"';
            }

            return error.message;
        }

        return error;
    }
}
