import { Injectable } from '@angular/core';
import { AppConstant } from '@app/app.constant';
import { GlobalService } from '@core/services/global.service';
import { LocalStorageService } from '@core/services/local-storage.service';
import { Router } from '@angular/router';
import { Location } from "@angular/common";

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(private globalService: GlobalService,
        private router:Router,
        private location: Location,) {

    }

    get isLoggedIn() {
        const isLogged = this.globalService.storage.get(AppConstant.GLOBAL_STORAGE.LOGIN_STATUS);
        const currToken = this.globalService.storage.get(AppConstant.GLOBAL_STORAGE.TOKEN);

        // Prevent load data in localstorage. Just get in globalService storage
        return isLogged && currToken;
    }

    get userInfo() {
        return LocalStorageService.getUserInfo();
    }

    setUserInfo(data) {
        LocalStorageService.setUserInfo(data);
    }

    setLoggedIn(data) {
        LocalStorageService.setLogInStatus(true);
        LocalStorageService.setAccessToken(data.accessToken);
        LocalStorageService.setRefreshToken(data.refreshToken);
        this.globalService.storage.dispatch(AppConstant.GLOBAL_STORAGE.TOKEN, data.accessToken);
        this.globalService.storage.dispatch(AppConstant.GLOBAL_STORAGE.REFRESH_TOKEN, data.refreshToken);
        this.globalService.storage.dispatch(AppConstant.GLOBAL_STORAGE.LOGIN_STATUS, true);
    }

    logout() {
        // LocalStorageService.clear();
        LocalStorageService.removeItem(AppConstant.LOCAL_STORAGE_KEYS.TOKEN);
        LocalStorageService.removeItem(AppConstant.LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
        LocalStorageService.removeItem(AppConstant.LOCAL_STORAGE_KEYS.LOGIN_STATUS);
        this.globalService.storage.dispatch(AppConstant.GLOBAL_STORAGE.TOKEN, null);
        this.globalService.storage.dispatch(AppConstant.GLOBAL_STORAGE.BUSINESS_UNITS, null);
        this.globalService.storage.dispatch(AppConstant.GLOBAL_STORAGE.ORGANIZATION, null);
        this.globalService.storage.dispatch(AppConstant.GLOBAL_STORAGE.GROUPS_PERMISSIONS, null);
    }
    redirectToLogin():void{
        const currentUrl = this.location.path(true);
         this.router.navigate(['/auth/login'], {
          queryParams: { redirectUrl: currentUrl }
      });
    }
}
