import { Injectable } from '@angular/core';
import { BasePolicyCollection } from '@modules/permission/base.policy-collection';
import { Policies } from '../permission/policy.constant';

@Injectable()
export class CaseSubmissionPersonalPolicyCollection extends BasePolicyCollection {
    readonly policyCollectionKey = 'personal-case';

    accessUrl() {
        return this.permissionService.hasPolicy(this.policyCollectionKey + '::viewOwner') &&
            this.permissionService.hasPermissions(Policies[this.policyCollectionKey].own);
    }
}
