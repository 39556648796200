export const environment = {
  baseUrl: "https://agent-dev.redoc.co",
  huttonUrl: "https://huttons-portal-dev.azureedge.net",
  production: false,
  hmr: false,
  apiHost: "https://dev-gateway.redoc.co/",
  storageMediaUrl: "https://m4storagedev.blob.core.windows.net",
  apiPrefix: "/api/",
  storageDrive: "azure",
  editorDebug: false,
  appId: "dev",
  logger: {
    sentryLog: {
      enable: true,
      dns: "https://08917726ff4e3d9efe410f7d8c68cfbb@o434962.ingest.us.sentry.io/4505661418373120",
      debug: false,
      environment:'DEV'
    },
    appInsights: {
      enable: false,
      instrumentationKey: "6ee73849-ae9c-4c0e-b67a-ab9f8f362a3f",
    },
  },
  developerMode: false,
  recaptchaSitekey: "6Le-KEIoAAAAAACItuo5OUNkQcgUDkFBhkoAi813",
  googleAnalytics: {
    trackingIds: ["G-WYTW4WE4MZ"],
  },
  signingMode: "html",
  developFeatures: {},
  defaultTimeZone: "Asia/Singapore",
  blackListEmail: [],
  // https://www.squarefoot.com.sg/api
  squarefootApiToken: "lyc0u1xfdwtj79gkz2bv4ore6iaq8ms5",
  firebaseConfig: {
    apiKey: "AIzaSyAaCSQVLh2as3lVvHJ2e-QsmXqIwx-Byi4",
    authDomain: "redoc-none-production.firebaseapp.com",
    databaseURL:
      "https://redoc-none-production-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "redoc-none-production",
    storageBucket: "redoc-none-production.appspot.com",
    messagingSenderId: "836982514076",
    appId: "1:836982514076:web:0b6b770bbdef39ea5a2859",
  },
  redocCloud: {
    serviceOrigin: "https://redoc-cloud-api.azurewebsites.net",
  },
  redocConnect: {
    issuer: "https://id-dev.redoc.co",
    loginUrl: `https://id-dev.redoc.co/authorize`,
    logoutUrl: `https://id-dev.redoc.co/sign-out`,
    authorizeUrl: `https://id-dev.redoc.co/authorize`,
    redirectUri: window.location.origin + "/auth/login",
    postLogoutRedirectUri:
      window.location.origin + "/auth/login?forcedLogin=true",
    clientId: "dev.agent.redoc",
    responseType: "code",
    scope: "openid profile email api",
    tokenSetConfig: {
      prefix: "redoc_dev",
    },
    sessionConfig: { prefix: "dev" },
    showDebugInformation: true,
  },
};
