import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { IQuickAction, IQuickActions } from "../quick-action.interface";
import { environment } from "environments/environment";
import { Router } from "@angular/router";
import { MatMenu } from "@angular/material/menu";

@Component({
    selector: 'app-quick-action-menu',
    templateUrl: './quick-action-menu.component.html',
    styleUrls: ['./quick-action-menu.component.scss'],
})
export class QuickActionMenuComponent implements OnInit, OnDestroy {
    @Input() actions: IQuickActions;
    @ViewChild('menu',{static:true}) public menu;
    constructor(
        private _router:Router
        ){}
    ngOnDestroy(): void {
    }
    ngOnInit(): void {
    }
    onSelectAction(event,action:IQuickAction):void{
        console.log('onSelectAction --> ',event,action)
        if(action.click){
            action.click(event);
            return;
        }
        if(action.link){
            try {
                const url = new URL(action.link);
                if(environment.baseUrl.includes(url.origin) ){
                    this._router.navigateByUrl(action.link)
                }else{
                    location.href = url.href
                }
            } catch (error) {
                this._router.navigateByUrl(action.link)
            }
            return;
        }
    }
}