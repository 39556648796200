<div class="flex flex-col flex-auto">
    <div *ngIf="!isReadonly" class="case-card__action md:absolute relative flex flex-row items-center justify-end">
        <ng-content select="[card-action]"></ng-content>
        <button mat-icon-button (click)="edit()" *ngIf="allowEdit"><mat-icon>edit</mat-icon></button>
        <button mat-icon-button class="btn-clear" (click)="clear()">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <div class="flex flex-row flex-auto gap-4">
        <div *ngIf="!noAvatar" class="h-10 w-10">
            <ng-content select="[card-avatar]"></ng-content>
        </div>
        <div class="flex flex-col flex-auto">
            <ng-content select="[card-info]"></ng-content>
        </div>
    </div>
</div>


