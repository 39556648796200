import { UserModel } from '@app/shared/models/user.model';
import { UserInterface } from './user.model';
import { FileUploaderModel } from '@app/modules/upload/file-uploader.model';
import { BaseModel } from '@core/models/base.model';
import { Expose, Exclude, Type } from 'class-transformer';

export interface AttachmentInterface {
    id?: string;
    name: string;
    keyPath: string;
    contentType: string;
    drive: string;
    contractId: string;
    userId: string;
    participantId?: string;
    createdBy?: UserInterface;
    createdAt: string;
    updatedAt: string;
    attrs: any;
}

@Exclude()
export class AttachmentModel extends BaseModel implements AttachmentInterface {
    @Expose()
    id: string;

    @Expose()
    name: string;

    @Expose()
    keyPath: string;

    @Expose()
    contentType: string;

    @Expose()
    drive: string;

    @Expose()
    contractId: string;

    @Expose()
    userId: string;

    @Expose()
    participantId: string;

    @Expose()
    attrs: any;

    @Expose()
    @Type(() => UserModel)
    createdBy?: UserModel;

    @Expose()
    createdAt: string;

    @Expose()
    updatedAt: string;

    static fromFileUploaderModel(data: FileUploaderModel): AttachmentModel {
        return AttachmentModel.fromJson({
            name: data.file.name,
            contentType: data.file.type,
            keyPath: data.key,
            attrs: {
                size: data.file.size
            }
        });
    }
}
