<ng-container *ngIf="showSwitchBU">
    <section class="bg-white border-b flex justify-between py-3 px-4">
        <img [routerLink]="['']" class="h-8 cursor-pointer" src="assets/images/red-logo-black.svg">

        <div [matMenuTriggerFor]="accountSetting" class="flex gap-2 items-center cursor-pointer">
            <ng-container *ngIf="user.avatar; else noAvatar">
                <img class="h-8 w-8 rounded-2xl" appLoadImage [key]="user.avatar" />
            </ng-container>
            <ng-template #noAvatar>
                <div
                    class="h-8 w-8 rounded-sm flex"
                    [ngStyle]="user?.profile?.color && {
                        'background-color': user?.profile?.color
                    }"
                >
                    <span class="m-auto">
                        {{ user.defaultAvatar }}
                    </span>
                </div>
            </ng-template>
            <div class="hidden md:inline-block">
                <div class="flex">
                    <span class="font-semibold m-auto text-s2">{{ user.name }}</span>
                </div>
            </div>
            <span class="hidden md:inline-block">
                <mat-icon class="icon-size-8">arrow_drop_down</mat-icon>
            </span>
        </div>
    </section>
</ng-container>

<div class="app-request-subscription">
    <ng-container *ngIf="!showSwitchBU">
        <div fxLayout="row" fxLayoutAlign="end center">
            <button type="button" mat-icon-button (click)="close()" *ngIf="!isHideCloseButton">
                <mat-icon>close</mat-icon>
            </button>
            <button type="button" mat-icon-button routerLink="/auth/logout" *ngIf="!isHideLogoutButton" matTooltip="Logout">
                <mat-icon>logout</mat-icon>
            </button>
        </div>
    </ng-container>
    <div class="app-request-subscription-content" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="24px">
        <ng-container *ngIf="!showSwitchBU">
            <div>
                <img src="assets/images/red-logo-black.svg">
            </div>
        </ng-container>
        <div style="padding-top: 16px;">
            <label class="switch-mode-btn">
                <input type="checkbox" [formControl]="inputCtrl" />
                <div class="slider">
                    <div class="slider__mode">
                        <span>Pay Monthly</span>
                    </div>
                    <div class="slider__mode">
                        <span>Pay Annually</span>
                    </div>
                    <div class="slider__switcher">
                        <span>{{interval === 'month' ? 'Pay Monthly' : 'Pay Annually' }}</span>
                    </div>
                    <div class="save">Save up to {{saveUpPercentage}}%</div>
                </div>
            </label>
        </div>
        <div class="block-wrapper">
            <div class="block" *ngFor="let plan of plans">
                <img appLoadImage [key]="plan.attrs?.photo" width="172px" height="172px">

                <div class="block-text-1">{{plan.name}}</div>
                <div class="block-currency">{{ (interval === 'month' ? plan.prices.month : plan.pricePerMonthOfYear) |
                    currency: currency}}</div>
                <div class="block-text-2">per user, per month</div>
                <div class="btn-submit" (click)="upgrade(plan, interval)">
                    Upgrade
                </div>

                <div class="block-content" *ngFor="let item of plan.attrs.intro" fxLayout="row" fxLayoutGap="8px">
                    <mat-icon color="accent">check_circle</mat-icon>
                    <div class="block-content-text">{{item}}</div>
                </div>

            </div>
        </div>
    </div>
</div>

<mat-menu #accountSetting="matMenu">
    <button mat-menu-item [matMenuTriggerFor]="switchMenu">
        Switch Business Unit
    </button>
    <ng-container *ngIf="!isHideLogoutButton">
        <button  routerLink="/auth/logout" mat-menu-item>
            <span>{{ "common.sign_out" | translate }}</span>
        </button>
    </ng-container>
</mat-menu>

<mat-menu #switchMenu="matMenu" class="top-bar-bu-list">
    <div class="title">
        {{ "common.business_unit" | translate }}
    </div>
    <button
        mat-menu-item
        *ngFor="let buItem of buList"
        (click)="switchToOrganization(buItem)"
        [class.mat-menu-item-highlighted]="buItem.id === currentBU?.id"
    >
        <div class="bu-item">
            <div class="bu-logo img-preview img-preview--circle">
                <img appLoadImage [key]="buItem.logo" />
            </div>
            <div class="bu-info">
                <div class="name">{{ buItem.name }}</div>
            </div>
        </div>
    </button>
</mat-menu>
