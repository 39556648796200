import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-input-auto-vertical',
    templateUrl: './input-auto-vertical.component.html',
    styleUrls: ['./input-auto-vertical.component.scss']
})
export class InputAutoVerticalComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}
