import { BaseModel } from '@core/models/base.model';
import { Expose, Exclude } from 'class-transformer';
import { Default } from '@app/core/decorators/default.decorator';

export interface DealTableInterface {
    id: string;
    discountType: string;
    discountValue: number;
    surchargeType: string;
    surchargeValue: number;
    taxType: string;
    taxValue: number;
    subTotalPrices: number;
    totalPrices: number;
    entries: any;
}

@Exclude()
export class DealTableModel extends BaseModel implements DealTableInterface {
    @Expose()
    id: string;
    @Expose()
    discountType: string;
    @Expose()
    @Default(0)
    discountValue: number;
    @Expose()
    surchargeType: string;
    @Expose()
    @Default(0)
    surchargeValue: number;
    @Expose()
    taxType: string;
    @Expose()
    @Default(0)
    taxValue: number;
    @Expose()
    subTotalPrices: number;
    @Expose()
    totalPrices: number;
    @Expose()
    entries: any;
}
