import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-welcome-modal',
    templateUrl: './welcome-modal.component.html',
    styleUrls: ['./welcome-modal.component.scss']
})
export class WelcomeModalComponent implements OnInit {

    constructor(
        protected dialogRef: MatDialogRef<WelcomeModalComponent>,
    ) {

    }

    ngOnInit() { }

    close(isStart: boolean = false) {
        this.dialogRef.close(isStart);
    }
}
