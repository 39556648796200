import { Directive } from '@angular/core';

@Directive({
  selector: '[appStatusLabel]',
})
export class StatusLabelDirective {

  constructor() { }

}
