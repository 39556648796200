import {
    animate,
    keyframes,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { Component } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-custom-toast-component',
    styleUrls: ['./custom-toast.component.scss'],
    templateUrl: './custom-toast.component.html',
    animations: [
        trigger('flyInOut', [
            state('inactive', style({opacity: 0})),
            transition(
                'inactive => active',
                animate(
                    '300ms ease-out',
                    keyframes([
                        style({
                            opacity: 0,
                            bottom: '-15px',
                            'max-height': 0,
                            'max-width': 0,
                            'margin-top': 0,
                        }),
                        style({
                            opacity: 0.8,
                            bottom: '-3px',
                        }),
                        style({
                            opacity: 1,
                            bottom: '0',
                            'max-height': '200px',
                            'margin-top': '12px',
                            'max-width': '400px',
                        }),
                    ]),
                ),
            ),
            state(
                'active',
                style({
                    bottom: '0',
                    'max-height': '200px',
                    'margin-top': '12px',
                    'max-width': '400px',
                }),
            ),
            transition(
                'active => removed',
                animate(
                    '300ms ease-out',
                    keyframes([
                        style({
                            opacity: 1,
                            transform: 'translateY(0)'
                        }),
                        style({
                            opacity: 0,
                            transform: 'translateY(25%)'
                        }),
                    ]),
                ),
            ),
        ]),
    ],
})
export class CustomToastComponent extends Toast {

    constructor(
        protected toastrService: ToastrService,
        public toastPackage: ToastPackage,
    ) {
        super(toastrService, toastPackage);
    }

    action(event: Event) {
        event.stopPropagation();
        this.toastPackage.triggerAction();
        return false;
    }
}
