import { FormFieldBase } from '@app/core/models/form-field-base.model';
import { ContentBuilderFieldType } from '@app/core/types/content-builder-field-type';
export interface ParticipantFieldConfigInterface {
    markupId: string;
    isForward?: boolean;
    html?: string;
}
export class ParticipantFieldConfig implements ParticipantFieldConfigInterface {
    markupId: string;
    isForward?: boolean;
    html: string;

    constructor(data: ParticipantFieldConfigInterface) {
        this.markupId = data && data.markupId;
        this.isForward = data?.isForward;
        this.html = data?.html || '';
    }
}
export class ParticipantField extends FormFieldBase {
    constructor(options = {}) {
        super(options);
        this.config = new ParticipantFieldConfig(options['config']);
        this.count = 1;
    }
    getPlaceHolder(): string {
        const frag = document
            .createRange()
            .createContextualFragment(this.config.html);
        const parent = frag.querySelector('.redr-inline-field');
        let owner = null;

        if (this.config.html) {
            owner = parent.getAttribute('owner');
        }

        // return this.config?.html ? this.config?.html : `<div class="fake-label">${this.label}</div>`;
        // tslint:disable-next-line: max-line-length
        return (
            `<span class="label-fake-mask">${this.label}` +
            (owner
                ? `<span style="margin-left:8px" class="redr-inline-field__tag">${owner}</span>`
                : '') +
            `</span>`
        );
    }

    getMarkerName(): string {
        return `${this.key}`;
    }
}
