import { DynamicFormFile } from './dynamic-form-file.model';
import { Exclude, Expose, Transform } from 'class-transformer';
import { BaseModel } from '@app/core/models/base.model';
import { FormFieldBase } from '@app/core/models/form-field-base.model';
import { DynamicFormTextBox } from './dynamic-form-textbox.model';
import { DynamicFormDropDown } from './dynamic-form-dropdown.model';
import { DynamicFormDateTime } from './dynamic-form-datetime.model';
import { DynamicFormControlLayout, DynamicFormGroupLayout, DynamicFormLayout } from './dynamic-form-layout.model';
import { Default } from '@app/core/decorators/default.decorator';
import { DynamicFormNumber } from './dynamic-form-number.model';
import { SignatureField } from './signature-field.model';
import { ContentBuilderFieldType } from '@app/core/types/content-builder-field-type';
import { DealTableField } from './deal-table-field.model';
import { ParticipantTextField } from './participant-text-field.model';
import { ParticipantDateField } from './participant-date-field.model';
import { ControlInterface } from '@modules/pdf/pdf-editor/controls/control.type';

export interface ContentBuilderFieldInterface {
    type?: string;
    data?: object;
    properties?: { [x: string]: FormFieldBase };
    placeholder?: object;
    layouts?: { [x in ContentBuilderFieldType]?: DynamicFormLayout[] };
    controls?: ControlInterface[];
}
function isBase64Image(str: string): boolean {
    const base64ImageRegex = /^data:image\/(png|jpg|jpeg|gif|bmp|webp);base64,[A-Za-z0-9+/]+={0,2}$/;
    return base64ImageRegex.test(str);
  }
  function isURL(str: string): boolean {
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    try {
      new URL(str); // additional check using URL constructor
      return urlRegex.test(str);
    } catch (_) {
      return false;
    }
  }
export  function isSignatureValue(str:string):boolean{
    return isBase64Image(str) || isURL(str)
  }
@Exclude()
export class ContentBuilderFieldModel extends BaseModel implements ContentBuilderFieldInterface {
    @Expose()
    @Default('jsonSchema')
    type: string;

    @Expose()
    @Default({})
    @Transform((data: any) => {
        if (typeof data === 'string' || Array.isArray(data)) {
            return {};
        }
        return data;
    })
    @Transform((data:object,obj)=> {
        if(!data){
            return {}
        }
        const res:Record<string,any> = {};
        const properties = obj.properties
        Object.keys(data).forEach(key => {
            if(properties[key]?.fieldType === 'signatureField'){
                res[key]= data[key] && isSignatureValue(data[key]) ? data[key] : ''
            }else{
                res[key] = data[key]
            }
        })
        return res
    })
    data: object;

    @Expose()
    @Default({})
    @Transform((data: object) => {
        const properties = {};
        if (data instanceof Object) {
            Object.keys(data).forEach(key => {
                if (data[key].fieldType === 'dynamicField') {
                    switch (data[key].controlType) {
                        case 'textbox':
                            properties[key] = new DynamicFormTextBox(data[key]);
                            break;
                        case 'number':
                            properties[key] = new DynamicFormNumber(data[key]);
                            break;
                        case 'dropdown':
                            properties[key] = new DynamicFormDropDown(data[key]);
                            break;
                        case 'date':
                            properties[key] = new DynamicFormDateTime(data[key]);
                            break;
                        case 'file':
                            properties[key] = new DynamicFormFile(data[key]);
                            break;
                        default:
                            properties[key] = data[key];
                    }
                } else if (data[key].fieldType === 'signatureField') {
                    properties[key] = new SignatureField(data[key]);
                } else if (data[key].fieldType === 'dealTable') {
                    properties[key] = new DealTableField(data[key]);
                } else if (data[key].fieldType === 'participantField') {
                    switch (data[key].controlType) {
                        case 'textbox':
                            properties[key] = new ParticipantTextField(data[key]);
                            break;
                        case 'date':
                            properties[key] = new ParticipantDateField(data[key]);
                            break;
                        default:
                            properties[key] = data[key];
                    }
                }


            });
        }

        return properties;

    })

    properties: { [x: string]: FormFieldBase };

    @Expose()
    @Transform(data => {
        if (data && typeof data === 'object' && !Array.isArray(data)) {
            const layouts = {};
            Object.keys(data).forEach(key => {
                layouts[key] = data[key].map(layout => {
                    layout.type = layout.type || 'control';
                    if (layout.type === 'control') {
                        return new DynamicFormControlLayout(layout);
                    } else {
                        return new DynamicFormGroupLayout(layout);
                    }
                });
            });
            return layouts;
        }
        // if (Array.isArray(data)) {
        //     const layouts = {};
        //     return data.map(layout => {
        //         layout.type = layout.type || 'control';
        //         if (layout.type === 'control') {
        //             return new DynamicFormControlLayout(layout);
        //         } else {
        //             return new DynamicFormGroupLayout(layout);
        //         }
        //     });
        // }
        return {
            dynamicField: [],
            signatureField: [],
            dealTable: [],
            participantField: []
        };

    })
    layouts: { [x in ContentBuilderFieldType]?: DynamicFormLayout[] };


    @Expose()
    placeholder: object;

    @Expose()
    @Default([])
    controls?: ControlInterface[];
}
