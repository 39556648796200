import { Observable, of, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { PreloadingStrategy, Route } from '@angular/router';
import { AppConstant } from '@app/app.constant';
import { Injectable } from '@angular/core';

@Injectable()
export class ModulePreLoading implements PreloadingStrategy {

    preload(route: Route, fn: () => Observable<boolean>): Observable<boolean> {
        if (!AppConstant.APP_PRODUCTION || !route.data || !route.data.preload) {
            return of(null);
        }

        if (route.data && route.data.preload) {
            const delayMilliseconds = route.data.delayMilliseconds || 2000;

            return timer(delayMilliseconds).pipe(mergeMap(_ => fn()));
        }
    }
}
