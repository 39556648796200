import { BaseModel } from '@core/models/base.model';
import { Type } from 'class-transformer';
import { UserProfileInterface, UserProfileModel } from './user-profile.model';
import { Default } from '@app/core/decorators/default.decorator';

export interface UserInterface {
    id?: number;
    firstName: string;
    lastName: string;
    email: string;
    avatar: string;
    lastLogin: string;
    profile: UserProfileInterface;
}

export class UserModel extends BaseModel implements UserInterface {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    lastLogin: string;

    @Default({})
    @Type(() => UserProfileModel)
    profile: UserProfileModel;

    get name(): string {
        return normalizeName(this.firstName, this.lastName);
    }

    get defaultAvatar(): string {
        return (
            (this.firstName ? this.firstName[0] : '') +
            (this.lastName ? this.lastName[0] : '')
        );
    }

    avatar: string;
}
export function normalizeName(firstName: string, lastName: string): string {
    const first = firstName?.trim();
    const last = lastName?.trim();
    return [first,last].filter(item => !!item).join(' ');
}
