import { Inject, Injectable } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import {
  CookieDict,
  CookieOptions,
  buildCookieString,
  isPresent,
  mergeOptions,
  parseCookieString,
} from "./utils";
import { decodeJWT } from "@app/core/common/common.utils";
import { LocalStorageService } from "@app/core/services/local-storage.service";
import { RedocConnectService } from "./redoc-connect.service";
import { environment } from "environments/environment";
@Injectable({
  providedIn: "root",
})
export class RedocConnectSSOService {
  options = {};
  c_user_name: string;
  iss_name: string;
  constructor(
    @Inject(DOCUMENT) private document: any,
    @Inject(RedocConnectService) private redocConnect: RedocConnectService
  ) {
    const session = environment.redocConnect.sessionConfig;
    this.c_user_name = [session ? session.prefix : "", "redoc.c_user"]
      .filter((item) => !!item)
      .join("_");
    this.iss_name = [session ? session.prefix : "", "redoc.iss"]
      .filter((item) => !!item)
      .join("_");
  }
  /**
   * @description
   * Returns if the given cookie key exists or not.
   *
   * @param key Id to use for lookup.
   * @returns true if key exists, otherwise false.
   */
  hasKey(key: string): boolean {
    const value = this.get(key);
    return isPresent(value);
  }
  isLogin(currentToken: string): boolean {
    const { userId,exp } = decodeJWT(currentToken);
    // console.log(decodeJWT(currentToken))
    return String(userId) === String(this.cuid);
  }
  isExpired(currentToken:string):boolean{
    const { exp } = decodeJWT(currentToken);
    return exp * 1000 < new Date().getTime()
  }
  getTokenSet() {
    const currentAccessToken = LocalStorageService.getAccessToken();
    const currentRefreshToken = LocalStorageService.getRefreshToken();
    console.log('currentAccessToken',currentAccessToken,this.get(this.c_user_name))
    const isLogin = currentAccessToken
      ? this.isLogin(currentAccessToken)
      : false;
    const accessToken = isLogin ? currentAccessToken : "";
    const refreshToken = isLogin ? currentRefreshToken : "";
    // LocalStorageService.setAccessToken(accessToken);
    // LocalStorageService.setRefreshToken(refreshToken);
    return {
      accessToken,
      refreshToken,
      isLogin,
    };
  }
  get cuid(): string | undefined {
    return this.get(this.c_user_name);
  }
  set cuid(val: string | undefined) {
    if (!val) {
      this.remove(this.c_user_name);
    }
    const duration = 365 * 24 * 60 * 60 * 1000;
    this.put(this.c_user_name, val, {
      sameSite: "strict",
      secure: true,
      expires: new Date(Date.now() + duration),
      path: "/",
    });
  }
  get iss(): string | undefined {
    return this.get(this.iss_name);
  }
  /**
   * @description
   * Returns the value of given cookie key.
   *
   * @param key Id to use for lookup.
   * @returns Raw cookie value.
   */
  get(key: string): string | undefined {
    const cookies = this.getAll();
    return cookies?.[key];
  }
  readAllCookieAsString(): string {
    return this.document.cookie || "";
  }

  /**
   * @description
   * Sets a value for given cookie key.
   *
   * @param key Id for the `value`.
   * @param value Raw value to be stored.
   * @param options (Optional) Options object.
   */
  put(key: string, value: string | undefined, options?: CookieOptions): void {
    const opts = mergeOptions(this.options, options);
    this.write(key, value, opts);
  }
  /**
   * @description
   * Returns a key value object with all the cookies.
   *
   * @returns All cookies
   */
  getAll(): CookieDict {
    const cookieString = this.readAllCookieAsString();
    return parseCookieString(cookieString);
  }
  /**
   * @description
   * Remove given cookie.
   *
   * @param key Id of the key-value pair to delete.
   * @param options (Optional) Options object.
   */
  remove(key: string, options?: CookieOptions): void {
    this.put(key, undefined, options);
  }
  write(
    name: string,
    value: string | undefined,
    options?: CookieOptions
  ): void {
    this.document.cookie = buildCookieString(name, value, options);
  }
}
