import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-small-empty',
    templateUrl: './small-empty.component.html',
    styleUrls: ['./small-empty.component.scss']
})
export class SmallEmptyComponent implements OnInit {
    @Input() style = {};
    @Input() layout = 'row';
    @Input() message = 'common.no_item';

    constructor() {
    }

    ngOnInit() {
    }

}
