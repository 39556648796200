import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from '@app/core/services/global.service';
import { RouterService } from '@app/core/services/router.service';
import { PolicyService } from '@app/modules/permission/policy.service';
import { ClientApiService } from '@app/shared/services/client-api.service';
import { ItemApiService } from '@app/shared/services/item-api.service';
import { OneMapApiService } from '@app/shared/services/one-map-api.service';
import { TranslateService } from '@ngx-translate/core';
import { MESSAGE_TYPE } from '@shared/models/message.model';
import { ToastrService } from 'ngx-toastr';
import { ItemFormComponent } from '../item-form/item-form.component';

@Component({
    selector: 'app-item-create-quick-modal',
    templateUrl: './item-create-quick-modal.component.html',
    styleUrls: ['./item-create-quick-modal.component.scss']
})
export class ItemCreateQuickModalComponent extends ItemFormComponent implements OnInit {
    
    constructor(
        @Inject(ItemApiService) itemApiService: ItemApiService,
        @Inject(GlobalService) globalService: GlobalService,
        @Inject(ActivatedRoute) route: ActivatedRoute,
        @Inject(ToastrService) toastrService: ToastrService,
        @Inject(TranslateService) translateService: TranslateService,
        @Inject(OneMapApiService) oneMapApiService: OneMapApiService,
        @Inject(PolicyService) policyService: PolicyService,
        @Inject(ClientApiService) clientApiService: ClientApiService,
        @Inject(RouterService) routerService: RouterService,
        @Inject(FormBuilder) formBuilder: FormBuilder,
        @Inject(MatDialog) dialog: MatDialog,
        @Inject(Router) router: Router,
        private dialogRef: MatDialogRef<ItemCreateQuickModalComponent>,

    ) {
        super(itemApiService, globalService, route, toastrService, translateService, oneMapApiService,
            policyService, clientApiService, routerService, formBuilder, dialog, router);
    }

    sendRequest(data: object): void {
        this.itemApiService.create(data).subscribe((res) => {
            this.globalService.message.next({
                type: MESSAGE_TYPE.success,
                message: 'item.create_successfully'
            });
            this.dialogRef.close(res);
        }, error => {
            if (error && error.error && error.error.sku && error.error.sku.isExisted) {
                this.form.controls.sku.setErrors({isExisted: true});
                this.globalService.message.next({
                    type: MESSAGE_TYPE.error,
                    message: 'validator.specifics.sku_existed'
                });
            }
        });
    }

    close() {
        this.dialogRef.close();
    }
}
