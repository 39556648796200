import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppConstant } from '@app/app.constant';
import { Observable } from 'rxjs';
import { Helper } from '../common/helper';
import { finalize, map, timeout } from 'rxjs/operators';
import { GlobalService } from './global.service';
import { ApiOptionInterface } from '../models/api-option.interface';
import * as moment from 'moment';
import {  assign } from 'lodash-es';


@Injectable({
    providedIn: 'root'
})
export class ApiService {
    private apiHost = AppConstant.API_HOST;
    private apiUrlPrefix = AppConstant.API_URL_PREFIX;
    private requestTimeout = AppConstant.DEFAULT_SETTINGS.REQUEST_TIMEOUT;
    headers: HttpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
        ILI: 'false' // Is Logged In Request
    });
    private defaultOptions: ApiOptionInterface = {
        loader: true,
        pretreatmentResponse: true,
        excludeFields: [],
        requestOptions: {
            headers: this.headers
        }
    };

    constructor(
        private httpClient: HttpClient,
        private globalService: GlobalService
    ) {
        this.globalService.storage
            .watch(AppConstant.GLOBAL_STORAGE.TOKEN)
            .subscribe(token => {
                this.headers = this.headers.set('Authorization', `Bearer ${token}`);
                if (token) {
                    this.headers = this.headers.set('ILI', 'true'); // Is Logged In Request
                } else {
                    this.headers = this.headers.set('ILI', 'false');
                }
                this.defaultOptions.requestOptions.headers = this.headers;
            });
    }

    static pretreatmentDataRequest(data, options: ApiOptionInterface) {
        let payload = assign(data)
        payload = ApiService.formatMomentObject(payload);
        const hasRemoveEmpty = typeof options?.removeEmpty === 'boolean' ? options.removeEmpty: true;
        if(hasRemoveEmpty){
            payload = Helper.removeEmpty(payload, options?.excludeFields);
        }
       
        return payload;
    }

    static formatMomentObject(data) {
        if (data && typeof data === 'object') {
            Object.keys(data).forEach(key => {
                if (typeof data[key] === 'object') {
                    if (moment.isMoment(data[key])) {
                        data[key] = data[key].format();
                    } else {
                        data[key] = ApiService.formatMomentObject(data[key]);
                    }
                }
            });
        }
        return data;
    }

    static normalizeOption(options: ApiOptionInterface, optionsMatching: ApiOptionInterface): ApiOptionInterface {
        if (!optionsMatching) {
            return options;
        }
        let results = {...options};
        optionsMatching.requestOptions = {...results.requestOptions, ...optionsMatching.requestOptions};
        results = {...results, ...optionsMatching};
        if (optionsMatching.exposeHeaders) {
            Object.keys(optionsMatching.exposeHeaders).forEach(key => {
                const headers = results.requestOptions.headers as HttpHeaders;
                results.requestOptions.headers = headers.set(key, optionsMatching.exposeHeaders[key]);
            });
        }
        return results;
    }

    createAPIURL(url: string, params: Record<string,any>): string {
        const paths = url.split('/');
        paths.forEach((path, i) => {
            if (path.startsWith(':')) {
                const key = path.slice(1);
                paths[i] = params[key];
                delete params[key];
            }
        });

        return this.apiHost + this.apiUrlPrefix + paths.join('/');
    }

    get(url: string, params?: HttpParams | any, options?: ApiOptionInterface): Observable<any> {
        params = ApiService.pretreatmentDataRequest(params, options);
        const defaultRequestOptions = {
            ...this.defaultOptions,
            ...{
                requestOptions: {
                    params: params,
                    headers: this.headers
                }
            }
        };
        options = ApiService.normalizeOption(defaultRequestOptions, options);
        const fullUrl = this.createAPIURL(url, params);
        if (options.loader) {
            this.globalService.loader.loading();
            return this.httpClient.get(fullUrl, options.requestOptions).pipe(
                timeout(this.requestTimeout),
                map(response => options.pretreatmentResponse ? response['data'] : response),
                finalize(() => {
                    this.globalService.loader.loaded();
                })
            );
        } else {
            return this.httpClient.get(fullUrl, options.requestOptions).pipe(
                timeout(this.requestTimeout),
                map(response => options.pretreatmentResponse ? response['data'] : response)
            );
        }
    }

    post(url: string, params?: any, options?: ApiOptionInterface): Observable<any> {
        params = ApiService.pretreatmentDataRequest(params, options);
        options = ApiService.normalizeOption(this.defaultOptions, options);
        const fullUrl = this.createAPIURL(url, params);
        if (options.loader) {
            this.globalService.loader.loading();
            return this.httpClient
                       .post(fullUrl, params, options.requestOptions)
                       .pipe(
                           timeout(this.requestTimeout),
                           map(response => options.pretreatmentResponse ? response['data'] : response),
                           finalize(() => {
                               this.globalService.loader.loaded();
                           })
                       );
        } else {
            return this.httpClient
                       .post(fullUrl, params, options.requestOptions)
                       .pipe(
                           timeout(this.requestTimeout),
                           map(response => options.pretreatmentResponse ? response['data'] : response),
                       );
        }
    }

    put(url: string, params?: any, options?: ApiOptionInterface): Observable<any> {
        params = ApiService.pretreatmentDataRequest(params, options);
        options = ApiService.normalizeOption(this.defaultOptions, options);
        const fullUrl = this.createAPIURL(url, params);
        if (options.loader) {
            this.globalService.loader.loading();
            return this.httpClient
                       .put(fullUrl, params, options.requestOptions)
                       .pipe(
                           timeout(this.requestTimeout),
                           map(response => options.pretreatmentResponse ? response['data'] : response),
                           finalize(() => {
                               this.globalService.loader.loaded();
                           })
                       );
        } else {
            return this.httpClient
                       .put(fullUrl, params, options.requestOptions)
                       .pipe(
                           timeout(this.requestTimeout),
                           map(response => options.pretreatmentResponse ? response['data'] : response)
                       );
        }
    }

    patch(url: string, params?: any, options?: ApiOptionInterface): Observable<any> {
        params = ApiService.pretreatmentDataRequest(params, options);
        options = ApiService.normalizeOption(this.defaultOptions, options);
        const fullUrl = this.createAPIURL(url, params);
        if (options.loader) {
            this.globalService.loader.loading();
            return this.httpClient
                       .patch(fullUrl, params, options.requestOptions)
                       .pipe(
                           timeout(this.requestTimeout),
                           map(response => options.pretreatmentResponse ? response['data'] : response),
                           finalize(() => {
                               this.globalService.loader.loaded();
                           })
                       );
        } else {
            return this.httpClient
                       .patch(fullUrl, params, options.requestOptions)
                       .pipe(
                           timeout(this.requestTimeout),
                           map(response => options.pretreatmentResponse ? response['data'] : response)
                       );
        }
    }

    delete(url: string, params?: any, options?: ApiOptionInterface): Observable<any> {
        console.log('params -->', JSON.stringify(params));
        params = ApiService.pretreatmentDataRequest(params, options);
        const defaultRequestOptions = {
            ...this.defaultOptions,
            ...{
                requestOptions: {
                    body: params,
                    headers: this.headers
                }
            }
        };
        options = ApiService.normalizeOption(defaultRequestOptions, options);
        const fullUrl = this.createAPIURL(url, params);
        if (options.loader) {
            this.globalService.loader.loading();
            return this.httpClient
                       .request('delete', fullUrl, options.requestOptions)
                       .pipe(
                           timeout(this.requestTimeout),
                           map(response => options.pretreatmentResponse ? response['data'] : response),
                           finalize(() => {
                               this.globalService.loader.loaded();
                           })
                       );
        } else {
            return this.httpClient
                       .request('delete', fullUrl, options.requestOptions)
                       .pipe(
                           timeout(this.requestTimeout),
                           map(response => options.pretreatmentResponse ? response['data'] : response)
                       );
        }
    }
    download(method:'get'|'post',url:string,params: Record<string,any> = {},isStreaming= false,options?: ApiOptionInterface){
        const headers = new HttpHeaders({
            'Accept': 'application/octet-stream',
            'Authorization':this.headers.get('Authorization'),
            'ILI':this.headers.get('ILI')
          }); 
        params = ApiService.pretreatmentDataRequest(params, options);
        const defaultRequestOptions = {
            ...this.defaultOptions,
            ...{
                requestOptions: {
                    body: params,
                    headers,
                    responseType: 'blob',
                    reportProgress: true, // For progress monitoring
                    observe: 'events'
                }
            },
            pretreatmentResponse:false
        };
        options = ApiService.normalizeOption(defaultRequestOptions, options);
        const fullUrl = this.createAPIURL(url, params);
        if (options.loader) {
            this.globalService.loader.loading();
            return this.httpClient
                       .request(method, fullUrl, options.requestOptions)
                       .pipe(
                           timeout(this.requestTimeout),
                           map(response => options.pretreatmentResponse ? response['data'] : response),
                           finalize(() => {
                               this.globalService.loader.loaded();
                           })
                       );
        } else {
            return this.httpClient
                       .request(method, fullUrl, options.requestOptions)
                       .pipe(
                           timeout(this.requestTimeout),
                           map(response => options.pretreatmentResponse ? response['data'] : response)
                       );
        }
    }

}
