import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, EventEmitter, OnInit, Output, OnDestroy, ViewEncapsulation, Input } from '@angular/core';

@Component({
    selector: 'app-case-submission-card',
    templateUrl: './case-submission-card.component.html',
    styleUrls: ['./case-submission-card.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CaseSubmissionCardComponent implements OnInit, OnDestroy {
    @Input() get noAvatar(): boolean {
        return this._noAvatar;
    }
    set noAvatar(val) {
        this._noAvatar = coerceBooleanProperty(val);
    }
    @Input() get readonly(): boolean {
        return this.isReadonly;
    }
    set readonly(val) {
        this.isReadonly = coerceBooleanProperty(val);
    }
    @Input() allowEdit = false;
    @Output() clearCard = new EventEmitter<void>();
    @Output() editCard = new EventEmitter<void>();
    isReadonly = false;
    _noAvatar = false;
    constructor() { }
    ngOnDestroy(): void {
        this.clearCard.complete();
    }

    ngOnInit(): void {
    }
    edit(): void {
        this.editCard.emit();
    }
    clear(): void {
        this.clearCard.emit();
    }
}
