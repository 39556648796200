import { Component, OnInit, Input, HostBinding, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-badge',
    template: ' <ng-content></ng-content>',
    styleUrls: ['./badge.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class BadgeComponent implements OnInit {
    @Input() color: 'default' | 'violet' | 'gray' | 'red' = 'default';
    @Input() size: 'small' | 'medium' | 'large' = 'medium';

    @HostBinding('class.default')
    get colorDefault() {
        return this.color === 'default';
    }

    @HostBinding('class.violet')
    get colorViolet() {
        return this.color === 'violet';
    }

    @HostBinding('class.gray')
    get colorGray() {
        return this.color === 'gray';
    }

    @HostBinding('class.red')
    get colorRed() {
        return this.color === 'red';
    }

    @HostBinding('class.small')
    get small() {
        return this.size === 'small';
    }

    @HostBinding('class.medium')
    get medium() {
        return this.size === 'medium';
    }

    @HostBinding('class.large')
    get large() {
        return this.size === 'large';
    }
    constructor() { }

    ngOnInit(): void {
    }

}
