<div class="app-welcome-modal" fxLayout="column" fxLayoutAlign="center center">
    <div class="img-wrapper">
        <img src="../../../../assets/images/welcome.png" width="100%" height="100%">
        <img src="../../../../assets/images/welcome-red-logo.png" class="img-logo" width="88px" height="88px">

    </div>
    <div class="welcome-content" fxLayout="column" fxLayoutAlign="center center">
        <div class="welcome-title">{{ 'welcome.welcome_to_the_red_cloud' | translate}}</div>
        <div class="welcome-des">{{ 'welcome.welcome_des' | translate}}</div>
        <button type="button" mat-flat-button color="primary" (click)="close(true)">{{ 'welcome.let_get_start' | translate}}</button>
    </div>
    <button type="button" mat-button class="skip-btn" (click)="close()">{{ 'common.skip' | translate}}</button>

</div>
