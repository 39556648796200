<mat-menu #menu="matMenu">
    <ng-container *ngFor="let action of actions">
        <div
            mat-menu-item
            *ngIf="!action.children || action.children.length === 0"
            (click)="onSelectAction($event, action)"
        >
            <div class="flex flex-row h-12 gap-1">
                <img [src]="action.icon" alt="" />
                <div class="flex flex-col flex-auto justify-center">
                    <span class="text-s2 text-theme-dark">{{
                        action.label
                    }}</span>
                    <span class="text-s3 text-theme-gray">{{
                        action.description
                    }}</span>
                </div>
            </div>
        </div>
        <ng-container   *ngIf="action.children && action.children.length > 0">
            <div
            mat-menu-item
            [matMenuTriggerFor]="childMenu.menu"
        >
            <div  
                class="flex flex-row h-12 gap-1"
               
            >
                <img [src]="action.icon" alt="" />
                <div class="flex flex-col flex-auto justify-center">
                    <span class="text-s2 text-theme-dark">{{
                        action.label
                    }}</span>
                    <span class="text-s3 text-theme-gray">{{
                        action.description
                    }}</span>
                </div>
            </div>
            <app-quick-action-menu
                #childMenu
                [actions]="action.children"
            ></app-quick-action-menu>
        </div>
        </ng-container>
       
    </ng-container>
</mat-menu>
