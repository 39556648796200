import { BaseModel } from '@app/core/models/base.model';
import { Exclude, Expose, Type } from 'class-transformer';
export interface PaymentInfoInterface {
    qrcode?: string;
    bankName?: string;
    accountHolder?: string;
    accountNumber?: string;
    description?: string;

}
@Exclude()
export class PaymentInfoModel extends BaseModel implements PaymentInfoInterface {
    @Expose()
    qrcode?: string;

    @Expose()
    bankName?: string;

    @Expose()
    accountHolder?: string;

    @Expose()
    accountNumber?: string;

    @Expose()
    description?: string;
}
