import { BaseModel } from '@core/models/base.model';
import { Exclude, Expose, Transform, Type } from 'class-transformer';
import { UserModel } from './user.model';
import { Default } from '@app/core/decorators/default.decorator';
import { ContentBuilderContentModel } from './content-builder-content.model';
import { ContentBuilderFieldModel } from './content-builder-field.model';
import {
    TemplateMetadataInterface,
    TemplateMetadataModel,
} from './metadata.model';
import { ParticipantInterface, ParticipantModel } from './participant.model';
import { IContentBuilderContentType } from './content-builder.model';
import { CONTENT_BUILDER_STYLE } from '@app/modules/content-builder/constants/config';

export interface DocumentTemplateInterface {
    id: string;
    name: string;
    slug: string;
    previewPhoto: string;
    fields: ContentBuilderFieldModel;
    category: string;
    content: ContentBuilderContentModel;
    contentType: IContentBuilderContentType;
    status: string;
    createdBy: UserModel;
    createdAt: string;
    updatedAt: string;
    components: any;
    metadata: TemplateMetadataInterface;
    shareMode: string;
    // participants: ParticipantInterface[];
}

export class DocumentTemplateModel
    extends BaseModel
    implements DocumentTemplateInterface
{
    @Expose()
    id: string;

    @Expose()
    @Default('Untitled Document')
    name: string;

    @Expose()
    slug: string;

    @Expose()
    previewPhoto: string;

    @Expose()
    category: string;

    @Expose()
    components: any;

    @Expose()
    @Transform((val,obj) => {
        console.log('transform shareMode')
        // convert old version
        if(obj.metadata && typeof obj.metadata['mode'] === 'object' && obj.metadata.mode['shareMode']){           
            return obj.metadata.mode['shareMode']
        }
        return val;
    })
    shareMode: string;

    @Expose()
    @Transform((data) => {
        if (data) {
            return TemplateMetadataModel.fromJson(data);
        } else {
            return TemplateMetadataModel.createEmpty();
        }
    })
    // @Default({
    //     groupDataFields:[]
    // })
    // @Type(() => TemplateMetadataModel)
    metadata: TemplateMetadataModel;

    // @Expose()
    // @Default([])
    // @Type(() => ParticipantModel)
    // participants: ParticipantModel[];

    @Expose()
    @Transform((data) => {
        let res = {};
        if (typeof data === 'string') {
            res['html'] = data;
            res['css'] = [];
        } else {
            res = data;
        }
        return res;
    })
    @Type(() => ContentBuilderContentModel)
    content: ContentBuilderContentModel;

    @Expose()
    @Default('jsonM')
    contentType: IContentBuilderContentType;

    @Expose()
    status: string;

    @Expose({ name: 'createdByUser' })
    @Type(() => UserModel)
    createdBy: UserModel;

    @Expose()
    // @Transform(data => {
    //     if (data) {
    //         return ContentBuilderFieldModel.fromJson(data)
    //     }
    //     return ContentBuilderFieldModel.fromJson({})
    // })
    @Default(ContentBuilderFieldModel.fromJson({}))
    @Type(() => ContentBuilderFieldModel)
    fields: ContentBuilderFieldModel;

    @Expose()
    createdAt: string;

    @Expose()
    updatedAt: string;
}

export interface TemplateDraftInterface {
    id: number;
    content: any;
    key: string;
    userId: number;
    templateId?: number;
    createdAt: string;
    updatedAt: string;
}
export function createTemplatePayloadByHtml(
    html: string,
    options?: { name: string;category?:string }
): Record<string, any> {
    return {
        name: options?.name ?? 'Untitled Template',
        content: {
            html,
            css:  CONTENT_BUILDER_STYLE.URL,
            printOptions: {
                margin: {
                    top: CONTENT_BUILDER_STYLE.PRINT_OPTIONS.MARGIN.TOP + 'px',
                    bottom: CONTENT_BUILDER_STYLE.PRINT_OPTIONS.MARGIN.BOTTOM + 'px',
                    left: CONTENT_BUILDER_STYLE.PRINT_OPTIONS.MARGIN.LEFT + 'px',
                    right: CONTENT_BUILDER_STYLE.PRINT_OPTIONS.MARGIN.RIGHT + 'px'
               
                },
            },
        },
        contentType: 'jsonM',
        metadata: {
            mode: 'editable-mode',
            eSigning: {
                enableESigning: false,
                provider: 'singpass',
            },
            groupDataFields: [],
            groupIdOrders: [
                'Client Fields',
                'Property Fields',
                'Leasing / Rental Fields',
                'Sales / Purchase Fields',
                'Agent Fields',
                'Co-broke Agent',
                'Invoice Fields',
                'General Fields',
            ],
        },
        fields: {
            data: {},
            properties: {},
            layouts: {
                dynamicField: [],
                signatureField: [],
                participantField: [],
            },
            type: 'jsonSchema',
        },
        category:options?.category ?? 'Untitled Category',
        shareMode:'private'

    };
}
