const data: { id: number; name: string }[] = [
    {
        id: 1,
        name: 'Advertising'
    },
    {
        id: 2,
        name: 'Agriculture Industry'
    },
    {
        id: 3,
        name: 'Communications Industry'
    },
    {
        id: 4,
        name: 'Construction Industry'
    },
    {
        id: 5,
        name: 'Creative Industries'
    },
    {
        id: 6,
        name: 'Education'
    },
    {
        id: 7,
        name: 'Entertainment Industry'
    },
    {
        id: 8,
        name: 'Farming'
    }, {
        id: 9,
        name: 'Fashion'
    },
    {
        id: 10,
        name: 'Finance'
    },
    {
        id: 11,
        name: 'Green Industry'
    },
    {
        id: 12,
        name: 'Heavy Industry'
    },
    {
        id: 13,
        name: 'Hospitality Industry'
    },
    {
        id: 14,
        name: 'Information Industry'
    },
    {
        id: 15,
        name: 'Information Technology'
    },
    {
        id: 16,
        name: 'Infrastructure'
    },
    {
        id: 17,
        name: 'IT Industry'
    },
    {
        id: 18,
        name: 'Light Industry'
    },
    {
        id: 19,
        name: 'Manufacturing'
    },
    {
        id: 20,
        name: 'Materials'
    },
    {
        id: 21,
        name: 'Media'
    },
    {
        id: 22,
        name: 'Music Industry'
    },
    {
        id: 23,
        name: 'Primary Industry'
    },
    {
        id: 24,
        name: 'Publishing'
    },
    {
        id: 25,
        name: 'Retail'
    },
    {
        id: 26,
        name: 'Robotics'
    },
    {
        id: 27,
        name: 'Secondary Industry'
    },
    {
        id: 28,
        name: 'Service Industry'
    },
    {
        id: 29,
        name: 'Space'
    },
    {
        id: 30,
        name: 'Space Industry'
    },
    {
        id: 31,
        name: 'Technology Industry'
    },
    {
        id: 32,
        name: 'Telecom'
    },
    {
        id: 33,
        name: 'Tertiary Sector'
    },
    {
        id: 35,
        name: 'Real Estate'
    },
    {
        id: 36,
        name: 'Logistics'
    },
].sort((a, b) => {
    if (a.name > b.name) {
        return 1;
    } else if (a.name < b.name) {
        return -1;
    } else {
        return 0;
    }
});
data.push({id: 34, name: 'Other'});

export const INDUSTRIES = data;
