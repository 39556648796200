import { PaginationAdapter } from '@core/adapters/pagination.adapter';
import { DocumentModel } from '../models/document.model';
import { DocumentByClientMetaModel } from '../models/metadata.model';

export class DocumentPaginationAdapter extends PaginationAdapter<DocumentModel> {
    constructor(data) {
        data.items = data.items.map(item => DocumentModel.fromJson(item));
        super(data);
    }
}

export class DocumentByClientPaginationAdapter extends PaginationAdapter<DocumentModel> {
    meta: DocumentByClientMetaModel;
    constructor(data) {
        data.items = data.items.map(item => DocumentModel.fromJson(item));
        super(data);
        this.meta = DocumentByClientMetaModel.fromJson(data.meta);
    }
}
