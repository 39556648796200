import { BaseModel } from '@app/core/models/base.model';

export interface OneMapResultInterface {
    ADDRESS: string;
    BLK_NO: string;
    BUILDING: string;
    POSTAL: string;
    ROAD_NAME: string;
    SEARCHVAL: string;
}
export class OneMapResultModel extends BaseModel implements OneMapResultInterface {
    ADDRESS: string;
    BLK_NO: string;
    BUILDING: string;
    POSTAL: string;
    ROAD_NAME: string;
    SEARCHVAL: string;
}
