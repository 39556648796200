import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, retryWhen, delay, take, concatMap } from 'rxjs/operators';

@Injectable()
export class RetryInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      retryWhen(errors =>
        errors.pipe(
          concatMap((error, count) => {
            if (count <= 2 && req.method === 'GET' &&  error.status === 502) {
              // Retry up to 2 times for server errors (method get, status code 502)
              return of(error).pipe(delay(1000)); // Retry after 1 second delay
            }
            return throwError(error); // If more than 2 retries or not a server error, propagate the error
          })
        )
      ),
    //   catchError((error: HttpErrorResponse) => {
    //     // Handle the error here
    //     console.error('HTTP error:', error);
    //     return throwError(error);
    //   })
    );
  }
}
