import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

export interface StorageItemInterface {
    [key: string]: BehaviorSubject<any> | Subject<any>;
}

export class StorageModel {
    private storage: StorageItemInterface = {};

    view() {
        return this.storage;
    }

    add(key, data) {
        this.storage[key] = new BehaviorSubject(data);
    }
    has(key):boolean{
        return !!this.storage[key]
    }

    dispatch(key, data) {
        if (!this.storage[key]) {
            this.add(key, data);
        } else {
            this.storage[key].next(data);
        }
    }

    watch(key): Observable<any> {
        return this.storage[key] ? this.storage[key].asObservable() : of();
    }

    remove(key) {
        this.storage[key].complete();
        delete this.storage[key];
    }

    removeAll() {
    }

    get(key) {
        return (this.storage[key] as BehaviorSubject<any>).value;
    }
}

