import { Injectable } from '@angular/core';
import { BasePolicyCollection } from '@modules/permission/base.policy-collection';
import { InvoicePolicyCollection } from '@app/modules/invoice/invoice.policy-collection';
import { PermissionService } from '@modules/permission/permission.service';
import { Observable } from 'rxjs';

@Injectable()
export class DocumentPolicyCollection extends BasePolicyCollection {
    readonly policyCollectionKey = 'document';

    constructor(
        protected permissionService: PermissionService,
        protected invoicePolicyCollection: InvoicePolicyCollection,
    ) {
        super(permissionService);
    }

    signingFlow() {
        return this.allowEdit() || this.create();
    }

    allowEdit() {
        return this.invoicePolicyCollection.update() ||
            this.update();
    }

    createInvoice() {
        return this.invoicePolicyCollection.create();
    }

    create(): boolean {
        return (this.permissionService.hasPolicy(this.policyCollectionKey + '::create') ||
            this.permissionService.hasPolicy(this.policyCollectionKey + '::createOwner')) &&
            this.permissionService.hasPermissions(['contract::create']);
    }

    update(): boolean | Promise<boolean> | Observable<boolean> {
        return (this.permissionService.hasPolicy(this.policyCollectionKey + '::update') ||
            this.permissionService.hasPolicy(this.policyCollectionKey + '::updateOwner')) &&
            this.permissionService.hasPermissions(['contract::update']);
    }

    updateOwner(id): boolean | Promise<boolean> | Observable<boolean> {
        return (this.permissionService.hasPolicy(this.policyCollectionKey + '::update') ||
            (this.permissionService.hasPolicy(this.policyCollectionKey + '::updateOwner') && +id === this.permissionService.user.id))
            && this.permissionService.hasPermissions(['contract::update']);
    }

    send() {
        return this.permissionService.hasPermissions(['contract::start']);
    }
}
