import { Injectable } from '@angular/core';
import { BasePolicyCollection } from '@modules/permission/base.policy-collection';
import { Policies } from '../permission/policy.constant';

@Injectable()
export class CaseSubmissionAgencyPolicyCollection extends BasePolicyCollection {
    readonly policyCollectionKey = 'case-submission';

    accessUrlMyCase() {
        return (
            this.permissionService.hasPolicy(
                this.policyCollectionKey + '::viewOwner'
            ) &&
            this.permissionService.hasPermissions(
                Policies[this.policyCollectionKey].own
            )
        );
    }
    generateSupplierInvoice() {
        console.log(
            'generateSupplierInvoice --> ',
            this.permissionService.hasPolicy('supplier-invoice::create'),
            this.permissionService.hasPolicy('commission-distribution::view')
        );
        return (
            this.permissionService.hasPolicy('supplier-invoice::create') &&
            this.permissionService.hasPolicy('commission-distribution::view')
        );
    }
}
