import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from './search.component';
import { MatIconModule } from '@angular/material/icon';
const components = [MatIconModule];
@NgModule({
    imports: [CommonModule, ...components],
    declarations: [SearchComponent],
    exports: [SearchComponent],
})
export class SearchModule {}
