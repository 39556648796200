import { Component, OnInit, Input, ViewChild, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppConstant } from '@app/app.constant';
import { GlobalService } from '@app/core/services/global.service';
import { RedocCloudService } from '@app/core/services/redoc-cloud.service';
import { FileUploaderInterface } from '@app/modules/upload/file-uploader.model';
import { UploadComponent } from '@app/modules/upload/upload.component';
import { BusinessUnitModel } from '@app/shared/models/business-unit.model';
import {
    DocumentModel,
    createDocumentPayloadByHtml,
} from '@app/shared/models/document.model';
import { UserModel } from '@app/shared/models/user.model';
import { DocumentApiService } from '@app/shared/services/document-api.service';
import { Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';

@Component({
    selector: 'app-document-upload-and-sign-dialog',
    templateUrl: './document-upload-and-sign-dialog.component.html',
    styleUrls: ['./document-upload-and-sign-dialog.component.scss'],
})
export class DocumentUploadAndSignDialogComponent implements OnInit {
    static pdfFile = null;
    maxFileSize = 5 * 1024 * 1024;
    allowedMimeType = [
        'application/pdf',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ];
    allowExtensions = ['docx', 'pdf'];
    file: FileUploaderInterface;
    @ViewChild('appUploadFile') appUploadFile: UploadComponent;
    isShowUploadBox = true;
    isConverting = false;
    currentBU:BusinessUnitModel;
    constructor(
        private redocCloudService: RedocCloudService,
        private documentApiService: DocumentApiService,
        private dialogRef: MatDialogRef<DocumentUploadAndSignDialogComponent>,
        private globalService: GlobalService,
        @Inject(MAT_DIALOG_DATA)
        private data: {
            documentIdSelected: number[];
            maxFiles: number;
        }
    ) {}

    ngOnInit(): void {
        this.globalService.storage
                .watch(AppConstant.GLOBAL_STORAGE.BUSINESS_UNIT)
                .pipe(
                    tap(data => {
                        this.currentBU = data;
                        
                    }),
                    take(1)
                ).subscribe()
    }

    uploaded(data: FileUploaderInterface) {
        if (data.error.status) {
            this.file = null;
            this.isShowUploadBox = true;
        } else {
            this.file = data;
            this.isShowUploadBox = false;
        }
    }

    removed(fileUploaders: FileUploaderInterface[]) {
        this.file = null;
        this.isShowUploadBox = true;
    }

    submit() {
        console.log('this.file', this.file);
        this.isConverting = true;
        let isDocx = false;
        of(this.file)
            .pipe(
                switchMap((file) => {
                    const ext = file.file.name.split('.')[1];
                    isDocx = ext === 'docx';
                    return isDocx
                        ? this.submitDocx(file)
                        : this.submitPdf(file);
                })
            )
            .subscribe({
                next: (res:DocumentModel) => {
                    this.dialogRef.close(res);
                },
                error: (err) => {
                    this.isConverting = false;
                },
            });
        // this.isConverting = true;
        // this.redocCloudService.docxToHtml(this.file.file).subscribe({next:(res) => {
        //     this.isConverting = false;
        //     const html = '<div class="ck ck-content ck-print">'+res.html+'</div>';
        //     const name = this.file.file.name.split('.')[0]
        //     this.dialogRef.close({name,html});
        // },error:(err)=> {
        //     this.isConverting = false;
        // }});
    }
    submitDocx(file): Observable<DocumentModel> {
        return this.redocCloudService.docxToHtml(file.file).pipe(
            switchMap((res) => {
                const html =
                    '<div class="ck ck-content ck-print">' +
                    res.html +
                    '</div>';
                const name = this.file.file.name.split('.')[0];
                const payload = createDocumentPayloadByHtml(html, { name,signingPortal: [AppConstant.APP_BASE_URL,'document-signing',this.currentBU.signingPortalPrefix].filter(val => !!val).join('/')});
                return this.documentApiService.create(payload);
            })
        );
    }
    submitPdf(file): Observable<DocumentModel> {
        const input = new FormData();
        input.append('file', this.file.file);
        input.append(
            'signingPortal',
            [AppConstant.APP_BASE_URL,'pdf-signing',this.currentBU.signingPortalPrefix].filter(val => !!val).join('/')
        );

        return this.documentApiService.upload(input);
    }
    close(): void {
        this.dialogRef.close();
    }
}
