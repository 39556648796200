export enum DOC_TYPES {
    public = 'public',
    private = 'private',
    org = 'org'
}

export enum DOC_SHARE_MODE {
    PRIVATE = 'private',
    READ_ONLY = 'o::ro',
    EDITABLE = 'o::r,e'
}
