import { AbstractControl, ValidationErrors, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { AppConstant } from '@app/app.constant';
import { Helper } from '../common/helper';
import { PhoneNumber, PhoneNumberInterface } from '@app/modules/shared/phone-number/phone-number.component';
import { RedrEmailValidator } from './subs/redr-email-validator.config';
import * as moment from 'moment';
import { DateMoment } from '../common/date-moment';
import { ParticipantModel } from '@app/shared/models/participant.model';
import { isObject } from 'lodash-es';
export type CompareType = 'lt' | 'lte' | 'gt' | 'gte';
export type ValueCompareType = 'number' | 'string';
export class RedrValidator {
    static duplicatedRecipient(control: AbstractControl,participants:ParticipantModel[]):ValidationErrors | null{
  
        let controlVal = control.value;
        if(!controlVal || participants.length === 0){
            return null
        }
        const oldParticipant = participants.filter(
          (participant) =>
            participant.email === controlVal 
        );
        return controlVal && oldParticipant.length === 0 ? null : { duplicated: true };
      
      }
      static duplicated(control: AbstractControl,duplicateFn:(value:any)=> boolean):ValidationErrors | null{
  
        let controlVal = control.value;
        if(!controlVal ){
            return null
        }
        return controlVal && duplicateFn(controlVal) ?  { duplicated: true }: null;
      
      }
    static containSpace(control: AbstractControl): ValidationErrors | null {
        if ((control.value as string).indexOf(' ') >= 0) {
            return { containSpace: true };
        }

        return null;
    }

    static whiteSpace(control: AbstractControl): ValidationErrors | null {
        if ((control.value || '').trim().length === 0) {
            return { whiteSpace: true };
        }

        return null;
    }

    static url(control: AbstractControl): ValidationErrors | null {
        if (!control.value) {
            return null;
        }

        if (!AppConstant.FIELD_VALIDATION.URL.test(control.value as string)) {
            return { url: true };
        }

        return null;
    }

    static price(control: AbstractControl): ValidationErrors | null {
        if (!control.value) {
            return null;
        }

        const value = Helper.removeCommaFromText(control.value);
        return value > AppConstant.MAX_PRICE_INPUT ? { isMaxPrice: true } : null;
    }

    static mustMatch(controlName: string, matchingControlName: string) {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            const matchingControl = formGroup.controls[matchingControlName];

            if (matchingControl.errors && !matchingControl.errors.mustMatch) {
                // return if another validator has already found an error on the matchingControl
                return;
            }

            // set error on matchingControl if validation fails
            if (control.value !== matchingControl.value) {
                matchingControl.setErrors({ mustMatch: true });
            } else {
                matchingControl.setErrors(null);
            }
        };
    }
    static email(control: AbstractControl): ValidationErrors | null {
        if (!control.value) {
            return null;
        }
        const str = control.value;
        if (RedrEmailValidator.isEmail(str)) {
            return null;
        }
        return { email: true };

    }

    static compare(controlName: string, matchingControlName: string, compareType: CompareType) {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            const matchingControl = formGroup.controls[matchingControlName];

            if (matchingControl.errors && !matchingControl.errors.compare) {
                // return if another validator has already found an error on the matchingControl
                return;
            }

            if (!control.value || !matchingControl.value) {
                matchingControl.setErrors(null);
                return;
            }


            // set error on matchingControl if validation fails
            const operation = {
                lt: (a, b) => {
                    return a < b;
                },
                lte: (a, b) => {
                    return a <= b;
                },
                gt: (a, b) => {
                    return a > b;
                },
                gte: (a, b) => {
                    return a >= b;
                }
            };
            if (operation[compareType](control.value, matchingControl.value)) {
                matchingControl.setErrors(null);
            } else {
                matchingControl.setErrors({ compare: true });
            }
        };

    }
    static addressRequired(control: AbstractControl): ValidationErrors | null {
  
        if(isObject(control.value) && control.value['postalCode']){
            return null;
        }

        return {required:true};
    }

    static generateValidators(type: string): ValidatorFn[] {
        const validators = [Validators.required];
        switch (type) {
            case 'email':
                validators.push(RedrValidator.email);
                break;
            case 'url':
                validators.push(RedrValidator.url);
                break;
        }
        return validators;
    }

    static compareDate(controlName: string, matchingControlName: string, compareType: CompareType, unit: 'day' | 'month' | 'year') {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            const matchingControl = formGroup.controls[matchingControlName];

            // if (matchingControl.errors && !matchingControl.errors.compare) {
            //     // return if another validator has already found an error on the matchingControl
            //     return;
            // }

            if (!control.value || !matchingControl.value) {
                // matchingControl.setErrors(null);
                return;
            }


            // set error on matchingControl if validation fails
            const operation = {
                lt: (differ: number) => {
                    return differ < 0;
                },
                lte: (differ) => {
                    return differ <= 0;
                },
                gt: (differ) => {
                    return differ > 0;
                },
                gte: (differ) => {
                    return differ >= 0;
                }
            };
            const controlValue = moment.isMoment(control.value) ? control.value : moment();
            const matchingControlValue = moment.isMoment(matchingControl.value) ? matchingControl.value : moment();

            const diff = controlValue.diff(matchingControlValue, `${unit}s` as moment.unitOfTime.Diff, true);
            if (operation[compareType](diff)) {
                matchingControl.setErrors(null);
            } else {
                matchingControl.setErrors({ compareDate: true });
            }
        };
    }

    static isFetureDateTime(control: AbstractControl): ValidationErrors | null {
        if (!control.value) {
            return null;
        }
        const now = DateMoment.now();
        const date = DateMoment.of(control.value);

        if (!date.isBefore(now)) {
            return null;
        }

        return { isFetureDateTime: true };

    }

    static max(max: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (isEmptyInputValue(control.value) || isEmptyInputValue(max)) {
                return null;  // don't validate empty values to allow optional controls
            }
            const value = Helper.removeCommaFromText(control.value);
            // Controls with NaN values after parsing should be treated as not having a
            // maximum, per the HTML forms spec: https://www.w3.org/TR/html5/forms.html#attr-input-max
            return !isNaN(value) && value > max ? { max: { max: max, actual: control.value } } : null;
        };
    }
    static lessThan(max: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (isEmptyInputValue(control.value) || isEmptyInputValue(max)) {
                return null;  // don't validate empty values to allow optional controls
            }
            const value = Helper.removeCommaFromText(control.value);
            // Controls with NaN values after parsing should be treated as not having a
            // maximum, per the HTML forms spec: https://www.w3.org/TR/html5/forms.html#attr-input-max
            return !isNaN(value) && value >= max ? { lessThan: { lessThan: max, actual: control.value } } : null;
        };
    }
    static min(min: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (isEmptyInputValue(control.value) || isEmptyInputValue(min)) {
                return null;  // don't validate empty values to allow optional controls
            }
            const value = Helper.removeCommaFromText(control.value);
            // Controls with NaN values after parsing should be treated as not having a
            // minimum, per the HTML forms spec: https://www.w3.org/TR/html5/forms.html#attr-input-min
            return !isNaN(value) && value < min ? { min: { min: min, actual: control.value } } : null;
        };
    }

    static blackListEmail(control: AbstractControl): ValidationErrors | null {
        if (!control.value) {
            return null;
        }
        const str = control.value;
        if (RedrEmailValidator.isEmail(str)) {
            const blackList: string[] = AppConstant.BLACK_LIST_EMAIL;
            const emailDomain = str.split('@')[1];

            if (blackList.includes(emailDomain.trim().toLowerCase())) {
                return {
                    blackListEmail: true
                };
            }
        }
        return null;
    }
}


function isEmptyInputValue(value: any): boolean {
    // we don't check for string here so it also works with arrays
    return value == null || value.length === 0;
}
