
import { FirebaseOptions,DataSnapshot } from './firebase.interface';
import { Inject, Injectable } from "@angular/core";
import { FIREBASE_CONFIGS } from "./firebase.module";
import firebase from "firebase/app";
import "firebase/database";
import { Observable, from } from "rxjs";
@Injectable()
export class FirebaseService {
    app!:firebase.app.App;
    db!:firebase.database.Database;
    dbRef!:firebase.database.Reference;
    constructor(
        @Inject(FIREBASE_CONFIGS) private configs:FirebaseOptions
    ){

        this.app =firebase.apps.length ? firebase.app() : firebase.initializeApp(this.configs);
        console.log('app -->',this.app)
        this.db = this.app.database();
        this.dbRef =this.db.ref()
    }
    get(path:string):Observable<DataSnapshot>{
        return from(this.dbRef.child(path).get())
    }
    update(path:string,data:Record<string,unknown>):Observable<void>{
        return from(this.dbRef.child(path).update(data))
    }
    on(path:string,key:firebase.database.EventType,callback:(data:DataSnapshot)=> void):void{
        this.dbRef.child(path).on(key,(snapshot)=> callback(snapshot))
    }
}
