import { Injectable, ModuleWithProviders, NgModule, Type } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RedocConnectComponent } from './redoc-connect.component';
import { RedocConnectService } from './redoc-connect.service';
import {
    RedocOAuthLogger,
    RedocOAuthStorage,
} from '@redocco/oauth2-redoc-connect';
import { REDOC_OAUTH2_CONFIG } from './token';
@Injectable()
export class MemoryStorage implements RedocOAuthStorage {
    private data = new Map<string, string>();

    getItem(key: string): string {
        return this.data.get(key) as string;
    }

    removeItem(key: string): void {
        this.data.delete(key);
    }

    setItem(key: string, data: string): void {
        this.data.set(key, data);
    }
}
export function createDefaultLogger() {
    return console;
}
export function createDefaultStorage() {
    console.log('sessionStorage', sessionStorage);
    return typeof sessionStorage !== 'undefined'
        ? sessionStorage
        : new MemoryStorage();
}
@NgModule({
    imports: [CommonModule],
    declarations: [RedocConnectComponent],
})
export class RedocConnectModule {
    static forRoot(
        config,
        storage?: Storage
    ): ModuleWithProviders<RedocConnectModule> {
        return {
            ngModule: RedocConnectModule,
            providers: [
                RedocConnectService,
                { provide: RedocOAuthLogger, useFactory: createDefaultLogger },
                {
                    provide: RedocOAuthStorage,
                    useFactory: storage ? () => storage : createDefaultStorage,
                },
                {
                    provide: REDOC_OAUTH2_CONFIG,
                    useValue: config,
                },
            ],
        };
    }
}
