import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class OneMapApiService {

    constructor(
        private httpClient: HttpClient,
    ) { }

    search(params: { searchVal: string; returnGeom: string; getAddrDetails: string; pageNum?: string; }) {
        return this.httpClient.get('https://api.redoc.co/one-map', {
            params
        });
    }
}
