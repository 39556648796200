import { ChangeDetectorRef, EventEmitter, Inject, OnDestroy, OnInit, Optional, Pipe, PipeTransform, ɵisObservable, ɵisPromise } from "@angular/core";
import { dateTimeUtils, IFormatDistanceToNowOptions } from "./datetime-formatter.utils";
import { Observable, Subject, Subscribable, timer, Unsubscribable } from "rxjs";
interface SubscriptionStrategy {
    createSubscription(
      async: Subscribable<any> | Promise<any>,
      updateLatestValue: any,
    ): Unsubscribable | Promise<any>;
    dispose(subscription: Unsubscribable | Promise<any>): void;
    onDestroy(subscription: Unsubscribable):void
}
  class ObservableStrategy implements SubscriptionStrategy {
    createSubscription(async:Subscribable<any> , updateLatestValue:any) {
        return async.subscribe({
            next: updateLatestValue,
            error: (e) => {
                throw e;
            }
        });
    }
    dispose(subscription) {
        subscription.unsubscribe();
    }
    onDestroy(subscription) {
        subscription.unsubscribe();
    }
}
  const _subscribableStrategy = new ObservableStrategy();
@Pipe({name: 'formatDistanceToNow',pure:false})
export class FormatDistanceToNowPipe implements OnInit, OnDestroy, PipeTransform {
    private _ref: ChangeDetectorRef | null;
    private _subscription: Unsubscribable | null = null;
    private _strategy: SubscriptionStrategy | null = null;
    private _countdown = 0
    private _latestValue: any = null;
    private _latestFormattedValue: any = null;
    private markForCheckOnValueUpdate = true;
    private _obj:Subscribable<any>| null = null;
    private _unsubscribeAll: Subject<void> = new Subject<void>();
    constructor(ref: ChangeDetectorRef, private service:DateTimeFormatterService, @Optional() @Inject(OVER_DUE_LABEL) private overDueLabel?:string ){
        // Assign `ref` into `this._ref` manually instead of declaring `_ref` in the constructor
        // parameter list, as the type of `this._ref` includes `null` unlike the type of `ref`.
        this._ref = ref;
    }
    ngOnInit(): void {
        throw new Error("Method not implemented.");
    }
    ngOnDestroy(): void {
        console.log('formatDistanceToNow destroy !!!')
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        if (this._subscription) {
            this._dispose();
          }
          // Clear the `ChangeDetectorRef` and its association with the view data, to mitigate
          // potential memory leaks in Observables that could otherwise cause the view data to
          // be retained.
          // https://github.com/angular/angular/issues/17624
          this._ref = null;
    }
    private _dispose(): void {
        // Note: `dispose` is only called if a subscription has been initialized before, indicating
        // that `this._strategy` is also available.
        this._strategy!.dispose(this._subscription!);
        this._latestValue = null;
        this._latestFormattedValue = null;
        this._subscription = null;
        this._obj = null;
      }
      transform(value:string | number | Date | null | undefined):string {
        if(this._latestValue === value){
            return this._latestFormattedValue;
        }
        this._latestValue = value;
        let obj:any
        if(value){
            const formated:Date = dateTimeUtils.isDate(value) ? value as Date : new Date(value)
            this._countdown = dateTimeUtils.nowToDistanceInSeconds(formated);
            if(this._countdown > 0){
                // console.log('countdown --> ',this._countdown)
                obj = timer(1000,1000).pipe(
                    takeWhile(() => this._countdown > 0),
                    takeUntil(this._unsubscribeAll),
                    map(() => {
                        const counted = this._countdown--;
                        return counted
                    }),
                    
                )
            }
          
        }
        
        return this.transformAsync(obj);
        
      }
      transformAsync(obj) {
        if(!obj){
            this._latestFormattedValue = this.overDueLabel || null
           
        }else{

            this._latestFormattedValue =this.formatString(this._countdown);
            if (!this._obj) {
                if (obj) {
                    this._subscribe(obj);
                }
                return this._latestFormattedValue;
            }
            if (obj !== this._obj) {
                this._dispose();
                return this.transformAsync(obj);
            }
        }
        
        return this._latestFormattedValue;
    }
      private _subscribe(obj: Subscribable<any>): void {
        this._obj = obj;
        this._strategy = this._selectStrategy(obj);
        this._subscription = this._strategy.createSubscription(obj, (value: Object) =>
          this._updateLatestValue(obj, value),
        ) as Unsubscribable;
      }
      private _updateLatestValue(async, value) {
        if (async === this._obj) {
            this._latestFormattedValue =this.formatString(value);
            this._ref.markForCheck();
        }
    }
    private formatString(seconds:number):string{
        if(seconds > (60 * 60)){
            const formated:Date = dateTimeUtils.isDate(this._latestValue) ? this._latestValue as Date : new Date(this._latestValue)
            return dateTimeUtils.formatDistanceToNow(formated)
        }
        
        const duration = dateTimeUtils.secondsToDuration(seconds);
        return dateTimeUtils.formatDuration(duration)
    }
      private _selectStrategy(
        obj: Subscribable<any> | Promise<any> | EventEmitter<any>,
      ): SubscriptionStrategy {
        // if (ɵisPromise(obj)) {
        //   return _promiseStrategy;
        // }
    
        if (ɵisObservable(obj)) {
          return _subscribableStrategy;
        }
    
        throw invalidPipeArgumentError(FormatDistanceToNowPipe, obj);
      }
    // transform(value:string | number | Date,options?:IFormatDistanceToNowOptions):string {
    //     if(!value){
    //         return ''
    //     }
    //     const formated = dateTimeUtils.isDate(value) ? value : new Date(value)
    //     if(dateTimeUtils.isValid(formated)){
    //         return dateTimeUtils.formatDistanceToNow(formated as Date)
    //     }
    //     return ''
        
    // }
//     private intervalId: number | null = null;
//   private remainingTime: number = 0;

//   transform(value:string | number | Date): string {
//     if (!this.intervalId) {
//       this.startCountdown(value);
//     }

//     return this.formatTime(this.remainingTime);
//   }

//   private startCountdown(seconds: number): void {
//     this.remainingTime = seconds;

//     this.intervalId = window.setInterval(() => {
//       this.remainingTime--;
//       if (this.remainingTime <= 0) {
//         this.clearInterval();
//       }
//     }, 1000);
//   }

//   private clearInterval(): void {
//     if (this.intervalId) {
//       clearInterval(this.intervalId);
//       this.intervalId = null;
//     }
//   }

//   private formatTime(seconds: number): string {
//     const minutes: number = Math.floor(seconds / 60);
//     const remainingSeconds: number = seconds % 60;
//     return `${this.pad(minutes)}:${this.pad(remainingSeconds)}`;
//   }

//   private pad(value: number): string {
//     return value < 10 ? `0${value}` : `${value}`;
//   }
}

import { ɵstringify as stringify } from '@angular/core';
import { DateTimeFormatterService } from "./datetime-formatter.service";
import { map, takeUntil, takeWhile, tap } from "rxjs/operators";
import { OVER_DUE_LABEL } from "./datetime-formatter.token";
export function invalidPipeArgumentError(type, value) {
    return Error(`InvalidPipeArgument: '${value}' for pipe '${stringify(type)}'`);
}