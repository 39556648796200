import {
    Component,
    OnInit,
    forwardRef,
    ViewEncapsulation,
    Input,
    HostBinding,
    Output,
    EventEmitter,
    HostListener
} from '@angular/core';
import {
    ControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR
} from '@angular/forms';
import { ComponentSize } from '@app/core/types/component-size';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SearchComponent),
            multi: true
        }
    ],
    encapsulation: ViewEncapsulation.None
})
export class SearchComponent implements ControlValueAccessor {
    @Input() size: ComponentSize = 'medium';

    @Input() placeholder = 'Search...';

    /** Event emitted when the search's value changes. */
    @Output() readonly search: EventEmitter<string> = new EventEmitter<
        string
    >();

    @HostBinding('class.small')
    get small() {
        return this.size === 'small';
    }

    @HostBinding('class.medium')
    get medium() {
        return this.size === 'medium';
    }

    @HostBinding('class.large')
    get large() {
        return this.size === 'large';
    }
    data = '';
    isFocus: boolean;

    /** Value of the select control. */
    // @Input()
    // get value(): string {
    //     return this.data;
    // }
    // set value(newValue: string) {
    //     if (newValue !== this.data) {
    //         this.writeValue(newValue);
    //         this.data = newValue;
    //     }
    // }
    propagateChange = (_: any) => { };
    writeValue(value: any): void {
        if (value !== undefined) {
            this.data = value;
        }
    }
    // registers 'fn' that will be fired wheb changes are made
    // this is how we emit the changes back to the form
    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }
    // not used, used for touch input
    registerOnTouched() { }
    setDisabledState?(isDisabled: boolean): void {
        throw new Error('Method not implemented.');
    }
    private emitChangeEvent(): void {
        this.search.emit(this.data);
    }
    onChange(event): void {
        // get value from input
        this.data = event.target.value;
        this.propagateChange(this.data);
        this.emitChangeEvent();
    }
    forcusInput() {
        this.isFocus = true;
    }
    blur() {
        this.isFocus = false;
    }
    clear() {
        this.data = '';
        this.propagateChange(this.data);
    }
    constructor() { }
}
