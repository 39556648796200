import * as moment from 'moment';
import { Injectable } from '@angular/core';

@Injectable()
export class DateMoment {
    public static FORMAT = {
        WITH_HOUR: 'DD MMM YYYY HH:mm',
        WITHOUT_HOUR: 'DD MMM YYYY',
    };

    // Basics functions
    public static current(utc = false) {
        return utc ? moment.utc() : moment();
    }

    public static getToday() {
        return DateMoment.current().startOf('day');
    }

    public static getOffSet(value: moment.Moment) {
        return value.utcOffset() / 60;
    }

    public static toDate(value: moment.Moment) {
        return moment(value).toDate();
    }

    public static getCurrentDateAtTimezone(timezone) {
        return moment.tz(timezone);
    }

    public static formatDate(
        dateString,
        format = 'DD MMM YYYY',
        isUTC = false
    ) {
        const date = DateMoment.date(dateString, isUTC);
        if (date && date.isValid()) {
            return date.format(format);
        }
        return dateString;
    }

    public static dateDiff(
        startDate,
        endDate,
        isMomentObject = false,
        unit = 'milliseconds'
    ) {
        if (!isMomentObject) {
            startDate = DateMoment.date(startDate);
            endDate = DateMoment.date(endDate);
        }

        return endDate.diff(startDate, unit);
    }

    public static setDefaultTimezone(timezone?) {
        if (timezone) {
            moment.tz.setDefault(timezone);

            return timezone;
        } else {
            moment.tz.setDefault(moment.tz.guess());
            return moment.tz.guess();
        }
    }

    public static getCurrentTimeZone() {
        return moment().tz();
    }

    public static getTime(value: moment.Moment, type: string) {
        switch (type) {
            case 'date':
                return moment(value).date();
            case 'day':
                return moment(value).day();
            case 'month':
                return moment(value).month();
            case 'year':
                return moment(value).year();
            default:
                break;
        }
    }

    public static date(value?, utc = false) {
        let date;

        if (value) {
            if (utc) {
                date = moment.utc(value);
            } else {
                if (isNaN(value)) {
                    date = moment(value);
                } else {
                    date = moment(+value);
                }
            }
        }

        if (!date || !date.isValid()) {
            return value;
        }

        return date;
    }

    public static formatDateForContractList(dateString) {
        const date = DateMoment.date(dateString);
        if (!date || !date.isValid()) {
            return dateString;
        }
        const currentTime = moment();
        const today = currentTime.clone();
        const yesterday = today.clone().subtract(1, 'days');
        const tomorrow = today.clone().add(1, 'days');
        if (date.isSame(today, 'day')) {
            return 'Today';
        } else if (date.isSame(yesterday, 'day')) {
            return 'Yesterday';
        } else if (date.isSame(tomorrow, 'day')) {
            return 'Tomorrow';
        }

        return date.format('DD MMM YYYY');
    }

    public static fromNow(value) {
        if (!moment.isMoment(value)) {
            value = this.date(value);
        }
        if (this.dateDiff(value, this.current(), true, 'day') < 2) {
            return value.fromNow();
        } else {
            return value.format('DD-MM-YYYY HH:mm');
        }
    }

    public static toNow(value) {
        if (!moment.isMoment(value)) {
            value = this.date(value);
        }

        return value.toNow(true);
    }

    public static getDatesBetween(startDate, endDate, unit = 'day') {
        if (!moment.isMoment(startDate) || !moment.isMoment(endDate)) {
            startDate = this.date(startDate);
            endDate = this.date(endDate);
        } else {
            // @ts-ignore
            startDate = startDate.clone().startOf(unit);
            // @ts-ignore
            endDate = endDate.clone().endOf(unit);
        }
        const dates = [];
        while (startDate.diff(endDate) < 0) {
            dates.push(startDate.clone());
            startDate.add(1, unit);
        }

        return dates;
    }

    public static countListDayOfMonths(startDate, endDate) {
        const result = [];
        let start = null;
        let end = null;
        let to = null;

        if (!moment.isMoment(startDate) || !moment.isMoment(endDate)) {
            start = this.date(startDate).startOf('day');
            end = start.clone();
            to = this.date(endDate);
        } else {
            start = startDate.clone().startOf('day');
            end = start.clone();
            to = endDate.clone();
        }

        while (start.isSameOrBefore(to)) {
            const endOfCurMonth = start.clone().endOf('month');

            end = endOfCurMonth.isSameOrBefore(to)
                ? endOfCurMonth
                : to.endOf('day');

            result.push({
                period: `${start.format('DD')} - ${end.format('DD MMMM YYYY')}`,
                start: start.clone(),
                end: end.clone(),
                month: start.month(),
            });
            start = endOfCurMonth.add(1, 'millisecond');
        }
        return result;
    }


    public static isFormatted(input): boolean {
        return moment.isMoment(input);
    }

    public static createInstance(input?: moment.MomentInput, format?: moment.MomentFormatSpecification): moment.Moment {
        return moment(input, format);
    }

    public static isValid(input: moment.MomentInput, format: moment.MomentFormatSpecification): boolean {
        return DateMoment.createInstance(input, format).isValid();
    }

    static of(value?: string | Date | number): moment.Moment {
        if (typeof value === 'number') {
            return moment.unix(value);
        }
        return moment(value);
    }

    static now(): moment.Moment {
        return moment();
    }

    static inFormat(value: string, format: string) {
        const date = moment(value, format);
        return date.isValid() ? date : null;
    }


    static toISOString(input: any) {
        if (!DateMoment.isFormatted(input)) {
            throw new Error('input must be moment');
        }
        return input.toISOString();
    }
}
