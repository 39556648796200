import { BaseModel } from '@app/core/models/base.model';
import { Exclude, Expose } from 'class-transformer';
export interface IOneMapResultInput {
    ADDRESS: string;
    BLK_NO: string;
    BUILDING: string;
    POSTAL: string;
    ROAD_NAME: string;
    SEARCHVAL: string;
}
export interface IOneMapResult {
    address: string;
    blkNo: string;
    building: string;
    postal: string;
    roadName: string;
    searchVal: string;
}
@Exclude()
export class OneMapResultModel extends BaseModel implements IOneMapResult {
    @Expose({name:'POSTAL'})
    postal:string
    @Expose({name:'ADDRESS'})
    address: string;
    @Expose({name:'BLK_NO'})
    blkNo: string;
    @Expose({name:'BUILDING'})
    building: string;
    @Expose({name:'ROAD_NAME'})
    roadName: string;
    @Expose({name:'SEARCHVAL'})
    searchVal: string;
}