import { Transform } from 'class-transformer';
import { isObject } from 'lodash-es';

export function PhoneNumber() {
  return Transform((val,obj) => {
    if(val && isObject(val) && val['phoneNumber']){
        return val
    }
    return undefined
  });
}
