import { AttachmentInterface, AttachmentModel } from './attachment.model';
import { BaseModel } from '@core/models/base.model';
import { Exclude, Expose, Type } from 'class-transformer';
import { Default } from '@app/core/decorators/default.decorator';
import { DocumentESigningModel } from '@shared/models/metadata.model';

export interface ParticipantMetadataInterface {
    emailSubject?: string;
    useCustomeSubject?: boolean;
    emailBody?: string;
    useCustomeBody?: boolean;
    contactId?: string;
    requireOTP?: boolean;
    requireForwarderOTP?: boolean;
    refClientId?: number;
    forwardedBy?: {
        id: string;
        email: string;
        markupId: string;
        name: string
    };

    forwardFields?: string[];
    channelConfigs?:{userId:string;channelId:string}
}

export class ParticipantMetadataModel extends BaseModel implements ParticipantMetadataInterface {
    @Expose()
    @Default('')
    emailSubject: string;

    @Expose()
    @Default(false)
    useCustomeSubject: boolean;

    @Expose()
    @Default('')
    emailBody: string;

    @Expose()
    @Default(false)
    useCustomeBody: boolean;
    @Expose()
    contactId: string;

    @Expose()
    requireOTP: boolean;

    @Expose()
    requireForwarderOTP: boolean;

    @Expose()
    refClientId: number;

    @Expose()
    eSigning: DocumentESigningModel;

    @Expose()
    forwardedBy?: {
        id: string;
        email: string;
        markupId: string;
        name: string
    };
    @Expose()
    forwardFields?: string[];

    @Expose()
    channelConfigs:{userId:string;channelId:string}

}

export interface ParticipantInterface {
    markupId?: string;
    // firstName: string;
    // lastName: string;
    email?: string;
    role?: string;
    status?: string;
    name?: string;
    data?: string;
    phone?: string;
    priority?: number;
    attachments?: AttachmentInterface[];
    metadata?: ParticipantMetadataInterface;
    id?: number;
}

export class ParticipantModel extends BaseModel implements ParticipantInterface {
    @Expose()
    id: number;
    @Expose()
    markupId: string;
    @Expose()
    name: string;
    // @Expose()
    // firstName: string;
    // @Expose()
    // lastName: string;
    @Expose()
    @Default('')
    email: string;

    @Expose()
    status: string; // pending, processing
    @Expose()
    phone: string;

    @Expose()
    @Default({
        emailSubject: '',
        emailBody: ''
    })
    @Type(() => ParticipantMetadataModel)
    @Default({})
    metadata: ParticipantMetadataModel;

    @Expose()
    @Default('signer')
    role: string;

    @Expose()
    data: string;

    @Expose()
    @Type(() => AttachmentModel)
    attachments: AttachmentModel[];

    @Expose()
    @Default(1)
    priority: number;

    clientActionAt: string;

    get empty(): boolean {
        return !!(this.name && this.email);
    }
    get isForwardedRecipient():boolean{
        return !!this.metadata.forwardedBy
    }
    get defaultAvatar(): string {
        const strName = this.name.split(' ');
        let i = 0;
        let avatar = '';
        while (i < 2 && strName[i]) {
            avatar += strName[i][0];
            i++;
        }
        return avatar;
    }
    isEqual(data:Partial<ParticipantInterface>):boolean{
        if(data.markupId && data.markupId === this.markupId){
            return true
        }
        if(data.name === this.name && data.email === this.email){
            return true
        }
        return false
    }
    static toJsonForClone(data: ParticipantModel): any {
        return {
            markupId: data.markupId,
            metadata: {
                contactId: data.metadata?.contactId,
                refClientId: data.metadata?.refClientId,
                emailSubject: data.metadata?.emailSubject,
                useCustomeSubject: data.metadata?.useCustomeSubject,
                emailBody: data.metadata?.emailBody,
                useCustomeBody: data.metadata?.useCustomeBody,
                forwardedBy: data.metadata?.forwardedBy,
                forwardFields: data.metadata?.forwardFields,
            },
            name: data.name,
            email: data.email,
            role: data.role,
            priority: data.priority,
        };

    }




}


