import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { Injectable } from '@angular/core';
import { AppConstant } from '@app/app.constant';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

@Injectable({
    providedIn: 'root'
})
export class LoggerService {
    sentry: any;
    appInsights: ApplicationInsights;

    constructor() {
        if (AppConstant.APP_LOGGER.sentryLog.enable) {
            this.sentry = true;
            Sentry.init({
                enabled: AppConstant.APP_LOGGER.sentryLog.enable,
                environment: AppConstant.APP_LOGGER.sentryLog.environment,
                dsn: AppConstant.APP_LOGGER.sentryLog.dns,
                integrations: [
                    new Integrations.BrowserTracing({
                        routingInstrumentation: Sentry.routingInstrumentation,
                    }),
                ],
                tracesSampleRate: 1.0,
            });
        }
        if (AppConstant.APP_LOGGER.appInsights.enable) {
            this.appInsights = new ApplicationInsights({
                config: {
                    instrumentationKey: AppConstant.APP_LOGGER.appInsights.instrumentationKey,
                    enableAutoRouteTracking: true,
                    autoTrackPageVisitTime: true,
                    appId: AppConstant.APP_ID
                }
            });
            this.appInsights.loadAppInsights();
        }
    }

    error(error) {
        if (this.appInsights) {
            this.appInsights.trackException({exception: error, severityLevel: 3});
        }
        if (this.sentry) {
            Sentry.captureException(error);
        }
    }
}
