export interface FileUploaderInterface {
    id: number;
    isPreview: boolean;
    file?: File | any;
    uploading: boolean;
    error: {
        status: boolean;
        message: string;
    };
    key: string;
    fullKeyName?: string;
    data: string;
    url?: string;
    completed?: boolean;
    isExistFile: boolean;
}

export class FileUploaderModel implements FileUploaderInterface {
    id: number;
    isPreview = false;
    file: File | any;
    uploading = false;
    error = {
        status: false,
        message: ''
    };
    key: string;
    fullKeyName?: string;
    data: string;
    url?: string;
    completed = false;
    isExistFile = false;
}

