<table mat-table [dataSource]="dataSource" matSort class="small-table">

    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>  {{ 'common.title' | translate }} </th>
        <td mat-cell *matCellDef="let element">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                <div class="icon-attachment">
                    <mat-icon>insert_drive_file</mat-icon>
                </div>
                <app-text-truncate style="width: 90%;">{{element.name}}</app-text-truncate>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="contentType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>  {{ 'common.type' | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.contentType}} </td>
    </ng-container>

    <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>  {{ 'common.date' | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.createdAt | datetime: formatDate}} </td>
    </ng-container>

    <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> {{ 'common.action' | translate }} </th>
        <td mat-cell *matCellDef="let element">
            <button mat-icon-button>
                <mat-icon aria-hidden="true">more_horiz</mat-icon>
            </button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
