import { CommonModule } from "@angular/common";
import { QuickActionComponent } from "./quick-action.component";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { QuickActionService } from "./quick-action.service";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { QuickActionMenuComponent } from "./quick-action-menu/quick-action-menu.component";
import { DocumentUploadAndSignDialogModule } from "@app/modules/document/document-upload-and-sign-dialog/document-upload-and-sign-dialog.module";
import { SelectTemplateDialogModule } from "@app/modules/document/select-template/select-template.module";

@NgModule({
    imports: [
      CommonModule,
      MatButtonModule,
      MatIconModule,
      MatMenuModule,
      MatBottomSheetModule,
      DocumentUploadAndSignDialogModule,
      SelectTemplateDialogModule,
    ],
    declarations: [QuickActionComponent,QuickActionMenuComponent],
    exports: [
        QuickActionComponent
    ],
    providers: [
    ],
  })
  export class QuickActionModule {
    static forRoot():ModuleWithProviders<QuickActionModule>{
        return {
            ngModule:QuickActionModule,
            providers:[
                
            ]
        }
    }
  }
  