import { DynamicFormLayoutType } from '../types/dynamic-form-layout.type';

export interface DynamicFormLayoutInterface {
    type?: DynamicFormLayoutType;
    key: string;
    label: string;
    elements?: DynamicFormLayout[];
    scope?: string;
    isDeleted?: boolean;
}
export class DynamicFormLayout {
    type: DynamicFormLayoutType;
    key: string;
    label: string;
    elements: DynamicFormLayout[];
    scope: string;
    isDeleted: boolean;

    constructor(option: DynamicFormLayoutInterface) {
        if(!option){
            return
        }
        this.key = option.key;
        this.label = option.label;
        this.isDeleted = !!option.isDeleted;
    }
    get isControl(){
        return this.type === 'control'
    }
    get isGroup(){
        return this.type === 'group'
    }
}

export class DynamicFormGroupLayout extends DynamicFormLayout {
    elements: DynamicFormLayout[];
    constructor(option: DynamicFormLayoutInterface) {
        super(option);
        if(!option){
            return
        }
        this.type = 'group';
        if (option.elements && Array.isArray(option.elements)) {
            this.elements = option.elements.map(element => new DynamicFormLayout(element));
        }
    }
}

export class DynamicFormControlLayout extends DynamicFormLayout {
    scope: string;
    constructor(option: DynamicFormLayoutInterface) {
        super(option);
        if(!option){
            return
        }
        this.type = 'control';
        this.scope = option.scope;
    }
}
