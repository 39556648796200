import { Default } from '@app/core/decorators/default.decorator';
import { BaseModel } from '@core/models/base.model';
import { Expose, Exclude, Type, } from 'class-transformer';
export interface SettingEntityInterface {
    key: string;
    mappingKey: string | string[];
    name: string;
    path: string;
    type: string;
    mappingData?: any[];
    readonly: boolean;
}

@Exclude()
export class SettingEntityModel extends BaseModel implements SettingEntityInterface {
    @Expose()
    key: string;
    @Expose()
    mappingKey: string | string[];

    @Expose()
    name: string;
    @Expose()
    path: string;
    @Expose()
    type: string;
    @Expose()
    section: {
        name: string;
        object?: string;
        inputFormat?: string
    };

    @Expose()
    @Default(false)
    readonly: boolean;

}


export interface ParticipantInnerEntityInterface {
    key: string;
    name: string;
    path?: string;
    role: string;
    enable: boolean;
}

@Exclude()
export class ParticipantInnerEntityModel extends BaseModel implements ParticipantInnerEntityInterface {
    @Expose()
    key: string;
    @Expose()
    name: string;
    @Expose()
    path?: string;
    @Expose()
    role: string;

    @Expose()
    conditions: { [x: string]: any };

    @Expose()
    enable: boolean;
}
export interface ParticipantOuterEntityInterface {
    userId: number;
    role: string;
    conditions: { [x: string]: any };
}

@Exclude()
export class ParticipantOuterEntityModel extends BaseModel implements ParticipantOuterEntityInterface {
    @Expose()
    userId: number;
    @Expose()
    role: string;
    @Expose()
    conditions: { [x: string]: any };

}
export interface ParticipantSettingEntityInterface {
    inner: ParticipantInnerEntityInterface[];
    outer: ParticipantOuterEntityInterface[];
}

@Exclude()
export class ParticipantSettingEntityModel extends BaseModel implements ParticipantSettingEntityInterface {
    @Expose()
    @Default([])
    @Type(() => ParticipantInnerEntityModel)
    inner: ParticipantInnerEntityModel[];

    @Expose()
    @Default([])
    @Type(() => ParticipantOuterEntityModel)
    outer: ParticipantOuterEntityModel[];
}
