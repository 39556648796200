import {
    Inject,
    Injectable,
    Optional,
    Renderer2,
    RendererFactory2,
} from '@angular/core';
import { Observable, bindCallback, defer, iif, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { GRECAPTCHA_SITE_KEY } from './grecaptcha.token';

@Injectable()
export class GrecaptchaService {
    private readonly renderer: Renderer2;
    grecaptcha
    constructor(
        @Optional() @Inject(GRECAPTCHA_SITE_KEY) private siteKey: string,
        private rendererFactory: RendererFactory2
    ) {
        // Get an instance of Renderer2
        this.renderer = this.rendererFactory.createRenderer(null, null);
        // this.embeddedScript()
    }

    embeddedScript(): void {
        if (!this.siteKey) {
            return;
        }
        const script = this.renderer.createElement('script');
        script.type = 'text/javascript';
        script.src = `https://www.google.com/recaptcha/enterprise.js?render=${this.siteKey}`;
        this.renderer.appendChild(document.head, script);
    }

    recaptchaToken(
        action: string = 'deafult_action'
    ): Observable<string | null> {
        if (!this.siteKey) {
            return of(null);
        }
        
        const grecaptcha = window['grecaptcha'];
        if (!grecaptcha || !grecaptcha?.enterprise) {
            return throwError({
                error: {
                    errorCode: 'RECAPTCHA_NOT_DEFINED',
                    message: 'ReCaptcha is not defined',
                },
            });
        }
        const readyCallback = (cbFn) =>  grecaptcha.enterprise.ready( () => {
            console.log('hello')
            cbFn()
        })
        const cb = bindCallback( readyCallback) 
        return (cb() as Observable<any>)
       .pipe(
        switchMap(() => {
            return defer( async ()=> {
                return await grecaptcha.enterprise.execute(this.siteKey, { action })
            })
        }),
            catchError((err) =>
                throwError({
                    error: {
                        errorCode: 'RECAPTCHA_NOT_EXECUTED',
                        message: err,
                    },
                })
            )
        );
    }
}
