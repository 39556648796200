import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { Highlightable } from '@angular/cdk/a11y';
import { CountryModel } from '@app/core/models/country.model';

@Component({
    selector: 'app-country-item',
    templateUrl: './country-item.component.html',
    styleUrls: ['./country-item.component.scss']
})
export class CountryItemComponent implements Highlightable {
    @Input() item: CountryModel;
    @Input() disabled = false;
    private actived = false;

    @HostBinding('class.active') get isActive() {
        return this.actived;
    }

    setActiveStyles() {
        this.actived = true;
    }

    setInactiveStyles() {
        this.actived = false;
    }

    getLabel() {
        return this.item.name;
    }

}
