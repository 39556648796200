import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface RatingControlInterface {
    selected: boolean;
    active: boolean;
    click: (e) => void;
    enter: () => void;
}

@Component({
    selector: 'app-rating-bar',
    templateUrl: './rating-bar.component.html',
    styleUrls: ['./rating-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: RatingBarComponent
        },
    ]
})
export class RatingBarComponent implements OnInit, ControlValueAccessor {
    rate = 0;
    disabled = false;
    max = 5;
    controls: RatingControlInterface[] = [];
    nextRate: number;


    @Input() readOnly = false;
    @Input() required = false;
    /* Event fired when a user is hovering over a given rating. */
    @Output() hover = new EventEmitter<number>();
    /* Event fired when a user stops hovering over a given rating. */
    @Output() leave = new EventEmitter<number>();
    /* Event fired when the rating value which is changed. */
    @Output() rateChange = new EventEmitter<number>(true);


    constructor(
        private changeDetectorRef: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this.controls = Array.from({ length: this.max }, (context, i) => ({
            selected: false,
            active: false,
            click: (e) => this.handleClick(e, i + 1),
            enter: () => this.handleEnter(i + 1)
        }));

        this.updateState(this.rate);
    }

    writeValue(rate: number): void {
        this.rate = rate;
        this.update(rate, false);

        this.changeDetectorRef.markForCheck();
    }

    onChange = (_: any) => {
    }
    onTouched = () => {
    }

    registerOnChange(fn: (value: any) => any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }



    update(newRate: number, internalChange = true): void {
        if (!this.readOnly && !this.disabled && this.rate !== newRate) {
            this.rate = newRate;
            this.rateChange.emit(this.rate);
        }
        if (internalChange) {
            this.onChange(this.rate);
            this.onTouched();
        }
        this.updateState(this.rate);
    }

    /* Reset rating */
    reset(): void {
        this.leave.emit(this.nextRate);
        this.updateState(this.rate);
    }

    private updateState(nextValue): void {
        this.nextRate = nextValue - 1;
        /* Setup control for rating */
        this.controls = Array.from({ length: this.max }, (context, index) => ({
            selected: index + 1 <= nextValue,
            active: false,
            click: (e) => this.handleClick(e, index),
            enter: () => this.handleEnter(index)
        }));
    }


    private handleClick(e, value): void {
        e.preventDefault();
        e.stopPropagation();
        this.update(value + 1);
    }

    private handleEnter(index): void {
        if (!this.disabled && !this.readOnly) {
            /** Add selected class for rating hover */
            this.controls.map((context, i) => {
                context.active = i <= index;
                context.selected = false;
            });
            this.nextRate = index;
            this.hover.emit(index);
        }
    }
}
