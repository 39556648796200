import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { Observable } from 'rxjs';
import { PermissionService } from '@modules/permission/permission.service';

@Directive({
    selector: '[appPermission]'
})
export class PermissionDirective implements OnInit, OnDestroy {
    @Input() permissions: string | string[];
    @Input() preAction: Promise<any> | Observable<any> | (() => void);
    @Input() postAction: 'hide' | 'delete' | 'disable' | ((el) => void) = 'hide';
    @Input() customClasses: string | string[];
    @Output() forbidden: EventEmitter<ElementRef> = new EventEmitter();
    @Output() allowable: EventEmitter<ElementRef> = new EventEmitter();

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private permissionService: PermissionService
    ) {
    }

    ngOnInit(): void {
        this.handle();
    }

    check() {
        return this.permissionService.hasPermissions(this.permissions);
    }

    async handle() {
        await this.doPreAction();
        if (!this.check()) {
            this.forbidden.next(this.elementRef);
            this.elementRef.nativeElement.classList.add(this.customClasses);
            if (typeof this.postAction === 'string') {
                switch (this.postAction) {
                    case 'delete': {
                        this.delete();
                        break;
                    }
                    case 'disable': {
                        this.disable();
                        break;
                    }
                    case 'hide': {
                        this.hide();
                        break;
                    }
                    default: {
                        this.redirect();
                    }

                }
            } else if (typeof this.postAction === 'function') {
                this.postAction(this.elementRef);
            }
        } else {
            this.allowable.next(this.elementRef);
        }
    }

    hide() {
        this.elementRef.nativeElement.style.display = 'none';
    }

    disable() {
        this.renderer.setAttribute(this.elementRef.nativeElement, 'disabled', 'true');
        this.elementRef.nativeElement.style.cursor = 'not-allowed';
        this.elementRef.nativeElement.style.pointerEvents = 'none';
    }

    delete() {
        this.elementRef.nativeElement.remove();
    }

    redirect() {

    }

    async doPreAction() {
        if (typeof this.preAction === 'function') {
            this.preAction();
        } else if (typeof this.preAction === 'object') {
            if (this.preAction instanceof Promise) {
                await this.preAction;
            } else if (this.preAction instanceof Observable) {
                await this.preAction.toPromise();
            }
        }
    }

    ngOnDestroy(): void {
    }
}
