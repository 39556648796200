import { BaseModel } from '@app/core/models/base.model';
import { Expose } from 'class-transformer';

export interface SalespersonDataFromGovInterface {
    estateAgentLicenseNumber: string;
    estateAgentName: string;
    rank: string;
    registrationNumber: string;
    registrationStartDate: string;
    registrationEndDate: string;
    salespersonName: string;
}

export class SalespersonDataFromGovModel extends BaseModel implements SalespersonDataFromGovInterface {
    @Expose({ name: 'estate_agent_license_no' })
    estateAgentLicenseNumber: string;

    @Expose({ name: 'estate_agent_name' })
    estateAgentName: string;

    @Expose()
    rank: string;

    @Expose({ name: 'registration_no' })
    registrationNumber: string;

    @Expose({ name: 'registration_start_date' })
    registrationStartDate: string;

    @Expose({ name: 'registration_end_date' })
    registrationEndDate: string;

    @Expose({ name: 'salesperson_name' })
    salespersonName: string;
}


export class SalespersonDataModel
    extends BaseModel
    implements SalespersonDataFromGovInterface
{
    @Expose()
    estateAgentLicenseNumber: string;

    @Expose()
    estateAgentName: string;

    @Expose()
    rank: string;

    @Expose()
    registrationNumber: string;

    @Expose()
    registrationStartDate: string;

    @Expose()
    registrationEndDate: string;

    @Expose()
    salespersonName: string;
}
