import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'has'
})
export class HasPipe implements PipeTransform {

    transform(items: any[],  value: any): boolean {
        console.log('has --->',items,value)
        if (!items) { return false; }
        if (!value || value.length === 0) { return false; }
        
            return items.some(it => it === value);
        

    }

}
