import { DynamicFormFile } from './../models/dynamic-form-file.model';
import { SystemFieldMappingModel } from './../models/system-field-mapping.model';
import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { Observable, from, of, forkJoin } from 'rxjs';
import { ItemInterface, ItemModel } from '@shared/models/item.model';
import { catchError, map } from 'rxjs/operators';
import { ItemPaginationAdapter } from '@app/shared/adapters/item-pagination.adapter';
import { ItemCategoryModel } from '../models/item-category.model';
import { COUNTRIES } from '@app/core/data/countries.data';
import { CountryModel } from '@app/core/models/country.model';
import { IndustryModel } from '@app/core/models/industry.model';
import { INDUSTRIES } from '@app/core/data/industries.data';
import { SYSTEM_FIELDS } from '@app/core/data/system-field.data';
import { FormFieldBase } from '@app/core/models/form-field-base.model';
import { DynamicFormTextBox } from '../models/dynamic-form-textbox.model';
import { DynamicFormNumber } from '../models/dynamic-form-number.model';
import { DynamicFormDropDown } from '../models/dynamic-form-dropdown.model';
import { DynamicFormDateTime } from '../models/dynamic-form-datetime.model';
import { ContentBuilderFieldModel } from '../models/content-builder-field.model';
import { Helper } from '@app/core/common/helper';
import { GeneralKeyInterface, ClientRoleModel, ClientStatusModel, ClientTypeModel, DocumentStatusModel, InvoiceStatusModel, SupplierInvoiceStatusModel } from '../models/metadata.model';
import { HttpClient } from '@angular/common/http';
import { ClientModel } from '../models/client.model';
import { InvoiceMappingModel } from '../models/invoice-mapping.model';
import { AppConstant } from '@app/app.constant';
@Injectable({
    providedIn: 'root'
})
export class MetadataApiService {
    static COUNTRIES = COUNTRIES;
    static INDUSTRIES = INDUSTRIES;
    static SYSTEM_FIELDS = SYSTEM_FIELDS;

    constructor(
        private apiService: ApiService,
        private http: HttpClient
    ) { }

    getCountries(): Observable<CountryModel[]> {
        const countries = MetadataApiService.COUNTRIES.map(item => CountryModel.fromArray(item));
        return of(countries);
    }

    getIndustries(): Observable<IndustryModel[]> {
        const industries = MetadataApiService.INDUSTRIES.map(item => new IndustryModel(item));
        return of(industries);
    }

    getSystemFields(): Observable<Map<string, FormFieldBase[]>> {
        return this.apiService.get('fields').pipe(
            map((items) => {
                // items.push({
                //     controlType: 'file',
                //     fieldType: 'dynamicField',
                //     config: { defaultValue: 'https://raw.githubusercontent.com/Redocly/redoc/master/docs/images/redoc-logo.png' },
                //     isSystemField: true,
                //     key: 'systemssss_organization_businessUnit_logo',
                //     defaultValue: 'https://raw.githubusercontent.com/Redocly/redoc/master/docs/images/redoc-logo.png',
                //     type: 'image',
                //     label: 'Logo of Your Business Unit',
                //     description: '',
                //     groups: ['general'],
                //     realonly: true,
                //     section: {
                //         id: 0,
                //         order: 100,
                //         name: 'General Fields'
                //     }
                // } );
                const grouped = Helper.groupBy(items, property => property.section.name, item => {
                    item.required = true;
                    switch (item.controlType) {
                        case 'textbox':
                            return new DynamicFormTextBox(item);
                        case 'file':
                            return new DynamicFormFile(item);
                        case 'number':
                            return new DynamicFormNumber(item);
                        case 'dropdown':
                            return new DynamicFormDropDown(item);
                        case 'date':
                            return new DynamicFormDateTime(item);
                        default:
                            throw new Error(`controlType '${item.controlType}' is not support.
                            It must be textbox | number | dropdown | date`);
                    }
                });
                return grouped;
            })
        );
    }

    getClientTypes(): Observable<ClientTypeModel[]> {
        return this.http.get('assets/data/client-type.json').pipe(
            map((items: GeneralKeyInterface[]) => items.map(item => new ClientTypeModel(item)))
        );
    }

    getClientRoles(): Observable<ClientRoleModel[]> {
        return this.http.get('assets/data/client-role.json').pipe(
            map((items: GeneralKeyInterface[]) => items.map(item => new ClientRoleModel(item)))
        );
    }

    getClientStatus(): Observable<ClientStatusModel[]> {
        return this.http.get('assets/data/client-status.json').pipe(
            map((items: GeneralKeyInterface[]) => items.map(item => new ClientStatusModel(item)))
        );
    }

    getDocumentStatus(): Observable<DocumentStatusModel[]> {
        return this.http.get('assets/data/document-status.json').pipe(
            map((items: GeneralKeyInterface[]) => items.map(item => new DocumentStatusModel(item)))
        );
    }
    getInvoiceStatus(): Observable<InvoiceStatusModel[]> {
        return this.http.get('assets/data/invoice-status.json').pipe(
            map((items: GeneralKeyInterface[]) => items.map(item => new InvoiceStatusModel(item)))
        );
    }
    getSupplierStatusModel(): Observable<SupplierInvoiceStatusModel[]> {
        return this.http.get('assets/data/supplier-invoice-status.json').pipe(
            map((items: GeneralKeyInterface[]) => items.map(item => new SupplierInvoiceStatusModel(item)))
        );
    }
    getInvoiceSettingDefaults(): Observable<InvoiceMappingModel[]> {
        return this.http.get('assets/data/invoice-mapping.json').pipe(
            map((data: any[]) => data.map(item => InvoiceMappingModel.fromJson(item)))
        );
    }

    getSystemFieldMappingDefault(): Observable<SystemFieldMappingModel> {
        return this.http.get('assets/data/system-field-mapping.json').pipe(
            map((data: any) => SystemFieldMappingModel.fromJson(data))
        );
    }
}
