import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'month'
})
export class MonthPipe implements PipeTransform {
    transform(value: any): any {
        let result = '';

        switch (value) {
            case '01':
                result = 'January';
                break;
            case '02':
                result = 'February';
                break;
            case '03':
                result = 'March';
                break;
            case '04':
                result = 'April';
                break;
            case '05':
                result = 'May';
                break;
            case '06':
                result = 'June';
                break;
            case '07':
                result = 'July	';
                break;
            case '08':
                result = 'August';
                break;
            case '09':
                result = 'September';
                break;
            case '10':
                result = 'October';
                break;
            case '11':
                result = 'November';
                break;
            case '12':
                result = 'December';
                break;
        }

        return result;
    }
}


@Pipe({
    name: 'day'
})
export class DayPipe implements PipeTransform {
    transform(value: any): any {
        let result = '';

        switch (value) {
            case 0:
                result = 'Sunday';
                break;
            case 1:
                result = 'Monday';
                break;
            case 2:
                result = 'Tuesday';
                break;
            case 3:
                result = 'Wednesday';
                break;
            case 4:
                result = 'Thursday';
                break;
            case 5:
                result = 'Friday';
                break;
            case 6:
                result = 'Saturday';
                break;
        }

        return result;
    }
}


@Pipe({
    name: 'intervalPipe'
})
export class IntervalPipe implements PipeTransform {
    transform(value: any): any {
        switch (value) {
            case 'month':
                return 'monthly';
            case 'year':
                return 'yearly';
            default:
                return value;
        }
    }
}
