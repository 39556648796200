import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    Router,
    RouterStateSnapshot,
    UrlTree
} from '@angular/router';
import { PolicyService } from '@modules/permission/policy.service';
import { GlobalService } from '@core/services/global.service';
import { AuthApiService } from '@shared/services/auth-api.service';
import { Observable } from 'rxjs';
import { UserResolver } from '@core/resolvers/user.resolver';

@Injectable()
export class PolicyGuard implements CanActivate, CanActivateChild {

    constructor(
        private policyService: PolicyService,
        private globalService: GlobalService,
        private authApiService: AuthApiService,
        private userResolver: UserResolver,
        private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean | UrlTree> {
        if (!route.data || !route.data.policy) {
            return false;
        }
        return new Observable(observer => {
            this.userResolver.loadUserProfile().subscribe(() => {
                if (this.policyService.can(route.data.policy)) {
                    observer.next(true);
                    observer.complete();
                } else {
                    observer.next(this.router.createUrlTree(['403'], {skipLocationChange: true}));
                    observer.complete();
                }
            });
        });
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean | UrlTree> {
        if (!childRoute.data || !childRoute.data.policy) {
            return true;
        }
        return new Observable(observer => {
            this.userResolver.loadUserProfile().subscribe(() => {
                if (this.policyService.can(childRoute.data.policy)) {
                    observer.next(true);
                    observer.complete();
                } else {
                    observer.next(this.router.createUrlTree(['403'], {skipLocationChange: true}));
                    observer.complete();
                }
            });
        });
    }
}
