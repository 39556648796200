import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { RedocConnectService } from "@app/shared/redoc-connect/redoc-connect.service";
import { AuthApiService } from "@app/shared/services/auth-api.service";

@Component({
  selector: "app-log-out-modal",
  templateUrl: "./log-out-modal.component.html",
  styleUrls: ["./log-out-modal.component.scss"],
})
export class LogOutModalComponent implements OnInit {
  constructor(
    protected dialogRef: MatDialogRef<LogOutModalComponent>,
    private router: Router,
    private authApiService: AuthApiService,
    private redocConnect: RedocConnectService
  ) {}

  ngOnInit(): void {}

  logout(): void {
    // this.authApiService.logout().subscribe(() => { });
    this.dialogRef.close();
    this.redocConnect.logOut();
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
