import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { DateTimeFormatterService } from './datetime-formatter.service';
import { FormatDistanceToNowPipe } from './datetime-formatter.pipe';

@NgModule({
    imports: [CommonModule],
    declarations: [FormatDistanceToNowPipe],
    exports: [FormatDistanceToNowPipe],
})
export class DateTimeFormatterModule {
    static forRoot():ModuleWithProviders<DateTimeFormatterModule>{
        return {
            ngModule:DateTimeFormatterModule,
            providers:[
                DateTimeFormatterService
            ]
        }
    }
}
