import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ErrorResponseInterface } from '@app/core/models/error-response.interface';
import { GlobalService } from '@app/core/services/global.service';
import { FileUploaderModel } from '@app/modules/upload/file-uploader.model';
import { MESSAGE_TYPE } from '@app/shared/models/message.model';
import { FeedbackApiService } from '@app/shared/services/feedback-api.service';


@Component({
    selector: 'app-feedback',
    templateUrl: './feedback-modal.component.html',
    styleUrls: ['./feedback-modal.component.scss']
})
export class FeedbackModalComponent implements OnInit {
    form: FormGroup;
    attachFiles: FileUploaderModel[] = [];
    allowedMimeType = [
        'image/jpg',
        'image/jpeg',
        'image/png',
        'image/bmp',
        'image/gif',
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'text/csv',
        'text/plain',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ];

    allowExtensions = [
        'jpg', 'jpeg', 'png', 'bmp', 'gif', 'pdf', 'csv', 'doc', 'docx', 'xls', 'xlsx', 'txt'
    ];
    constructor(
        private formBuilder: FormBuilder,
        private globalService: GlobalService,
        private feedbackApiService: FeedbackApiService,
        private dialogRef: MatDialogRef<FeedbackModalComponent>,
    ) {
        this.form = this.createForm();
    }

    ngOnInit() {

    }

    createForm(): FormGroup {
        return this.formBuilder.group({
            subject: ['', [Validators.required]],
            content: ['', [Validators.required]],
        });
    }

    submit() {
        if (this.form.invalid) {
            return;
        }

        const formValue = this.form.getRawValue();
        this.feedbackApiService.send({
            subject: formValue.subject,
            content: formValue.content,
            attachFiles: this.attachFiles?.map(v => {
                const url = v.url;
                const index = url.indexOf('?se');
                return url.substr(0, index);
            })
        })
            .subscribe(data => {
                this.globalService.message.next({
                    type: MESSAGE_TYPE.success,
                    message: 'common.send_successfully'
                });
                this.dialogRef.close();
            }, (error: ErrorResponseInterface) => {
                this.globalService.message.next({
                    type: MESSAGE_TYPE.error,
                    message: error.message
                });
            });
    }

    onFinishedFile(file: FileUploaderModel) {
        if (file.completed) {
            this.attachFiles.push(file);
        } else {
            this.globalService.message.next({
                type: MESSAGE_TYPE.error,
                message: file.error.message
            });
        }
    }

    onDeleteFile(file: FileUploaderModel) {
        const index = this.attachFiles.findIndex(v => v.key === file.key);
        if (index > -1) {
            this.attachFiles.splice(index, 1);
        }
    }
}
