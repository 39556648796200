import { filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class RouterService {
    private history = [];

    constructor(private router: Router) {
    }
    loadRouting(): void {
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(({ urlAfterRedirects }: NavigationEnd) => {
                this.history = [...this.history, urlAfterRedirects];
            });
    }

    getHistory(): string[] {
        return this.history;
    }

    getPreviousUrl(): string {
        return this.history[this.history.length - 2] || null;
    }
}
