import { NgModule } from "@angular/core";
import { DatetimePickerComponent } from "./datetime-picker.component";
import { CommonModule } from "@angular/common";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
    imports: [
        CommonModule,
        NgxMaterialTimepickerModule,
        MatDatepickerModule,
        MatInputModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        TranslateModule
    ],
    declarations: [
        DatetimePickerComponent,
    ],
    exports: [
        DatetimePickerComponent
    ],
    providers: [],
})
export class DatetimePickerModule {}