import {
  compactNavigation,
  compactNavigationSeting,
  defaultNavigation,
  navigationSettings,
} from "./navigation.constant";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  NavigationItem,
  NavigationBaseService,
  NavigationGroup,
} from "./classic-layout/navigation";
// import { NavigationBaseService, NavigationGroup } from '@shared/components/layout';
import { cloneDeep } from "lodash-es";
import { Observable, of, ReplaySubject } from "rxjs";
import { tap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class NavigationService extends NavigationBaseService {
  protected readonly _compactNavigation: NavigationItem[] = compactNavigation;
  protected readonly _defaultNavigation: NavigationItem[] = defaultNavigation;

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get all navigation data
   */
  get(): Observable<NavigationGroup> {
    // Fill compact navigation children using the default navigation
    this._compactNavigation.forEach((compactNavItem) => {
      this._defaultNavigation.forEach((defaultNavItem) => {
        if (defaultNavItem.id === compactNavItem.id) {
          compactNavItem.children = cloneDeep(defaultNavItem.children);
        }
      });
    });
    return of({
      compact: cloneDeep(this._compactNavigation),
      default: cloneDeep(this._defaultNavigation),
    }).pipe(
      tap((navigation) => {
        this._navigation.next(navigation);
      })
    );
  }
}
@Injectable({
  providedIn: "root",
})
export class NavigationSettingService extends NavigationBaseService {
  protected readonly _compactNavigation: NavigationItem[] =
    compactNavigationSeting;
  protected readonly _defaultNavigation: NavigationItem[] = navigationSettings;

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get all navigation data
   */
  get(): Observable<NavigationGroup> {
    // Fill compact navigation children using the default navigation
    this._compactNavigation.forEach((compactNavItem) => {
      this._defaultNavigation.forEach((defaultNavItem) => {
        if (defaultNavItem.id === compactNavItem.id) {
          compactNavItem.children = cloneDeep(defaultNavItem.children);
        }
      });
    });
    return of({
      compact: cloneDeep(this._compactNavigation),
      default: cloneDeep(this._defaultNavigation),
    }).pipe(
      tap((navigation) => {
        this._navigation.next(navigation);
      })
    );
  }
}
