import { RedrConfig } from '../models/redr-config.model';

export const redrConfig: RedrConfig = {
    layout: {
        header: {
            show: true
        },
        sideNav: {
            show: true
        }
    }
};
