import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  QueryList,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { BooleanInput } from '@angular/cdk/coercion';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IUser, UserAdapter, USER_LOADER } from './user.service';
import { UserMenuItemDirective } from './user-menu-item.directive';

@Component({
  selector: 'red-user',
  templateUrl: './user.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'user',
})
export class UserComponent implements OnInit, OnDestroy {
  /* eslint-disable @typescript-eslint/naming-convention */
  static ngAcceptInputType_showAvatar: BooleanInput;
  /* eslint-enable @typescript-eslint/naming-convention */

  @Input() showAvatar = true;
  user: IUser | undefined;
  @ViewChild('itemContainer', { read: ViewContainerRef, static: true }) itemContainer!: ViewContainerRef;

  @ContentChildren(UserMenuItemDirective, { descendants: true, read: UserMenuItemDirective })
  get children(): QueryList<UserMenuItemDirective> | undefined {
    return this._children;
  }
  set children(value: QueryList<UserMenuItemDirective> | undefined) {
    this._children = value;
    this.renderView();
  }

  public _children?: QueryList<UserMenuItemDirective>;

  private _unsubscribeAll: Subject<void> = new Subject<void>();
  private _hasView = false;
  /**
   * Constructor
   */
  constructor(private _changeDetectorRef: ChangeDetectorRef, private _router: Router, @Inject(USER_LOADER) private _userService: UserAdapter) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to user changes
    this._userService.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe((user: IUser) => {
      this.user = user;
      if (this.children) {
        for (const child of this.children) {
          child.context = { $implicit: user };
        }
      }
      // Mark for check
      this.renderView();
    });
  }
  renderView(): void {
    if (this._hasView) {
      this.itemContainer.clear();
      this._hasView = false;
    }
    if (this.children) {
      for (const child of this.children) {
        this.itemContainer.createEmbeddedView(child.templateRef, { $implicit: this.user });
      }
      this._hasView = true;
    }
    this._changeDetectorRef.markForCheck();
  }
  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Sign out
   */
  signOut(): void {
    // this._router.navigate([AppConstant.ROUTE.LOGOUT]);
  }
}
