import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EmptyComponent } from './empty.component';

@NgModule({
    imports: [CommonModule],
    declarations: [EmptyComponent],
    exports: [EmptyComponent],
    providers: [],
})
export class EmptyComponentModule {}
