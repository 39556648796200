import { Inject, Injectable } from "@angular/core";
import { AppConstant } from "@app/app.constant";
import { DOCUMENT } from "@angular/common";
import { GlobalService } from "@core/services/global.service";
import { LocalStorageService } from "@core/services/local-storage.service";
import { RedocConnectSSOService } from "@app/shared/redoc-connect/redoc-connect-sso.service";
import { RedocConnectService } from "@app/shared/redoc-connect/redoc-connect.service";
import { Location } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class SSOAuthService {
  constructor(
    @Inject(RedocConnectSSOService)
    private ssoService: RedocConnectSSOService,
    private redocConnect: RedocConnectService,
    private globalService: GlobalService,
    private location: Location
  ) {}

  get isLoggedIn() {
    const tokenSet = this.ssoService.getTokenSet();
    if(tokenSet.isLogin){
      if(!this.ssoService.isExpired(tokenSet.refreshToken) ){       
        return true;
      }
    }
    return false
    // console.log('tokenSet',tokenSet,this.ssoService.isExpired(tokenSet.accessToken),this.ssoService.isExpired(tokenSet.refreshToken))
    // if(this.ssoService.isExpired(tokenSet.accessToken) ){
    //   if(this.canRefresh){

    //   }
    //   return false;
    // }
    // console.log('isLoggedIn',tokenSet)
    // Prevent load data in localstorage. Just get in globalService storage
    return tokenSet.isLogin;
  }
  get canRefresh(){
    const tokenSet = this.ssoService.getTokenSet();
    return this.ssoService.isExpired(tokenSet.refreshToken)
  }

  get userInfo() {
    return LocalStorageService.getUserInfo();
  }

  setUserInfo(data) {
    LocalStorageService.setUserInfo(data);
  }

  setLoggedIn(data) {
    LocalStorageService.setLogInStatus(true);
    LocalStorageService.setAccessToken(data.accessToken);
    LocalStorageService.setRefreshToken(data.refreshToken);
    this.globalService.storage.dispatch(
      AppConstant.GLOBAL_STORAGE.TOKEN,
      data.accessToken
    );
    this.globalService.storage.dispatch(
      AppConstant.GLOBAL_STORAGE.REFRESH_TOKEN,
      data.refreshToken
    );
    this.globalService.storage.dispatch(
      AppConstant.GLOBAL_STORAGE.LOGIN_STATUS,
      true
    );
  }

  logout() {
    // LocalStorageService.clear();
    // LocalStorageService.removeItem(AppConstant.LOCAL_STORAGE_KEYS.TOKEN);
    // LocalStorageService.removeItem(
    //     AppConstant.LOCAL_STORAGE_KEYS.REFRESH_TOKEN
    // );
    LocalStorageService.removeItem(AppConstant.LOCAL_STORAGE_KEYS.LOGIN_STATUS);
    this.redocConnect.cleanUp();
    this.globalService.storage.dispatch(AppConstant.GLOBAL_STORAGE.TOKEN, null);
    this.globalService.storage.dispatch(
      AppConstant.GLOBAL_STORAGE.BUSINESS_UNITS,
      null
    );
    this.globalService.storage.dispatch(
      AppConstant.GLOBAL_STORAGE.ORGANIZATION,
      null
    );
    this.globalService.storage.dispatch(
      AppConstant.GLOBAL_STORAGE.GROUPS_PERMISSIONS,
      null
    );
  }
  redirectToLogin():void{
    const currentUrl = this.location.path(true);
      const url = new URL("auth/login", window.location.origin);
      const querSearch = new URLSearchParams({ redirectUrl: currentUrl });
      url.search = querSearch.toString();
      this.redocConnect.loginWithRedirect(url.href);
}
}
