import { ChangeDetectorRef, EventEmitter, OnDestroy, Pipe, PipeTransform, ɵisPromise, ɵisObservable } from '@angular/core';
import { Unsubscribable, Subscribable } from 'rxjs';
import { NavigationItemRouterLinkService } from '../../navigation-item-route-link.service';
interface SubscriptionStrategy {
  createSubscription(async: Subscribable<any> | Promise<any>, updateLatestValue: any): Unsubscribable | Promise<any>;
  dispose(subscription: Unsubscribable | Promise<any>): void;
}

class SubscribableStrategy implements SubscriptionStrategy {
  createSubscription(async: Subscribable<any>, updateLatestValue: any): Unsubscribable {
    return async.subscribe({
      next: updateLatestValue,
      error: (e: any) => {
        throw e;
      },
    });
  }

  dispose(subscription: Unsubscribable): void {
    subscription.unsubscribe();
  }
}

class PromiseStrategy implements SubscriptionStrategy {
  createSubscription(async: Promise<any>, updateLatestValue: (v: any) => any): Promise<any> {
    return async.then(updateLatestValue, e => {
      throw e;
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispose(subscription: Promise<any>): void {}
}

const _promiseStrategy = new PromiseStrategy();
const _subscribableStrategy = new SubscribableStrategy();
@Pipe({
  name: 'navItemRouteLink',
  pure: false,
})
export class NavigationItemRouteLink implements OnDestroy, PipeTransform {
  private _ref: ChangeDetectorRef | null;
  private _latestValue: any = null;

  private _subscription: Unsubscribable | Promise<any> | null = null;
  private _linkId: string | null = null;
  private _strategy: SubscriptionStrategy | null = null;

  constructor(ref: ChangeDetectorRef, private _service: NavigationItemRouterLinkService) {
    // Assign `ref` into `this._ref` manually instead of declaring `_ref` in the constructor
    // parameter list, as the type of `this._ref` includes `null` unlike the type of `ref`.
    this._ref = ref;
  }

  ngOnDestroy(): void {
    if (this._subscription) {
      this._dispose();
    }
    // Clear the `ChangeDetectorRef` and its association with the view data, to mitigate
    // potential memory leaks in Observables that could otherwise cause the view data to
    // be retained.
    // https://github.com/angular/angular/issues/17624
    this._ref = null;
  }
  transform(linkId: string, ...args: any[]): string {

    if (!this._linkId) {
      if (linkId) {
        this._subscribe(linkId);
      }
      return this._latestValue;
    }

    if (linkId !== this._linkId) {
      this._dispose();
      return this.transform(linkId);
    }

    return this._latestValue;
  }
  private _subscribe(linkId: string): void {
    this._linkId = linkId;
    this._strategy = this._selectStrategy(this._service.onLinkUpdate());
    this._subscription = this._strategy.createSubscription(this._service.onLinkUpdate(), (fn: (link: string) => string) => this._updateLatestValue(linkId, fn));
  }

  private _selectStrategy(obj: Subscribable<any> | Promise<any> | EventEmitter<any>): SubscriptionStrategy {
    if (ɵisPromise(obj)) {
      return _promiseStrategy;
    }

    if (ɵisObservable(obj)) {
      return _subscribableStrategy;
    }

    throw new Error('can not choose Strategy');
  }
  private _dispose(): void {
    // Note: `dispose` is only called if a subscription has been initialized before, indicating
    // that `this._strategy` is also available.
    if (this._strategy && this._subscription) {
      this._strategy.dispose(this._subscription);
    }
    this._latestValue = null;
    this._subscription = null;
    this._linkId = null;
  }

  private _updateLatestValue(linkId: string, fn: (link: string) => string): void {

    if (this._linkId === linkId) {
      this._latestValue = fn(linkId);
      console.log('heelo _updateLatestValue',this._latestValue);
      // Note: `this._ref` is only cleared in `ngOnDestroy` so is known to be available when a
      // value is being updated.
      this._ref?.markForCheck();
    }
  }
}
