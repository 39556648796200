import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-what-new-modal',
    templateUrl: './what-new-modal.component.html',
    styleUrls: ['./what-new-modal.component.scss']
})
export class WhatNewModalComponent implements OnInit {
    listNewFeature = [
        'New Updated Document and Template Editor',
        'New Pricebook UI',
        'More Templates Added',
        'New Feature: Group & Team Members'
    ];

    constructor(
        protected dialogRef: MatDialogRef<WhatNewModalComponent>,
    ) {

    }

    ngOnInit() { }

    close() {
        this.dialogRef.close();
    }
}
