import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaymentMethodModel } from '../models/payment.model';
import { ApiOptionInterface } from '@app/core/models/api-option.interface';

@Injectable({
    providedIn: 'root',
})
export class PaymentApiService {
    // old version
    static PAYMENT_METHOD = 'payments/methods';
    // end old version

    static PAYMENT = 'payments';
    static PAYMENT_ITEM = 'payment-items';
    static PAYMENT_HISTORIES = 'payment-histories';

    constructor(private apiService: ApiService) {}
    // old version
    add(data: { redirectUrl: string; cancelUrl?: string }) {
        return this.apiService.post(PaymentApiService.PAYMENT_METHOD, data);
    }

    getPaymentMethod(): Observable<PaymentMethodModel[]> {
        return this.apiService
            .get(PaymentApiService.PAYMENT_METHOD)
            .pipe(map((rs) => rs.map((v) => PaymentMethodModel.fromJson(v))));
    }
}
