import { Component, OnInit } from '@angular/core';
import { filter, take } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { RouterService } from '@core/services/router.service';

@Component({
    selector: 'app-forbidden-2',
    templateUrl: './forbidden-2.component.html',
    styleUrls: ['./forbidden-2.component.scss']
})
export class Forbidden2Component implements OnInit {
    ngOnInit() {
    }

}
